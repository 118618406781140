import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import MenuOptions from "components/MenuOptions/MenuOptions";
import UserService from "services/UserService";
import { IUser, IUserFilters } from "interfaces/User";
import { Color } from "enums/Common";
import Loading from "components/Loading/Loading";
import { createUrlParams, formatDate, formatName } from "helpers/CommonHelper";
import { IMenuOption } from "interfaces/Test";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import tableStyles from "styles/shared/table.module.scss";
import styles from "./UsersTable.module.scss";

interface UsersTableProps {
  filters: IUserFilters;
  setFilters: Dispatch<SetStateAction<IUserFilters>>;
  onViewProfileClick: (value: IUser) => void;
}

const tableColumns: ITableColumn[] = [
  { label: "Name", sortProperty: "last_name" },
  { label: "Email", sortProperty: "email" },
  { label: "Date added", sortProperty: "created_on" },
  { label: "Last log in", sortProperty: "last_login" },
  { label: "Role", sortProperty: "max_role_id" },
  { label: "Status", sortProperty: "is_inactive" },
  { label: "" },
];

const UsersTable = ({
  filters,
  setFilters,
  onViewProfileClick,
}: UsersTableProps) => {
  const [, setSearchParams] = useSearchParams();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();
  const { data, setData, loading, tableRef, lastElementRef } =
    useFetchTableData<IUser, IUserFilters>({
      filters,
      onPageIncrement,
      fetchAPI: UserService.getUsers,
      onError: setSnackbarError,
    });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      withInactive,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  const handleToggleActivation = useCallback(
    ({ id, isInactive }: { id: string; isInactive: boolean }) => {
      const newIsInactiveFlag = !isInactive;

      UserService.updateUser(id, { isInactive: newIsInactiveFlag })
        .then(() => {
          setData((prev) =>
            prev.map((item) =>
              item.id === id ? { ...item, isInactive: newIsInactiveFlag } : item
            )
          );

          setSnackbarSuccess(
            `User successfully ${
              newIsInactiveFlag ? "deactivated" : "activated"
            }.`
          );
        })
        .catch(() => {
          setSnackbarError();
        });
    },
    [setSnackbarSuccess, setSnackbarError, setData]
  );

  const getMenuOptions = useCallback(
    ({
      id,
      isInactive,
    }: {
      id: string;
      isInactive: boolean;
    }): IMenuOption[] => {
      return [
        {
          label: isInactive ? "Activate" : "Deactivate",
          color: isInactive ? Color.Pelorous : Color.Crimson,
          onClick: () => handleToggleActivation({ id, isInactive }),
        },
      ];
    },
    [handleToggleActivation]
  );

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={tableStyles.shared_table_container}
          ref={tableRef}
        >
          <Table className={styles.users_table}>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((user, index) => {
                const {
                  id,
                  email,
                  firstName,
                  lastName,
                  middleName,
                  createdOn,
                  lastLogin,
                  maxRoleName,
                  isInactive,
                } = user;

                return (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell>
                      {formatName({ firstName, lastName, middleName })}
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{formatDate(createdOn)}</TableCell>
                    <TableCell>
                      {lastLogin ? formatDate(lastLogin) : "-"}
                    </TableCell>
                    <TableCell>{maxRoleName || "-"}</TableCell>
                    <TableCell>{isInactive ? "Inactive" : "Active"}</TableCell>
                    <TableCell className="w-[170px]">
                      <div className="flex justify-between items-center gap-7 w-[130px]">
                        <button
                          className="text-pelorous hover:underline"
                          onClick={() => onViewProfileClick(user)}
                        >
                          View Profile
                        </button>
                        <MenuOptions
                          options={getMenuOptions({ id, isInactive })}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Loading loading={loading} />

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={filters["email|name"] ? "icon_search" : "icon_no_results"}
              message={
                filters["email|name"]
                  ? "No results found for"
                  : "No existing users."
              }
              search={filters["email|name"]}
            />
          </div>
        )}
      </div>

      {snackbarComponent}
    </>
  );
};

export default UsersTable;
