import { ButtonHTMLAttributes } from "react";

const ButtonRunReport = ({
  className,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...rest}
      className={`rounded-3xl bg-tropicalRainForest px-5 py-1.5 text-base text-white font-medium ${
        className ? className : ""
      }`}
    >
      View Report
    </button>
  );
};

export default ButtonRunReport;
