import { generatePath } from "react-router-dom";
import { AxiosResponse } from "axios";
import axios from "config/axios";
import { API } from "enums/API";
import {
  IHistoryTest,
  ITestHistoryFilters,
  ITestNote,
  ITestNoteFilters,
  IUsageParams,
} from "interfaces/Test";
import { ITrendParams } from "interfaces/Patient";

class TestService {
  getTestHistory = (
    params: Partial<ITestHistoryFilters>,
    signal?: AbortSignal
  ): Promise<AxiosResponse<IHistoryTest[]>> => {
    return axios.get(API.TestHistory, { params, signal });
  };

  getTest = (orderItemId: string) => {
    return axios.get(generatePath(API.TestDetails, { orderItemId }));
  };

  getTrendsData = (params: ITrendParams, signal?: AbortSignal) => {
    return axios.get(API.Trends, {
      params,
      signal,
    });
  };

  getUsageData = (params: IUsageParams, signal?: AbortSignal) => {
    return axios.get(API.Usage, { params, signal });
  };

  getNotes = (
    params: ITestNoteFilters,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ITestNote[]>> => {
    return axios.get(API.TestNote, { params, signal });
  };

  createNote = (data: {
    orderItemIterationId: string;
    text: string;
    includeOnReport: boolean;
  }): Promise<AxiosResponse<ITestNote>> => {
    return axios.post(API.TestNote, data);
  };

  updateNote = (
    id: string,
    data: Pick<ITestNote, "text" | "includeOnReport">
  ) => {
    return axios.put(`${API.TestNote}/${id}`, data);
  };

  deleteNote = (id: string): Promise<AxiosResponse<void>> => {
    return axios.delete(`${API.TestNote}/${id}`);
  };
}

export default new TestService();
