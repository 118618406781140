import { useMemo, useState } from "react";
import CardContainer from "../CardContainer/CardContainer";
import { IToggleButton } from "../../interfaces/Common";
import { ToggleButtons } from "../ToggleButtons/ToggleButtons";
import GeneratedLabelsSection from "./GeneratedLabelsSection/GeneratedLabelsSection";
import ManualLabelsSection from "./ManualLabelsSection/ManualLabelsSection";
import { ISensorDetails } from "../../interfaces/Sensor";

interface ShipSensorCardProps {
  sensor: ISensorDetails;
  onCancel: () => void;
  onSuccess: () => void;
  onError: (message?: string) => void;
}

const ShipSensorCard = ({
  sensor,
  onCancel,
  onSuccess,
  onError,
}: ShipSensorCardProps) => {
  const [areLabelsGenerated, setAreLabelsGenerated] = useState<boolean>(true);

  const toggleButtons = useMemo(
    (): IToggleButton[] => [
      { label: "Generate label", onClick: () => setAreLabelsGenerated(true) },
      {
        label: "Manual entry",
        onClick: () => setAreLabelsGenerated(false),
        // disabled in first version
        disabled: true,
      },
    ],
    []
  );

  return (
    <CardContainer
      className="relative p-7 lg:grow lg:w-0 lg:max-w-[728px] lg:max-h-full lg:overflow-auto"
      childrenClassName="flex flex-col gap-5"
      title="Ship Sensor"
    >
      <ToggleButtons buttons={toggleButtons} initialActiveIndex={0} />

      {areLabelsGenerated ? (
        <GeneratedLabelsSection
          sensor={sensor}
          onCancel={onCancel}
          onSuccess={onSuccess}
          onError={onError}
        />
      ) : (
        <ManualLabelsSection onCancel={onCancel} />
      )}
    </CardContainer>
  );
};

export default ShipSensorCard;
