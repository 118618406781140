import { generatePath } from "react-router-dom";
import { AxiosResponse } from "axios";
import axios from "../config/axios";
import { API } from "../enums/API";
import { ShortlistType } from "../enums/Common";
import {
  IPatient,
  IPatientFilters,
  IPatientProgressFilters,
} from "../interfaces/Patient";

class PatientService {
  getPatients = (
    params: Partial<IPatientFilters>,
    signal?: AbortSignal
  ): Promise<AxiosResponse<IPatient[]>> => {
    return axios.get(API.PatientDirectory, { params, signal });
  };

  getPatientsWithSensor = (params: { name?: string }) => {
    return axios.get(API.PatientWithSensor, { params });
  };

  getOne = (patientId: string, signal?: AbortSignal) => {
    return axios.get(generatePath(API.PatientDetails, { patientId }), {
      signal,
    });
  };

  getOneDashboardData = (params: { id: string }, signal?: AbortSignal) => {
    return axios.get(API.PatientDashboard, { params, signal });
  };

  getActiveOrders = (params: { patientId: string }) => {
    return axios.get(API.PatientActiveOrders, { params });
  };

  getPatientProgress = (
    params: IPatientProgressFilters,
    signal?: AbortSignal
  ) => {
    return axios.get(API.PatientProgress, { params, signal });
  };

  shortlist = (itemUuid: string) => {
    return axios.post(API.Shortlist, {
      itemUuid,
      type: ShortlistType.Patient,
    });
  };

  removeShortlist = (itemUuid: string) => {
    return axios.post(API.ShortlistRemove, {
      itemUuid,
      type: ShortlistType.Patient,
    });
  };

  updatePatient = (
    patientId: string,
    data: Omit<IPatient, "id" | "address">
  ) => {
    return axios.put(generatePath(API.PatientDetails, { patientId }), data);
  };

  createPatient = (data: Omit<IPatient, "id">) => {
    return axios.post(API.Patients, data);
  };

  deletePatient = (patientId: string) => {
    return axios.delete(generatePath(API.PatientDetails, { patientId }));
  };
}

export default new PatientService();
