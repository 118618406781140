import axios from "../config/axios";
import { API } from "../enums/API";
import {
  ISingleReportsParams,
  IMultipleReportParams,
  ITrendsAndSummaryReportParams,
} from "../interfaces/Reports";

class ReportsService {
  getSingle = (params: ISingleReportsParams) => {
    return axios.get(API.ReportSingle, {
      params,
      responseType: "blob",
    });
  };

  getMultiple = (params: IMultipleReportParams) => {
    return axios.get(API.ReportMultiple, {
      params,
      responseType: "blob",
    });
  };

  getSummary = (params: ITrendsAndSummaryReportParams) => {
    return axios.get(API.ReportSummary, { params, responseType: "blob" });
  };

  getTrends = (params: ITrendsAndSummaryReportParams) => {
    return axios.get(API.ReportTrends, { params, responseType: "blob" });
  };
}

export default new ReportsService();
