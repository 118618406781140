import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { downloadFile, formatDate } from "helpers/CommonHelper";
import { IOrderAttachment } from "interfaces/Order";
import { Color } from "enums/Common";
import OrderService from "services/OrderService";

interface OrderExistingAttachmentProps {
  orderId: string;
  attachment: IOrderAttachment;
  onDownloaded: (message: string) => void;
  onDownloadFailed: () => void;
}

const OrderExistingAttachment = (props: OrderExistingAttachmentProps) => {
  const { orderId, attachment, onDownloaded, onDownloadFailed } = props;
  const { id, name, description, createdOn, mimeType } = attachment;

  const [downloading, setDownloading] = useState<boolean>(false);

  const handleDownloadAttachment = (attachmentId: string) => {
    setDownloading(true);

    OrderService.downloadAttachment({ orderId, attachmentId })
      .then(({ request }) => {
        const file = new File([request.response], name, { type: mimeType });
        const url = URL.createObjectURL(file);

        downloadFile(url, name);
        onDownloaded("Attachment has been downloaded successfully.");
      })
      .catch(() => onDownloadFailed())
      .finally(() => setDownloading(false));
  };

  return (
    <div className="text-cloudBurst text-base leading-5 flex flex-col items-start gap-[5px]">
      <p>
        File Name: <span className="text-pelorous">{name}</span>
      </p>
      {description && (
        <p>
          Description: <span className="text-pelorous">{description}</span>
        </p>
      )}
      <p>
        Date Added:{" "}
        <span className="text-pelorous">{formatDate(createdOn)}</span>
      </p>
      <button
        className="text-pelorous text-lg leading-5 font-medium flex items-center gap-2.5 disabled:opacity-50"
        disabled={downloading}
        onClick={() => handleDownloadAttachment(id)}
      >
        View Attachment
        {downloading && (
          <CircularProgress size={18} style={{ color: Color.Pelorous }} />
        )}
      </button>
    </div>
  );
};

export default OrderExistingAttachment;
