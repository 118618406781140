import { useEffect, useMemo, useState } from "react";
import { Outlet, generatePath, useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Navbar from "../../Navbar/Navbar";
import { INavItem } from "../../../interfaces/Common";
import { URLRoutes } from "../../../enums/Routing";
import { IPatient } from "../../../interfaces/Patient";
import PatientService from "../../../services/PatientService";
import useSnackbar from "../../../hooks/useSnackbar";
import { getPatientInfoLabel } from "../../../helpers/PatientHelper";

const PatientOperationsActivityLayout = () => {
  const { patientId } = useParams();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [patient, setPatient] = useState<IPatient | undefined>(undefined);

  const patientInfoLabel = useMemo(
    (): string | undefined => getPatientInfoLabel(patient),
    [patient]
  );

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      {
        label: "Patients",
        to: URLRoutes.PatientsOperations,
      },
      {
        label: patientInfoLabel ?? "",
        to: "#",
      },
    ],
    [patientInfoLabel]
  );

  const navbarItems = useMemo(
    (): INavItem[] => [
      {
        to: generatePath(URLRoutes.PatientOperationsAllActivity, {
          patientId: patientId ?? "",
        }),
        label: "All Activity",
      },
      {
        to: generatePath(URLRoutes.PatientOperationsAttachments, {
          patientId: patientId ?? "",
        }),
        label: "Attachments",
      },
    ],
    [patientId]
  );

  useEffect(() => {
    if (!patientId) return;

    const controller = new AbortController();

    PatientService.getOne(patientId, controller.signal)
      .then(({ data }) => setPatient(data))
      .catch((e) => {
        if (axios.isCancel(e)) return;

        setSnackbarError();
      });

    return () => controller.abort();
  }, [patientId, setSnackbarError]);

  return (
    <div className="h-full flex flex-col gap-4">
      <Breadcrumbs items={breadcrumbsItems} />

      <Navbar
        items={navbarItems}
        drawerTitle={"Patient Operations"}
        drawerLabel={patientInfoLabel}
      />

      <div className="grow h-0">
        <Outlet />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default PatientOperationsActivityLayout;
