import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import {
  formatAnglesAvgLeftRight,
  formatTargetRange,
} from "../../../../../helpers/TestHelper";
import {
  IFormatAnglesParams,
  ITestDetails,
} from "../../../../../interfaces/Test";
import TestResultsTableContainer from "../../../../Tables/TestResultsTableContainer/TestResultsTableContainer";

const AnglesTestResults = (props: ITestDetails) => {
  const { test } = props;

  const anglesMetricsData = useMemo(() => {
    const params: IFormatAnglesParams = {
      side: test.side,
      state: test.state,
      metric: test.orderIterations[0].metric,
    };

    return [
      {
        label: "Avg Left",
        value: formatAnglesAvgLeftRight(params)?.avgLeft,
      },
      {
        label: "Avg Right",
        value: formatAnglesAvgLeftRight(params)?.avgRight,
      },
      {
        label: "Start Angle",
        value: formatAnglesAvgLeftRight(params)?.startAngle,
      },
      { label: "Target Range", value: formatTargetRange(test) },
    ];
  }, [test]);

  return (
    <div className="flex items-center justify-between gap-6 overflow-auto">
      {anglesMetricsData.map(({ label, value }, index) => (
        <div className="w-fit" key={index}>
          <TestResultsTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{label}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{value}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TestResultsTableContainer>
        </div>
      ))}
    </div>
  );
};

export default AnglesTestResults;
