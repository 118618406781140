import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactElement, ReactNode } from "react";
import { Color } from "../../enums/Common";
import { hexAndAlphaToRGBA } from "../../helpers/CommonHelper";

type TextAlign = "left" | "center" | "right" | "justify";

interface ITooltipBox {
  content: ReactNode;
  children: ReactElement;
  offsetX?: number;
  offsetY?: number;
  maxWidth?: number;
  textAlign?: TextAlign;
}

const TooltipBox = (props: ITooltipBox) => {
  const {
    content,
    children,
    offsetX = 0,
    offsetY = 0,
    maxWidth,
    textAlign = "center",
  } = props;

  const TooltipBox = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Color.Endeavour,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: Color.Endeavour,
      fontSize: "14px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginTop: "-5px !important",
      color: Color.White,
      textAlign,
      fontFamily: "Cabin",
      fontWeight: "500",
      boxShadow: `0px 4px 6px 0px ${hexAndAlphaToRGBA(Color.Black, 0.18)}`,
      maxWidth,
    },
  }));

  return (
    <TooltipBox
      title={content}
      placement={"bottom-start"}
      PopperProps={{
        modifiers: [
          { name: "offset", options: { offset: [offsetX, offsetY] } },
        ],
      }}
    >
      {children}
    </TooltipBox>
  );
};

export default TooltipBox;
