import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { debounce } from "lodash";
import isNumeric from "validator/lib/isNumeric";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { DebounceWait } from "enums/Common";
import styles from "./TableInputFilter.module.scss";

type TableInputFilterProps = Omit<TextFieldProps, "onChange"> & {
  isNumericField?: boolean;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

const TableInputFilter = (props: TableInputFilterProps) => {
  const { isNumericField, className, placeholder, value, onChange, ...rest } =
    props;

  const [enteredValue, setEnteredValue] = useState<string | undefined>(value);

  const handleReset = (e: MouseEvent) => {
    e.stopPropagation();
    onChange(undefined);
    setEnteredValue(undefined);
  };

  const debouncedChange = useMemo(
    () =>
      debounce(
        (value: string | undefined) => onChange(value),
        DebounceWait.Input
      ),
    [onChange]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? e.target.value : undefined;

    if (isNumericField && value && !isNumeric(value)) return;

    setEnteredValue(value);
    debouncedChange(value);
  };

  return (
    <TextField
      className={`${styles.input_field} ${!enteredValue ? styles.empty : ""} ${
        className ?? ""
      }`}
      variant="standard"
      autoComplete="off"
      placeholder={placeholder}
      value={enteredValue ?? ""}
      onChange={handleChange}
      InputProps={{
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton onClick={handleReset}>
              <span className="icon_svg icon_cross" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default TableInputFilter;
