import { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { ValidationError } from "../../enums/Common";
import { ValidationErrorChangeEvent } from "../../interfaces/Common";
import styles from "./DatePickerInput.module.scss";

interface IDatePicker {
  label?: string;
  name?: string;
  required?: boolean;
  value?: string | null;
  onChange: (value: string | null) => void;
  onValidationErrorChange?: (e: ValidationErrorChangeEvent) => void;
}

const DatePickerInput = ({
  label,
  name,
  required,
  value,
  onChange,
  onValidationErrorChange,
}: IDatePicker) => {
  const [invalidFormat, setInvalidFormat] = useState<boolean>(false);

  const validationError = useMemo((): ValidationError | undefined => {
    switch (true) {
      case required && value === undefined:
        return ValidationError.DefaultEmpty;
      case required && value === null:
        return ValidationError.Empty;
      case invalidFormat:
        return ValidationError.InvalidFormat;
    }
  }, [invalidFormat, required, value]);

  const errorMessage = useMemo((): string | undefined => {
    switch (true) {
      case validationError === ValidationError.Empty:
        return "This field is required";
      case validationError === ValidationError.InvalidFormat:
        return "Invalid date format";
    }
  }, [validationError]);

  const handleChange = useCallback(
    (value: Moment | null) => {
      onChange(value ? (value as Moment).format("YYYY-MM-DD") : null);
    },
    [onChange]
  );

  useEffect(() => {
    if (!name) return;

    onValidationErrorChange?.({ error: validationError, name });
  }, [validationError, onValidationErrorChange, name]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={`flex flex-col ${styles.datepicker_input}`}>
        {label && (
          <label className="self-start text-base leading-5 text-cloudBurst font-medium">{`${label}${
            required ? " *" : ""
          }`}</label>
        )}
        <DatePicker
          disableFuture
          renderInput={(props) => (
            <TextField
              {...props}
              helperText={errorMessage}
              error={
                Boolean(validationError) &&
                validationError !== ValidationError.DefaultEmpty
              }
            />
          )}
          value={value ? moment(value) : ""}
          onChange={handleChange}
          onError={(error) => setInvalidFormat(error !== null)}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DatePickerInput;
