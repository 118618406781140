import { useCallback, useState } from "react";
import { Accordion as MUIAccordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./TestingAccordion.module.scss";
import CheckboxInput from "../../../../CheckboxInput/CheckboxInput";

interface TestingAccordionProps {
  testName: string;
  children: JSX.Element;
  selected: boolean;
  error?: boolean;
  onSelectionChange: (value: boolean) => void;
}

const TestingAccordion = ({
  testName,
  children,
  selected,
  error,
  onSelectionChange,
}: TestingAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleSelectionChange = useCallback(
    (value: boolean) => {
      onSelectionChange(value);
      // expand or collapse test on selection change
      setExpanded(value);
    },
    [onSelectionChange]
  );

  return (
    <div
      className={`relative ${styles.testing_accordion_wrapper} ${
        error ? styles.error : ""
      }`}
    >
      <MUIAccordion
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary
          expandIcon={
            <span className="icon_svg icon_navigate_arrow -rotate-90" />
          }
        >
          <CheckboxInput
            label={`${testName} testing`}
            value={selected}
            onChange={handleSelectionChange}
          />
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MUIAccordion>

      {error && (
        <p className="absolute top-0 right-0 -translate-y-full text-crimson text-base leading-5">
          Please fill out all required fields
        </p>
      )}
    </div>
  );
};

export default TestingAccordion;
