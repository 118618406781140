import { ChangeEvent, useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import CardContainer from "components/CardContainer/CardContainer";
import DebouncedAutocomplete from "components/DebouncedAutocomplete/DebouncedAutocomplete";
import { IPatient } from "interfaces/Patient";
import PatientService from "services/PatientService";
import { formatDashDate } from "helpers/CommonHelper";
import LinkButton from "components/Buttons/LinkButton/LinkButton";
import { URLRoutes } from "enums/Routing";
import Button from "components/Buttons/Button/Button";
import Property from "components/Property/Property";
import InputField from "components/InputField/InputField";
import { InputFieldLength, NPI } from "enums/Common";
import useValidationErrors from "hooks/useValidationErrors";
import {
  Billable,
  CardsValidation,
  DiagnosisCodes,
  InitialPatient,
  ModalState,
  Patient,
} from "recoil-states/create-order-states";
import { AccountAuth, Auth } from "recoil-states/auth-states";
import PayerTypeDropdown from "components/Dropdowns/PayerTypeDropdown/PayerTypeDropdown";
import { hasSuperAdminRole } from "helpers/PermissionsHelper";
import CheckboxInput from "components/CheckboxInput/CheckboxInput";
import { OrderModalState, PayerType } from "enums/Order";
import DatePickerInput from "components/DatePickerInput/DatePickerInput";
import { Billing } from "enums/Account";

interface PatientCardProps {
  isPatientPreselected: boolean;
}

const PatientCard = ({ isPatientPreselected }: PatientCardProps) => {
  const { hasErrors, handleValidationErrorChange, resetErrors } =
    useValidationErrors();

  const auth = useRecoilValue(Auth);
  const accountAuth = useRecoilValue(AccountAuth);
  const modalState = useRecoilValue(ModalState);
  const [patient, setPatient] = useRecoilState(Patient);
  const [initialPatient, setInitialPatient] = useRecoilState(InitialPatient);
  const [diagnosisCodes, setDiagnosisCodes] = useRecoilState(DiagnosisCodes);
  const [billable, setBillable] = useRecoilState(Billable);
  const setCardsValidation = useSetRecoilState(CardsValidation);

  const isCardDisabled = modalState === OrderModalState.InProgress;

  // visible only to super admin
  const showDemoUserCheckbox = hasSuperAdminRole(
    accountAuth?.initialData?.permissions ?? auth?.initialData?.permissions
  );

  // visible only to billable accounds
  const isBillableAccount =
    (accountAuth?.initialData?.billing?.billable ??
      auth?.initialData?.billing?.billable) === Billing.Billable;

  // visible only to billable accounds
  const isWorkerCompEnabled =
    (accountAuth?.initialData?.billing?.isWorkerComp ??
      auth?.initialData?.billing?.isWorkerComp) === true;

  // visible only for billable accounts with worker compensation enabled when Workers Compensation option is selected
  const showAdditionalInsuranceFields =
    isBillableAccount &&
    isWorkerCompEnabled &&
    billable === PayerType.WorkersCompensation;

  useEffect(() => {
    const validPayerType = isBillableAccount ? Boolean(billable) : true;

    setCardsValidation((prev) => ({
      ...prev,
      patient: patient ? !hasErrors && validPayerType : false,
    }));
  }, [hasErrors, patient, setCardsValidation, billable, isBillableAccount]);

  const handleInsuranceInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setPatient((prev) => ({
        ...prev!,
        meta: {
          ...prev?.meta,
          insuranceData: { ...prev?.meta?.insuranceData, [name]: value },
        },
      }));
    },
    [setPatient]
  );

  const handleChangePatientClick = () => {
    resetErrors();
    setPatient(null);
    setInitialPatient(null);
    setBillable(undefined);
    setDiagnosisCodes([]);
  };

  const handleChangePayerType = (value: PayerType) => {
    resetErrors();
    setBillable(value);
    setPatient((prev) => ({ ...prev!, meta: initialPatient?.meta }));
    setDiagnosisCodes([]);
  };

  const formatDiagnosisCodeValue = (index: number): string => {
    // show initially error only for first field
    if (index === 0) {
      return diagnosisCodes[0] ?? "";
    }

    return diagnosisCodes[index];
  };

  const handleSelectPatient = (value: IPatient | null) => {
    setPatient(value);
    setInitialPatient(value);
  };

  return (
    <CardContainer
      className="p-5 relative"
      title="Patient"
      disabled={isCardDisabled}
    >
      {patient && !isPatientPreselected && (
        <Button
          className="absolute top-5 right-5 border-2 border-pelorous !bg-white !text-pelorous"
          onClick={handleChangePatientClick}
        >
          Change Patient
        </Button>
      )}

      {!patient ? (
        <div className="flex flex-wrap gap-5 justify-between sm:items-center">
          <div className="w-[386px]">
            <DebouncedAutocomplete<IPatient>
              showEmptyError
              className="grow"
              placeholder="Search by Patient Name"
              value={patient}
              onChange={handleSelectPatient}
              getOptions={(value) =>
                PatientService.getPatients({
                  name: value,
                })
              }
              getOptionLabel={({ lastName, firstName }) =>
                `${lastName}, ${firstName}`
              }
              renderOption={(props, { id, lastName, firstName, dob }) => (
                <li {...props} key={id}>
                  <div className="flex flex-col gap-1">
                    <span className="text-pelorous ">
                      {`${lastName}, ${firstName}`}
                    </span>
                    <span>{`DOB: ${formatDashDate(dob)}`}</span>
                  </div>
                </li>
              )}
            />
          </div>

          {/* Opens patient dashboard screen with opened patient creation modal */}
          <LinkButton
            className="self-start bg-tropicalRainForest text-white border-none sm:self-auto"
            to={`${URLRoutes.PatientsDashboard}?create=1`}
            target="_blank"
          >
            Create New Patient
          </LinkButton>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          {showDemoUserCheckbox && (
            <CheckboxInput
              className="absolute top-5 left-28"
              label="Demo User"
              value={patient.meta?.isDemoUser ?? false}
              onChange={(isDemoUser) =>
                setPatient((prev) => ({
                  ...prev!,
                  meta: { ...prev?.meta, isDemoUser },
                }))
              }
            />
          )}

          <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
            <Property
              required
              property="Name"
              value={`${patient.lastName}, ${patient.firstName}`}
            />
            <Property
              required
              property="DOB"
              value={formatDashDate(patient.dob)}
            />
          </div>

          {isBillableAccount && (
            <>
              <p className="text-base font-bold leading-5">
                Patient Insurance Information
              </p>
              <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
                <PayerTypeDropdown
                  selected={billable}
                  onChange={handleChangePayerType}
                />
              </div>
            </>
          )}

          {showAdditionalInsuranceFields && (
            <>
              <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
                <InputField
                  required
                  reduceFontSizeOnError
                  label="Primary Insurance Provider"
                  placeholder="Enter insurance provider"
                  name="provider"
                  value={patient.meta?.insuranceData?.provider ?? ""}
                  onChange={handleInsuranceInputChange}
                  onValidationErrorChange={handleValidationErrorChange}
                />
                <InputField
                  required
                  reduceFontSizeOnError
                  label="Primary Insurance Policy Number"
                  placeholder="Enter policy number"
                  name="policy"
                  value={patient.meta?.insuranceData?.policy ?? ""}
                  onChange={handleInsuranceInputChange}
                  onValidationErrorChange={handleValidationErrorChange}
                />
              </div>

              <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
                <InputField
                  reduceFontSizeOnError
                  label="Secondary Insurance Provider"
                  placeholder="Enter insurance provider"
                  name="secondaryProvider"
                  value={patient.meta?.insuranceData?.secondaryProvider}
                  onChange={handleInsuranceInputChange}
                  onValidationErrorChange={handleValidationErrorChange}
                />
                <InputField
                  reduceFontSizeOnError
                  label="Secondary Insurance Policy Number"
                  placeholder="Enter policy number"
                  name="secondaryPolicy"
                  value={patient.meta?.insuranceData?.secondaryPolicy}
                  onChange={handleInsuranceInputChange}
                  onValidationErrorChange={handleValidationErrorChange}
                />
              </div>

              <InputField
                multiline
                rows={4}
                label="Insurance Notes"
                placeholder="Enter insurance notes here"
                name="notes"
                value={patient.meta?.insuranceData?.notes}
                onChange={handleInsuranceInputChange}
                onValidationErrorChange={handleValidationErrorChange}
              />

              <div className="flex flex-col gap-1">
                <label className="text-base font-medium leading-4">
                  Diagnosis Information *
                </label>
                <p className="text-sm text-pelorous leading-4">
                  Please enter at least one diagnosis code.
                </p>
                <div className="flex flex-wrap gap-5 [&>div]:w-[calc(50%-10px)] md:[&>div]:!w-[calc(25%-15px)]">
                  {Array.from(Array(4).keys()).map((i) => (
                    <InputField
                      key={i}
                      hideErrorMessage
                      required={i === 0}
                      disabled={i > 0 && diagnosisCodes.length < i}
                      maxLength={InputFieldLength.DiagnosisCodeMax}
                      placeholder={`${i + 1}.`}
                      name={`diagnosisCode${i + 1}`}
                      value={formatDiagnosisCodeValue(i)}
                      onChange={({ target: { value } }) =>
                        // push new diagnosis code if new field is entered
                        setDiagnosisCodes((prev) =>
                          prev[i] !== undefined
                            ? prev.map((x, index) =>
                                i === index ? value.toUpperCase() : x
                              )
                            : [...prev, value.toUpperCase()]
                        )
                      }
                      onBlur={() =>
                        // remove empty codes on unfocus
                        setDiagnosisCodes((prev) => prev.filter((x) => x))
                      }
                      onValidationErrorChange={handleValidationErrorChange}
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
                <InputField
                  isNumericField
                  reduceFontSizeOnError
                  type={NPI.InputType}
                  label="NPI Number"
                  placeholder="Enter NPI number"
                  name="npi"
                  value={patient.meta?.insuranceData?.npi}
                  onChange={handleInsuranceInputChange}
                  onValidationErrorChange={handleValidationErrorChange}
                />
                <DatePickerInput
                  required
                  label="Date of Injury"
                  name="injuryDate"
                  value={patient.meta?.insuranceData?.injuryDate ?? null}
                  onChange={(injuryDate) =>
                    setPatient((prev) => ({
                      ...prev!,
                      meta: {
                        ...prev?.meta,
                        insuranceData: {
                          ...prev?.meta?.insuranceData,
                          injuryDate: injuryDate ?? undefined,
                        },
                      },
                    }))
                  }
                  onValidationErrorChange={handleValidationErrorChange}
                />
              </div>
            </>
          )}
        </div>
      )}
    </CardContainer>
  );
};

export default PatientCard;
