import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { IPatient } from "interfaces/Patient";
import InputField from "components/InputField/InputField";
import Button from "components/Buttons/Button/Button";
import { DeepPartial } from "interfaces/Common";
import DatePickerInput from "components/DatePickerInput/DatePickerInput";
import useValidationErrors from "hooks/useValidationErrors";
import TooltipInfoIcon from "components/TooltipInfoIcon/TooltipInfoIcon";
import { removeNonNumericCharacters } from "helpers/PatientHelper";

const USA_COUNTRY_ID = 233;

// on create, no initial data will be passed, so we need to make all params optional
type PartialPatient = DeepPartial<IPatient>;

interface IPatientForm {
  initialData?: PartialPatient;
  onConfirm: (value: IPatient) => void;
  onCancel?: () => void;
}

enum NestedProp {
  Address = 1,
  Insurance = 2,
}

const PatientForm = ({
  initialData = {},
  onConfirm,
  onCancel,
}: IPatientForm) => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const [data, setData] = useState<PartialPatient>({
    ...initialData,
    // hardcode country, since we don't have option to select country trough UI
    address: { ...initialData.address, countryId: USA_COUNTRY_ID },
  });

  const editMode = useMemo(
    (): boolean => Boolean(Object.keys(initialData).length),
    [initialData]
  );

  const noChangedData = useMemo(
    (): boolean => isEqual(initialData, data),
    [initialData, data]
  );

  const disableSubmit = useMemo(
    (): boolean => hasErrors || noChangedData,
    [hasErrors, noChangedData]
  );

  const handleInputChange = useCallback(
    (
      {
        target: { name, value },
      }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      nestedProp?: NestedProp
    ) => {
      switch (nestedProp) {
        case undefined:
          setData((prev) => ({
            ...prev,
            [name]: value,
          }));
          break;
        case NestedProp.Address:
          setData((prev) => ({
            ...prev,
            address: { ...prev?.address, [name]: value },
          }));
          break;
        case NestedProp.Insurance:
          setData((prev) => ({
            ...prev,
            meta: {
              ...prev?.meta,
              insuranceData: { ...prev?.meta?.insuranceData, [name]: value },
            },
          }));
      }
    },
    []
  );

  return (
    <div className="grow h-0 relative">
      <div className="max-h-full overflow-auto flex flex-col gap-5">
        <div className="order-1 self-center md:order-none md:z-10 md:absolute md:-top-[48px] md:right-0 flex gap-5">
          {editMode && (
            <Button
              className="!bg-white !text-pelorous border-2 border-pelorous"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={disableSubmit}
            onClick={() =>
              // if phone is entered remove non numeric characters on submit, since it should be saved as plain phone number
              onConfirm({
                ...(data as IPatient),
                cellPhone: data.cellPhone
                  ? removeNonNumericCharacters(data.cellPhone)
                  : undefined,
              })
            }
          >
            {editMode ? "Save Changes" : "Create New Patient"}
          </Button>
        </div>

        <div className="order-3 flex flex-col gap-5 md:order-none">
          <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-12px)] md:last:[&>div]:!w-full md:justify-between">
            <InputField
              required
              label="Last Name"
              placeholder="Enter last name"
              name="lastName"
              value={data?.lastName}
              onChange={handleInputChange}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              required
              label="First Name"
              name="firstName"
              placeholder="Enter first name"
              value={data?.firstName}
              onChange={handleInputChange}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              label="Middle Name/Initial"
              placeholder="Enter middle name/initial"
              name="middleName"
              value={data?.middleName}
              onChange={handleInputChange}
            />
            <InputField
              label="Suffix"
              placeholder="Select suffix"
              name="nameSuffix"
              value={data?.nameSuffix}
              onChange={handleInputChange}
            />
            <InputField
              label="Patient ID"
              placeholder="Enter patient id"
              name="externalId"
              value={data?.externalId}
              onChange={handleInputChange}
            />
            <DatePickerInput
              required
              label="DOB"
              name="dob"
              value={data?.dob}
              onChange={(value) =>
                setData((prev) => ({
                  ...prev,
                  dob: value,
                }))
              }
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              label="Address Line 1"
              placeholder="Enter address"
              name="street"
              value={data?.address?.street}
              onChange={(e) => handleInputChange(e, NestedProp.Address)}
              onValidationErrorChange={handleValidationErrorChange}
              helperText={
                !editMode ? (
                  <TooltipInfoIcon
                    content="If this is expected to be a Remote Therapeutic Monitoring (RTM)
                  patient, shipping address and phone number will be required upon
                  order entry."
                  />
                ) : undefined
              }
            />
            <InputField
              label="Address Line 2"
              placeholder="Enter address"
              name="street2"
              value={data?.address?.street2}
              onChange={(e) => handleInputChange(e, NestedProp.Address)}
            />
            <InputField
              label="City"
              placeholder="Enter city"
              name="city"
              value={data?.address?.city}
              onChange={(e) => handleInputChange(e, NestedProp.Address)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              label="State"
              placeholder="Enter state"
              name="state"
              value={data?.address?.state}
              onChange={(e) => handleInputChange(e, NestedProp.Address)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              label="ZIP"
              placeholder="Enter zip code"
              name="postCode"
              value={data?.address?.postCode}
              onChange={(e) => handleInputChange(e, NestedProp.Address)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <div className="h-[60px] hidden md:block" />
            <InputField
              type="email"
              label="Email"
              placeholder="Enter email address"
              name="email"
              value={data?.email}
              onChange={handleInputChange}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              type="phone"
              label="Phone"
              placeholder="Enter phone number"
              name="cellPhone"
              value={data.cellPhone}
              onChange={handleInputChange}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              reduceFontSizeOnError
              label="Primary Insurance Provider"
              placeholder="Enter insurance provider"
              name="provider"
              value={data?.meta?.insuranceData?.provider}
              onChange={(e) => handleInputChange(e, NestedProp.Insurance)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              reduceFontSizeOnError
              label="Primary Insurance Policy Number"
              placeholder="Enter policy number"
              name="policy"
              value={data?.meta?.insuranceData?.policy}
              onChange={(e) => handleInputChange(e, NestedProp.Insurance)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              reduceFontSizeOnError
              label="Secondary Insurance Provider"
              placeholder="Enter insurance provider"
              name="secondaryProvider"
              value={data?.meta?.insuranceData?.secondaryProvider}
              onChange={(e) => handleInputChange(e, NestedProp.Insurance)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              reduceFontSizeOnError
              label="Secondary Insurance Policy Number"
              placeholder="Enter policy number"
              name="secondaryPolicy"
              value={data?.meta?.insuranceData?.secondaryPolicy}
              onChange={(e) => handleInputChange(e, NestedProp.Insurance)}
              onValidationErrorChange={handleValidationErrorChange}
            />
            <InputField
              multiline
              rows={5}
              label="Insurance Notes"
              placeholder="Enter insurance notes here"
              name="notes"
              value={data?.meta?.insuranceData?.notes}
              onChange={(e) => handleInputChange(e, NestedProp.Insurance)}
              onValidationErrorChange={handleValidationErrorChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
