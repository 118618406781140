import { useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";
import Button from "../../components/Buttons/Button/Button";
import { usePatientViewLayoutContext } from "../../components/Layouts/PatientViewLayout/PatientViewLayout";
import { formatDashDate } from "../../helpers/CommonHelper";
import { IProperty } from "../../interfaces/Common";
import Property from "../../components/Property/Property";
import PatientForm from "../../components/PatientForm/PatientForm";
import { IPatient } from "../../interfaces/Patient";
import PatientService from "../../services/PatientService";
import useSnackbar from "../../hooks/useSnackbar";
import AddressService from "../../services/AddressService";
import ButtonCreateOrder from "../../components/Buttons/ButtonCreateOrder/ButtonCreateOrder";
import { formatPhoneNumber } from "../../helpers/PatientHelper";

const PatientProfile = () => {
  const { patient, setPatient, clinician } = usePatientViewLayoutContext();

  const { snackbarComponent, setSnackbarError, setSnackbarSuccess } =
    useSnackbar();

  const [isEditActive, setIsEditActive] = useState<boolean>(false);

  const patientLeftColumnProperties = useMemo(
    (): IProperty[] => [
      { property: "Last Name", value: patient?.lastName, required: true },
      { property: "Middle Name/Initial", value: patient?.middleName },
      { property: "Patient ID", value: patient?.externalId },
      {
        property: "Address Line 1",
        value: patient?.address?.street,
      },
      { property: "City", value: patient?.address?.city },
      { property: "ZIP", value: patient?.address?.postCode },
      { property: "Email", value: patient?.email },
      {
        property: "Primary Insurance Provider",
        value: patient?.meta?.insuranceData?.provider,
      },
      {
        property: "Secondary Insurance Provider",
        value: patient?.meta?.insuranceData?.secondaryProvider,
      },
    ],
    [patient]
  );

  const patientTopRightColumnProperties = useMemo(
    (): IProperty[] => [
      { property: "First Name", value: patient?.firstName, required: true },
      { property: "Suffix", value: patient?.nameSuffix },
      {
        property: "DOB",
        value: formatDashDate(patient?.dob),
        required: true,
      },
      { property: "Address Line 2", value: patient?.address?.street2 },
      { property: "State", value: patient?.address?.state },
    ],
    [patient]
  );

  const patientBottomRightColumnProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Phone",
        value: formatPhoneNumber(patient?.cellPhone ?? ""),
      },
      {
        property: "Primary Insurance Policy Number",
        value: patient?.meta?.insuranceData?.policy,
      },
      {
        property: "Secondary Insurance Policy Number",
        value: patient?.meta?.insuranceData?.secondaryPolicy,
      },
    ],
    [patient]
  );

  const handleEdit = useCallback(
    (value: IPatient) => {
      if (!patient) return;

      const { address: oldAddressData, ...oldPatientData } = patient;
      const { address: newAddressData, ...newPatientData } = value;
      const addressId = value.addressId;

      const addressDataChanged = !isEqual(newAddressData, oldAddressData);
      const patientDataChanged = !isEqual(newPatientData, oldPatientData);

      new Promise<void>((resolve, reject) => {
        if (addressId) {
          const requests = [
            // send update address request only if address is changed
            ...(addressDataChanged
              ? [AddressService.updateAddress(addressId, newAddressData!)]
              : []),
            // send update patient request only if patient is changed
            ...(patientDataChanged
              ? [
                  PatientService.updatePatient(
                    newPatientData.id,
                    newPatientData
                  ),
                ]
              : []),
          ];

          Promise.all(requests)
            .then(() => resolve())
            .catch(() => reject());
        } else {
          AddressService.createAddress(newAddressData!)
            .then(({ data }) => data.id)
            .then((addressId) => {
              if (patientDataChanged)
                return PatientService.updatePatient(newPatientData.id, {
                  ...newPatientData,
                  addressId,
                });

              resolve();
            })
            .then(() => resolve())
            .catch(() => reject());
        }
      })
        .then(() => {
          setSnackbarSuccess("Patient has been edited successfully.");
          setPatient(value);
          setIsEditActive(false);
        })
        .catch(() => setSnackbarError());
    },
    [setPatient, setSnackbarError, setSnackbarSuccess, patient]
  );

  return (
    <div className="h-full flex flex-col gap-4 pr-5 xl:pr-0">
      <ButtonCreateOrder
        className="self-center relative md:fixed md:top-16 md:right-5 md:portrait:top-3.5 lg:top-3.5"
        preselectedPatient={patient}
        preselectedClinician={clinician}
      />

      <div className="grow h-0 pb-1 flex flex-col gap-5 overflow-auto xl:items-start xl:flex-row">
        {/* Patient Information */}
        <div className="h-full relative flex flex-col gap-1 box-border p-5 w-full rounded-md border bg-white/50 border-pelorous xl:h-full xl:max-h-full xl:w-[728px]">
          <div className="grow h-0 flex flex-col gap-5 pt-2">
            <h2 className="text-lg leading-5 text-pelorous font-bold">
              Patient Information
            </h2>

            {!isEditActive && (
              <Button
                className="shrink-0 self-center !bg-white !text-pelorous border-2 border-pelorous md:z-10 md:absolute md:top-5 md:right-5"
                onClick={() => setIsEditActive(true)}
              >
                Edit Patient
              </Button>
            )}

            {isEditActive ? (
              <PatientForm
                initialData={patient}
                onConfirm={handleEdit}
                onCancel={() => setIsEditActive(false)}
              />
            ) : (
              <div className="grow h-0 overflow-auto flex gap-5 flex-wrap [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)] md:last:[&>div]:!w-full">
                <div className="flex flex-col gap-5 [&>div]:min-h-[60px]">
                  {patientLeftColumnProperties.map((property) => (
                    <Property key={property.property} {...property} />
                  ))}
                </div>

                <div className="flex flex-col gap-5 [&>div]:min-h-[60px]">
                  {patientTopRightColumnProperties.map((property) => (
                    <Property key={property.property} {...property} />
                  ))}

                  <div className="h-[60px] hidden md:block" />

                  {patientBottomRightColumnProperties.map((property) => (
                    <Property key={property.property} {...property} />
                  ))}
                </div>

                <Property
                  property="Insurance Notes"
                  value={patient?.meta?.insuranceData?.notes}
                />
              </div>
            )}
          </div>
        </div>

        {/* Hidden in first version */}
        {/* <PatientProfileOrderHistory patientId={patient?.id} /> */}
      </div>

      {snackbarComponent}
    </div>
  );
};

export default PatientProfile;
