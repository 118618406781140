import axiosInstance from "../config/axios";
import { API } from "../enums/API";
import { ILoginData } from "../interfaces/Auth";

class AuthService {
  login = (credentials: ILoginData, data?: { clientId: string }) => {
    return axiosInstance.post(API.Login, data, {
      headers: {
        Authorization:
          "Basic " + btoa(`${credentials.email}:${credentials.password}`),
        "X-Is-Mobile": "0",
      },
    });
  };

  forgotPassword = (params: { email: string; portal: boolean }) => {
    return axiosInstance.get(API.ForgotPassword, { params });
  };

  resetPassword = (data: { pwd: string; token: string }) => {
    return axiosInstance.post(API.ResetPassword, data);
  };

  logout = () => {
    return axiosInstance.post(API.Logout);
  };
}

export default new AuthService();
