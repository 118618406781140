import { useCallback, useMemo } from "react";
import { IOrderSummaryField, IOrderVersionDetails } from "interfaces/Order";
import CardContainer from "components/CardContainer/CardContainer";
import { formatDashDate } from "helpers/CommonHelper";
import {
  formatDuration,
  formatFrequency,
  getOrderTypeLabel,
} from "helpers/OrderHelper";
import { formatBalanceParams, formatTestNameByID } from "helpers/TestHelper";
import { TestType } from "enums/Test";
import NotesTooltip from "components/NotesTooltip/NotesTooltip";
import { formatPhoneNumber } from "helpers/PatientHelper";
import { Apps } from "enums/Common";

interface OrderDetailsSummaryCardProps {
  className?: string;
  order: IOrderVersionDetails;
}

const OrderDetailsSummaryCard = (props: OrderDetailsSummaryCardProps) => {
  const { className, order } = props;

  const showShippingInfo = order.product === Apps.AtHome;

  const formatTestParams = useCallback(
    (testId: TestType): string => {
      if (!order.meta?.tests) return "";

      switch (testId) {
        case TestType.Gait: {
          const gaitTests = order.meta.tests[TestType.Gait];

          if (!gaitTests?.length) return "";

          return gaitTests
            .map(
              (x) =>
                `Walk, ${formatDuration(x.duration)} - ${formatFrequency(x)}`
            )
            .join(", ");
        }
        case TestType.Balance: {
          const balanceTests = order.meta.tests[TestType.Balance];

          if (!balanceTests?.length) return "";

          return balanceTests
            .map(
              (x) =>
                `${formatBalanceParams(
                  x.params.feet,
                  x.params.eyes
                )} - ${formatFrequency(x)}`
            )
            .join(", ");
        }
        // case TestType.Angles:
        //   testParams = formatAnglesParams(state, distanceType);
        //   break;
        default:
          return "";
      }
    },
    [order.meta?.tests]
  );

  const summaryData = useMemo(
    (): IOrderSummaryField[] => [
      {
        title: "Patient",
        items: [
          {
            label: "Name",
            value:
              order.patientFname && order.patientLname
                ? `${order.patientLname}, ${order.patientFname}`
                : undefined,
          },
          { label: "DOB", value: formatDashDate(order.patientDob) },
          ...(order.meta?.insurance
            ? [
                {
                  label: "Diagnosis Information",
                  value: order.meta.diagnosisCodes?.join(" - "),
                },
              ]
            : []),
        ],
      },
      {
        title: "Order",
        items: [
          {
            label: "Type",
            value: getOrderTypeLabel(order.product),
          },
          {
            label: "Ordering Clinician",
            value:
              order.orderedByFname && order.orderedByLname
                ? `${order.orderedByLname}, ${order.orderedByFname}`
                : undefined,
          },
          {
            label: "Order Submitted",
            value: formatDashDate(order.createdOn),
          },
          {
            label: "Duration",
            value: order.durationMonth
              ? `${order.durationMonth} months`
              : undefined,
          },
        ],
      },
      {
        title: "Tests",
        items: Object.keys(order.meta?.tests ?? {}).map((key) => ({
          label: formatTestNameByID(Number(key)),
          value: formatTestParams(Number(key)),
        })),
      },
      {
        title: "Shipping",
        items: showShippingInfo
          ? [
              {
                label: "Address",
                value: order.meta?.shipping
                  ? [
                      order.meta.shipping.street1,
                      order.meta.shipping.street2,
                      order.meta.shipping.city,
                      order.meta.shipping.state,
                      order.meta.shipping.zip,
                    ]
                      .filter((x) => Boolean(x))
                      .join(", ")
                  : undefined,
              },
              {
                label: "Phone",
                value: formatPhoneNumber(order.meta?.phone ?? ""),
              },
              { label: "Email", value: order.meta?.email },
            ]
          : [{ label: "Not Applicable", value: "LAB REMOTE" }],
      },
    ],
    [order, formatTestParams, showShippingInfo]
  );

  return (
    <CardContainer
      title="Order Summary"
      className={`gap-2 relative ${className ?? ""}`}
      childrenClassName="flex flex-col gap-9"
    >
      {order.meta?.cancellationComment && (
        <NotesTooltip
          className="absolute right-5 top-5"
          content={order.meta.cancellationComment}
        />
      )}

      <div className="pr-12 flex flex-col gap-6">
        {summaryData.map(({ title, items }) => (
          <div key={title}>
            <p className="text-base leading-5 text-cloudBurst font-medium">
              {title}
            </p>
            <ul className="ml-4 list-disc [&>li]:ml-3">
              {items.map(({ label, value }) => (
                <li key={label}>
                  <p className="inline">
                    <span className="text-cloudBurst">{label}:</span>
                    <span className="pl-1 text-pelorous">{value || "-"}</span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <p className="self-end text-lg leading-5 font-medium text-pelorous">
        Submitted by:{" "}
        {order.createdByFname && order.createdByLname
          ? `${order.createdByLname} ${order.createdByFname}`
          : "-"}
      </p>
    </CardContainer>
  );
};

export default OrderDetailsSummaryCard;
