import { useEffect, useMemo, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ISensorHistory } from "../../../interfaces/Sensor";
import useSnackbar from "../../../hooks/useSnackbar";
import SensorService from "../../../services/SensorService";
import { ITableColumn } from "../../../interfaces/Common";
import { formatDashDate, formatName } from "../../../helpers/CommonHelper";
import Loading from "../../Loading/Loading";
import tableStyles from "../../../styles/shared/table.module.scss";
import NoTableItems from "../NoTableItems/NoTableItems";
import { formatSensorStatusLabel } from "../../../helpers/SensorHelper";
import { SensorHistoryAction } from "../../../enums/Sensor";
import NotesTooltip from "../../NotesTooltip/NotesTooltip";

interface SensorHistoryTableProps {
  sensorId: string;
  onOpenTrackingInfo: (value: ISensorHistory) => void;
}

const SensorHistoryTable = ({
  sensorId,
  onOpenTrackingInfo,
}: SensorHistoryTableProps) => {
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [data, setData] = useState<ISensorHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    SensorService.getHistory(sensorId)
      .then(({ data }) => setData(data ?? []))
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  }, [sensorId, setSnackbarError]);

  const tableColumns = useMemo(
    (): ITableColumn[] => [
      { label: "Updated on" },
      { label: "Status" },
      { label: "Updated by" },
      { label: "" },
      { label: "" },
    ],
    []
  );

  const formatStatusColumn = ({
    actionId,
    patientLn,
    patientFn,
    statusId,
  }: ISensorHistory): string => {
    switch (actionId) {
      case SensorHistoryAction.Assign:
        return `Assigned to ${formatName({
          firstName: patientFn,
          lastName: patientLn,
        })}`;
      case SensorHistoryAction.Unassign:
        return "Unassigned";
      case SensorHistoryAction.ReturnShippingLabel:
        return "Return label shipped to customer";
      case SensorHistoryAction.StatusUpdate:
      case SensorHistoryAction.OutboundShippingLabel:
        return formatSensorStatusLabel(statusId);
      default:
        return "-";
    }
  };

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${tableStyles.padding_16_20}`}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label }, index) => (
                  <TableCell key={index}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{formatDashDate(item.createdOn)}</TableCell>
                  <TableCell>{formatStatusColumn(item)}</TableCell>
                  <TableCell>{item.createdByName}</TableCell>
                  <TableCell className="w-44">
                    {item.outboundShipping && (
                      <button
                        className="text-pelorous hover:underline"
                        onClick={() => onOpenTrackingInfo(item)}
                      >
                        Open tracking info
                      </button>
                    )}
                  </TableCell>
                  <TableCell className="w-32">
                    {item.comment && <NotesTooltip content={item.comment} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={"icon_no_results"}
              message={"No history data"}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>
      {snackbarComponent}
    </>
  );
};

export default SensorHistoryTable;
