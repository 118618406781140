import { forwardRef } from "react";
import { OperationsActivity } from "enums/PatientOperations";
import { formatDate, formatName, formatTime } from "helpers/CommonHelper";
import { PatientActivity } from "interfaces/PatientOperations";
import DownloadOperationsAttachmentButton from "components/Buttons/DownloadOperationsAttachmentButton/DownloadOperationsAttachmentButton";

interface PatientActivityItemProps {
  data: PatientActivity;
  onDownloadSuccess: (message: string) => void;
  onDownloadFailed: () => void;
}

const PatientActivityItem = forwardRef<
  HTMLDivElement,
  PatientActivityItemProps
>((props, ref) => {
  const { data, onDownloadSuccess, onDownloadFailed } = props;
  const { type, createdOn, createdByFn, createdByLn } = data;

  return (
    <div
      className="flex flex-col gap-4 p-5 bg-white/50 border border-t-0 border-linkWater"
      ref={ref}
    >
      <div className="flex items-start justify-between gap-5 text-cloudBurst text-base leading-5 font-medium">
        <p>{`${
          type === OperationsActivity.Note ? "Note" : "Attachment"
        } added`}</p>
        <p className="text-right text-sm left-4 max-w-[100px] sm:max-w-none sm:text-base sm:leading-5">
          {`${formatDate(createdOn)}, ${formatTime(createdOn)}`}
        </p>
      </div>
      <div className="flex items-end justify-between gap-5">
        <div className="flex flex-col gap-2.5 text-cloudBurst text-sm leading-4 font-medium">
          <p>{`Updated by: ${formatName({
            lastName: createdByLn,
            firstName: createdByFn,
          })}`}</p>
          <p className="whitespace-pre-wrap">
            {`Added ${
              type === OperationsActivity.Note ? "a note:\n" : "an attachment: "
            }`}
            <span className="text-pelorous italic">
              {type === OperationsActivity.Note ? data.text : data.name}
            </span>
          </p>
          {type === OperationsActivity.Attachment && data.description && (
            <p>
              File description:{" "}
              <span className="text-pelorous italic">{data.description}</span>
            </p>
          )}
        </div>

        {type === OperationsActivity.Attachment && (
          <DownloadOperationsAttachmentButton
            attachment={data}
            onSuccess={onDownloadSuccess}
            onError={onDownloadFailed}
          />
        )}
      </div>
    </div>
  );
});

export default PatientActivityItem;
