interface IPageTitleDescription {
  title: string;
  description?: string;
  className?: string;
}

const PageTitleDescription = ({
  title,
  description,
  className,
}: IPageTitleDescription) => {
  return (
    <div className={`flex flex-col gap-1 ${className ?? ""}`}>
      <h1 className="text-2xl leading-7 font-bold text-cloudBurst">{title}</h1>
      {description && (
        <p className="text-base leading-5 font-medium text-cloudBurst">
          {description}
        </p>
      )}
    </div>
  );
};

export default PageTitleDescription;
