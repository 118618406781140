import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import isNumeric from "validator/lib/isNumeric";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "./DebouncedSearchField.module.scss";
import { DebounceWait } from "../../enums/Common";

interface IDebouncedSearchField {
  isNumericField?: boolean;
  className?: string;
  placeholder: string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const DebouncedSearchField = ({
  isNumericField,
  className,
  placeholder,
  value,
  onChange,
}: IDebouncedSearchField) => {
  const [enteredValue, setEnteredValue] = useState<string | undefined>(value);

  const debouncedSearch = useMemo(
    () => debounce((value: string) => onChange(value), DebounceWait.Input),
    [onChange]
  );

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (isNumericField && !isNumeric(value) && value !== "") return;

      setEnteredValue(value);
      debouncedSearch(value);
    },
    [debouncedSearch, isNumericField]
  );

  const handleClear = useCallback(() => {
    setEnteredValue(undefined);
    onChange(undefined);
  }, [onChange]);

  return (
    <TextField
      className={`${styles.debounced_search_field} ${
        className ? className : ""
      } w-full`}
      autoComplete="off"
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="flex items-center gap-2">
              {Boolean(enteredValue?.length) && (
                <button className="p-0 flex" onClick={handleClear}>
                  <span className="icon_svg icon_cross" />
                </button>
              )}

              <div className="bg-malibu w-8 h-8 rounded-full flex items-center justify-center">
                <span className="icon_svg icon_search" />
              </div>
            </div>
          </InputAdornment>
        ),
      }}
      value={enteredValue || ""}
      onChange={handleChange}
    />
  );
};

export default DebouncedSearchField;
