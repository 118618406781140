import { formatDate, formatTime } from "../../../../../helpers/CommonHelper";
import {
  formatCognitiveHandLabel,
  formatCognitiveTypeLabel,
  formatIntervalLabel,
  formatStatus,
} from "../../../../../helpers/TestHelper";
import { ITestDetails } from "../../../../../interfaces/Test";
import PropertyLabel from "../../../../PropertyLabel/PropertyLabel";

const CognitiveTestDetails = ({
  test: {
    createdOn,
    createdByFirstName,
    createdByLastName,
    status,
    feet,
    surface,
    distance,
    durationType,
    duration,
    repeat,
    side,
    orderIterations,
  },
}: ITestDetails) => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel
          property="Test type"
          value={formatCognitiveTypeLabel(feet, surface)}
        />
        <PropertyLabel
          property="Repeats"
          value={repeat ? String(repeat) : "-"}
        />
        <PropertyLabel
          property="Interval"
          value={formatIntervalLabel(durationType, duration, distance)}
        />
        <PropertyLabel property="Status" value={formatStatus(status)} />
        <PropertyLabel
          property="Run by"
          value={`${createdByLastName}, ${createdByFirstName}`}
        />
      </div>
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel
          property="Hand performing test"
          value={formatCognitiveHandLabel(side)}
        />
        <PropertyLabel
          property="Date and time"
          value={formatDate(createdOn) + ", " + formatTime(createdOn)}
        />
        <PropertyLabel
          property="Test ID"
          value={orderIterations[0].accession}
        />
      </div>
    </div>
  );
};

export default CognitiveTestDetails;
