import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TestTableProps } from "interfaces/Test";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import {
  formatCompletedOnTime,
  formatDate,
  secondsToString,
} from "helpers/CommonHelper";
import { BalanceMetric } from "enums/Test";
import {
  formatEyesLabel,
  formatFeetLabel,
  formatSurfaceLabel,
  getSensorPositionName,
  roundTestScore,
} from "helpers/TestHelper";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./BalanceTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Details", sortProperty: "created_on" },
  { label: "Test" },
  {
    label: "Average Position",
    sortProperty: `metric_${BalanceMetric.AvgDeviationDisplacement}`,
  },
  {
    label: "Average Movement",
    sortProperty: `metric_${BalanceMetric.Movement}`,
  },
  { label: "Average Speed", sortProperty: `metric_${BalanceMetric.Composite}` },
  {
    label: "Balance Score",
    sortProperty: `metric_${BalanceMetric.BalanceScore}`,
  },
  { label: "" },
];

const BalanceTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    orderBy,
    onSort,
    onRunReportBtnClick,
    getTestNotesLink,
    getMenuOptions,
  } = props;

  return (
    <Table className={styles.balance_table}>
      <TableHead>
        <TableRow>
          <TableCell>Balance Tests</TableCell>
          <TableCell colSpan={6} />
        </TableRow>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }, index) => (
            <TableCell key={index}>
              {sortProperty ? (
                <TableSortLabel
                  whiteTheme
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map(
          (
            {
              id,
              accession,
              createdOn,
              orderItemId,
              metric,
              surface,
              eyes,
              duration,
              performedDuration,
              feet,
              sensors,
              noteCount,
            },
            index
          ) => (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatCompletedOnTime(createdOn, performedDuration / 1000)}
                <br />
                <span className={styles.accession_label}>Test ID</span>
                <br />
                {accession}
              </TableCell>
              <TableCell>
                {formatFeetLabel(feet)},
                <br />
                {`Eyes ${formatEyesLabel(eyes)}`},
                <br />
                {surface
                  ? formatSurfaceLabel(surface) + " Surface"
                  : "Surface: -"}
                ,
                <br />
                {`Duration: ${secondsToString(duration)}`},
                <br />
                {`Sensor position: ${getSensorPositionName(sensors, 0)}`}
              </TableCell>
              <TableCell>
                {(
                  metric[BalanceMetric.AvgDeviationDisplacement] as number
                )?.toFixed(1)}
                °
              </TableCell>
              <TableCell>
                {(metric[BalanceMetric.Movement] as number)?.toFixed(1)}°
              </TableCell>
              <TableCell>
                {metric[BalanceMetric.Composite] as number}°/s
              </TableCell>
              <TableCell className="!text-4xl !font-bold">
                {roundTestScore(metric[BalanceMetric.BalanceScore] as number)}
              </TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default BalanceTable;
