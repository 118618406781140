import moment, { Moment } from "moment";
import { RollupInterval, Interval } from "../enums/Test";

export const getTrendsAndUsageIntervalOptions = (): {
  value: Interval;
  label: string;
}[] => {
  return [
    {
      value: Interval.OneWeek,
      label: "7 days",
    },
    {
      value: Interval.OneMonth,
      label: "30 days",
    },
    {
      value: Interval.TwoMonths,
      label: "60 days",
    },
    {
      value: Interval.ThreeMonths,
      label: "90 days",
    },
    {
      value: Interval.All,
      label: "All",
    },
  ];
};

export const calculateCreatedOnFromInterval = ({
  interval,
  isTrends,
}: {
  interval: Interval;
  isTrends: boolean;
}): string | undefined => {
  let startDate: Moment | undefined = moment();

  switch (interval) {
    case Interval.OneWeek:
      startDate.subtract(1, "week");
      break;
    case Interval.OneMonth:
      startDate.subtract(30, "days");
      break;
    case Interval.TwoMonths:
      startDate.subtract(60, "days");
      break;
    case Interval.ThreeMonths:
      startDate.subtract(90, "days");
      break;
    case Interval.All:
      startDate = undefined;
  }

  const start = startDate?.format("YYYY-MM-DD");

  /*   
    trends API receives createdOn range, while other APIs receives only start date,
    trends API expects endDate to be incremented by 1 day
  */
  if (isTrends) {
    return start
      ? `${start}~\`${moment().add(1, "day").format("YYYY-MM-DD")}`
      : undefined;
  } else {
    return start;
  }
};

export const getTrendsIntervalOptions = (): {
  value: RollupInterval;
  label: string;
}[] => {
  return [
    {
      value: RollupInterval.Day,
      label: "Day",
    },
    {
      value: RollupInterval.Week,
      label: "Week",
    },
    {
      value: RollupInterval.Month,
      label: "Month",
    },
  ];
};

export const getRollupLabel = (rollup: RollupInterval): string => {
  switch (rollup) {
    case RollupInterval.Day:
      return "Day";
    case RollupInterval.Week:
      return "Week";
    case RollupInterval.Month:
      return "Month";
    case RollupInterval.Year:
      return "Year";
  }
};
