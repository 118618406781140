import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DebouncedSearchField from "../../components/DebouncedSearchField/DebouncedSearchField";
import PatientsTable from "../../components/Tables/PatientsTable/PatientsTable";
import { IPatientFilters } from "../../interfaces/Patient";
import { OrderDirection, PageSize } from "../../enums/Common";
import ButtonCreatePatient from "../../components/Buttons/ButtonCreatePatient/ButtonCreatePatient";

const ClinicianPatients = () => {
  const { clinicianId } = useParams();
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<IPatientFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `last_name ${OrderDirection.Asc}`,
    name: searchParams.get("name") || undefined,
    clinicianId,
    id: searchParams.get("id") || undefined,
  });

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-wrap gap-5 items-center justify-center sm:justify-between pr-5 xl:pr-0">
        <DebouncedSearchField
          className="order-2 sm:order-1"
          placeholder="Search by Patient Name"
          value={filters.name}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              name: value,
            }))
          }
        />

        <ButtonCreatePatient
          className="order-1 sm:order-2"
          onCreated={() => setFilters((prev) => ({ ...prev, page: 1 }))}
        />
      </div>

      <div className="grow h-0">
        <PatientsTable
          isClinicianScreen
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};

export default ClinicianPatients;
