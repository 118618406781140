import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AxiosProgressEvent } from "axios";
import { isEqual } from "lodash";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CardContainer from "components/CardContainer/CardContainer";
import {
  ICreateOrderData,
  IEditOrderData,
  IOrderDetails,
  IOrderSummaryField,
  IReferringPhysician,
} from "interfaces/Order";
import { formatDashDate } from "helpers/CommonHelper";
import { Auth } from "recoil-states/auth-states";
import Button from "components/Buttons/Button/Button";
import { TestType } from "enums/Test";
import {
  formatBalanceDataForAPI,
  formatDuration,
  formatFrequency,
  formatGaitDataForAPI,
  getOrderTypeLabel,
  getTestDataFromExistingOrder,
} from "helpers/OrderHelper";
import { formatEyesLabel, formatFeetLabel } from "helpers/TestHelper";
import { FrequencyType, OrderModalState, OrderType } from "enums/Order";
import {
  AnglesData,
  BalanceData,
  CardsValidation,
  DiagnosisCodes,
  Duration,
  GaitData,
  Patient,
  Clinician,
  TestsData,
  UploadedAttachments,
  ModalState,
  Billable,
  Product,
  HideShipping,
  InitialPatient,
} from "recoil-states/create-order-states";
import PatientService from "services/PatientService";
import OrderService from "services/OrderService";
import { hasPermissions } from "helpers/PermissionsHelper";
import { Apps, Permissions } from "enums/Common";
import {
  formatPhoneNumber,
  removeNonNumericCharacters,
} from "helpers/PatientHelper";

interface SummaryCardProps {
  isRenew?: boolean;
  initialOrder?: IOrderDetails;
  onStarted: () => void;
  onCompleted: () => void;
  onFailed: (message?: string) => void;
  onAttachmentsUploadFailed: (count: number) => void;
}

type SummaryCardItem =
  | IOrderSummaryField & {
      completed: boolean;
    };

const SummaryCard = (props: SummaryCardProps) => {
  const {
    isRenew,
    initialOrder,
    onStarted,
    onCompleted,
    onFailed,
    onAttachmentsUploadFailed,
  } = props;

  const auth = useRecoilValue(Auth);
  const modalState = useRecoilValue(ModalState);
  const patient = useRecoilValue(Patient);
  const initialPatient = useRecoilValue(InitialPatient);
  const clinician = useRecoilValue(Clinician);
  const diagnosisCodes = useRecoilValue(DiagnosisCodes);
  const duration = useRecoilValue(Duration);
  const billable = useRecoilValue(Billable);
  const product = useRecoilValue(Product);
  const hideShipping = useRecoilValue(HideShipping);
  const testData = useRecoilValue(TestsData);
  const gaitData = useRecoilValue(GaitData);
  const balanceData = useRecoilValue(BalanceData);
  const anglesData = useRecoilValue(AnglesData);
  const [uploadedAttachments, setUploadedAttachments] =
    useRecoilState(UploadedAttachments);
  const [cardsValidation, setCardsValidation] = useRecoilState(CardsValidation);

  const formatFrequencyString = useCallback(
    ({
      frequency,
      frequencyReps,
    }: {
      frequency?: FrequencyType;
      frequencyReps?: number;
    }): string =>
      frequency && frequencyReps
        ? ` - ${formatFrequency({
            frequency,
            frequencyReps,
          })}`
        : "",
    []
  );

  const gaitLabel = useMemo((): string | undefined => {
    const { selected, tests } = gaitData;

    if (!selected || !tests.length) return;

    const validSelections = tests.filter(
      ({ frequency, frequencyReps, duration }) =>
        frequency && frequencyReps && duration
    );

    return validSelections
      .map(
        ({ duration, frequency, frequencyReps }) =>
          `Walk, ${formatDuration(duration!)}${formatFrequencyString({
            frequency,
            frequencyReps,
          })}`
      )
      .join(", ");
  }, [gaitData, formatFrequencyString]);

  const balanceLabel = useMemo((): string | undefined => {
    const { selected, frequency, frequencyReps, tests } = balanceData;

    if (!selected || !tests.length) return;

    const validSelections = tests.filter(({ params: { eyes } }) => eyes);

    return validSelections
      .map(
        ({ params: { feet, eyes } }) =>
          eyes &&
          `${formatFeetLabel(feet)}, Eyes ${formatEyesLabel(
            eyes
          )}${formatFrequencyString({ frequency, frequencyReps })}`
      )
      .join(", ");
  }, [balanceData, formatFrequencyString]);

  // const anglesLabel = useMemo((): string | undefined => {
  //   const { selected, frequency, frequencyReps, params } = anglesData;

  //   if (!selected || !params.length) return;

  //   const result: string[] = [];

  //   for (const { motion, reps } of params) {
  //     if (reps) {
  //       result.push(
  //         `${formatMotionLabel(
  //           motion
  //         )}${` - ${reps} reps`}${formatFrequencyString({
  //           frequency,
  //           frequencyReps,
  //         })}`
  //       );
  //     }
  //   }

  //   return result.join(", ");
  // }, [anglesData, formatFrequencyString]);

  const validTestData = useMemo((): boolean => {
    const testValues = [gaitData, balanceData, anglesData];
    const testsAreValid = testValues.every(({ valid }) => valid);
    const atLeastOneTestSelected = testValues.some(({ selected }) => selected);

    return atLeastOneTestSelected && testsAreValid;
  }, [gaitData, balanceData, anglesData]);

  useEffect(() => {
    setCardsValidation((prev) => ({ ...prev, tests: validTestData }));
  }, [validTestData, setCardsValidation]);

  const patientSummary: SummaryCardItem = {
    title: "Patient",
    completed: cardsValidation.patient,
    items: [
      ...(patient
        ? [
            {
              label: "Name",
              value: `${patient.lastName}, ${patient.firstName}`,
            },
            {
              label: "DOB",
              value: formatDashDate(patient.dob),
            },
          ]
        : []),
      ...(diagnosisCodes.length
        ? [
            {
              label: "Diagnosis Codes",
              value: diagnosisCodes.join(" - "),
            },
          ]
        : []),
    ],
  };

  const orderSummary: SummaryCardItem = {
    title: "Order",
    completed: cardsValidation.order,
    items: [
      ...(product
        ? [
            {
              label: "Type",
              value: getOrderTypeLabel(product),
            },
          ]
        : []),
      ...(clinician
        ? [
            {
              label: "Ordering Clinician",
              value: `${clinician.lastName}, ${clinician.firstName}`,
            },
          ]
        : []),
      ...(duration
        ? [
            {
              label: "Duration",
              value: `${duration} months`,
            },
          ]
        : []),
    ],
  };

  const testsSummary: SummaryCardItem = {
    title: "Tests",
    completed: cardsValidation.tests,
    items: [
      ...(gaitLabel
        ? [
            {
              label: "Gait",
              value: gaitLabel,
            },
          ]
        : []),
      ...(balanceLabel
        ? [
            {
              label: "Balance",
              value: balanceLabel,
            },
          ]
        : []),
      // {
      //   label: "Angles",
      //   value: anglesLabel,
      // },
    ],
  };

  const addressInfoArray = [
    patient?.address?.street,
    patient?.address?.street2,
    patient?.address?.city,
    patient?.address?.state,
    patient?.address?.postCode,
  ];

  const shippingSummary: SummaryCardItem = {
    title: "Shipping",
    completed: cardsValidation.shipping,
    items: hideShipping
      ? [{ label: "Not Applicable", value: "LAB REMOTE" }]
      : [
          ...(addressInfoArray.some((x) => x)
            ? [
                {
                  label: "Address",
                  value: addressInfoArray.filter((x) => x).join(", "),
                },
              ]
            : []),
          ...(patient?.cellPhone || patient?.phone
            ? [
                {
                  label: "Phone",
                  value: formatPhoneNumber(
                    patient.cellPhone ?? patient.phone ?? ""
                  ),
                },
              ]
            : []),
          ...(patient?.email ? [{ label: "Email", value: patient.email }] : []),
        ],
  };

  const summaryData: SummaryCardItem[] = [
    ...(patientSummary.items.length ? [patientSummary] : []),
    ...(orderSummary.items.length ? [orderSummary] : []),
    ...(testsSummary.items.length ? [testsSummary] : []),
    ...(shippingSummary.items.length ? [shippingSummary] : []),
  ];

  const initialDataChanged = useMemo((): boolean => {
    // when renewing order, data doesn't need to be changed
    if (!initialOrder || isRenew) return true;

    // order can be created without diagnosis codes, in that case it will have undefined value
    const initialDiagnosisCodes = initialOrder.meta?.diagnosisCodes ?? [];
    const codesChanged = !isEqual(initialDiagnosisCodes, diagnosisCodes);

    // order can be created without insurance data, in that case it will have undefined value
    const initialInsurance = initialOrder.meta?.insuranceData ?? {};
    const patientInsurance = patient?.meta?.insuranceData ?? {};
    const insuranceChanged = !isEqual(initialInsurance, patientInsurance);

    const shippingDataChanged =
      product === Apps.AtHome
        ? initialOrder.meta?.phone !== patient?.cellPhone ||
          initialOrder.meta?.email !== patient?.email ||
          initialOrder.meta?.shipping?.street1 !== patient?.address?.street ||
          initialOrder.meta?.shipping?.street2 !== patient?.address?.street2 ||
          initialOrder.meta?.shipping?.city !== patient?.address?.city ||
          initialOrder.meta?.shipping?.state !== patient?.address?.state ||
          initialOrder.meta?.shipping?.zip !== patient?.address?.postCode
        : false;

    // submit will be disabled if no data has changed
    return (
      Boolean(initialPatient?.meta?.isDemoUser) !==
        Boolean(patient?.meta?.isDemoUser) ||
      initialOrder.product !== product ||
      initialOrder.patientId !== patient?.id ||
      initialOrder.authorizedById !== clinician?.id ||
      initialOrder.billable !== billable ||
      String(initialOrder.durationMonth) !== duration ||
      insuranceChanged ||
      codesChanged ||
      !isEqual(getTestDataFromExistingOrder(initialOrder), testData) ||
      shippingDataChanged ||
      initialOrder.meta?.referringPhysician?.firstName !==
        clinician.referringPhysician?.firstName ||
      initialOrder.meta?.referringPhysician?.lastName !==
        clinician.referringPhysician?.lastName ||
      initialOrder.meta?.referringPhysician?.npi !==
        clinician.referringPhysician?.npi
    );
  }, [
    initialOrder,
    patient,
    diagnosisCodes,
    duration,
    clinician,
    testData,
    isRenew,
    product,
    billable,
    initialPatient?.meta?.isDemoUser,
  ]);

  // disable submit if any of cards isn't valid
  const disableSubmit = useMemo(
    (): boolean =>
      Object.values(cardsValidation).some((validation) => !validation) ||
      (!initialDataChanged && !Boolean(uploadedAttachments.length)) ||
      modalState === OrderModalState.InProgress,
    [
      cardsValidation,
      initialDataChanged,
      uploadedAttachments.length,
      modalState,
    ]
  );

  const submitBtnText = useMemo((): string => {
    switch (true) {
      case Boolean(isRenew && initialOrder):
        return "Renew Order";
      case Boolean(initialOrder):
        return "Save Changes";
      default:
        return "Submit Order";
    }
  }, [isRenew, initialOrder]);

  const generateCommonData = useCallback(():
    | Omit<IEditOrderData, "endDate">
    | undefined => {
    if (!patient?.address || !clinician || !product) return;

    const {
      id: patientId,
      email,
      cellPhone,
      phone,
      address: { city, state, street, street2, postCode },
      meta,
    } = patient;

    const referringPhysician: IReferringPhysician | undefined =
      clinician.referringPhysician?.firstName &&
      clinician.referringPhysician.lastName &&
      clinician.referringPhysician.npi
        ? {
            firstName: clinician.referringPhysician.firstName,
            lastName: clinician.referringPhysician.lastName,
            npi: clinician.referringPhysician.npi,
          }
        : undefined;

    const insuranceData = meta?.insuranceData;

    return {
      type: OrderType.AtHome,
      product,
      billable,
      authorizedById: clinician.id,
      patientId,
      meta: {
        ...(insuranceData
          ? {
              diagnosisCodes,
              insurance: true,
              insuranceData: {
                provider: insuranceData.provider!,
                policy: insuranceData.policy!,
                secondaryProvider: insuranceData.secondaryProvider,
                secondaryPolicy: insuranceData.secondaryPolicy,
                notes: insuranceData.notes,
                npi: insuranceData.npi,
                injuryDate: insuranceData.injuryDate,
              },
            }
          : { insurance: false }),
        ...(hideShipping
          ? { needShipping: false }
          : {
              needShipping: true,
              phone: removeNonNumericCharacters(cellPhone ? cellPhone : phone!),
              email: email ?? undefined,
              shipping: {
                city: city!,
                state: state!,
                street1: street!,
                street2: street2 ?? undefined,
                zip: postCode!,
              },
            }),
        referringPhysician,
        tests: {
          [TestType.Gait]: formatGaitDataForAPI(gaitData),
          [TestType.Balance]: formatBalanceDataForAPI(balanceData),
          // ...(anglesData.selected && {
          //   [TestType.Angles]: {
          //     frequency: anglesData.frequency!,
          //     frequencyReps: Number(anglesData.frequencyReps),
          //     ...formatAnglesSideAndParamsForAPI({ ...anglesData }),
          //   },
          // }),
        },
      },
    };
  }, [
    balanceData,
    gaitData,
    diagnosisCodes,
    patient,
    clinician,
    product,
    billable,
    hideShipping,
  ]);

  const calculateEndDate = useCallback(
    (startDate: moment.Moment): string => {
      return startDate
        .add(Number(duration) * 30, "days")
        .subtract(1, "day")
        .toISOString(true); // ignore timezone offset, we are just using date
    },
    [duration]
  );

  const createOrder = useCallback(
    (
      commonData: Omit<IEditOrderData, "endDate">
    ): Promise<{ orderId: string }> => {
      // resolve with orderId, we need it for attachments upload
      return new Promise((resolve, reject) => {
        const startDate = moment().toISOString();
        const endDate = calculateEndDate(moment());

        const data: ICreateOrderData = {
          ...commonData,
          startDate,
          endDate,
        };

        OrderService.create(data)
          .then(({ data: { id } }) => resolve({ orderId: id }))
          .catch((e) => reject(e));
      });
    },
    [calculateEndDate]
  );

  const editOrderData = useCallback(
    (
      { id, startDate }: IOrderDetails,
      commonData: Omit<IEditOrderData, "endDate">
    ): Promise<{ orderId: string }> => {
      // resolve with orderId, we need it for attachments upload
      return new Promise((resolve, reject) => {
        // order can be edited in two ways (two different API requests)
        // - data changed
        // - new attachments added
        // - no need to send order edit request if only new attacments are added
        if (!initialDataChanged) {
          resolve({ orderId: id });
          return;
        }

        const endDate = calculateEndDate(moment(startDate));

        const data: IEditOrderData = {
          ...commonData,
          endDate,
        };

        OrderService.edit(id, data)
          .then(() => resolve({ orderId: id }))
          .catch((e) => reject(e));
      });
    },
    [calculateEndDate, initialDataChanged]
  );

  const uploadAttachments = useCallback(
    (orderId: string): Promise<void> => {
      const handleUploadProgress = (
        e: AxiosProgressEvent,
        attachmentId: string
      ) => {
        const { total, loaded } = e;
        if (total === undefined) return;

        const uploadProgress = Math.round((loaded * 100) / total);
        setUploadedAttachments((prev) =>
          prev.map((x) =>
            x.id === attachmentId ? { ...x, uploadProgress } : x
          )
        );
      };

      return new Promise((resolve) => {
        const requests = uploadedAttachments.map(({ id, file, description }) =>
          OrderService.uploadAttachment({ orderId, file, description }, (e) =>
            handleUploadProgress(e, id)
          )
        );

        Promise.allSettled(requests).then((responses) => {
          const failedCount = responses.filter(
            (x) => x.status === "rejected"
          ).length;

          if (failedCount) {
            onAttachmentsUploadFailed(failedCount);
          }

          resolve();
        });
      });
    },
    [onAttachmentsUploadFailed, uploadedAttachments, setUploadedAttachments]
  );

  const handleSubmit = useCallback(() => {
    const canEditPatient = hasPermissions(auth?.initialData?.permissions, [
      Permissions.AddEditPatient,
    ]);

    const commonData = generateCommonData();

    if (!commonData || !patient) return;

    onStarted();

    (canEditPatient
      ? PatientService.updatePatient(patient.id, patient)
      : Promise.resolve()
    )
      .then(() =>
        initialOrder && !isRenew // on renew, new order should be created
          ? editOrderData(initialOrder, commonData)
          : createOrder(commonData)
      )
      .then(({ orderId }) => uploadAttachments(orderId))
      .then(() => onCompleted())
      .catch((e) => {
        // extract message from error data only for 422 code (order limit exceeded)
        // otherwise default message will be shown
        const errorMessage =
          e?.response?.data?.status === 422
            ? e.response.data.message
            : undefined;

        onFailed(errorMessage);
      });
  }, [
    patient,
    onStarted,
    onCompleted,
    onFailed,
    initialOrder,
    editOrderData,
    createOrder,
    generateCommonData,
    auth,
    isRenew,
    uploadAttachments,
  ]);

  return (
    <CardContainer
      className="p-5 gap-3"
      childrenClassName="flex flex-col gap-5 justify-between"
      title="Order Summary"
    >
      <div className="pr-12 flex flex-col gap-6">
        {summaryData.map(({ title, items, completed }) => (
          <div key={title}>
            <p
              className={`w-fit relative text-base leading-5 font-medium transition-colors ease-in-out duration-300 ${
                completed ? "text-tropicalRainForest" : "text-crimson"
              }`}
            >
              {title}
              {completed ? (
                <CheckIcon
                  className="absolute top-0 -right-6 !w-5 !h-5"
                  color="success"
                />
              ) : (
                <PriorityHighIcon className="absolute top-1 -right-5 !h-3" />
              )}
            </p>

            <ul className="ml-4 list-disc [&>li]:ml-3">
              {items.map(({ label, value }) => (
                <li key={label}>
                  {value && (
                    <p className="inline">
                      <span className="text-cloudBurst">{label}:</span>
                      <span className="pl-1 text-pelorous">{value}</span>
                    </p>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {!summaryData.length && (
        <p className="text-pelorous font-medium">
          Enter more information to see a summary for this order.
        </p>
      )}

      <Button
        className="self-end"
        disabled={disableSubmit}
        onClick={handleSubmit}
      >
        {submitBtnText}
      </Button>
    </CardContainer>
  );
};

export default SummaryCard;
