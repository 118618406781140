import { IOrderDetails } from "interfaces/Order";
import OrderComponent from "components/OrderComponent/OrderComponent";

interface LayoutOrderDetailsProps {
  layoutContext: () => {
    order: IOrderDetails | undefined;
    loadingOrder: boolean;
    refreshOrder: () => void;
  };
}

const LayoutOrderDetails = ({ layoutContext }: LayoutOrderDetailsProps) => {
  const { order, loadingOrder, refreshOrder } = layoutContext();

  return (
    <OrderComponent
      order={order}
      loading={loadingOrder}
      onSuccessfullyEdited={refreshOrder}
    />
  );
};

export default LayoutOrderDetails;
