import { generatePath } from "react-router-dom";
import axios from "../config/axios";
import { API } from "../enums/API";
import { IClinicianFilters } from "../interfaces/Clinician";
import { IPatientFilters } from "../interfaces/Patient";

class ClinicianService {
  getAll = (params: Partial<IClinicianFilters>, signal?: AbortSignal) => {
    return axios.get(API.Clinician, { params, signal });
  };

  getOne = (clinicianId: string, signal?: AbortSignal) => {
    return axios.get(generatePath(API.ClinicianDetails, { clinicianId }), {
      signal,
    });
  };

  getAllDashboardData = (signal?: AbortSignal) => {
    return axios.get(API.ClinicianDashboardAll, { signal });
  };

  getOneDashboardData = (params: { id: string }, signal?: AbortSignal) => {
    return axios.get(API.ClinicianDashboard, { params, signal });
  };

  getClinicianPatients = (
    params: Partial<IPatientFilters>,
    signal?: AbortSignal
  ) => {
    return axios.get(API.ClinicianPatients, { params, signal });
  };
}

export default new ClinicianService();
