import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITableColumn } from "interfaces/Common";
import { TestTableProps } from "interfaces/Test";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { formatDate, formatTime } from "helpers/CommonHelper";
import {
  formatAnglesAvgLeftRight,
  formatAnglesRegionLabel,
  formatLocationLabel,
  formatMotionLabel,
  formatRepetitions,
  formatTargetRange,
} from "helpers/TestHelper";
import { AnglesLocation } from "enums/Test";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./AnglesTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Details", sortProperty: "created_on" },
  { label: "Region" },
  { label: "Motion" },
  { label: "Location" },
  { label: "Repetitions" },
  { label: "Avg Left" },
  { label: "Avg Right" },
  { label: "Start Angle" },
  { label: "Target Range" },
  { label: "" },
];

const AnglesTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    getMenuOptions,
    onRunReportBtnClick,
    getTestNotesLink,
    onSort,
    orderBy,
  } = props;

  return (
    <Table className={styles.angles_table}>
      <TableHead>
        <TableRow>
          <TableCell>Angles Tests</TableCell>
          <TableCell colSpan={9} />
        </TableRow>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }) => (
            <TableCell key={label}>
              {sortProperty ? (
                <TableSortLabel
                  whiteTheme
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map((test, index) => {
          const {
            id,
            createdOn,
            accession,
            distance,
            state,
            side,
            orderItemId,
            metric,
            noteCount,
          } = test;
          const avgParams = formatAnglesAvgLeftRight(test);

          return (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatTime(createdOn)}
                <br />
                <span className={styles.accession_label}>Test ID</span>
                <br />
                {accession}
              </TableCell>
              <TableCell>{formatAnglesRegionLabel(distance)}</TableCell>
              <TableCell>
                <div className="!whitespace-pre-wrap">
                  {formatMotionLabel(state)}
                </div>
              </TableCell>
              <TableCell>
                {formatLocationLabel(side as AnglesLocation)}
              </TableCell>
              <TableCell>{formatRepetitions(metric)}</TableCell>
              <TableCell>{avgParams?.avgLeft}</TableCell>
              <TableCell>{avgParams?.avgRight}</TableCell>
              <TableCell>{avgParams?.startAngle}</TableCell>
              <TableCell>{formatTargetRange(test)}</TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AnglesTable;
