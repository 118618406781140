import { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import TestingAccordion from "../TestingAccordion/TestingAccordion";
import {
  GaitData,
  TestsData,
} from "../../../../../recoil-states/create-order-states";
import { TestType } from "../../../../../enums/Test";
import { ICreateGaitOrderItem } from "../../../../../interfaces/Order";
import { getTestDurationOptions } from "../../../../../helpers/TestHelper";
import GaitTestInput from "./GaitTestInput/GaitTestInput";
import GaitTestCustomInput from "./GaitTestCustomInput/GaitTestCustomInput";
import { getDefaultTestsData } from "../../../../../helpers/OrderHelper";

const GaitTest = () => {
  const { selected, tests } = useRecoilValue(GaitData);
  const setTestsData = useSetRecoilState(TestsData);

  const frequencyNotSelected =
    !tests.length ||
    tests.some(({ frequency, frequencyReps }) => !frequency || !frequencyReps);

  const error = useMemo(
    (): boolean => selected && frequencyNotSelected,
    [selected, frequencyNotSelected]
  );

  const handleDataChange = useCallback(
    (value: Partial<ICreateGaitOrderItem>) => {
      setTestsData((prev) => ({
        ...prev,
        [TestType.Gait]: {
          ...prev[TestType.Gait],
          ...value,
          selected: value.selected ?? true, // select test on input change
        },
      }));
    },
    [setTestsData]
  );

  useEffect(() => {
    setTestsData((prev) => ({
      ...prev,
      [TestType.Gait]: { ...prev[TestType.Gait], valid: !error },
    }));
  }, [error, setTestsData]);

  const handleSelectionChange = (value: boolean): void => {
    if (value) {
      handleDataChange({ selected: true });
    } else {
      handleDataChange(getDefaultTestsData()[TestType.Gait]);
    }
  };

  return (
    <TestingAccordion
      error={error}
      testName="Gait"
      selected={selected}
      onSelectionChange={handleSelectionChange}
      children={
        <div className="flex flex-col">
          <div className="flex">
            <p
              className={`${
                !tests.length ? "text-crimson" : "text-cloudBurst"
              } font-medium w-[43%]`}
            >
              Test Duration *
            </p>
            <p
              className={`${
                frequencyNotSelected ? "text-crimson" : "text-cloudBurst"
              } font-medium`}
            >
              Testing Frequency *
            </p>
          </div>

          {getTestDurationOptions().map(({ label, value }) => (
            <GaitTestInput
              key={value}
              label={label}
              duration={value}
              tests={tests}
              onChange={(value) => handleDataChange({ tests: value })}
            />
          ))}
          <GaitTestCustomInput
            tests={tests}
            onChange={(value) => handleDataChange({ tests: value })}
          />
        </div>
      }
    />
  );
};

export default GaitTest;
