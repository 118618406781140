import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import biomechFullLogo from "../../assets/images/biomech_full_logo.svg";
import Sidebar from "../Sidebar/Sidebar";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <div className="relative h-12 flex justify-center items-center bg-gradient-to-r from-prussianBlue via-endeavour to-pelorous">
      <button
        className="absolute left-5 top-1/2 -translate-y-1/2 flex"
        onClick={() => setIsMenuOpen(true)}
      >
        <span className="icon_svg icon_menu" />
      </button>

      <img src={biomechFullLogo} className="h-4" alt="logo" />

      <Drawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{ className: "w-4/5" }}
      >
        <Sidebar closeMobileMenu={() => setIsMenuOpen(false)} />
      </Drawer>
    </div>
  );
};

export default Header;
