import { ActiveProductMaskPosition, Apps } from "enums/Common";
import { useRecoilValue } from "recoil";
import { AccountAuth, Auth } from "recoil-states/auth-states";

const useAvailableApps = () => {
  const auth = useRecoilValue(Auth);
  const accountAuth = useRecoilValue(AccountAuth);

  const activeProduct =
    accountAuth?.initialData?.user.activeProduct ??
    auth?.initialData?.user.activeProduct;

  const hasInClinic =
    activeProduct?.[ActiveProductMaskPosition.InClinic] === "1";
  const hasRemote = activeProduct?.[ActiveProductMaskPosition.Remote] === "1";
  const hasAtHome = activeProduct?.[ActiveProductMaskPosition.AtHome] === "1";

  const availableApps = [
    ...(hasInClinic ? [Apps.InClinic] : []),
    ...(hasAtHome ? [Apps.AtHome] : []),
    ...(hasRemote ? [Apps.Remote] : []),
  ];

  // In Clinic Accounts can also create At Home Order
  const canCreateAtHomeOrder = hasAtHome || hasInClinic;

  const canCreateRemoteOrder = hasRemote;

  return {
    availableApps,
    canCreateAtHomeOrder,
    canCreateRemoteOrder,
  };
};

export default useAvailableApps;
