import { useCallback, useMemo, useState } from "react";
import Button from "../../Buttons/Button/Button";
import RatesContainer from "../RatesContainer/RatesContainer";
import { ISelectedRate } from "../../../interfaces/Shipping";
import { ISensorDetails } from "../../../interfaces/Sensor";
import ShippingService from "../../../services/ShippingService";
import Loading from "../../Loading/Loading";
import TotalPriceBox from "../../TotalPriceBox/TotalPriceBox";

interface GeneratedLabelsSectionProps {
  sensor: ISensorDetails;
  onCancel: () => void;
  onSuccess: () => void;
  onError: (message?: string) => void;
}

enum Rates {
  Outgoing = 1,
  All = 2,
}

const GeneratedLabelsSection = ({
  sensor,
  onCancel,
  onSuccess,
  onError,
}: GeneratedLabelsSectionProps) => {
  const [shownRates, setShownRates] = useState<Rates | undefined>(undefined);
  const [outgoingRate, setOutgoingRate] = useState<ISelectedRate | undefined>(
    undefined
  );
  const [returnRate, setReturnRate] = useState<ISelectedRate | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const totalPrice = useMemo(
    (): number | undefined =>
      outgoingRate || returnRate
        ? [outgoingRate?.rate, returnRate?.rate]
            .filter((x) => x)
            .reduce((sum, a) => sum + Number(a), 0)
        : undefined,
    [outgoingRate, returnRate]
  );

  const handleRatesFetchFail = useCallback(() => {
    setShownRates(undefined);
    onError("Error while fetching rates. Please try again.");
  }, [onError]);

  const handleGenerate = useCallback(() => {
    setLoading(true);

    ShippingService.buyShipment({
      sensorId: sensor.id,
      shipmentLabel: { id: outgoingRate!.shipmentId, rateId: outgoingRate!.id },
      ...(returnRate && {
        returnLabel: { id: returnRate.shipmentId, rateId: returnRate.id },
      }),
    })
      .then(() => onSuccess())
      .catch(() => onError())
      .finally(() => setLoading(false));
  }, [sensor, outgoingRate, returnRate, onError, onSuccess]);

  return (
    <div className="h-full flex flex-col gap-7">
      <div className="relative h-[355px]">
        {/* Price box */}
        {totalPrice && (
          <TotalPriceBox
            className="absolute right-0 -top-16 -translate-y-full md:-top-5"
            value={totalPrice}
          />
        )}

        {!shownRates ? (
          <div className="pt-24 h-full flex flex-col items-center gap-7 border rounded-md bg-white/30 border-pelorous/30">
            <p className="text-center text-cloudBurst font-medium">
              Click the button bellow to see the list of rates
            </p>
            <Button onClick={() => setShownRates(Rates.Outgoing)}>
              Show Rates
            </Button>
          </div>
        ) : (
          <>
            <button
              className="text-pelorous font-medium absolute right-0 top-4 -translate-y-full"
              onClick={() =>
                setShownRates(
                  shownRates === Rates.Outgoing ? Rates.All : Rates.Outgoing
                )
              }
            >
              {shownRates === Rates.Outgoing
                ? "Show Return Rates"
                : "Hide Return Rates"}
            </button>

            <div className="h-full flex flex-wrap gap-6 [&>div]:min-w-[calc(50%-12px)]">
              <RatesContainer
                patientId={sensor.patientId}
                selected={outgoingRate}
                onSelect={setOutgoingRate}
                onFetchFail={handleRatesFetchFail}
              />

              <RatesContainer
                isReturn
                hidden={shownRates !== Rates.All}
                patientId={sensor.patientId}
                selected={returnRate}
                onSelect={setReturnRate}
                onFetchFail={handleRatesFetchFail}
              />
            </div>
          </>
        )}
      </div>

      <div className="self-center flex flex-col items-center gap-5 sm:flex-row">
        <Button
          className="border-2 border-pelorous !bg-white !text-pelorous"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button disabled={!outgoingRate} onClick={handleGenerate}>
          Generate Label(s) with Selected Rate
        </Button>
      </div>

      <Loading loading={loading} />
    </div>
  );
};

export default GeneratedLabelsSection;
