import { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import CircleIcon from "@mui/icons-material/Circle";
import InputField from "../../../../InputField/InputField";
import { FrequencyType } from "../../../../../enums/Order";
import styles from "./TestFrequencyInputs.module.scss";

interface TestFrequencyInputsProps {
  className?: string;
  hideLabel?: boolean;
  isTestSelected: boolean;
  frequency: FrequencyType | undefined;
  frequencyReps: number | undefined;
  onChange: (value: {
    frequency?: FrequencyType;
    frequencyReps?: number;
  }) => void;
}

const TestFrequencyInputs = (props: TestFrequencyInputsProps) => {
  const {
    className,
    hideLabel,
    isTestSelected,
    frequency,
    frequencyReps,
    onChange,
  } = props;

  const showRepsError = isTestSelected && !frequencyReps;

  const radioOptions = useMemo(
    (): {
      label: string;
      value: FrequencyType;
    }[] => [
      {
        label: "Daily",
        value: FrequencyType.Daily,
      },
      {
        label: "Weekly",
        value: FrequencyType.Weekly,
      },
    ],
    []
  );

  return (
    <div className={`flex flex-col gap-2 ${className ?? ""}`}>
      {!hideLabel && (
        <label
          className={`text-base leading-4 font-medium ${
            !frequency ? "text-crimson" : "text-cloudBurst"
          }`}
        >
          Testing Frequency *
        </label>
      )}

      <FormControl className={styles.radio_selection}>
        <RadioGroup
          value={frequency ?? ""}
          onChange={({ target: { value } }) =>
            onChange({ frequency: Number(value) })
          }
        >
          {radioOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Radio
                  icon={<CircleTwoToneIcon />}
                  checkedIcon={<CircleIcon />}
                />
              }
              {...option}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <div className="self-start relative">
        <InputField
          hideErrorMessage
          // disable input field if frequency type isn't selected
          disabled={!frequency}
          className="w-20"
          required
          isNumericField
          error={showRepsError}
          placeholder="Enter #"
          value={
            frequencyReps !== undefined ? String(frequencyReps) : undefined
          }
          onChange={({ target: { value } }) =>
            onChange({
              frequencyReps: value.length ? Number(value) : undefined,
            })
          }
        />

        {frequency && (
          // hide label until type isn't selected
          <span className="absolute left-[93px] bottom-2.5 text-cloudBurst text-base font-medium whitespace-nowrap">
            per {frequency === FrequencyType.Daily ? "day" : "week"}
          </span>
        )}
      </div>
    </div>
  );
};

export default TestFrequencyInputs;
