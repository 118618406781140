import { ButtonHTMLAttributes, useState } from "react";
import Button from "components/Buttons/Button/Button";
import AddOperationsAttachmentModal from "components/Modals/AddOperationsAttachmentModal/AddOperationsAttachmentModal";

interface AddOperationsAttachmentButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onSuccess: () => void;
  onError: () => void;
}

const AddOperationsAttachmentButton = (
  props: AddOperationsAttachmentButtonProps
) => {
  const { onSuccess, onError, ...rest } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Button {...rest} onClick={() => setIsModalOpen(true)}>
        Add Attachment
      </Button>

      {isModalOpen && (
        <AddOperationsAttachmentModal
          onClose={() => setIsModalOpen(false)}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    </>
  );
};

export default AddOperationsAttachmentButton;
