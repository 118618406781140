import { useEffect, useMemo, useState } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { TestType } from "../../../enums/Test";
import { formatGender } from "../../../helpers/PatientHelper";
import { IPatient } from "../../../interfaces/Patient";
import { IOrderTest } from "../../../interfaces/Test";
import TestService from "../../../services/TestService";
import Accordion from "../../Accordion/Accordion";
import ButtonCloseModal from "../../Buttons/ButtonCloseModal/ButtonCloseModal";
import ButtonRunReport from "../../Buttons/ButtonRunReport/ButtonRunReport";
import Loading from "../../Loading/Loading";
import PropertyLabel from "../../PropertyLabel/PropertyLabel";
import ReportsModal from "../ReportsModal/ReportsModal";
import AnglesTestDetails from "./TestDetails/AnglesTestDetails/AnglesTestDetails";
import BalanceTestDetails from "./TestDetails/BalanceTestDetails/BalanceTestDetails";
import CognitiveTestDetails from "./TestDetails/CognitiveTestDetails/CognitiveTestDetails";
import FreeformAnglesTestDetails from "./TestDetails/FreeformAnglesTestDetails/FreeformAnglesTestDetails";
import GaitTestDetails from "./TestDetails/GaitTestDetails/GaitTestDetails";
import SymmetryTestDetails from "./TestDetails/SymmetryTestDetails/SymmetryTestDetails";
import AnglesTestResults from "./TestResults/AnglesTestResults/AnglesTestResults";
import BalanceTestResults from "./TestResults/BalanceTestResults/BalanceTestResults";
import CognitiveTestResults from "./TestResults/CognitiveTestResults/CognitiveTestResults";
import FreeformAnglesTestResults from "./TestResults/FreeformAnglesTestResults/FreeformAnglesTestResults";
import GaitTestResults from "./TestResults/GaitTestResults/GaitTestResults";
import SymmetryTestResults from "./TestResults/SymmetryTestResults/SymmetryTestResults";
import { formatDashDate, getTimezone } from "../../../helpers/CommonHelper";
import useSnackbar from "../../../hooks/useSnackbar";
import { SlideTimeout } from "../../../enums/Common";
import Button from "../../Buttons/Button/Button";
import { handleExportResults } from "../../../helpers/ExportResultsHelper";
import ReportsService from "../../../services/ReportsService";

interface IViewResultsModal {
  patient: IPatient;
  orderTestId: string;
  orderIterationId: string;
  handleClose: () => void;
}

const ViewResultsModal = ({
  patient: { fullName, externalId, dob, gender },
  orderTestId,
  orderIterationId,
  handleClose,
}: IViewResultsModal) => {
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [test, setTest] = useState<IOrderTest | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isReportsModalOpen, setIsReportsModalOpen] = useState<boolean>(false);

  const resultsData:
    | {
        label: string;
        testDetails: JSX.Element;
        results: JSX.Element;
      }
    | undefined = useMemo(() => {
    switch (test?.testId) {
      case TestType.Angles:
        return {
          label: "Angles",
          testDetails: <AnglesTestDetails test={test} />,
          results: <AnglesTestResults test={test} />,
        };
      case TestType.Balance:
        return {
          label: "Balance",
          testDetails: <BalanceTestDetails test={test} />,
          results: (
            <BalanceTestResults orderIteration={test.orderIterations[0]} />
          ),
        };
      case TestType.Cognitive:
        return {
          label: "Cognitive",
          testDetails: <CognitiveTestDetails test={test} />,
          results: (
            <CognitiveTestResults orderIteration={test.orderIterations[0]} />
          ),
        };
      case TestType.FreeformAngles:
        return {
          label: "Freeform angles",
          testDetails: <FreeformAnglesTestDetails test={test} />,
          results: (
            <FreeformAnglesTestResults
              orderIteration={test.orderIterations[0]}
            />
          ),
        };
      case TestType.Gait:
        return {
          label: "Gait",
          testDetails: <GaitTestDetails test={test} />,
          results: <GaitTestResults orderIteration={test.orderIterations[0]} />,
        };
      case TestType.Symmetry:
        return {
          label: "Symmetry",
          testDetails: <SymmetryTestDetails test={test} />,
          results: (
            <SymmetryTestResults orderIteration={test.orderIterations[0]} />
          ),
        };
    }
  }, [test]);

  useEffect(() => {
    setLoading(true);

    TestService.getTest(orderTestId)
      .then(({ data }) => {
        let orderTests = data;
        const filteredData = data.orderIterations.filter(
          (x: { id: string }) => x.id === orderIterationId
        );
        orderTests.orderIterations = filteredData;
        setTest(orderTests);
      })
      .finally(() => setLoading(false));
  }, [orderTestId, orderIterationId]);

  return (
    <Modal open onClose={handleClose}>
      <Slide in direction="left" timeout={SlideTimeout.Default}>
        <div
          className={`overflow-auto bg-white border border-cloudBurst rounded-t-md px-9 py-5 absolute top-3 right-0 bottom-0 left-0 lg:landscape:left-auto lg:landscape:w-[732px] xl:left-auto xl:w-[732px] ${
            isReportsModalOpen ? "hidden" : ""
          }`}
        >
          <ButtonCloseModal className="left-4" onClick={handleClose} />

          {resultsData && (
            <div className="h-full flex flex-col">
              <div className="text-center mb-3 text-lg font-bold text-cloudBurst">
                {resultsData?.label} results
              </div>

              <ButtonRunReport
                className="w-fit mb-3 self-end relative top-auto right-auto md:absolute md:top-4 md:right-9 z-10"
                onClick={() => setIsReportsModalOpen(true)}
              />

              <div className="grow overflow-auto">
                <Accordion summary="Patient">
                  <div className="flex flex-col gap-4 py-4 bg-pattensBlue rounded border border-pelorous">
                    <div className="flex flex-col gap-4 md:flex-row">
                      <div className="w-1/2">
                        <PropertyLabel
                          property="Patient Name"
                          value={fullName}
                        />
                      </div>
                      <div className="w-1/2">
                        <PropertyLabel
                          property="Patient ID"
                          value={externalId}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 md:flex-row">
                      <div className="w-1/2">
                        <PropertyLabel
                          property="Date of Birth"
                          value={formatDashDate(dob)}
                        />
                      </div>
                      <div className="w-1/2">
                        <PropertyLabel
                          property="Gender"
                          value={formatGender(gender)}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion>

                <Accordion summary="Test details">
                  <div className="px-2 py-4 bg-pattensBlue rounded border border-pelorous">
                    {resultsData.testDetails}
                  </div>
                </Accordion>

                <Accordion summary="Results">
                  <div className="flex flex-col items-end gap-3">
                    <div className="overflow-auto px-6 py-4 self-stretch bg-pattensBlue rounded border border-pelorous">
                      {resultsData.results}
                    </div>
                    <Button
                      className="!border-2 !border-pelorous !bg-white !text-pelorous"
                      onClick={() =>
                        handleExportResults({
                          params: test!.orderIterations[0].id,
                          onError: setSnackbarError,
                        })
                      }
                    >
                      Export Results
                    </Button>
                  </div>
                </Accordion>
              </div>
            </div>
          )}

          <Loading loading={loading} />

          {isReportsModalOpen && test && (
            <ReportsModal
              reportPromises={[
                ReportsService.getSingle({
                  id: test.orderIterations[0].id,
                  _timezone: getTimezone(),
                  showSubjectAttributes: true,
                }),
              ]}
              onClose={() => setIsReportsModalOpen(false)}
              onError={setSnackbarError}
            />
          )}

          {snackbarComponent}
        </div>
      </Slide>
    </Modal>
  );
};

export default ViewResultsModal;
