import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DebouncedSearchField from "../../components/DebouncedSearchField/DebouncedSearchField";
import PageTitleDescription from "../../components/PageTitleDescription/PageTitleDescription";
import PatientsTable from "../../components/Tables/PatientsTable/PatientsTable";
import { BooleanEnum, OrderDirection, PageSize } from "../../enums/Common";
import { IPatientFilters } from "../../interfaces/Patient";
import ButtonCreatePatient from "../../components/Buttons/ButtonCreatePatient/ButtonCreatePatient";
import { IToggleButton } from "../../interfaces/Common";
import { ToggleButtons } from "../../components/ToggleButtons/ToggleButtons";

const PatientsDashboard = () => {
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<IPatientFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `last_name ${OrderDirection.Asc}`,
    name: searchParams.get("name") || undefined,
    isShortlist: Number(searchParams.get("isShortlist")) || undefined,
    id: searchParams.get("id") || undefined,
  });

  const toggleButtons = useMemo(
    (): IToggleButton[] => [
      {
        label: "Shortlist",
        onClick: () =>
          setFilters((prev) => ({
            ...prev,
            page: 1,
            isShortlist: BooleanEnum.True,
          })),
      },
      {
        label: "All",
        onClick: () =>
          setFilters((prev) => ({
            ...prev,
            page: 1,
            isShortlist: undefined,
          })),
      },
    ],
    []
  );

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="pr-5 flex justify-between flex-col gap-6 lg:flex-row xl:pr-0">
        <div className="flex flex-col gap-4">
          <PageTitleDescription
            title="Patients"
            description="Search and select a patient to view their test results, orders and
          information."
          />

          <ToggleButtons
            buttons={toggleButtons}
            initialActiveIndex={filters.isShortlist ? 0 : 1}
          />
        </div>

        <div className="flex flex-col items-center gap-6 sm:flex-row sm:justify-between lg:flex-col lg:self-end lg:items-end">
          <ButtonCreatePatient
            className="shrink-0"
            onCreated={() => setFilters((prev) => ({ ...prev, page: 1 }))}
          />

          <DebouncedSearchField
            className="self-stretch"
            placeholder="Search by Patient Name or Patient ID"
            value={filters.name}
            onChange={(value) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                name: value,
              }))
            }
          />
        </div>
      </div>

      <div className="grow h-0">
        <PatientsTable filters={filters} setFilters={setFilters} />
      </div>
    </div>
  );
};

export default PatientsDashboard;
