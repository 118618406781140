import { SensorStatus } from "../enums/Sensor";

export const formatSensorStatusLabel = (status: SensorStatus): string => {
  switch (status) {
    case SensorStatus.Unassigned:
      return "Unassigned";
    case SensorStatus.InWarehouse:
      return "In warehouse";
    case SensorStatus.ShippedToCustomer:
      return "Shipped to customer";
    case SensorStatus.AtCustomer:
      return "At customer";
    case SensorStatus.AssignedInternal:
      return "Assigned internal";
    case SensorStatus.Damaged:
      return "Damaged";
    case SensorStatus.Lost:
      return "Lost";
    default:
      return "-";
  }
};
