import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { isEqual } from "lodash";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { ICreateUser, IUpdateUser, IUser } from "interfaces/User";
import InputField from "components/InputField/InputField";
import RadioInput from "components/RadioInput/RadioInput";
import Button from "components/Buttons/Button/Button";
import { DeepPartial } from "interfaces/Common";
import useValidationErrors from "hooks/useValidationErrors";
import RolesDropdown from "components/Dropdowns/RolesDropdown/RolesDropdown";
import { UserType } from "enums/User";
import UserService from "services/UserService";
import AuthService from "services/AuthService";
import useSnackbar from "hooks/useSnackbar";
import { generateRandomPassword } from "helpers/CommonHelper";

interface UserModalProps {
  initialData?: PartialUser;
  onClose: () => void;
  onSuccess: (value: string) => void;
  onError: () => void;
}

// on create, no initial data will be passed, so we need to make all params optional
type PartialUser = DeepPartial<IUser>;

const UserModal = ({
  initialData = {},
  onClose,
  onSuccess,
  onError,
}: UserModalProps) => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const { snackbarComponent, setSnackbarError, setSnackbarSuccess } =
    useSnackbar();

  const [data, setData] = useState<PartialUser>(initialData);
  const [roleId, setRoleId] = useState<number | undefined>(
    initialData.maxRoleId ?? undefined
  );
  const [isResetPasswordRequestActive, setIsResetPasswordRequestActive] =
    useState<boolean>(false);
  const [isSubmitRequestActive, setIsSubmitRequestActive] =
    useState<boolean>(false);

  const editMode = useMemo(
    (): boolean => Boolean(Object.keys(initialData).length),
    [initialData]
  );

  const noChangedData = useMemo(
    (): boolean => isEqual(initialData, data),
    [initialData, data]
  );

  const noChangedRole = useMemo(
    (): boolean => initialData.maxRoleId === roleId,
    [initialData.maxRoleId, roleId]
  );

  const disableSubmit = useMemo(
    () =>
      hasErrors ||
      !roleId ||
      isSubmitRequestActive ||
      (noChangedData && noChangedRole),
    [roleId, hasErrors, noChangedData, noChangedRole, isSubmitRequestActive]
  );

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const validateEmail = useCallback((value: string) => {
    return UserService.validateEmail({ email: value });
  }, []);

  const handleCreate = useCallback(() => {
    const createData: ICreateUser = {
      email: data.email!,
      firstName: data.firstName!,
      lastName: data.lastName!,
      middleName: data.middleName ?? undefined,
      password: generateRandomPassword(), // user will receive email for creating new password
      type: data.userType,
    };

    setIsSubmitRequestActive(true);

    UserService.createUser(createData)
      .then(({ data: { id } }) => id as string)
      .then((id) => UserService.setRoles({ userId: id, roleIds: [roleId!] }))
      .then(() => onSuccess("User has been successfully created."))
      .catch(() => onError())
      .finally(() => onClose());
  }, [data, roleId, onClose, onSuccess, onError]);

  const handleEdit = useCallback(() => {
    const updateData: IUpdateUser = {
      email: data.email,
      firstName: data.firstName,
      middleName: data.middleName ?? undefined,
      lastName: data.lastName,
      password: data.password,
      type: data.userType,
    };

    const requests: Promise<AxiosResponse>[] = [
      ...(!noChangedData ? [UserService.updateUser(data.id!, updateData)] : []),
      // if role is changed, assign new one
      ...(!noChangedRole
        ? [UserService.setRoles({ userId: data.id!, roleIds: [roleId!] })]
        : []),
    ];

    setIsSubmitRequestActive(true);

    Promise.all(requests)
      .then(() => onSuccess("User has been successfully updated."))
      .catch(() => onError())
      .finally(() => onClose());
  }, [data, noChangedData, noChangedRole, roleId, onClose, onSuccess, onError]);

  const handleSendPasswordResetEmail = () => {
    if (!initialData.email) return;

    setIsResetPasswordRequestActive(true);

    AuthService.forgotPassword({ email: initialData.email, portal: true })
      .then(() => setSnackbarSuccess("Password reset email successfully sent."))
      .catch(() => setSnackbarError())
      .finally(() => setIsResetPasswordRequestActive(false));
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 max-h-[80%] max-w-[745px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-6 md:px-10 md:py-7">
        <ButtonCloseModal className="top-4" onClick={onClose} />

        <h2 className="self-center text-lg font-bold text-cloudBurst leading-5">
          {editMode ? "User Profile" : "Create new user"}
        </h2>

        <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-12px)] md:justify-between overflow-auto">
          <InputField
            required
            label="Last Name"
            placeholder="Enter last name"
            name="lastName"
            value={data.lastName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <InputField
            required
            label="First Name"
            placeholder="Enter first name"
            name="firstName"
            value={data.firstName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <InputField
            label="Middle Name/Initial"
            placeholder="Enter middle name or initial"
            name="middleName"
            value={data.middleName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <RadioInput
            required
            label="Ordering Clinician"
            infoTooltipContent={
              <div className="flex flex-col gap-4 text-left">
                <p>
                  Select “Yes” if the user is a Qualified Healthcare
                  Professional that is eligible to order and bill for RTM
                  services.
                </p>
                <p>
                  Ordering Clinicians will appear within the Clinician tab. They
                  will also be an Ordering Clinician option on the RTM Order
                  Form.
                </p>
              </div>
            }
            value={data.userType === UserType.Clinician}
            onChange={(value) =>
              setData((prev) => ({
                ...prev,
                userType: value ? UserType.Clinician : UserType.Standard,
              }))
            }
          />
          <InputField
            required
            type="email"
            label="Email"
            placeholder="Enter email address"
            name="email"
            value={data.email}
            initialValue={initialData.email}
            validateInput={validateEmail}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <RolesDropdown
            placeholder={initialData.maxRoleName ?? "Select a role"}
            value={roleId}
            onChange={setRoleId}
          />

          <div className="flex flex-col gap-5">
            {editMode && (
              <>
                <InputField
                  reduceFontSizeOnError
                  autoComplete="new-password"
                  infoTooltipContent={
                    <div className="text-left">
                      <p>Your password must contain: </p>
                      <ul className="list-disc list-inside [&>li]:ml-3">
                        <li>At least 8 characters</li>
                        <li>Both lower and uppercase letters</li>
                        <li>At least 1 number</li>
                        <li>At least 1 special character</li>
                      </ul>
                    </div>
                  }
                  type="password"
                  label="Reset User Password"
                  placeholder="Enter password"
                  name="password"
                  value={data.password}
                  onChange={handleInputChange}
                  onValidationErrorChange={handleValidationErrorChange}
                />

                <div className="flex justify-end items-center gap-7">
                  <span className="text-cloudBurst font-medium">Or</span>
                  <button
                    className="relative text-white bg-dodgerBlue rounded px-8 py-2 disabled:opacity-50"
                    disabled={isResetPasswordRequestActive}
                    onClick={handleSendPasswordResetEmail}
                  >
                    Send password reset email
                    {isResetPasswordRequestActive && (
                      <CircularProgress
                        className="absolute right-2 top-3 "
                        size={17}
                        sx={{ color: "white" }}
                      />
                    )}
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="self-end flex justify-end">
            <Button
              disabled={disableSubmit}
              loading={isSubmitRequestActive}
              onClick={editMode ? handleEdit : handleCreate}
            >
              {editMode ? "Save Changes" : "Create New User"}
            </Button>
          </div>
        </div>

        {snackbarComponent}
      </div>
    </Modal>
  );
};

export default UserModal;
