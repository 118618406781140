import axios from "../config/axios";
import { ISensorFilters, ISensorUpdateBaseData } from "../interfaces/Sensor";
import { API } from "../enums/API";
import { SensorStatus } from "../enums/Sensor";

class SensorService {
  getAll = (params: Partial<ISensorFilters>, signal?: AbortSignal) => {
    return axios.get(API.Sensors, { params, signal });
  };

  getOne = (id: string) => {
    return axios.get(API.Sensors, { params: { id } });
  };

  getHistory = (id: string) => {
    return axios.get(API.SensorHistory, { params: { id } });
  };

  getDashboardData = () => {
    return axios.get(API.SensorDashboard);
  };

  assign = (data: ISensorUpdateBaseData & { patientId: string }) => {
    return axios.post(API.SensorAssign, data);
  };

  unassign = (data: ISensorUpdateBaseData) => {
    return axios.post(API.SensorUnassign, data);
  };

  updateStatus = (data: ISensorUpdateBaseData & { statusId: SensorStatus }) => {
    return axios.post(API.SensorStatusUpdate, data);
  };
}

export default new SensorService();
