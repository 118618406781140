import { useEffect, useState } from "react";
import axios from "axios";
import { RollupInterval, TestType, Interval } from "enums/Test";
import {
  calculateCreatedOnFromInterval,
  getTrendsAndUsageIntervalOptions,
} from "helpers/TrendsAndUsageHelper";
import ToggleInterval from "components/ToggleInterval/ToggleInterval";
import BlueRibbon from "components/PatientDashboard/BlueRibbon/BlueRibbon";
import {
  IBalanceTrendsData,
  IPatientTestUsage,
  ITrendParams,
} from "interfaces/Patient";
import TestService from "services/TestService";
import UsageChart from "components/PatientDashboard/UsageChart/UsageChart";
import { formatEyesLabel, formatFeetLabel } from "helpers/TestHelper";
import OverallChangeBox from "components/PatientDashboard/OverallChangeBox/OverallChangeBox";
import Loading from "components/Loading/Loading";
import TrendsChart from "components/TrendsChart/TrendsChart";
import { IUsageParams } from "interfaces/Test";
import { usePatientViewLayoutContext } from "components/Layouts/PatientViewLayout/PatientViewLayout";
import NoTestsBox from "components/NoTestsBox/NoTestsBox";

const BalanceTrendsAndUsage = () => {
  const {
    filters: { patientId, showTrends, balanceInterval },
    setFilters,
  } = usePatientViewLayoutContext();

  const [trendsData, setTrendsData] = useState<IBalanceTrendsData>(null);
  const [usageData, setUsageData] = useState<IPatientTestUsage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const sortedTrendsData = trendsData
    ? trendsData.sort((a, b) => a.eyes - b.eyes)
    : null;
  const noData = showTrends ? !trendsData?.length : !usageData.length;

  // balance trends can have multiple charts items
  // usage always have one chart
  // if there is 1 item, it should have same style as gait
  const useOneItemStyle =
    noData || !showTrends || (showTrends && trendsData?.length === 1);

  useEffect(() => {
    if (!patientId) return;

    setLoading(true);

    const controller = new AbortController();

    const commonParams = { testId: TestType.Balance, patientId };
    const intervalFilter = calculateCreatedOnFromInterval({
      interval: balanceInterval,
      isTrends: showTrends,
    });

    const trendParams: ITrendParams = {
      ...commonParams,
      rollup: RollupInterval.Day,
      createdOn: intervalFilter,
    };

    const usageParams: IUsageParams = {
      ...commonParams,
      from: intervalFilter,
    };

    (showTrends
      ? TestService.getTrendsData(trendParams, controller.signal)
      : TestService.getUsageData(usageParams, controller.signal)
    )
      .then(({ data }) => {
        if (showTrends) {
          setTrendsData(data);
        } else {
          setUsageData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;

        setLoading(false);
      });

    return () => {
      controller.abort();
      setUsageData([]);
      setTrendsData(null);
    };
  }, [showTrends, patientId, balanceInterval]);

  const renderContent = (): JSX.Element => {
    switch (true) {
      case loading:
        return (
          <div className="grow h-0 bg-endeavour/10 relative">
            <Loading loading />
          </div>
        );
      case noData:
        return (
          <div className="grow h-0 bg-endeavour/10">
            <NoTestsBox />
          </div>
        );
      default:
        return (
          <>
            {showTrends ? (
              <>
                {sortedTrendsData?.map(
                  ({ eyes, feet, trends, trend }, index) => (
                    <div
                      key={index}
                      className="h-[500px] flex gap-5 sm:h-[392px]"
                    >
                      <div
                        className={`grow w-0 flex flex-col overflow-hidden ${
                          useOneItemStyle
                            ? ""
                            : "rounded-[20px] rounded-tr-lg border-[3px] border-pattensBlue3"
                        }`}
                      >
                        <div className="h-[44px] flex flex-col gap-1.5 sm:flex-row">
                          <p className="pl-3 mt-4 text-cloudBurst text-lg leading-5 font-bold">{`${formatFeetLabel(
                            feet
                          )}, Eyes ${formatEyesLabel(eyes)}`}</p>
                        </div>

                        <div className="grow h-0 bg-endeavour/10 relative">
                          <TrendsChart
                            data={trends}
                            rollup={RollupInterval.Day}
                          />
                        </div>
                      </div>

                      <OverallChangeBox value={trend} />
                    </div>
                  )
                )}
              </>
            ) : (
              <div className="bg-endeavour/10 h-[342px]">
                <UsageChart data={usageData} />
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <BlueRibbon text={`BALANCE ${showTrends ? "TRENDS" : "USAGE"}`} />

      <div
        className={useOneItemStyle ? "h-[500px] flex gap-5 sm:h-[392px]" : ""}
      >
        <div
          className={
            useOneItemStyle
              ? "grow w-0 flex flex-col rounded-[20px] overflow-hidden rounded-tr-lg border-[3px] border-pattensBlue3"
              : "flex flex-col gap-5"
          }
        >
          <div className="flex flex-col gap-1.5 sm:flex-row">
            <p className="pl-3 mt-4 text-cloudBurst text-lg leading-5 font-bold">{`BALANCE ${
              showTrends ? "SCORE" : "TESTS"
            } OVER THE MOST RECENT:`}</p>
            <ToggleInterval<Interval>
              active={balanceInterval}
              options={getTrendsAndUsageIntervalOptions()}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, balanceInterval: value }))
              }
            />
          </div>

          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default BalanceTrendsAndUsage;
