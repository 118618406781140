import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import LoginInput from "../../components/LoginInput/LoginInput";
import { URLRoutes } from "../../enums/Routing";
import { doesPasswordMeetsRequirements } from "../../helpers/CommonHelper";
import { KeyboardKey } from "../../enums/Common";
import AuthService from "../../services/AuthService";
import useSnackbar from "../../hooks/useSnackbar";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [password, setPassword] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsSubmitDisabled(!password || !doesPasswordMeetsRequirements(password));
  }, [password]);

  const handleResetPassword = useCallback(() => {
    const token = searchParams.get("pwd_token");

    setIsSubmitDisabled(true);

    AuthService.resetPassword({
      pwd: password as string,
      token: token as string,
    })
      .then(() => {
        setSnackbarSuccess("Password successfully changed.");

        setTimeout(() => {
          navigate(URLRoutes.Login);
        }, 2000);
      })
      .catch(() => {
        setSnackbarError();
      });
  }, [password, searchParams, navigate, setSnackbarSuccess, setSnackbarError]);

  const handleKeyPress = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key !== KeyboardKey.Enter || isSubmitDisabled) return;

      handleResetPassword();
    },
    [handleResetPassword, isSubmitDisabled]
  );

  return (
    <div onKeyDown={handleKeyPress}>
      <div className="mb-6 text-center text-white text-lg font-bold">
        Create new password
      </div>

      <div className="px-6 mb-6 text-center text-white text-base font-medium">
        Password must contain at least 8 characters, both lower and uppercase
        letters, at least 1 number and 1 special character.
      </div>

      <div className="mb-9 flex flex-col space-y-4 md:portrait:space-y-5 lg:space-y-5">
        <LoginInput
          label="Password"
          placeholder="Enter your password"
          name="password"
          type={showPassword ? "text" : "password"}
          value={password || ""}
          onChange={({ target: { value } }) => setPassword(value)}
          InputProps={{
            endAdornment: Boolean(password?.length) ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  <span
                    className={`icon_svg ${
                      showPassword ? "icon_visibility" : "icon_visibility_off"
                    }`}
                  />
                </IconButton>
              </InputAdornment>
            ) : (
              ""
            ),
          }}
        />

        <Link
          to={URLRoutes.Login}
          className="text-center text-pattensBlue text-base font-bold underline"
        >
          Back to Log In
        </Link>
      </div>

      <div className="text-center">
        <button
          className="rounded-3xl border-solid border-2 border-pattensBlue mb-9 px-12 py-3 bg-prussianBlue text-white text-base font-medium disabled:opacity-50"
          disabled={isSubmitDisabled}
          onClick={handleResetPassword}
        >
          Submit
        </button>
      </div>

      {snackbarComponent}
    </div>
  );
};

export default ResetPassword;
