import CardContainer from "components/CardContainer/CardContainer";
import OrderExistingAttachment from "components/OrderExistingAttachment/OrderExistingAttachment";
import { IOrderVersionDetails } from "interfaces/Order";

interface OrderDetailsAttachmentCardProps {
  order: IOrderVersionDetails;
  onDownloaded: (message: string) => void;
  onDownloadFailed: () => void;
}

const OrderDetailsAttachmentCard = (props: OrderDetailsAttachmentCardProps) => {
  const { order, onDownloaded, onDownloadFailed } = props;
  const { id, meta } = order;

  const attachments = meta?.attachments ?? [];

  return (
    <CardContainer title="Attachments (optional)">
      {!attachments.length ? (
        <p className="text-cloudBurst text-base leading-5 font-medium">
          The order has no attachments.
        </p>
      ) : (
        <div className="flex flex-col gap-5">
          {attachments.map((attachment) => (
            <OrderExistingAttachment
              key={attachment.id}
              orderId={id}
              attachment={attachment}
              onDownloaded={onDownloaded}
              onDownloadFailed={onDownloadFailed}
            />
          ))}
        </div>
      )}
    </CardContainer>
  );
};

export default OrderDetailsAttachmentCard;
