import { useMemo } from "react";
import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { Color } from "enums/Common";
import { IPatientTestUsage } from "interfaces/Patient";
import NoTestsBox from "components/NoTestsBox/NoTestsBox";

interface UsageChartProps {
  data: IPatientTestUsage[];
}

const UsageChart = ({ data }: UsageChartProps) => {
  const chartOptions = useMemo((): EChartsOption => {
    const textStyle = {
      padding: 8,
      color: Color.Nero,
      fontFamily: "Cabin",
      fontSize: 12,
      lineHeight: 15,
    };

    return {
      grid: { top: 40, left: 60, right: 30, bottom: 15, containLabel: true },
      tooltip: { trigger: "axis" },
      xAxis: {
        data: data.map(({ date }) => moment(date).format("MM/DD")),
        type: "category",
        axisLine: { show: false },
        axisTick: { alignWithLabel: true },
        axisLabel: textStyle,
      },
      yAxis: {
        type: "value",
        axisLabel: textStyle,
        axisTick: { show: true },
        splitLine: {
          lineStyle: { color: Color.VeryLightGrey },
          show: true,
        },
        minInterval: 1,
        name: "NUMBER OF TESTS",
        nameLocation: "middle",
        nameGap: 50,
        nameTextStyle: {
          ...textStyle,
          fontSize: 16,
          lineHeight: 19,
          fontWeight: 500,
          padding: 0,
        },
      },
      series: [
        {
          data: data.map(({ count }) => count),
          type: "bar",
          barWidth: 15,
          itemStyle: {
            color: Color.TropicalRainForest,
          },
        },
      ],
    };
  }, [data]);

  return !data.length ? (
    <NoTestsBox />
  ) : (
    <ReactECharts
      style={{ height: "100%", width: "100%" }}
      option={chartOptions}
    />
  );
};

export default UsageChart;
