import { useState } from "react";
import { useParams } from "react-router-dom";
import { ISensorHistory } from "../../interfaces/Sensor";
import SensorHistoryTable from "../../components/Tables/SensorHistoryTable/SensorHistoryTable";
import TrackingInfoModal from "../../components/Modals/TrackingInfoModal/TrackingInfoModal";

const SensorHistory = () => {
  const { sensorId } = useParams();

  const [selectedForTracking, setSelectedForTracking] = useState<
    ISensorHistory | undefined
  >(undefined);

  return (
    <div className="h-full">
      {sensorId && (
        <SensorHistoryTable
          sensorId={sensorId}
          onOpenTrackingInfo={setSelectedForTracking}
        />
      )}

      {selectedForTracking && (
        <TrackingInfoModal
          data={selectedForTracking}
          onClose={() => setSelectedForTracking(undefined)}
        />
      )}
    </div>
  );
};

export default SensorHistory;
