import {
  formatDate,
  formatTime,
  millisecondsToHMS,
} from "../../../../../helpers/CommonHelper";
import { formatStatus } from "../../../../../helpers/TestHelper";
import { ITestDetails } from "../../../../../interfaces/Test";
import PropertyLabel from "../../../../PropertyLabel/PropertyLabel";

const SymmetryTestDetails = ({
  test: {
    createdByFirstName,
    createdByLastName,
    createdOn,
    status,
    orderIterations,
    startingPositionCustom,
  },
}: ITestDetails) => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel property="Activity" value={startingPositionCustom} />
        <PropertyLabel property="Status" value={formatStatus(status)} />
        <PropertyLabel
          property="Run by"
          value={`${createdByLastName}, ${createdByFirstName}`}
        />
      </div>
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel
          property="Duration"
          value={millisecondsToHMS(orderIterations[0].duration)}
        />
        <PropertyLabel
          property="Date and time"
          value={formatDate(createdOn) + ", " + formatTime(createdOn)}
        />
        <PropertyLabel
          property="Test ID"
          value={orderIterations[0].accession}
        />
      </div>
    </div>
  );
};

export default SymmetryTestDetails;
