import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Loading from "components/Loading/Loading";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import AllTypesTable from "components/Tables/TestHistoryTables/AllTypesTable/AllTypesTable";
import AnglesTable from "components/Tables/TestHistoryTables/AnglesTable/AnglesTable";
import BalanceTable from "components/Tables/TestHistoryTables/BalanceTable/BalanceTable";
import CognitiveTable from "components/Tables/TestHistoryTables/CognitiveTable/CognitiveTable";
import FreeformAnglesTable from "components/Tables/TestHistoryTables/FreeformAnglesTable/FreeformAnglesTable";
import { GaitTable } from "components/Tables/TestHistoryTables/GaitTable/GaitTable";
import SymmetryTable from "components/Tables/TestHistoryTables/SymmetryTable/SymmetryTable";
import { TestType } from "enums/Test";
import {
  ITestHistoryFilters,
  IHistoryTest,
  TestTableProps,
  IMenuOption,
} from "interfaces/Test";
import TestService from "services/TestService";
import ViewResultsModal from "components/Modals/ViewResultsModal/ViewResultsModal";
import SingleReportsModal from "components/Modals/ReportsModal/ReportsModal";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import { getTimezone } from "helpers/CommonHelper";
import { usePatientViewLayoutContext } from "components/Layouts/PatientViewLayout/PatientViewLayout";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import Button from "components/Buttons/Button/Button";
import CustomReportModal from "components/Modals/CustomReportModal/CustomReportModal";
import { handleExportResults } from "helpers/ExportResultsHelper";
import ReportsService from "services/ReportsService";
import PatientFiltersInstructions from "components/PatientFiltersInstructions/PatientFiltersInstructions";
import { URLRoutes } from "enums/Routing";
import tableStyles from "styles/shared/table.module.scss";

enum Modals {
  CustomReports = 1,
  Results = 2,
  SingleReport = 3,
}

type IModalData =
  | { modal: Modals.CustomReports }
  | { modal: Modals.Results; testId: string; orderItemId: string }
  | { modal: Modals.SingleReport; testId: string };

const PatientResults = () => {
  const { clinicianId } = useParams();

  const { patient, filters, setFilters } = usePatientViewLayoutContext();

  const { apps, tests, createdOn, resultsOrderBy, accession, patientId } =
    filters;

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [modalData, setModalData] = useState<IModalData>();

  const isTestSelected = tests.length > 0;
  const isAppSelected = apps.length > 0;

  // we need sensors data for
  // - Symmetry table
  // - Balance and Gait tests
  const includeSensorsFilter = useMemo(
    (): boolean =>
      (tests.length === 1 && tests[0] === TestType.Symmetry) ||
      !tests.length ||
      tests.includes(TestType.Balance) ||
      tests.includes(TestType.Gait),
    [tests]
  );

  const apiParams = useMemo((): ITestHistoryFilters => {
    const {
      tests,
      ordersOrderBy,
      resultsOrderBy,
      apps,
      rollup,
      showTrends,
      gaitInterval,
      balanceInterval,
      ...rest
    } = filters;

    return {
      ...rest,
      includeSensors: includeSensorsFilter ? true : undefined,
      _order_by: resultsOrderBy,
      testId: tests.length ? tests.join("|") : undefined,
      product: apps.length ? apps.join("|") : undefined,
    };
  }, [filters, includeSensorsFilter]);

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, setData, loading, tableRef, lastElementRef } =
    useFetchTableData<IHistoryTest, ITestHistoryFilters>({
      // no need to fetch data if selected apps or tests are empty
      // we are not showing table in those cases
      preventFetch: !filters.apps.length || !filters.tests.length,
      filters: apiParams,
      onPageIncrement,
      fetchAPI: TestService.getTestHistory,
      onError: setSnackbarError,
    });

  useEffect(() => {
    /*
     each test type has specific table component and it's shown only when single type is selected,
     we need to reset data instantly on those changes 
     or it could happen that we show selected type specific table with other types data (while loading) 
    */
    if (tests.length < 2) {
      setData([]);
    }
  }, [tests, setData]);

  const getMenuOptions = useCallback(
    (orderItemId: string, testId: string): IMenuOption[] => [
      // {
      //   label: "View results",
      //   onClick: () =>
      //     setModalData({ modal: Modals.Results, orderItemId, testId }),
      // },
      {
        label: "Export Results",
        onClick: () =>
          handleExportResults({
            params: testId,
            onError: setSnackbarError,
          }),
      },
    ],
    [setSnackbarError]
  );

  const getTestNotesLink = useCallback(
    (patientId: string, testId: string) => {
      if (clinicianId) {
        return generatePath(URLRoutes.ClinicianPatientTestNotes, {
          clinicianId,
          patientId,
          testId,
        });
      }

      return generatePath(URLRoutes.PatientTestNotes, { patientId, testId });
    },
    [clinicianId]
  );

  const tableComponent = useMemo(() => {
    if (!patientId) return null;

    const props: TestTableProps = {
      lastElementRef,
      tests: data,
      orderBy: resultsOrderBy,
      onSort: (value) =>
        setFilters((prev) => ({ ...prev, page: 1, resultsOrderBy: value })),
      onRunReportBtnClick: (testId) =>
        setModalData({ modal: Modals.SingleReport, testId }),
      getTestNotesLink: (testId) => getTestNotesLink(patientId, testId),
      getMenuOptions,
    };

    if (
      tests.length === 1 &&
      // tremor table will be added in future
      tests[0] !== TestType.Tremor
    ) {
      switch (tests[0]) {
        case TestType.Balance:
          return <BalanceTable {...props} />;
        case TestType.Gait:
          return <GaitTable {...props} />;
        case TestType.Symmetry:
          return <SymmetryTable {...props} />;
        case TestType.Angles:
          return <AnglesTable {...props} />;
        case TestType.FreeformAngles:
          return <FreeformAnglesTable {...props} />;
        case TestType.Cognitive:
          return <CognitiveTable {...props} />;
      }
    } else {
      return <AllTypesTable {...props} />;
    }
  }, [
    lastElementRef,
    tests,
    setFilters,
    data,
    resultsOrderBy,
    getMenuOptions,
    patientId,
    getTestNotesLink,
  ]);

  return (
    <>
      <div className="h-full flex flex-col relative">
        <div className="flex flex-col gap-4 mb-4 pr-5 lg:landscape:pr-5 xl:landscape:pr-0 xl:pr-0 md:flex-row md:justify-between">
          <DebouncedSearchField
            className="order-2 md:order-none"
            placeholder="Search by Test ID"
            value={accession}
            onChange={(value) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                accession: value,
              }))
            }
          />

          <Button
            className="self-start"
            onClick={() => setModalData({ modal: Modals.CustomReports })}
          >
            Run Custom Report
          </Button>
        </div>

        {isAppSelected && isTestSelected ? (
          <div className="relative grow h-0">
            <TableContainer
              className={tableStyles.shared_table_container}
              ref={tableRef}
            >
              {tableComponent}
            </TableContainer>

            {!loading && !data.length && (
              <div className="mt-20">
                <NoTableItems
                  icon="icon_no_results"
                  message="Current filters produced no results. Try removing some of the applied filters."
                />
              </div>
            )}

            <Loading loading={loading} />
          </div>
        ) : (
          <PatientFiltersInstructions
            isAppSelected={isAppSelected}
            isTestSelected={isTestSelected}
          />
        )}

        {patient && modalData?.modal === Modals.Results && (
          <ViewResultsModal
            patient={patient}
            orderTestId={modalData.testId}
            orderIterationId={modalData.orderItemId}
            handleClose={() => setModalData(undefined)}
          />
        )}

        {modalData?.modal === Modals.SingleReport && (
          <SingleReportsModal
            reportPromises={[
              ReportsService.getSingle({
                id: modalData.testId,
                _timezone: getTimezone(),
                showSubjectAttributes: true,
              }),
            ]}
            onClose={() => setModalData(undefined)}
            onError={setSnackbarError}
          />
        )}

        {modalData?.modal === Modals.CustomReports && (
          <CustomReportModal
            initialFilters={{
              apps,
              tests,
              createdOn,
            }}
            onError={setSnackbarError}
            onClose={() => setModalData(undefined)}
          />
        )}
      </div>
      {snackbarComponent}
    </>
  );
};

export default PatientResults;
