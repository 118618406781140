import { Dispatch, SetStateAction, useCallback } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import Loading from "components/Loading/Loading";
import { formatDate } from "helpers/CommonHelper";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import useFetchTableData from "hooks/useFetchTableData";
import { IOrderHistory, IOrderHistoryFilters } from "interfaces/Order";
import { OrderAction } from "enums/Order";
import OrderService from "services/OrderService";
import tableStyles from "styles/shared/table.module.scss";

interface OrderHistoryTableProps {
  filters: IOrderHistoryFilters;
  setFilters: Dispatch<SetStateAction<IOrderHistoryFilters>>;
  onError: () => void;
  onViewVersionClick: (id: number) => void;
}

const tableColumns: ITableColumn[] = [
  { label: "Change Log", sortProperty: "action_type" },
  { label: "Date", sortProperty: "created_on" },
  { label: "Submitted by", sortProperty: "created_by_fn" },
  { label: "" },
];

const OrderHistoryTable = (props: OrderHistoryTableProps) => {
  const { filters, setFilters, onError, onViewVersionClick } = props;

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, loading, tableRef, lastElementRef } = useFetchTableData<
    IOrderHistory,
    IOrderHistoryFilters
  >({
    filters,
    onPageIncrement,
    fetchAPI: OrderService.getHistory,
    onError: onError,
  });

  const formatActionTypeLabel = (actionType: OrderAction): string => {
    switch (actionType) {
      case OrderAction.Create:
        return "Created";
      case OrderAction.Update:
        return "Edited";
      case OrderAction.Cancel:
        return "Cancelled";
    }
  };

  return (
    <div className="h-full relative">
      <TableContainer
        className={tableStyles.shared_table_container}
        ref={tableRef}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map(({ label, sortProperty }, index) => (
                <TableCell key={index}>
                  {sortProperty ? (
                    <TableSortLabel
                      label={label}
                      sortProperty={sortProperty}
                      orderBy={filters._order_by}
                      onSort={(value) =>
                        setFilters((prev) => ({
                          ...prev,
                          page: 1,
                          _order_by: value,
                        }))
                      }
                    />
                  ) : (
                    label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ actionType, createdOn, id, createdByFn }, index) => (
              <TableRow
                key={id}
                ref={index === data.length - 1 ? lastElementRef : null}
              >
                <TableCell>{formatActionTypeLabel(actionType)}</TableCell>
                <TableCell>{formatDate(createdOn)}</TableCell>
                <TableCell>{createdByFn}</TableCell>
                <TableCell>
                  <button
                    className="text-pelorous hover:underline"
                    onClick={() => onViewVersionClick(id)}
                  >
                    View Version
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Loading loading={loading} />

      {!loading && !data.length && (
        <div className="mt-7">
          <NoTableItems icon="icon_no_results" message="No history items" />
        </div>
      )}
    </div>
  );
};

export default OrderHistoryTable;
