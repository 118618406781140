import { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "components/Buttons/Button/Button";
import InputField from "components/InputField/InputField";
import Loading from "components/Loading/Loading";
import useValidationErrors from "hooks/useValidationErrors";
import useSnackbar from "hooks/useSnackbar";
import OrderService from "services/OrderService";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { Color } from "enums/Common";

interface CancelOrderModalProps {
  orderId: string;
  onClose: () => void;
  onEditBtnClick: () => void;
  onSuccess: () => void;
}

const CancelOrderModal = (props: CancelOrderModalProps) => {
  const { orderId, onClose, onEditBtnClick, onSuccess } = props;

  const { hasErrors, handleValidationErrorChange } = useValidationErrors();
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCancel = () => {
    if (!notes) return;

    setLoading(true);

    OrderService.cancel({ orderId, comment: notes })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  };

  return (
    <Modal open onClose={onClose}>
      <>
        <div className="absolute w-4/5 max-w-[506px] flex flex-col items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-2xl bg-white px-10 py-7">
          <ButtonCloseModal
            className="!top-5 left-4"
            color={Color.CloudBurst}
            onClick={onClose}
          />

          <h1 className="mb-5 text-cloudBurst text-lg leading-5 font-bold">
            Confirm Order Cancellation
          </h1>

          <div className="self-stretch">
            <InputField
              required
              multiline
              rows={5}
              label={"Notes"}
              name="notes"
              placeholder="Please enter the reason(s) for canceling this order"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              onValidationErrorChange={handleValidationErrorChange}
            />
          </div>

          <p className="my-7">Are you sure you want to cancel this order?</p>

          <div className="flex gap-5">
            <Button
              className="border-2 border-pelorous !bg-white !text-pelorous"
              onClick={onEditBtnClick}
            >
              Edit Order
            </Button>

            <Button
              className="!bg-crimson"
              disabled={hasErrors}
              onClick={handleCancel}
            >
              Cancel Order
            </Button>
          </div>

          <Loading loading={loading} />
        </div>

        {snackbarComponent}
      </>
    </Modal>
  );
};

export default CancelOrderModal;
