import { useRecoilValue } from "recoil";
import CardContainer from "components/CardContainer/CardContainer";
import GaitTest from "components/Modals/CreateEditOrderModal/TestsCard/GaitTest/GaitTest";
import BalanceTest from "components/Modals/CreateEditOrderModal/TestsCard/BalanceTest/BalanceTest";
import { CardsValidation, ModalState } from "recoil-states/create-order-states";
import { OrderModalState } from "enums/Order";

const TestsCard = () => {
  const modalState = useRecoilValue(ModalState);
  const cardsValidation = useRecoilValue(CardsValidation);

  const isCardDisabled =
    !cardsValidation.patient ||
    !cardsValidation.order ||
    modalState === OrderModalState.InProgress;

  return (
    <CardContainer
      title="Tests"
      className="p-5"
      childrenClassName="flex flex-col gap-6"
      disabled={isCardDisabled}
    >
      <GaitTest />
      <BalanceTest />

      {/* hidden in first version */}
      {/* <AnglesTest /> */}
    </CardContainer>
  );
};

export default TestsCard;
