export enum FrequencyType {
  Daily = 1,
  Weekly = 2,
}

export enum DurationType {
  Seconds = 1,
  Minutes = 2,
  Hours = 3,
  Days = 4,
}

export enum OrderType {
  Standard = 1, // generated by lab
  AtHome = 2,
}

export enum OrderFilterParams {
  PatientName = "patient_lname|patient_fname",
  OrderOrPatientName = "q", // 'q' value can either be full order id (order_num) or partial/full patient first/last name
}

export enum OrderStatus {
  Opened = 1,
  Cancelled = 2,
}

export enum OrderExpiration {
  ExpiresToday = 1,
  ExpiresInOneWeek = 2,
  ExpiresInTwoWeeks = 3,
  RecentlyExpired = 4,
}

export enum OrderAction {
  Create = 1,
  Update = 2,
  Cancel = 3,
}

export enum OrderModalState {
  Initial = 1,
  InProgress = 2,
  Submitted = 3,
}

export enum PayerType {
  Commercial = 1,
  Medicare = 2,
  Medicaid = 3,
  PersonalInjury = 4,
  SelfPay = 5,
  WorkersCompensation = 6,
}
