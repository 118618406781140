import { ChangeEvent, useMemo } from "react";
import { IGaitTestItem } from "../../../../../../interfaces/Order";
import CheckboxInput from "../../../../../CheckboxInput/CheckboxInput";
import InputField from "../../../../../InputField/InputField";
import TestFrequencyInputs from "../../TestFrequencyInputs/TestFrequencyInputs";

interface GaitTestCustomInputProps {
  tests: IGaitTestItem[];
  onChange: (value: IGaitTestItem[]) => void;
}

const GaitTestCustomInput = ({ tests, onChange }: GaitTestCustomInputProps) => {
  const currentTest = useMemo(
    () => tests.find((x) => "isCustom" in x && x.isCustom),
    [tests]
  );

  const isSelected = Boolean(currentTest);
  const frequencyError =
    isSelected && (!currentTest?.frequency || !currentTest.frequencyReps);
  const durationError = isSelected && !currentTest?.duration;

  const customTestInputs = useMemo(() => {
    if (!currentTest?.duration) return undefined;

    const seconds = currentTest.duration % 60;
    const minutes = (currentTest.duration - seconds) / 60;

    return { seconds, minutes };
  }, [currentTest]);

  const handleChangeCustomTest = (value: IGaitTestItem) => {
    if (isSelected) {
      onChange(
        tests.map((x) =>
          "isCustom" in x && x.isCustom ? { ...x, ...value } : x
        )
      );
      return;
    }

    onChange([...tests, { ...value, isCustom: true }]);
  };

  const handleSecondsChange = (e: ChangeEvent<HTMLInputElement>) => {
    // incurease minutes automatically, when value is >= 60
    const value = Number(e.target.value ?? 0);
    const minutes = customTestInputs?.minutes ?? 0;
    const duration = minutes * 60 + value;

    handleChangeCustomTest({
      duration: duration !== 0 ? duration : undefined,
      isCustom: true,
    });
  };

  const handleMinutesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value ?? 0);
    const seconds = customTestInputs?.seconds ?? 0;
    const duration = value * 60 + seconds;

    handleChangeCustomTest({
      duration: duration !== 0 ? duration : undefined,
      isCustom: true,
    });
  };

  return (
    <div className="flex flex-col items-center justify-between py-1.5 md:portrait:flex-row lg:flex-row">
      <div className="w-[43%] flex items-center gap-2 pr-4">
        <CheckboxInput
          isTestParamInput
          label="Custom"
          value={isSelected}
          onChange={(checked) =>
            // add or remove this test, depending of checked value
            onChange(
              checked
                ? [...tests, { isCustom: true }]
                : tests.filter((x) => !("isCustom" in x && x.isCustom))
            )
          }
        />
        <InputField
          isNumericField
          error={durationError}
          placeholder="Seconds"
          value={
            customTestInputs?.seconds ? String(customTestInputs?.seconds) : ""
          }
          onChange={handleSecondsChange}
        />
        <InputField
          isNumericField
          error={durationError}
          placeholder="Minutes"
          value={
            customTestInputs?.minutes ? String(customTestInputs?.minutes) : ""
          }
          onChange={handleMinutesChange}
        />
      </div>

      <div
        className={`grow relative ${
          frequencyError
            ? "after:content-[''] after:absolute after:-bottom-[7px] after:left-0 after:right-0 after:bg-crimson after:h-0.5"
            : ""
        }`}
      >
        <TestFrequencyInputs
          className="!flex-row items-center !gap-5"
          hideLabel
          isTestSelected={isSelected}
          frequency={currentTest?.frequency}
          frequencyReps={currentTest?.frequencyReps}
          onChange={(frequencyParams) =>
            handleChangeCustomTest({ ...frequencyParams, isCustom: true })
          }
        />
      </div>
    </div>
  );
};

export default GaitTestCustomInput;
