import { useMemo } from "react";
import { SensorStatus } from "enums/Sensor";
import Dropdown from "components/Dropdowns/Dropdown/Dropdown";
import { IDropdownOption } from "interfaces/Common";
import { formatSensorStatusLabel } from "helpers/SensorHelper";

interface SensorStatusDropdownProps {
  label?: string;
  useInputFieldStyle?: boolean;
  hideClearButton?: boolean;
  disabled?: boolean;
  selected: SensorStatus | undefined;
  onChange: (value: SensorStatus | undefined) => void;
}

const SensorStatusDropdown = ({
  label = "Select sensor status",
  ...rest
}: SensorStatusDropdownProps) => {
  const options = useMemo(
    (): IDropdownOption<SensorStatus>[] =>
      [
        SensorStatus.Unassigned,
        SensorStatus.InWarehouse,
        SensorStatus.ShippedToCustomer,
        SensorStatus.AtCustomer,
        SensorStatus.AssignedInternal,
        SensorStatus.Damaged,
        SensorStatus.Lost,
      ].map((x) => ({ value: x, label: formatSensorStatusLabel(x) })),
    []
  );

  return (
    <div className="min-w-[270px]">
      <Dropdown label={label} options={options} {...rest} />
    </div>
  );
};

export default SensorStatusDropdown;
