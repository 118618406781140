import { ButtonHTMLAttributes } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Color } from "enums/Common";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const Button = (props: ButtonProps) => {
  const { loading, children, className, ...rest } = props;

  return (
    <button
      className={`rounded-3xl box-border h-9 flex items-center gap-2 bg-tropicalRainForest px-5 text-base leading-4 text-white font-medium disabled:opacity-50 ${
        className ?? ""
      }`}
      {...rest}
    >
      {children}
      {loading && <CircularProgress size={15} sx={{ color: Color.White }} />}
    </button>
  );
};

export default Button;
