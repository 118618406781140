import { Fragment, useCallback, useMemo } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useRecoilValue } from "recoil";
import { CardsValidation } from "../../recoil-states/create-order-states";

enum StepState {
  Initial = 1,
  Active = 2,
  Completed = 3,
}

interface IStep {
  label: string;
  state: StepState;
}

const OrderSteps = () => {
  const { patient, order, shipping, tests } = useRecoilValue(CardsValidation);

  const getStepState = useCallback(
    (completedCondition: boolean, activeCondition: boolean): StepState => {
      switch (true) {
        case completedCondition:
          return StepState.Completed;
        case activeCondition:
          return StepState.Active;
        default:
          return StepState.Initial;
      }
    },
    []
  );

  const steps = useMemo(
    (): IStep[] => [
      {
        label: "Patient information",
        state: patient ? StepState.Completed : StepState.Active,
      },
      {
        label: "Order information",
        state: getStepState(order, patient),
      },
      {
        label: "Test information",
        state: getStepState(tests, patient && order),
      },
      {
        label: "Shipping information",
        state: getStepState(shipping, patient && order && tests),
      },
    ],
    [patient, order, tests, shipping, getStepState]
  );

  return (
    <div className="flex flex-col justify-center items-center gap-4 sm:flex-row sm:gap-2 md:gap-6">
      {steps.map(({ label, state }, index) => (
        <Fragment key={label}>
          <div className="w-56 flex items-center gap-2 sm:w-auto">
            <div
              className={`shrink-0 w-9 h-9 rounded-lg flex justify-center items-center shadow-md transition-colors ease-in-out duration-300 ${
                state === StepState.Active ? "bg-pelorous" : "bg-white"
              }`}
            >
              {state === StepState.Completed ? (
                <CheckIcon color="success" />
              ) : (
                <p
                  className={`text-lg leading-5 font-bold ${
                    state === StepState.Active
                      ? "text-white"
                      : "text-cloudBurst/50"
                  }`}
                >
                  {index + 1}
                </p>
              )}
            </div>
            <p
              className={`text-lg leading-5 font-bold text-center ${
                state === StepState.Active
                  ? "text-pelorous"
                  : "text-cloudBurst/50"
              }`}
            >
              {label}
            </p>
          </div>

          {/* Separator */}
          {index !== steps.length - 1 && (
            <div
              className={`hidden w-10 h-1 border-t-2 border-cloudBurst/50 sm:block ${
                state === StepState.Completed ? "" : "border-dashed"
              }`}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default OrderSteps;
