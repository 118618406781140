import { Carrier, ShippingMethod } from "enums/Sensor";
import { IDropdownOption, ValidationErrorChangeEvent } from "interfaces/Common";
import { IManualLabelsEntryData } from "interfaces/Sensor";
import Dropdown from "components/Dropdowns/Dropdown/Dropdown";
import InputField from "components/InputField/InputField";

// UI purposes:
const CARRIER_OPTIONS: IDropdownOption<Carrier>[] = [
  { label: "UPS", value: Carrier.UPS },
  { label: "FedEX", value: Carrier.FedEx },
  { label: "DHL", value: Carrier.DHL },
];

const SHIPPING_METHOD_OPTIONS: IDropdownOption<ShippingMethod>[] = [
  { label: "2 days", value: ShippingMethod.TwoDays },
  { label: "3 days", value: ShippingMethod.ThreeDays },
];

interface ManualLabelsEntryFormProps {
  data: IManualLabelsEntryData;
  onChange: (value: Partial<IManualLabelsEntryData>) => void;
  onValidationErrorChange: (e: ValidationErrorChangeEvent) => void;
}

const ManualLabelsEntryForm = ({
  data,
  onChange,
  onValidationErrorChange,
}: ManualLabelsEntryFormProps) => {
  return (
    <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
      <div className="flex flex-col gap-5">
        <Dropdown
          hideClearButton
          useInputFieldStyle
          label="Carrier *"
          options={CARRIER_OPTIONS}
          selected={data.carrier}
          onChange={(value) => onChange({ carrier: value })}
        />
        <Dropdown
          hideClearButton
          useInputFieldStyle
          label="Shipping method *"
          options={SHIPPING_METHOD_OPTIONS}
          selected={data.shippingMethod}
          onChange={(value) => onChange({ shippingMethod: value })}
        />
      </div>

      <div className="flex flex-col gap-5">
        <InputField
          required
          label="Outgoing label"
          placeholder="Enter outgoing label"
          name="outgoingLabel"
          value={data.outgoingLabel}
          onChange={(e) => onChange({ outgoingLabel: e.target.value })}
          onValidationErrorChange={onValidationErrorChange}
        />
        <InputField
          required
          label="Return label"
          placeholder="Enter return label"
          name="returnLabel"
          value={data.returnLabel}
          onChange={(e) => onChange({ returnLabel: e.target.value })}
          onValidationErrorChange={onValidationErrorChange}
        />
        <InputField
          multiline
          rows={3}
          label="Notes (optional)"
          placeholder="Enter notes for the status change"
          name="notes"
          value={data.notes}
          onChange={(e) => onChange({ notes: e.target.value })}
          onValidationErrorChange={onValidationErrorChange}
        />
      </div>
    </div>
  );
};

export default ManualLabelsEntryForm;
