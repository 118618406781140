import { IPatientFiltersInstruction } from "interfaces/Patient";
import PatientFiltersInstruction from "components/PatientFiltersInstruction/PatientFiltersInstruction";
import selectApplicationSvg from "assets/images/select_application.svg";
import selectTestTypeSvg from "assets/images/select_test_type.svg";

interface PatientFiltersInstructionsProps {
  isAppSelected: boolean;
  isTestSelected: boolean;
}

const PatientFiltersInstructions = (props: PatientFiltersInstructionsProps) => {
  const { isAppSelected, isTestSelected } = props;

  const instructionsData: IPatientFiltersInstruction[] = [
    {
      text: "Select application",
      imgSvg: selectApplicationSvg,
      completed: isAppSelected,
    },
    {
      text: "Select test type",
      imgSvg: selectTestTypeSvg,
      completed: isTestSelected,
    },
  ];

  return (
    <div className="border-[3px] border-pattensBlue3 rounded-[20px] overflow-hidden bg-solitude flex items-center justify-around h-[342px]">
      {instructionsData.map(({ text, completed, imgSvg }, index) => (
        <PatientFiltersInstruction
          key={text}
          text={`${index + 1}. ${text}`}
          completed={completed}
          imgSvg={imgSvg}
        />
      ))}
    </div>
  );
};

export default PatientFiltersInstructions;
