export enum Gender {
  Male = 1,
  Female = 2,
  NonBinary = 3,
  TransgenderMale = 4,
  TransgenderFemale = 5,
  Other = 6,
  DoesNotWishToDisclose = 7,
}

export enum PatientFilter {
  NameOrId = "first_name|last_name|external_id",
}

export enum PatientsOperationsSection {
  Information = 1,
  LabAtHomeOPS = 2,
  Support = 3,
  Billing = 4,
}
