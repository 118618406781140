import Button from "components/Buttons/Button/Button";
import { useDropzone, DropzoneProps } from "react-dropzone";

const ATTACHMENT_MAX_SIZE = 20_971_520; // 20 MB

interface UploadFileProps {
  onDrop: DropzoneProps["onDrop"];
  multiple?: boolean;
  maxSize?: number;
}

const UploadFile = (props: UploadFileProps) => {
  const { onDrop, multiple = false, maxSize = ATTACHMENT_MAX_SIZE } = props;

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple,
    maxSize,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div
      {...getRootProps({
        className: `flex flex-col items-center gap-1.5 py-2.5 px-5 bg-aliceBlue rounded-[9px] border border-transparent border-dashed ${
          isDragActive ? "opacity-50 !border-pelorous" : ""
        }`,
      })}
    >
      <p className="text-cloudBurst text-base leading-5 font-medium">
        Drag and drop your file here or
      </p>
      <Button
        className="border-2 border-pelorous !bg-white !text-pelorous"
        onClick={open}
      >
        Choose File
      </Button>
      <input {...getInputProps()} />
    </div>
  );
};

export default UploadFile;
