interface BlueRibbonProps {
  text: string;
}

const BlueRibbon = ({ text }: BlueRibbonProps) => {
  return (
    <div className="rounded-md bg-pelorous px-5 py-4 text-white text-base leading-5 font-medium">
      {text}
    </div>
  );
};

export default BlueRibbon;
