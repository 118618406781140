import moment from "moment";
import isStrongPassword from "validator/lib/isStrongPassword";
import { Color, DateRange } from "enums/Common";
import LocalStorageService from "services/LocalStorageService";

export const formatDate = (createdOn: string) => {
  return moment(createdOn).format("MM/DD/YYYY");
};

export const formatTime = (createdOn: string) => {
  return moment(createdOn).format("hh:mm:ss A");
};

export const formatCompletedOnTime = (createdOn: string, duration: number) => {
  let d = moment(createdOn);
  const formatted = d.add(duration, "seconds");
  return formatted.format("hh:mm:ss A");
};

export const millisecondsToHMS = (value: number) => {
  const seconds = Math.floor((value / 1000) % 60);
  const minutes = Math.floor((value / 1000 / 60) % 60);
  const hours = Math.floor((value / 1000 / 3600) % 24);

  return [hours, minutes, seconds]
    .map((x) => String(x).padStart(2, "0"))
    .join(":");
};

export const secondsToString = (value: number | null) => {
  if (!value) return "Duration N/A";

  const hrs = Math.floor(value / 3600);
  value = value - hrs * 3600;
  const mins = Math.floor(value / 60);
  const secs = value - mins * 60;

  return `${hrs ? `${hrs} hr` : ""} ${mins ? `${mins} min` : ""} ${
    secs ? `${secs} sec` : ""
  }`.trim();
};

export const createUrlParams = (value: object): string => {
  const temp = [];

  for (let key in value) {
    const currentValue = value[key as keyof typeof value];

    // skip null, undefined and empty arrays
    if (
      currentValue === null ||
      currentValue === undefined ||
      (Array.isArray(currentValue) && !(currentValue as unknown[]).length)
    )
      continue;

    temp.push(encodeURIComponent(key) + "=" + encodeURIComponent(currentValue));
  }

  return temp.join("&");
};

export const searchParamsToObject = (
  params: URLSearchParams
): Record<string, string> => {
  const obj: Record<string, string> = {};

  params.forEach((value, key) => {
    obj[key] = value;
  });

  return obj;
};

export const doesPasswordMeetsRequirements = (value: string) => {
  return isStrongPassword(value, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });
};

// YYYY-MM-DD to MM/DD/YYYY
export const formatDashDate = (dob: string | undefined | null) => {
  if (!dob) return "-";

  const y = dob.slice(0, 4);
  const month = dob.slice(5, 7);
  const day = dob.slice(8, 10);

  return `${month}/${day}/${y}`;
};

export const formatName = ({
  lastName,
  firstName,
  middleName,
}: {
  lastName: string | null;
  firstName: string | null;
  middleName?: string | null;
}): string => {
  // remove null and empty string values
  const truthyNames = [lastName, firstName, middleName].filter((x) => x);

  return truthyNames.length ? truthyNames.join(", ") : "-";
};

export const generateRandomPassword = () => {
  // ascii ranges
  const specialChars = [33, 47];
  const numbers = [48, 57];
  const uppercase = [65, 90];
  const lowercase = [97, 122];

  const generateRandomCharFromAsciiRange = ([min, max]: number[]): string => {
    const randomAscii = min + Math.floor(Math.random() * (max - min));

    return String.fromCharCode(randomAscii);
  };

  const passwordChars: string[] = [];

  passwordChars.push(generateRandomCharFromAsciiRange(specialChars));
  passwordChars.push(generateRandomCharFromAsciiRange(numbers));
  passwordChars.push(generateRandomCharFromAsciiRange(uppercase));

  for (let i = 0; i < 5; i++) {
    passwordChars.push(generateRandomCharFromAsciiRange(lowercase));
  }

  // shuffle array
  passwordChars.sort(() => Math.random() - 0.5);

  return passwordChars.join("");
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const hexAndAlphaToRGBA = (hex: Color, alpha: number): string => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const checkRefreshTokenExpiration = () => {
  const auth = LocalStorageService.getAuth();

  if (!auth) return;

  const refreshTokenExpirationTime = moment(auth.refreshExpiresOn);
  const currentTime = moment();

  if (currentTime.isSameOrAfter(refreshTokenExpirationTime)) {
    LocalStorageService.removeAuth();
    LocalStorageService.removeAccountAuth();
  }
};

export const downloadFile = (url: string, fileName: string): void => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const generateLastMonthRange = (): string => {
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  // API expects endDate to be incremented by 1 day in order to include it
  const endDate = moment().add(1, "days").format("YYYY-MM-DD");

  return `${startDate}${DateRange.Separator}${endDate}`;
};

/*  
  For numbers exactly halfway between two integers, round to the nearest even integer.
  42.5 rounds to 42 (since 42 is even).
  57.5 rounds to 58 (since 58 is even).
*/
export const roundHalfToEven = (value: number): number => {
  const rounded = Math.round(value);

  // Check if the number is exactly halfway between two integers
  if (Math.abs(value - rounded) === 0.5) {
    // If it's halfway, round to the nearest even integer
    return rounded % 2 === 0 ? rounded : rounded - 1;
  }

  return rounded;
};
