import { useMemo, useState } from "react";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import IntervalToggleButton from "components/IntervalToggleButtons/IntervalToggleButtons";
import PatientProgressTable from "components/Tables/PatientProgressTable/PatientProgressTable";
import {
  IPatientProgressFilters,
  IPatientProgressFiltersWithInterval,
} from "interfaces/Patient";
import { OrderDirection, PageSize } from "enums/Common";
import { PatientFilter } from "enums/Patient";
import { Interval } from "enums/Test";
import { calculateCreatedOnFromInterval } from "helpers/TrendsAndUsageHelper";
import styles from "./PatientProgress.module.scss";

interface PatientProgressProps {
  clinicianId?: string;
}

const PatientProgress = ({ clinicianId }: PatientProgressProps) => {
  const [filters, setFilters] = useState<IPatientProgressFiltersWithInterval>({
    _total_count: 1,
    page: 1,
    page_size: PageSize.Default,
    _order_by: `last_name ${OrderDirection.Asc}`,
    clinicianId,
    interval: Interval.OneMonth,
  });

  const filtersWithDate = useMemo((): IPatientProgressFilters => {
    /* 
    no need to send interval param
    from param is calculated from it and sent to API
    */
    const from = calculateCreatedOnFromInterval({
      interval: filters.interval,
      isTrends: false,
    });

    const { interval, ...rest } = filters;

    return { ...rest, from };
  }, [filters]);

  return (
    <div className="flex flex-col p-5 rounded-lg bg-white w-full grow">
      <div className="flex flex-col justify-between items-center md:flex-row mb-1">
        <div className="flex flex-col md:flex-row items-center">
          <p className="text-lg font-bold uppercase pr-2">
            Patient progress over the most recent:
          </p>
          <IntervalToggleButton
            active={filters.interval}
            onChange={(value) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                interval: value,
              }))
            }
          />
        </div>

        <DebouncedSearchField
          className={`order-2 sm:order-1 ${styles.search_field}`}
          placeholder="Search by Patient Name or ID"
          value={filters[PatientFilter.NameOrId]}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              [PatientFilter.NameOrId]: value,
            }))
          }
        />
      </div>

      <PatientProgressTable filters={filtersWithDate} setFilters={setFilters} />
    </div>
  );
};

export default PatientProgress;
