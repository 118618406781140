import { useEffect, useRef, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { TableCellProps } from "@mui/material/TableCell";
import TooltipBox from "components/TooltipBox/TooltipBox";

interface EllipsisTableCellProps extends TableCellProps {
  text?: string;
}

const EllipsisTableCell = (props: EllipsisTableCellProps) => {
  const { text, ...rest } = props;

  const contentRef = useRef<HTMLDivElement | null>(null);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (!contentRef.current) return;

    const { scrollWidth, offsetWidth, clientWidth } = contentRef.current;

    const isTextOverflowingElement =
      scrollWidth !== Math.max(offsetWidth, clientWidth);

    setShowTooltip(isTextOverflowingElement);
  }, [text]);

  return (
    <TableCell {...rest}>
      <TooltipBox content={showTooltip ? text : ""}>
        <div
          ref={contentRef}
          className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full"
        >
          {text}
        </div>
      </TooltipBox>
    </TableCell>
  );
};

export default EllipsisTableCell;
