import { Snackbar as MUISnackbar } from "@mui/material";
import { SnackbarEnum } from "enums/Common";
import { ISnackbar } from "interfaces/Common";
import styles from "./Snackbar.module.scss";

const Snackbar = ({ variant, ...rest }: ISnackbar) => {
  return (
    <div
      className={`${styles.snackbar_wrapper} ${
        variant === "success" ? styles.success : styles.error
      }`}
    >
      <MUISnackbar autoHideDuration={SnackbarEnum.AutohideDuration} {...rest} />
    </div>
  );
};

export default Snackbar;
