import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { IDropdownOption } from "interfaces/Common";
import TooltipBox from "components/TooltipBox/TooltipBox";
import styles from "./DropdownMultiselection.module.scss";

interface DropdownMultiselectionProps<T extends number | string> {
  useInputFieldStyle?: boolean;
  placeholder?: string;
  label: string;
  selected: T[];
  disabled?: boolean;
  options: IDropdownOption<T>[];
  onChange: (value: T[]) => void;
  clearHidden?: boolean;
}

const DropdownMultiselection = <T extends number | string>(
  props: DropdownMultiselectionProps<T>
) => {
  const {
    useInputFieldStyle,
    placeholder = "Select",
    options,
    label,
    selected,
    disabled,
    onChange,
    clearHidden,
  } = props;

  const valueContainerRef = useRef<HTMLDivElement | null>(null);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (!valueContainerRef.current) return;

    const { clientWidth, scrollWidth } = valueContainerRef.current;

    setShowTooltip(scrollWidth > clientWidth);
  }, [selected]);

  const selectedNames = useMemo(
    () => selected.map((x) => options.find(({ value }) => x === value)?.label),
    [options, selected]
  );

  const tooltipContent = useMemo(
    (): ReactNode =>
      showTooltip ? (
        <div className="flex flex-col items-start">
          {selectedNames.map((name, index) => (
            <div key={name}>
              {`${name}${index !== selectedNames.length - 1 ? "," : ""}`}
            </div>
          ))}
        </div>
      ) : (
        ""
      ),
    [showTooltip, selectedNames]
  );

  const handleRenderValue = useCallback(
    (value: T[]) => {
      // show All label if all options are selected

      switch (true) {
        case value.length === options.length:
          return "All";
        case Boolean(value.length):
          return (
            <TooltipBox content={tooltipContent}>
              <div className={styles.value_container} ref={valueContainerRef}>
                {selectedNames.join(", ")}
              </div>
            </TooltipBox>
          );
        default:
          return placeholder;
      }
    },
    [placeholder, selectedNames, options.length, tooltipContent]
  );

  return (
    <div
      className={`flex flex-col gap-1 ${
        styles.dropdown_multiselection_wrapper
      } ${useInputFieldStyle ? styles.input_field_style : ""} ${
        disabled ? "opacity-50" : ""
      }`}
    >
      <div className="flex items-end justify-between">
        <label className="text-base leading-5 text-cloudBurst font-medium">
          {label}
        </label>
        {!clearHidden && (
          <button
            className="text-sm leading-4 text-pelorous disabled:opacity-50 font-medium"
            disabled={!selected.length}
            onClick={() => onChange([])}
          >
            Clear
          </button>
        )}
      </div>

      <Select<T[]>
        multiple
        displayEmpty
        disabled={disabled}
        MenuProps={{
          classes: { root: styles.menu_root },
        }}
        value={selected}
        onChange={({ target: { value } }) => onChange(value as T[])}
        renderValue={handleRenderValue}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={selected.includes(value)} />
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropdownMultiselection;
