import { Outlet } from "react-router-dom";
import biomechLogo from "assets/images/biomech_logo.svg";
import biomechFullLogo from "assets/images/biomech_full_logo.svg";
import backgroundArc from "assets/images/background_arc.svg";

const LoginLayout = () => {
  return (
    <div className="pt-20 h-full flex justify-center bg-gradient-to-b from-prussianBlue via-endeavour to-pelorous overflow-x-scroll md:pt-0 lg:justify-end md:portrait:items-center lg:items-center lg:landscape:pr-24 lg:pr-44 xl:pr-44 2xl:!pr-96">
      <div
        style={{ backgroundImage: `url(${backgroundArc})` }}
        className="hidden absolute left-0 top-0 w-2/3 h-full bg-no-repeat bg-cover bg-right md:portrait:block lg:block"
      ></div>

      <img
        className="hidden absolute left-8 top-1/2 -translate-y-1/2 lg:landscape:block xl:left-36"
        src={biomechLogo}
        alt="logo"
      />

      <div className="w-4/5 md:w-96 z-10">
        <img
          className="mx-auto mb-32 sm:my-10 md:portrait:mb-16 lg:mb-16"
          src={biomechFullLogo}
          alt="logo"
        />
        <Outlet />
      </div>
    </div>
  );
};

export default LoginLayout;
