import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { URLRoutes } from "enums/Routing";
import AdminDashboard from "pages/AdminDashboard/AdminDashboard";
import Login from "pages/Login/Login";
import Profile from "pages/Profile/Profile";
import PatientsDashboard from "pages/PatientsDashboard/PatientsDashboard";
import GuestRoute from "components/Routing/GuestRoute/GuestRoute";
import LoginLayout from "components/Layouts/LoginLayout/LoginLayout";
import ProtectedRoute from "components/Routing/ProtectedRoute/ProtectedRoute";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import OrdersDashboard from "pages/OrdersDashboard/OrdersDashboard";
import CliniciansDashboard from "pages/CliniciansDashboard/CliniciansDashboard";
import PatientViewLayout, {
  usePatientViewLayoutContext,
} from "components/Layouts/PatientViewLayout/PatientViewLayout";
import PatientResults from "pages/PatientResults/PatientResults";
import PatientTrends from "pages/PatientTrends/PatientTrends";
import PatientOrders from "pages/PatientOrders/PatientOrders";
import PatientProfile from "pages/PatientProfile/PatientProfile";
import ClinicianViewLayout, {
  useClinicianViewLayoutContext,
} from "components/Layouts/ClinicianViewLayout/ClinicianViewLayout";
import ClinicianPatients from "pages/ClinicianPatients/ClinicianPatients";
import ClinicianOrders from "pages/ClinicianOrders/ClinicianOrders";
import ClinicianProfile from "pages/ClinicianProfile/ClinicianProfile";
import OrderDetails from "pages/OrderDetails/OrderDetails";
import OperationsDashboard from "pages/OperationsDashboard/OperationsDashboard";
import SensorViewLayout from "components/Layouts/SensorViewLayout/SensorViewLayout";
import Sensors from "pages/Sensors/Sensors";
import NewOrders from "pages/NewOrders/NewOrders";
import SensorDetails from "pages/SensorDetails/SensorDetails";
import SensorHistory from "pages/SensorHistory/SensorHistory";
import Accounts from "pages/Accounts/Accounts";
import ClinicianPreviewDashboard from "pages/ClinicianPreviewDashboard/ClinicianPreviewDashboard";
import PatientDashboard from "pages/PatientDashboard/PatientDashboard";
import AccountDashboard from "pages/AccountDashboard/AccountDashboard";
import PatientsOperations from "pages/PatientsOperations/PatientsOperations";
import PatientOperationsActivityLayout from "components/Layouts/PatientOperationsActivityLayout/PatientOperationsActivityLayout";
import PatientOperationsAttachments from "pages/PatientOperationsAttachments/PatientOperationsAttachments";
import PatientOperationsAllActivity from "pages/PatientOperationsAllActivity/PatientOperationsAllActivity";
import useStorageChange from "hooks/useStorageChange";
import AccountViewLayout, {
  useAccountViewLayoutContext,
} from "components/Layouts/AccountViewLayout/AccountViewLayout";
import NotAccessedPatients from "pages/NotAccessedPatients/NotAccessedPatients";
import ExpiringOrders from "pages/ExpiringOrders/ExpiringOrders";
import LayoutOrderDetails from "components/LayoutOrderDetails/LayoutOrderDetails";
import CookiesBanner from "components/CookiesBanner/CookiesBanner";
import BillingDashboard from "pages/BillingDashboard/BillingDashboard";
import {
  IsBillingCompany,
  IsClientAdmin,
  IsSuperAdmin,
} from "recoil-states/auth-states";
import ProtectedLayout from "components/Layouts/ProtectedLayout/ProtectedLayout";
import PatientTestNotes from "pages/PatientTestNotes/PatientTestNotes";

const Routing = () => {
  useStorageChange();

  const isClientAdmin = useRecoilValue(IsClientAdmin);
  const isSuperAdmin = useRecoilValue(IsSuperAdmin);
  const isBillingCompany = useRecoilValue(IsBillingCompany);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<GuestRoute />}>
          <Route element={<LoginLayout />}>
            <Route path={URLRoutes.Empty} element={<Login />} />
            <Route path={URLRoutes.Login} element={<Login />} />
            <Route
              path={URLRoutes.ForgotPassword}
              element={<ForgotPassword />}
            />
            <Route path={URLRoutes.ResetPassword} element={<ResetPassword />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route element={<ProtectedLayout />}>
            {/* Avaiblable to all logged users */}
            <Route path={URLRoutes.Profile} element={<Profile />} />

            {/* Available to all logged users except billing company */}
            <Route
              element={
                <ProtectedRoute
                  additionalCondition={!isBillingCompany}
                  fallbackRoute={URLRoutes.BillingDashboard}
                />
              }
            >
              {/* Account Dashboard routes */}
              <Route
                path={URLRoutes.AccountDashboard}
                element={<AccountViewLayout />}
              >
                <Route
                  path={URLRoutes.AccountDashboard}
                  element={<AccountDashboard />}
                />
                <Route
                  path={URLRoutes.AccountNotAccessedPatients}
                  element={
                    <NotAccessedPatients
                      layoutContext={useAccountViewLayoutContext}
                    />
                  }
                />
                <Route
                  path={URLRoutes.AccountExpiringOrders}
                  element={
                    <ExpiringOrders
                      layoutContext={useAccountViewLayoutContext}
                    />
                  }
                />
                <Route
                  path={URLRoutes.AccountExpiringOrderDetails}
                  element={
                    <LayoutOrderDetails
                      layoutContext={useAccountViewLayoutContext}
                    />
                  }
                />
              </Route>
              {/* Patient and Clinician Patient routes */}
              <Route
                path={URLRoutes.PatientsDashboard}
                element={<PatientsDashboard />}
              />
              {[URLRoutes.PatientView, URLRoutes.ClinicianPatientView].map(
                (path) => (
                  <Route key={path} path={path} element={<PatientViewLayout />}>
                    <Route
                      path={`${path}/dashboard`}
                      element={<PatientDashboard />}
                    />
                    <Route
                      path={`${path}/results`}
                      element={<PatientResults />}
                    />
                    <Route
                      path={`${path}/trends`}
                      element={<PatientTrends />}
                    />
                    <Route
                      path={`${path}/orders`}
                      element={<PatientOrders />}
                    />
                    <Route
                      path={`${path}/profile`}
                      element={<PatientProfile />}
                    />
                    <Route
                      path={`${path}/orders/:orderId`}
                      element={
                        <LayoutOrderDetails
                          layoutContext={usePatientViewLayoutContext}
                        />
                      }
                    />
                    <Route
                      path={`${path}/notes/:testId`}
                      element={<PatientTestNotes />}
                    />
                  </Route>
                )
              )}
              {/* Clinician routes */}
              <Route
                path={URLRoutes.CliniciansDashboard}
                element={<CliniciansDashboard />}
              />
              <Route
                path={URLRoutes.ClinicianView}
                element={<ClinicianViewLayout />}
              >
                <Route
                  path={URLRoutes.ClinicianPreviewDashboard}
                  element={<ClinicianPreviewDashboard />}
                />
                <Route
                  path={URLRoutes.ClinicianNotAccessedPatients}
                  element={
                    <NotAccessedPatients
                      layoutContext={useClinicianViewLayoutContext}
                    />
                  }
                />
                <Route
                  path={URLRoutes.ClinicianExpiringOrders}
                  element={
                    <ExpiringOrders
                      layoutContext={useClinicianViewLayoutContext}
                    />
                  }
                />
                <Route
                  path={URLRoutes.ClinicianExpiringOrderDetails}
                  element={
                    <LayoutOrderDetails
                      layoutContext={useAccountViewLayoutContext}
                    />
                  }
                />
                <Route
                  path={URLRoutes.ClinicianPatients}
                  element={<ClinicianPatients />}
                />
                <Route
                  path={URLRoutes.ClinicianOrders}
                  element={<ClinicianOrders />}
                />
                <Route
                  path={URLRoutes.ClinicianProfile}
                  element={<ClinicianProfile />}
                />
                <Route
                  path={URLRoutes.ClinicianOrderDetails}
                  element={
                    <LayoutOrderDetails
                      layoutContext={useClinicianViewLayoutContext}
                    />
                  }
                />
              </Route>
              {/* Order routes */}
              <Route
                path={URLRoutes.OrdersDashboard}
                element={<OrdersDashboard />}
              />
              <Route path={URLRoutes.OrderDetails} element={<OrderDetails />} />
            </Route>

            {/* Available only to billing company and super admin */}
            <Route
              element={
                <ProtectedRoute
                  additionalCondition={isBillingCompany || isSuperAdmin}
                  fallbackRoute={URLRoutes.PatientsDashboard}
                />
              }
            >
              <Route
                path={URLRoutes.BillingDashboard}
                element={<BillingDashboard />}
              />
            </Route>

            {/* Available only to billing company, client admin and super admin */}
            <Route
              element={
                <ProtectedRoute
                  additionalCondition={
                    isBillingCompany || isClientAdmin || isSuperAdmin
                  }
                  fallbackRoute={URLRoutes.PatientsDashboard}
                />
              }
            >
              <Route
                path={URLRoutes.AdminDashboard}
                element={<AdminDashboard />}
              />
            </Route>

            {/* Available only to super admin */}
            <Route
              element={
                <ProtectedRoute
                  additionalCondition={isSuperAdmin && !isBillingCompany}
                  fallbackRoute={URLRoutes.PatientsDashboard}
                />
              }
            >
              {/* Operations routes */}
              <Route
                path={URLRoutes.OperationsDashboard}
                element={<OperationsDashboard />}
              />

              <Route
                path={URLRoutes.PatientsOperations}
                element={<PatientsOperations />}
              />
              <Route
                path={URLRoutes.PatientOperationsActivityView}
                element={<PatientOperationsActivityLayout />}
              >
                <Route
                  path={URLRoutes.PatientOperationsAllActivity}
                  element={<PatientOperationsAllActivity />}
                />
                <Route
                  path={URLRoutes.PatientOperationsAttachments}
                  element={<PatientOperationsAttachments />}
                />
              </Route>

              <Route path={URLRoutes.NewOrders} element={<NewOrders />} />
              {/* Sensors routes */}
              <Route path={URLRoutes.Sensors} element={<Sensors />} />
              <Route
                path={URLRoutes.SensorsPendingShipment}
                element={<Sensors isPendingShipmentPage />}
              />
              <Route path={URLRoutes.SensorView} element={<SensorViewLayout />}>
                <Route
                  path={URLRoutes.SensorDetails}
                  element={<SensorDetails />}
                />
                <Route
                  path={URLRoutes.SensorHistory}
                  element={<SensorHistory />}
                />
              </Route>
              {/* Accounts */}
              <Route path={URLRoutes.Accounts} element={<Accounts />} />
            </Route>
          </Route>
        </Route>

        <Route
          path={URLRoutes.Other}
          element={<Navigate to={URLRoutes.Login} />}
        />
      </Routes>

      <CookiesBanner />
    </BrowserRouter>
  );
};

export default Routing;
