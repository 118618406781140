import {
  formatCompletedOnTime,
  formatDate,
  millisecondsToHMS,
} from "../../../../../helpers/CommonHelper";
import {
  formatGaitAidLabel,
  formatGaitDistanceLabel,
  formatStatus,
} from "../../../../../helpers/TestHelper";
import { ITestDetails } from "../../../../../interfaces/Test";
import PropertyLabel from "../../../../PropertyLabel/PropertyLabel";

const GaitTestDetails = ({
  test: { distance, aid, orderIterations, distanceType, duration },
}: ITestDetails) => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel
          property="Distance"
          value={formatGaitDistanceLabel(distance, distanceType)}
        />
        <PropertyLabel
          property="Duration"
          value={millisecondsToHMS(duration * 1000)}
        />
        <PropertyLabel
          property="Status"
          value={formatStatus(orderIterations[0].status)}
        />
        <PropertyLabel
          property="Run by"
          value={`${orderIterations[0].createdByLastName}, ${orderIterations[0].createdByFirstName}`}
        />
      </div>
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel property="Aid" value={formatGaitAidLabel(aid)} />
        <PropertyLabel
          property="Date and time"
          value={
            formatDate(orderIterations[0].createdOn) +
            ", " +
            formatCompletedOnTime(
              orderIterations[0].createdOn,
              orderIterations[0].duration
            )
          }
        />
        <PropertyLabel
          property="Test ID"
          value={orderIterations[0].accession}
        />
      </div>
    </div>
  );
};

export default GaitTestDetails;
