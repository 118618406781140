import { ReactNode, useCallback, useId, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./DebouncedAutocomplete.module.scss";
import { debounce } from "lodash";
import { DebounceWait } from "../../enums/Common";

interface DebouncedAutocompleteProps<T> {
  showEmptyError?: boolean;
  className?: string;
  label?: string;
  placeholder: string;
  value: T | null;
  onChange: (value: T | null) => void;
  getOptions: (value?: string) => Promise<AxiosResponse>;
  getOptionLabel: (option: T) => string;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T
  ) => ReactNode;
}

const DebouncedAutocomplete = <T,>({
  showEmptyError,
  className,
  label,
  placeholder,
  value,
  onChange,
  getOptions,
  getOptionLabel,
  renderOption,
}: DebouncedAutocompleteProps<T>) => {
  const id = useId();

  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleGetOptions = useCallback(
    (value?: string) => {
      setLoading(true);

      getOptions(value)
        .then(({ data }) => setOptions(data))
        .finally(() => setLoading(false));
    },
    [getOptions]
  );

  const debouncedGetOptions = useMemo(
    () =>
      debounce((value: string) => handleGetOptions(value), DebounceWait.Input),
    [handleGetOptions]
  );

  const errorText =
    showEmptyError && !value ? "This field is required" : undefined;

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className="self-start text-cloudBurst text-base font-medium"
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <Autocomplete
        id={id}
        className={`${styles.autocomplete} ${className ?? ""}`}
        componentsProps={{
          popper: { className: styles.autocomplete_menu_popper },
        }}
        // spinner shows only when loading state is true and options are empty
        options={loading ? [] : options}
        loading={loading}
        loadingText={<CircularProgress size={20} />}
        value={value}
        onOpen={() => handleGetOptions()}
        onChange={(e, value) => onChange(value)}
        // triger API call only on input change
        onInputChange={(e, value, reason) =>
          reason === "input" ? debouncedGetOptions(value) : undefined
        }
        filterOptions={(x) => x}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={Boolean(errorText)}
            helperText={errorText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <div className="flex items-center gap-2">
                    {Boolean(value) && (
                      <button
                        className="p-0 flex"
                        onClick={() => onChange(null)}
                      >
                        <span className="icon_svg icon_cross" />
                      </button>
                    )}

                    <div className="bg-malibu w-8 h-8 rounded-full flex items-center justify-center">
                      <span className="icon_svg icon_search" />
                    </div>
                  </div>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default DebouncedAutocomplete;
