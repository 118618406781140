import { Outlet, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { URLRoutes } from "enums/Routing";
import { Auth } from "recoil-states/auth-states";

type ProtectedRouteProps =
  | { additionalCondition: boolean; fallbackRoute: URLRoutes }
  | { additionalCondition?: never; fallbackRoute?: never };

// route available only to logged in users
// additionalCondition can be passed for additional checkings
const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { additionalCondition, fallbackRoute } = props;

  const auth = useRecoilValue(Auth);

  const canAccess =
    typeof additionalCondition === "boolean"
      ? additionalCondition && Boolean(auth)
      : Boolean(auth);
  const redirection = fallbackRoute ?? URLRoutes.Login;

  return canAccess ? <Outlet /> : <Navigate to={redirection} />;
};

export default ProtectedRoute;
