import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { INavItem } from "../../interfaces/Common";

interface NavbarProps {
  preserveQueryParams?: boolean;
  items: INavItem[];
  drawerTitle: string;
  drawerLabel?: string;
}

const Navbar = (props: NavbarProps) => {
  const { preserveQueryParams, items, drawerTitle, drawerLabel } = props;

  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleMobileItemClick = (onClick?: () => void): void => {
    setIsDrawerOpen(false);
    onClick?.();
  };

  return (
    <>
      <div className="bg-white -ml-5 py-1 border-t border-b border-pelorous xl:-mr-5">
        {/* show arrow button on mobile which opens menu drawer */}
        <div className="flex justify-center md:portrait:hidden lg:hidden">
          <button onClick={() => setIsDrawerOpen(true)}>
            <span className="icon_svg icon_navigate_arrow -rotate-90" />
          </button>
        </div>

        <div className="hidden justify-center gap-2.5 md:portrait:flex lg:flex">
          {items.map(({ label, to, onClick, isActive }) => (
            <NavLink
              className={(linkProps) =>
                `py-1.5 w-40 rounded-lg bg-transparent text-center text-lg leading-5 text-cloudBurst font-bold transition-colors ease-in-out duration-300 md:portrait:w-32 ${
                  isActive || (isActive === undefined && linkProps.isActive)
                    ? "!bg-pelorous !text-white pointer-events-none"
                    : ""
                }`
              }
              key={label}
              to={{
                pathname: to,
                search: preserveQueryParams ? location.search : undefined,
              }}
              onClick={onClick}
            >
              {label}
            </NavLink>
          ))}
        </div>
      </div>

      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          className:
            "py-5 flex flex-col items-center gap-4 bg-gradient-to-r from-prussianBlue via-endeavour to-pelorous",
        }}
      >
        <h2 className="text-lg leading-5 text-white font-bold">
          {drawerTitle}
        </h2>
        <p className="text-base leading-4 text-white font-bold">
          {drawerLabel}
        </p>
        {items.map(({ label, to, onClick }) => (
          <NavLink
            className="py-3 w-full text-center text-lg leading-5 bg-pattensBlue/20 text-white font-bold"
            key={label}
            to={to ?? ""}
            onClick={() => handleMobileItemClick(onClick)}
          >
            {label}
          </NavLink>
        ))}
      </Drawer>
    </>
  );
};

export default Navbar;
