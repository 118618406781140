import { useEffect, useMemo, useState } from "react";
import { IOperationBoxData } from "../../interfaces/Operations";
import OperationBox from "../../components/OperationBox/OperationBox";
import { URLRoutes } from "../../enums/Routing";
import { SensorFilterParams, SensorStatus } from "../../enums/Sensor";
import SensorService from "../../services/SensorService";
import useSnackbar from "../../hooks/useSnackbar";
import Loading from "../../components/Loading/Loading";

interface IDashboardData {
  all: number;
  assignedInternal: number;
  atCustomer: number;
  atWarehouse: number;
  inTransit: number;
  pendingSensorAssignment: number;
  pendingShipment: number;
}

const OperationsDashboard = () => {
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [data, setData] = useState<IDashboardData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    SensorService.getDashboardData()
      .then(({ data }) => setData(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  }, [setSnackbarError]);

  const firstRowBoxesData = useMemo(
    (): IOperationBoxData[] => [
      {
        title: "New orders",
        icon: "icon_new_orders",
        count: data?.pendingSensorAssignment,
        tooltip:
          "All patients that have no sensor assigned yet, but have active orders",
        to: URLRoutes.NewOrders,
        redHeader: true,
      },
      {
        title: "Pending shipment info",
        icon: "icon_pending_shipment",
        count: data?.pendingShipment,
        tooltip: "All assigned sensors that are missing shipment information",
        to: URLRoutes.SensorsPendingShipment,
        redHeader: true,
      },
    ],
    [data]
  );

  const secondRowBoxesData = useMemo(
    (): IOperationBoxData[] => [
      {
        title: "All sensors",
        icon: "icon_all_sensors",
        count: data?.all,
        to: URLRoutes.Sensors,
      },
      {
        title: "Warehouse unassigned",
        icon: "icon_warehouse",
        count: data?.atWarehouse,
        tooltip: "Unassigned sensors that are currently in warehouse",
        to: `${URLRoutes.Sensors}?${SensorFilterParams.Status}=${SensorStatus.Unassigned}`,
      },
      {
        title: "Warehouse assigned",
        icon: "icon_warehouse",
        count: data?.pendingShipment,
        tooltip: "Assigned sensors that are currently in warehouse",
        to: `${URLRoutes.Sensors}?${SensorFilterParams.Status}=${SensorStatus.InWarehouse}`,
      },
      {
        title: "Assigned internal",
        icon: "icon_home",
        count: data?.assignedInternal,
        tooltip: "Sensors that are assigned internally",
        to: `${URLRoutes.Sensors}?${SensorFilterParams.Status}=${SensorStatus.AssignedInternal}`,
      },
      {
        title: "At customer",
        icon: "icon_at_customer",
        tooltip: "Sensors that are currently at customer",
        count: data?.atCustomer,
        to: `${URLRoutes.Sensors}?${SensorFilterParams.Status}=${SensorStatus.AtCustomer}`,
      },
      {
        title: "In transit to customer",
        icon: "icon_in_transit",
        tooltip: "Sensors that are currently in transit to customer",
        count: data?.inTransit,
        to: `${URLRoutes.Sensors}?${SensorFilterParams.Status}=${SensorStatus.ShippedToCustomer}`,
      },
    ],
    [data]
  );

  return (
    <div className="h-full flex flex-col gap-4 pr-5 xl:pr-0">
      <h1 className="text-2xl leading-7 font-bold text-cloudBurst">
        Operations
      </h1>

      <div className="relative pb-5 grow h-0 flex flex-col gap-6 overflow-auto md:gap-12">
        {data &&
          [firstRowBoxesData, secondRowBoxesData].map((boxesData, index) => (
            <div key={index} className="flex flex-wrap gap-6">
              {boxesData.map((data) => (
                <OperationBox key={data.title} {...data} />
              ))}
            </div>
          ))}

        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default OperationsDashboard;
