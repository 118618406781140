import { HTMLAttributes } from "react";
import TooltipBox from "../TooltipBox/TooltipBox";

interface DashboardCardContainerProps extends HTMLAttributes<HTMLDivElement> {
  childrenClassName?: string;
  title: string;
  subtitle?: string;
  buttonData?: {
    text: string;
    disabled?: boolean;
    tooltip?: string;
    onClick: () => void;
  };
}

const DashboardCardContainer = (props: DashboardCardContainerProps) => {
  const {
    childrenClassName,
    title,
    subtitle,
    buttonData,
    children,
    className,
    ...rest
  } = props;

  return (
    <div
      className={`bg-white shadow-lg rounded-lg p-5 flex flex-col ${
        className ?? ""
      }`}
      {...rest}
    >
      <div className="mb-2.5">
        <h2 className="text-cloudBurst text-lg leading-5 font-bold uppercase">
          {title}
        </h2>
        {subtitle && (
          <p className="text-cloudBurst text-sm leading-4 font-medium uppercase">
            {subtitle}
          </p>
        )}
      </div>

      <div className={`grow h-0 ${childrenClassName ?? ""}`}>{children}</div>

      {buttonData && (
        <TooltipBox content={buttonData.tooltip}>
          <span className="self-end">
            <button
              className="mt-2 underline text-pelorous text-sm leading-4 font-bold disabled:opacity-50"
              disabled={buttonData.disabled}
              onClick={buttonData.onClick}
            >
              {buttonData.text}
            </button>
          </span>
        </TooltipBox>
      )}
    </div>
  );
};

export default DashboardCardContainer;
