import Modal from "@mui/material/Modal";
import Button from "../../Buttons/Button/Button";

interface ConfirmationModalProps {
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({
  title,
  message,
  onCancel,
  onConfirm,
}: ConfirmationModalProps) => {
  return (
    <Modal open onClose={onCancel}>
      <div className="w-[90%] max-w-[620px] py-5 px-3 flex flex-col items-center gap-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-md border border-pelorous md:py-7 md:px-10">
        <h2 className="text-lg leading-5 text-cloudBurst font-bold">{title}</h2>

        <div className="flex flex-col items-center gap-7">
          <p className="text-center text-base leading-5 text-cloudBurst font-medium whitespace-pre-line">
            {message}
          </p>

          <div className="flex gap-5">
            <Button
              className="border-2 border-pelorous !bg-white !text-pelorous"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button onClick={onConfirm}>Yes, continue</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
