import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITestResults } from "../../../../../interfaces/Test";
import TestResultsTableContainer from "../../../../Tables/TestResultsTableContainer/TestResultsTableContainer";
import { GaitMetric } from "../../../../../enums/Test";
import styles from "./GaitTestResults.module.scss";
import {
  formatMetricFrontBackLabel,
  formatMetricLeftRightLabel,
} from "../../../../../helpers/TestHelper";

const GaitTestResults = ({ orderIteration: { metric } }: ITestResults) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-wrap justify-center gap-5">
        <TestResultsTableContainer className="!w-fit">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Steps</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>Per Min</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{metric[GaitMetric.StepsTotal] as number}</TableCell>
                <TableCell>{metric[GaitMetric.Cadence] as number}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>

        <TestResultsTableContainer className="!w-fit">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Impact (%)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Left</TableCell>
                <TableCell>Right</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{metric[GaitMetric.ImpactLeft] as number}</TableCell>
                <TableCell>
                  {metric[GaitMetric.ImpactRight] as number}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>

        <TestResultsTableContainer className="!w-fit">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Support time (%)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Left</TableCell>
                <TableCell>Double</TableCell>
                <TableCell>Right</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {metric[GaitMetric.SupportTimeLeft] as number}
                </TableCell>
                <TableCell>
                  {metric[GaitMetric.SupportTimeDouble] as number}
                </TableCell>
                <TableCell>
                  {metric[GaitMetric.SupportTimeRight] as number}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>
      </div>
      <div className="flex flex-wrap justify-center gap-5">
        <TestResultsTableContainer className="!w-fit">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Toe off (%)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Left</TableCell>
                <TableCell>Right</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{metric[GaitMetric.ToeOffLeft] as number}</TableCell>
                <TableCell>
                  {metric[GaitMetric.ToeOffRight] as number}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>

        <TestResultsTableContainer className="!w-fit">
          <Table className={styles.pelvic_table}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>Pelvic Deviation (max)</TableCell>
                <TableCell colSpan={2}>(avg)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Left</TableCell>
                <TableCell>Right</TableCell>
                <TableCell>Front</TableCell>
                <TableCell>Back</TableCell>
                <TableCell>L/R</TableCell>
                <TableCell>F/B</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {(metric[GaitMetric.PelvicDeviationLeft] as number).toFixed(
                    1
                  )}
                </TableCell>
                <TableCell>
                  {(metric[GaitMetric.PelvicDeviationRight] as number).toFixed(
                    1
                  )}
                </TableCell>
                <TableCell>
                  {(metric[GaitMetric.PelvicDeviationFront] as number).toFixed(
                    1
                  )}
                </TableCell>
                <TableCell>
                  {(metric[GaitMetric.PelvicDeviationBack] as number).toFixed(
                    1
                  )}
                </TableCell>
                <TableCell>
                  {formatMetricLeftRightLabel(
                    metric[GaitMetric.PelvicDeviationLeftRight] as number
                  )}
                </TableCell>
                <TableCell>
                  {formatMetricFrontBackLabel(
                    metric[GaitMetric.PelvicDeviationFrontBack] as number
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>
      </div>
    </div>
  );
};

export default GaitTestResults;
