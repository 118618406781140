import { AxiosResponse } from "axios";
import axios from "config/axios";
import { API } from "enums/API";
import { BillingStatus } from "enums/Billing";
import { IBilling, IBillingFilters } from "interfaces/Billing";

class BillingService {
  getAll = (
    params: IBillingFilters,
    signal?: AbortSignal
  ): Promise<AxiosResponse<IBilling[]>> => {
    return axios.get(API.Billing, { params, signal });
  };

  update = (data: {
    transactionId: number;
    status: BillingStatus;
    comment?: string;
  }): Promise<AxiosResponse<void>> => {
    return axios.post(API.BillingStatusUpdate, data);
  };
}

export default new BillingService();
