import { ReactNode } from "react";
import InfoIcon from "@mui/icons-material/Info";
import TooltipBox from "../TooltipBox/TooltipBox";

interface TooltipInfoIconProps {
  content: ReactNode;
}

const TooltipInfoIcon = ({ content }: TooltipInfoIconProps) => {
  return (
    <TooltipBox content={content}>
      <InfoIcon className="text-malibu !w-5 !h-5" />
    </TooltipBox>
  );
};

export default TooltipInfoIcon;
