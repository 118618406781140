import CardContainer from "../CardContainer/CardContainer";
import { ISensorDetails } from "../../interfaces/Sensor";
import GeneratedLabelsInfoSection from "../GeneratedLabelsInfoSection/GeneratedLabelsInfoSection";
import Button from "../Buttons/Button/Button";
import { useCallback, useMemo, useState } from "react";
import { ISelectedRate } from "../../interfaces/Shipping";
import RatesContainer from "../ShipSensorCard/RatesContainer/RatesContainer";
import TotalPriceBox from "../TotalPriceBox/TotalPriceBox";
import ShippingService from "../../services/ShippingService";
import Loading from "../Loading/Loading";

interface TrackingInfoCardProps {
  sensor: ISensorDetails;
  onClose: () => void;
  onSuccess: () => void;
  onError: (message?: string) => void;
}

const TrackingInfoCard = ({
  sensor: { shippingLabel, returnLabel, patientId, id },
  onClose,
  onSuccess,
  onError,
}: TrackingInfoCardProps) => {
  const [isReturnLabelSectionActive, setIsReturnLabelSectionActive] =
    useState<boolean>(false);
  const [selectedReturnRate, setSelectedReturnRate] = useState<
    ISelectedRate | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const showGenerateBtn = useMemo(
    (): boolean => !returnLabel && !isReturnLabelSectionActive,
    [returnLabel, isReturnLabelSectionActive]
  );

  const handleRatesFetchFail = useCallback(() => {
    setIsReturnLabelSectionActive(false);
    onError("Error while fetching rates. Please try again.");
  }, [onError]);

  const handleCancelGenerateReturnLabel = useCallback(() => {
    setIsReturnLabelSectionActive(false);
    setSelectedReturnRate(undefined);
  }, []);

  const handleGenerateReturnLabel = useCallback(() => {
    if (!selectedReturnRate) return;

    setLoading(true);

    ShippingService.buyShipment({
      sensorId: id,
      returnLabel: {
        id: selectedReturnRate.shipmentId,
        rateId: selectedReturnRate.id,
      },
    })
      .then(() => {
        setIsReturnLabelSectionActive(false);
        onSuccess();
      })
      .catch(() => onError())
      .finally(() => setLoading(false));
  }, [id, onError, onSuccess, selectedReturnRate]);

  return (
    <CardContainer
      className="relative p-7 gap-1.5 max-w-[728px] lg:grow lg:w-0 lg:max-h-full lg:overflow-auto"
      childrenClassName="flex flex-col gap-5"
      title="Tracking info"
    >
      <h2 className="text-lg leading-5 text-cloudBurst font-medium">
        {!isReturnLabelSectionActive
          ? "Generated with EasyPost"
          : "Generate return label"}
      </h2>

      {showGenerateBtn && (
        <Button
          className="absolute top-9 right-7"
          onClick={() => setIsReturnLabelSectionActive(true)}
        >
          Generate Return Label
        </Button>
      )}

      <div className="flex flex-col gap-7">
        {isReturnLabelSectionActive ? (
          <>
            {selectedReturnRate && (
              <TotalPriceBox
                className="absolute top-9 right-7"
                value={Number(selectedReturnRate.rate)}
              />
            )}

            <div className="h-[355px] flex">
              <RatesContainer
                hideLabel
                hideEmptyOption
                isReturn
                patientId={patientId}
                onFetchFail={handleRatesFetchFail}
                selected={selectedReturnRate}
                onSelect={setSelectedReturnRate}
              />
            </div>
          </>
        ) : (
          <>
            {shippingLabel && (
              <GeneratedLabelsInfoSection
                shippingLabel={shippingLabel}
                returnLabel={returnLabel?.info ?? null}
              />
            )}
          </>
        )}

        <div className="self-center flex flex-col items-center gap-5 sm:flex-row">
          <Button
            className="border-2 border-pelorous !bg-white !text-pelorous"
            onClick={
              isReturnLabelSectionActive
                ? handleCancelGenerateReturnLabel
                : onClose
            }
          >
            {isReturnLabelSectionActive ? "Cancel" : "Close"}
          </Button>

          {isReturnLabelSectionActive && (
            <Button
              disabled={!selectedReturnRate}
              onClick={handleGenerateReturnLabel}
            >
              Generate Label(s) with Selected Rate
            </Button>
          )}
        </div>
      </div>

      <Loading loading={loading} />
    </CardContainer>
  );
};

export default TrackingInfoCard;
