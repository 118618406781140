import moment from "moment";
import {
  DurationType,
  FrequencyType,
  OrderExpiration,
  PayerType,
} from "enums/Order";
import {
  IBalanceTestItem,
  ICreateBalanceOrderItem,
  ICreateGaitOrderItem,
  IGaitTestItem,
  IGrouppedCreateOrderItems,
  IOrderDetails,
  IOrderItem,
} from "interfaces/Order";
import { TestType } from "enums/Test";
import { TestDurationValue } from "interfaces/Test";
import { getTestDurationOptions } from "helpers/TestHelper";
import { Apps } from "enums/Common";

export const formatDuration = (duration: number): string => {
  let secs = duration;

  const hrs = Math.floor(secs / 3600);
  secs = secs - hrs * 3600;
  const mins = Math.floor(secs / 60);
  secs = secs - mins * 60;

  return `${hrs ? `${hrs} hours ` : ""} ${
    mins ? `${mins} ${mins > 1 ? "minutes" : "minute"} ` : ""
  } ${secs ? `${secs} seconds` : ""}`;
};

export const formatFrequency = ({
  frequency,
  frequencyReps,
}: {
  frequency: FrequencyType;
  frequencyReps: number;
}): string => {
  return `${frequencyReps} per ${
    frequency === FrequencyType.Daily ? "day" : "week"
  }`;
};

export const hasOrderExpired = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): boolean => {
  const currentTime = moment();
  const start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");

  // check precision related to day, since we are not saving time creation
  return currentTime.isBefore(start, "day") || currentTime.isAfter(end, "day");
};

export const formatGaitDataForAPI = (data: ICreateGaitOrderItem) => {
  const { tests, selected } = data;

  if (!selected) return undefined;

  return tests.map(({ duration, durationType, frequency, frequencyReps }) => ({
    duration: duration!,
    durationType: durationType ?? DurationType.Seconds,
    frequency: frequency!,
    frequencyReps: Number(frequencyReps),
  }));
};

export const formatBalanceDataForAPI = (data: ICreateBalanceOrderItem) => {
  const { selected, duration, durationType, frequency, frequencyReps, tests } =
    data;

  if (!selected || !frequency || !frequencyReps) return undefined;

  if (!frequency) return [];

  return tests
    .filter(({ params: { eyes } }) => eyes)
    .map(({ params: { feet, eyes } }) => ({
      duration,
      durationType,
      frequency,
      frequencyReps,
      params: { feet, eyes: eyes! },
    }));
};

// export const formatAnglesSideAndParamsForAPI = ({
//   leftSide,
//   rightSide,
//   params,
// }: {
//   leftSide?: boolean;
//   rightSide?: boolean;
//   params: IAnglesParam[];
// }): {
//   side: AnglesLocation;
//   params: { region: AnglesRegion; motion: AnglesMotion; reps: number }[];
// } => {
//   let side: AnglesLocation;
//   const apiParams: {
//     region: AnglesRegion;
//     motion: AnglesMotion;
//     reps: number;
//   }[] = [];

//   switch (true) {
//     case leftSide && rightSide:
//       side = AnglesLocation.Both;
//       break;
//     case leftSide:
//       side = AnglesLocation.Left;
//       break;
//     default:
//       side = AnglesLocation.Right;
//   }

//   for (const { motion, region, reps } of params) {
//     apiParams.push({ motion, region, reps: Number(reps) });
//   }

//   return { side, params: apiParams };
// };

export const getDefaultTestsData = (): IGrouppedCreateOrderItems => {
  const defaultDuration = { duration: 10, durationType: DurationType.Seconds };

  // set all tests initially to true and unselected
  // submit will be disabled because we require at least one selected test
  // on selection, validation is automatically triggered for particular test
  return {
    [TestType.Gait]: {
      selected: false,
      valid: true,
      tests: [],
    },
    [TestType.Balance]: {
      selected: false,
      valid: true,
      tests: [],
      frequency: undefined,
      frequencyReps: undefined,
      ...defaultDuration,
    },
    // initialy valid, since it's hidden in first version
    [TestType.Angles]: { selected: false, valid: true, params: [] },
  };
};

const extractDurationAndFrequency = ({
  duration,
  durationType,
  subjectParam: { frequency, frequencyReps },
}: IOrderItem) => ({
  duration,
  durationType,
  frequency,
  frequencyReps,
});

const isCustomDuration = (duration: number): boolean => {
  const predefinedOptions = getTestDurationOptions();

  return predefinedOptions.every(({ value }) => {
    const currentDuration = Number(value.split("_")[0]);

    return duration !== currentDuration;
  });
};

export const getTestDataFromExistingOrder = ({
  orderItems,
}: IOrderDetails): IGrouppedCreateOrderItems => {
  const testsData: IGrouppedCreateOrderItems = getDefaultTestsData();

  const gaitOrderItems = orderItems.filter(
    ({ testId }) => testId === TestType.Gait
  );
  const balanceOrderItems = orderItems.filter(
    ({ testId }) => testId === TestType.Balance
  );

  if (gaitOrderItems.length) {
    const gaitTests: IGaitTestItem[] = gaitOrderItems.map((x) => ({
      ...extractDurationAndFrequency(x),
      isCustom: isCustomDuration(x.duration),
    }));

    testsData[TestType.Gait] = {
      selected: true,
      valid: true,
      tests: gaitTests,
    };
  }

  if (balanceOrderItems.length) {
    // duration and frequency are equal for all balance items
    const durationAndFrequency = extractDurationAndFrequency(
      balanceOrderItems[0]
    );

    const balanceTests: IBalanceTestItem[] = balanceOrderItems.map(
      ({ eyes, feet }) => ({
        params: { eyes, feet },
      })
    );

    testsData[TestType.Balance] = {
      selected: true,
      valid: true,
      tests: balanceTests,
      ...durationAndFrequency,
    };
  }

  return testsData;
};

export const extractDurationAndType = (
  value: TestDurationValue
): { duration: number; durationType: DurationType } => {
  const [duration, durationType] = value.split("_").map((x) => Number(x));

  return {
    duration,
    durationType,
  };
};

export const getExpiringOrdersLabel = (
  expiringEnum: OrderExpiration
): string => {
  switch (expiringEnum) {
    case OrderExpiration.ExpiresToday:
      return "Orders - Expires today";
    case OrderExpiration.ExpiresInOneWeek:
      return "Orders - Expires in 1 week";
    case OrderExpiration.ExpiresInTwoWeeks:
      return "Orders - Expires in 2 weeks";
    case OrderExpiration.RecentlyExpired:
      return "Orders - Recently expired";
  }
};

export const getPayerTypeLabel = (value: PayerType) => {
  switch (value) {
    case PayerType.Commercial:
      return "Commercial";
    case PayerType.Medicare:
      return "Medicare";
    case PayerType.Medicaid:
      return "Medicaid";
    case PayerType.PersonalInjury:
      return "Personal Injury";
    case PayerType.SelfPay:
      return "Self Pay";
    case PayerType.WorkersCompensation:
      return "Workers Compensation";
  }
};

export const getOrderTypeLabel = (value: Apps) => {
  switch (value) {
    case Apps.AtHome:
      return "LAB AT HOME";
    case Apps.Remote:
      return "LAB REMOTE";
  }
};
