import { useCallback } from "react";
import { formatDuration, formatFrequency } from "helpers/OrderHelper";
import { formatBalanceParams, formatTestNameByID } from "helpers/TestHelper";
import { IOrderVersionDetails } from "interfaces/Order";
import CardContainer from "components/CardContainer/CardContainer";
import Property from "components/Property/Property";
import { IProperty } from "interfaces/Common";
import { TestType } from "enums/Test";

interface OrderDetailsTestsCardProps {
  order: IOrderVersionDetails;
}

const OrderDetailsTestsCard = ({ order }: OrderDetailsTestsCardProps) => {
  const generateTestCardProperties = useCallback(
    (testId: TestType): IProperty[] => {
      if (!order.meta?.tests) return [];

      switch (testId) {
        case TestType.Gait: {
          const gaitTests = order.meta.tests[TestType.Gait];

          if (!gaitTests?.length) return [];

          return gaitTests.flatMap((x) => [
            { property: "Test Duration", value: formatDuration(x.duration) },
            {
              property: "Testing Frequency",
              value: formatFrequency(x),
            },
          ]);
        }
        case TestType.Balance: {
          const balanceTests = order.meta.tests[TestType.Balance];

          if (!balanceTests?.length) return [];

          // duration and frequency are same for all balance tests
          const firstTest = balanceTests[0];

          return [
            {
              property: "Test Duration",
              value: formatDuration(firstTest.duration),
            },
            {
              property: "Testing Frequency",
              value: formatFrequency(firstTest),
            },
            {
              property: "Test Parameters",
              value: balanceTests.map(({ params: { eyes, feet } }) =>
                formatBalanceParams(feet, eyes)
              ),
            },
          ];
        }
        // case TestType.Angles:
        //   return [
        //     frequencyProperty,
        //     {
        //       property: "Motions and repetitions",
        //       value: orderItems.map(({ distanceType, state }) =>
        //         formatAnglesParams(state, distanceType)
        //       ),
        //     },
        //     {
        //       property: "Side",
        //       value: formatLocationLabel(side as AnglesLocation),
        //     },
        //   ];
        default:
          return [];
      }
    },
    [order.meta]
  );

  return (
    <CardContainer title="Tests" childrenClassName="flex flex-col gap-5">
      {order.meta?.tests &&
        Object.keys(order.meta.tests).map((key) => (
          <CardContainer
            key={key}
            withoutOpacity
            title={`${formatTestNameByID(Number(key))} Testing`}
            childrenClassName={`flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)] ${
              key === "Angles" ? "[&>*:first-child]:!w-full" : ""
            }`}
          >
            {generateTestCardProperties(Number(key)).map((property, index) => (
              <Property key={index} required {...property} />
            ))}
          </CardContainer>
        ))}
    </CardContainer>
  );
};

export default OrderDetailsTestsCard;
