import { useState } from "react";
import { useParams } from "react-router-dom";
import { FileRejection } from "react-dropzone";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import Button from "components/Buttons/Button/Button";
import useSnackbar from "hooks/useSnackbar";
import PatientOperationsService from "services/PatientOperationsService";
import UploadFile from "components/UploadFile/UploadFile";
import UploadedFileInput from "components/UploadedFileInput/UploadedFileInput";
import { IUploadedAttachment } from "interfaces/Common";

interface AddOperationsAttachmentModalProps {
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}

const AddOperationsAttachmentModal = (
  props: AddOperationsAttachmentModalProps
) => {
  const { onClose, onSuccess, onError } = props;

  const { patientId } = useParams();

  const { setSnackbarError, snackbarComponent } = useSnackbar();

  const [attachment, setAttachment] =
    useState<Omit<IUploadedAttachment, "id">>(); // no need for id since only one file can be uploaded

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    const notValidCount = fileRejections.length;

    if (notValidCount) {
      const errorMessage =
        notValidCount > 1
          ? "Only one file can be added."
          : "File discarded. File size cannot exceed 20MB.";
      setSnackbarError(errorMessage);
      return;
    }

    if (acceptedFiles.length) {
      setAttachment({ file: acceptedFiles[0] });
    }
  };

  const handleAddAttachment = () => {
    if (!patientId || !attachment) return;

    PatientOperationsService.addAttachment({ patientId, ...attachment })
      .then(() => onSuccess())
      .catch(() => onError());

    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 max-h-[80%] max-w-[674px] p-6 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-7 md:px-10 md:py-7">
        <ButtonCloseModal onClick={onClose} />

        <h2 className="self-center text-lg font-bold text-cloudBurst leading-5">
          Add Attachment
        </h2>

        <div className="flex flex-col gap-2.5">
          <UploadFile onDrop={handleDrop} />

          {attachment && (
            <UploadedFileInput
              name={attachment.file.name}
              description={attachment.description}
              onDescriptionChange={(description) =>
                setAttachment((prev) => ({ ...prev!, description }))
              }
              onRemove={() => setAttachment(undefined)}
            />
          )}
        </div>

        <Button
          disabled={!attachment}
          className="self-end"
          onClick={handleAddAttachment}
        >
          Add Attachment
        </Button>

        {snackbarComponent}
      </div>
    </Modal>
  );
};

export default AddOperationsAttachmentModal;
