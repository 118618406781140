interface IPropertyLabel {
  property: string;
  bluePropertyText?: boolean;
  value: string | string[];
}

const PropertyLabel = ({
  property,
  bluePropertyText,
  value,
}: IPropertyLabel) => {
  return (
    <div className="flex gap-2">
      <span
        className={`${
          bluePropertyText ? "text-pelorous" : "text-cloudBurst"
        } shrink-0 text-right text-base font-bold w-28`}
      >
        {property}:
      </span>

      {typeof value === "string" ? (
        <span className="text-cloudBurst text-base self-end">{value}</span>
      ) : value?.length > 0 ? (
        <div className="flex flex-col">
          {value.map((x, index) => (
            <span key={index} className="text-cloudBurst text-base">
              {x}
            </span>
          ))}
        </div>
      ) : (
        "-"
      )}
    </div>
  );
};

export default PropertyLabel;
