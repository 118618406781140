import { ExportFormat, MimeType, ResponseHeader } from "../enums/Common";
import { IMultipleReportParams } from "../interfaces/Reports";
import ReportsService from "../services/ReportsService";
import { downloadFile, getTimezone } from "./CommonHelper";

export const handleExportResults = ({
  params,
  onError,
}: {
  params:
    | string
    | Omit<
        IMultipleReportParams,
        "showSubjectAttributes" | "export" | "_timezone"
      >;
  onError: () => void;
}) => {
  // pass only testId for single report export
  const isSingle = typeof params === "string";

  const commonParams = {
    _timezone: getTimezone(),
    showSubjectAttributes: true,
    export: ExportFormat.CSV,
  };

  (isSingle
    ? ReportsService.getSingle({ ...commonParams, id: params })
    : ReportsService.getMultiple({ ...commonParams, ...params })
  )
    .then(({ request, headers }) => {
      const fileName =
        headers[ResponseHeader.ContentDisposition]?.match(
          /filename="(.*?)"/i
        )?.[1] || `${isSingle ? params : params.testId}.csv`;

      const file = new File([request.response], fileName, {
        type: MimeType.CSV,
      });
      const url = URL.createObjectURL(file);

      downloadFile(url, fileName);
    })
    .catch(() => {
      onError();
    });
};
