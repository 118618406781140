import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DebouncedSearchField from "../../components/DebouncedSearchField/DebouncedSearchField";
import { IBaseOrder, IOrderFilters } from "../../interfaces/Order";
import { OrderDirection, PageSize } from "../../enums/Common";
import NewOrdersTable from "../../components/Tables/NewOrdersTable/NewOrdersTable";
import AssignSensorModal from "../../components/Modals/AssignSensorModal/AssignSensorModal";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { INavItem } from "../../interfaces/Common";
import { URLRoutes } from "../../enums/Routing";
import { OrderFilterParams, OrderStatus } from "../../enums/Order";

const NewOrders = () => {
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<IOrderFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `start_date ${OrderDirection.Desc}`,
    [OrderFilterParams.PatientName]:
      searchParams.get(OrderFilterParams.PatientName) || undefined,
    status: OrderStatus.Opened,
  });
  const [selectedOrder, setSelectedOrder] = useState<IBaseOrder | undefined>(
    undefined
  );

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      { label: "Operations", to: URLRoutes.OperationsDashboard },

      {
        label: "New Orders",
        to: URLRoutes.NewOrders,
      },
    ],
    []
  );

  return (
    <div className="h-full flex flex-col gap-4">
      <Breadcrumbs items={breadcrumbsItems} />
      <div className="grow h-0 flex flex-col gap-5">
        <div className="pr-5 xl:pr-0">
          <DebouncedSearchField
            placeholder="Search by Patient Name"
            value={filters["patient_lname|patient_fname"]}
            onChange={(value) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                [OrderFilterParams.PatientName]: value,
              }))
            }
          />
        </div>

        <div className="grow h-0">
          <NewOrdersTable
            filters={filters}
            setFilters={setFilters}
            onAssignSensor={setSelectedOrder}
          />
        </div>

        {selectedOrder && (
          <AssignSensorModal
            order={selectedOrder}
            onClose={() => setSelectedOrder(undefined)}
            // refresh data on success
            onSuccess={() => setFilters((prev) => ({ ...prev, page: 1 }))}
          />
        )}
      </div>
    </div>
  );
};

export default NewOrders;
