import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import TooltipBox from "../../components/TooltipBox/TooltipBox";
import { doesPasswordMeetsRequirements } from "../../helpers/CommonHelper";
import UserService from "../../services/UserService";
import styles from "./Profile.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import { Auth } from "../../recoil-states/auth-states";
import useSnackbar from "../../hooks/useSnackbar";

interface IPasswordForm {
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

interface IPasswordFieldValid {
  currentPassword?: boolean;
  newPassword?: boolean;
  confirmNewPassword?: boolean;
}

const Profile = () => {
  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [isEditableStateActive, setIsEditableStateActive] =
    useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [passwordForm, setPasswordForm] = useState<IPasswordForm | undefined>(
    undefined
  );
  const [isPasswordFormFieldValid, setIsPasswordFormFieldValid] = useState<
    IPasswordFieldValid | undefined
  >(undefined);
  const [isPasswordTextVisibile, setIsPasswordTextVisibile] = useState<
    IPasswordFieldValid | undefined
  >(undefined);

  const auth = useRecoilValue(Auth);

  useEffect(() => {
    if (!auth?.userId) return;

    UserService.getUserRole({ userId: auth.userId }).then(({ data }) => {
      setUserRole(data.map((x: { name: string }) => x.name).join(", "));
    });
  }, [auth?.userId]);

  const handleChangePassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { name, value } = target;

      setPasswordForm((prev) => ({
        ...prev,
        [name]: value,
      }));

      setIsPasswordFormFieldValid((prev) => ({
        ...prev,
        [name]: value && doesPasswordMeetsRequirements(value),
      }));
    },
    []
  );

  const isFormValid = useMemo(() => {
    return (
      passwordForm?.currentPassword &&
      passwordForm?.newPassword &&
      passwordForm?.confirmNewPassword &&
      passwordForm?.newPassword === passwordForm?.confirmNewPassword &&
      isPasswordFormFieldValid !== undefined &&
      Object.values(isPasswordFormFieldValid).every((item) => Boolean(item))
    );
  }, [isPasswordFormFieldValid, passwordForm]);

  const renderPasswordRequirements = useMemo(() => {
    return (
      <TooltipBox
        content={
          <div className="flex flex-col items-start">
            <span>Your password must contain: </span>
            <span>- At least 8 characters</span>
            <span>- Both lower and uppercase letters</span>
            <span>- At least 1 number</span>
            <span>- At least 1 special character</span>
          </div>
        }
      >
        <InfoIcon />
      </TooltipBox>
    );
  }, []);

  const handleVisibilityField = useCallback(
    (name: string) => {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() =>
              setIsPasswordTextVisibile((prev) => ({
                ...prev,
                [name]:
                  prev !== undefined ? !prev[name as keyof object] : false,
              }))
            }
          >
            <span
              className={`icon_svg ${
                isPasswordTextVisibile !== undefined &&
                isPasswordTextVisibile[name as keyof object]
                  ? "icon_visibility"
                  : "icon_visibility_off"
              }`}
            />
          </IconButton>
        </InputAdornment>
      );
    },
    [isPasswordTextVisibile]
  );

  const save = useCallback(() => {
    if (!auth?.userId) return;

    setIsEditableStateActive(false);
    setPasswordForm(undefined);
    setIsPasswordFormFieldValid(undefined);

    UserService.updateUser(auth.userId, {
      password: passwordForm?.newPassword,
    })
      .then(() => {
        setSnackbarSuccess("User data has been successfully updated.");
      })
      .catch(() => {
        setSnackbarError();
      });
  }, [passwordForm, auth?.userId, setSnackbarError, setSnackbarSuccess]);

  return (
    <div className="h-full flex flex-col relative pr-5 overflow-auto pb-3">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1 w-full xl:w-fit">
          <div className="text-2xl font-bold text-cloudBurst">Profile</div>
          <div className="font-medium text-cloudBurst">
            Preview your profile information
          </div>
        </div>
        <div className="flex flex-col gap-y-2.5 w-full xl:w-fit">
          {isEditableStateActive ? (
            <div className="flex flex-row self-start gap-x-5 xl:self-end">
              <button
                className="rounded-3xl bg-white/50 border-solid border-2 border-pelorous px-5 py-1 text-pelorous text-base font-medium"
                onClick={() => setIsEditableStateActive(false)}
              >
                Cancel
              </button>
              <button
                className="self-end rounded-3xl bg-tropicalRainForest px-5 py-1.5 text-base text-white font-medium disabled:opacity-50 disabled:pointer-events-none"
                onClick={save}
                disabled={!isFormValid}
              >
                Save Password
              </button>
            </div>
          ) : (
            <button
              className="self-start rounded-3xl bg-tropicalRainForest px-5 py-1.5 text-base text-white font-medium xl:self-end"
              onClick={() => setIsEditableStateActive(true)}
            >
              Change Password
            </button>
          )}
          <div className="flex flex-col gap-x-60 gap-y-7 bg-white/50 p-5 w-full rounded-md xl:flex-row xl:w-fit xl:gap-y-0">
            <div className="flex flex-col gap-y-7">
              <div>
                <p className="font-medium text-cloudBurst pb-2.5">Name</p>
                <p className="font-medium text-pelorous">
                  {auth?.initialData?.user.name || "-"}
                </p>
              </div>
              <div>
                <p className="font-medium text-cloudBurst pb-2.5">Email</p>
                <p className="font-medium text-pelorous">
                  {auth?.initialData?.user.email || "-"}
                </p>
              </div>
              <div>
                <p className="font-medium text-cloudBurst pb-2.5">Role</p>
                <p className="font-medium text-pelorous">{userRole || "-"}</p>
              </div>
            </div>
            <div className={`flex flex-col gap-y-5 ${styles.profile_form}`}>
              {isEditableStateActive ? (
                <>
                  <div className="w-full xl:w-96">
                    <p className="font-medium text-cloudBurst pb-0">
                      Current Password
                    </p>
                    <TextField
                      placeholder="Enter your current password"
                      name="currentPassword"
                      onChange={handleChangePassword}
                      error={
                        isPasswordFormFieldValid?.currentPassword === false
                      }
                      className={
                        isPasswordTextVisibile?.currentPassword
                          ? ""
                          : styles.hide_password
                      }
                      helperText={
                        <>
                          {isPasswordFormFieldValid?.currentPassword === false
                            ? "Invalid password format"
                            : undefined}
                          {renderPasswordRequirements}
                        </>
                      }
                      InputProps={{
                        endAdornment: passwordForm?.currentPassword?.length
                          ? handleVisibilityField("currentPassword")
                          : "",
                      }}
                    />
                  </div>
                  <div className="w-full xl:w-96">
                    <p className="font-medium text-cloudBurst pb-0">
                      New Password
                    </p>
                    <TextField
                      placeholder="Enter your new password"
                      name="newPassword"
                      onChange={handleChangePassword}
                      className={
                        isPasswordTextVisibile?.newPassword
                          ? ""
                          : styles.hide_password
                      }
                      error={isPasswordFormFieldValid?.newPassword === false}
                      helperText={
                        <>
                          {isPasswordFormFieldValid?.newPassword === false
                            ? "Invalid password format"
                            : undefined}
                          {renderPasswordRequirements}
                        </>
                      }
                      InputProps={{
                        endAdornment: passwordForm?.newPassword?.length
                          ? handleVisibilityField("newPassword")
                          : "",
                      }}
                    />
                  </div>
                  <div className="w-full xl:w-96">
                    <p className="font-medium text-cloudBurst pb-0">
                      Confirm Password
                    </p>
                    <TextField
                      placeholder="Confirm your new password"
                      name="confirmNewPassword"
                      onChange={handleChangePassword}
                      className={
                        isPasswordTextVisibile?.confirmNewPassword
                          ? ""
                          : styles.hide_password
                      }
                      error={
                        isPasswordFormFieldValid?.confirmNewPassword === false
                      }
                      helperText={
                        <>
                          {isPasswordFormFieldValid?.confirmNewPassword ===
                          false
                            ? "Invalid password format"
                            : undefined}
                          {renderPasswordRequirements}
                        </>
                      }
                      InputProps={{
                        endAdornment: passwordForm?.confirmNewPassword?.length
                          ? handleVisibilityField("confirmNewPassword")
                          : "",
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="w-64">
                  <p className="font-medium text-cloudBurst pb-2.5">Password</p>
                  <p
                    className={`font-medium text-xl text-cloudBurst ${styles.hide_password}`}
                  >
                    **********
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {snackbarComponent}
    </div>
  );
};

export default Profile;
