import { Outlet, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { URLRoutes } from "enums/Routing";
import { Auth } from "recoil-states/auth-states";

// route available only when not logged in
const GuestRoute = () => {
  const auth = useRecoilValue(Auth);

  return auth ? <Navigate to={URLRoutes.PatientsDashboard} /> : <Outlet />;
};

export default GuestRoute;
