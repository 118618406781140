import { forwardRef } from "react";
import { formatDate, formatName, formatTime } from "helpers/CommonHelper";
import { ITestNote } from "interfaces/Test";

interface TestNoteItemProps {
  note: ITestNote;
  onEditBtnClick: () => void;
  onDeleteBtnClick: () => void;
}

const TestNoteItem = forwardRef<HTMLDivElement, TestNoteItemProps>(
  (props, ref) => {
    const { note, onEditBtnClick, onDeleteBtnClick } = props;
    const {
      text,
      createdOn,
      createdByFirstName,
      createdByLastName,
      lastModified,
      lastModifiedFirstName,
      lastModifiedLastName,
    } = note;

    const isEdited = createdOn !== lastModified;

    return (
      <div
        className="flex flex-col p-5 bg-white/50 border border-t-0 border-linkWater"
        ref={ref}
      >
        <div className="mb-4 flex items-center justify-between gap-2.5">
          <p className="text-cloudBurst text-base leading-5 font-medium">
            Note Added
          </p>
          <p className="text-cloudBurst text-base leading-5 font-medium">
            {formatDate(createdOn)}, {formatTime(createdOn)}
          </p>
        </div>
        <p className="mb-2.5 text-cloudBurst text-sm leading-4 font-medium">
          {isEdited ? (
            <>
              Updated by:{" "}
              {formatName({
                firstName: lastModifiedFirstName,
                lastName: lastModifiedLastName,
              })}
            </>
          ) : (
            <>
              Added by:{" "}
              {formatName({
                firstName: createdByFirstName,
                lastName: createdByLastName,
              })}
            </>
          )}
        </p>
        <p className="mb-2.5 text-cloudBurst text-base leading-5 font-bold">
          {text}
        </p>
        <div className="self-end flex items-center gap-6">
          <button
            className="flex items-center gap-2 text-pelorous text-sm leading-4 font-medium"
            onClick={onEditBtnClick}
          >
            Edit Note
            <span className="icon_svg icon_edit" />
          </button>
          <button
            className="flex items-center gap-2 text-crimson text-sm leading-4 font-medium"
            onClick={onDeleteBtnClick}
          >
            Delete Note
            <span className="icon_svg icon_delete" />
          </button>
        </div>
      </div>
    );
  }
);

export default TestNoteItem;
