import { Apps } from "enums/Common";
import DropdownMultiselection from "components/Dropdowns/DropdownMultiselection/DropdownMultiselection";
import { IDropdownOption } from "interfaces/Common";
import useAvailableApps from "hooks/useAvailableApps";
import Switch from "components/Switch/Switch";

interface AppTypeSelectionProps {
  selected: Apps[];
  onChange: (value: Apps[]) => void;
  isModalFilter?: boolean;
}

// it will show only availabe apps as options
const AppTypeSelection = (props: AppTypeSelectionProps) => {
  const { selected, onChange, isModalFilter } = props;

  const { availableApps } = useAvailableApps();

  const getLabel = (app: Apps): string => {
    switch (app) {
      case Apps.InClinic:
        return "In Clinic";
      case Apps.AtHome:
        return "At Home";
      case Apps.Remote:
        return "Remote";
    }
  };

  const options: IDropdownOption<Apps>[] = availableApps.map((app) => ({
    label: getLabel(app),
    value: app,
  }));

  return (
    <>
      <div className="flex flex-col gap-1 relative">
        {isModalFilter && (
          <div className="flex justify-between items-center">
            <p className="text-base font-medium text-cloudBurst">
              Select Application(s)
            </p>
            <button
              className="absolute bottom-4 right-5 uppercase text-sm text-pelorous hover:cursor-pointer disabled:opacity-50 disabled:pointer-events-none xl:relative xl:right-auto xl:bottom-auto xl:normal-case"
              disabled={!selected.length}
              onClick={() => onChange([])}
            >
              Clear
            </button>
          </div>
        )}

        <div
          className={`p-3.5 rounded-md bg-white flex ${
            isModalFilter ? "flex-col gap-y-6 shadow-2xl pb-14" : "hidden"
          }`}
        >
          {options.map(
            ({ label, value }) =>
              isModalFilter && (
                <Switch
                  key={value}
                  className="flex-row-reverse justify-between"
                  label={label}
                  checked={selected.includes(value)}
                  onChange={(checked) =>
                    onChange(
                      checked
                        ? [...selected, value]
                        : selected.filter((x) => x !== value)
                    )
                  }
                />
              )
          )}
        </div>
        {!isModalFilter && (
          <DropdownMultiselection
            label="Select Application(s)"
            options={options}
            onChange={onChange}
            selected={selected}
          />
        )}
      </div>
    </>
  );
};

export default AppTypeSelection;
