import PageTitleDescription from "components/PageTitleDescription/PageTitleDescription";
import PatientProgress from "components/PatientProgress/PatientProgress";
import OrderCards from "components/OrderCards/OrderCards";
import { useAccountViewLayoutContext } from "components/Layouts/AccountViewLayout/AccountViewLayout";

const AccountDashboard = () => {
  const { dashboardData, loadingDashboardData } = useAccountViewLayoutContext();

  return (
    <div className="h-full flex flex-col gap-4">
      <PageTitleDescription title="Account Dashboard" />

      <div className="grow h-0 flex flex-col gap-5 overflow-auto pb-6">
        <OrderCards data={dashboardData} loading={loadingDashboardData} />
        <PatientProgress />
      </div>
    </div>
  );
};

export default AccountDashboard;
