import { formatDashDate } from "../../helpers/CommonHelper";
import { IShippingLabel } from "../../interfaces/Shipping";
import Property from "../Property/Property";
import TrackingLabelProperty from "../TrackingLabelProperty/TrackingLabelProperty";

interface GeneratedLabelsInfoSectionProps {
  shippingLabel: IShippingLabel;
  returnLabel: IShippingLabel | null;
}

const GeneratedLabelsInfoSection = ({
  shippingLabel,
  returnLabel,
}: GeneratedLabelsInfoSectionProps) => {
  return (
    <div className="flex gap-5 flex-wrap [&>div]:w-full md:order-none md:[&>div]:!w-[calc(50%-10px)]">
      <div className="flex flex-col gap-5 [&>div]:h-16">
        <Property
          property="Date"
          value={formatDashDate(shippingLabel.createdOn)}
        />
        <Property property="Carrier" value={shippingLabel.carrier} />
        <Property property="Days" value={shippingLabel.deliveryDays} />
        <Property property="Delivery method" value={shippingLabel.method} />
      </div>

      <div className="flex flex-col gap-5 [&>div]:min-h-[64px]">
        <Property
          property="Price"
          value={`$${
            !returnLabel
              ? shippingLabel.price
              : returnLabel.price + shippingLabel.price
          }`}
        />
        <TrackingLabelProperty data={shippingLabel} />
        {returnLabel && (
          <TrackingLabelProperty isReturnLabel data={returnLabel} />
        )}
      </div>
    </div>
  );
};

export default GeneratedLabelsInfoSection;
