import { useId } from "react";
import MUISwitch from "@mui/material/Switch";
import styles from "./Switch.module.scss";

interface SwitchProps {
  className?: string;
  disabled?: boolean;
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Switch = (props: SwitchProps) => {
  const { className, disabled, label, checked, onChange } = props;

  const id = useId();

  return (
    <div className={`${styles.switch_wrapper} ${className ?? ""}`}>
      <MUISwitch
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={(_, value) => onChange(value)}
      />
      <label
        htmlFor={id}
        className="text-cloudBurst text-base leading-5 font-medium"
      >
        {label}
      </label>
    </div>
  );
};

export default Switch;
