import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Outlet,
  generatePath,
  matchPath,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { INavItem } from "../../../interfaces/Common";
import { URLRoutes } from "../../../enums/Routing";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Navbar from "../../Navbar/Navbar";
import useSnackbar from "../../../hooks/useSnackbar";
import { IOrderDetails } from "../../../interfaces/Order";
import OrderService from "../../../services/OrderService";
import {
  IClinician,
  IClinicianDashboardData,
} from "../../../interfaces/Clinician";
import ClinicianService from "../../../services/ClinicianService";
import axios from "axios";
import { getExpiringOrdersLabel } from "helpers/OrderHelper";

const ClinicianViewLayout = () => {
  const { clinicianId, orderId, expiringEnumParam } = useParams();
  const { pathname } = useLocation();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [dashboardData, setDashboardData] = useState<IClinicianDashboardData>();
  const [loadingDashboardData, setLoadingDashboardData] =
    useState<boolean>(true);
  const [clinician, setClinician] = useState<IClinician>();
  const [order, setOrder] = useState<IOrderDetails>();
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);

  const clinicianInfoLabel = useMemo(
    (): string | undefined =>
      clinician ? `${clinician.lastName}, ${clinician.firstName}` : undefined,
    [clinician]
  );

  const dashboardBreadcrumbItems = useMemo(
    (): INavItem[] =>
      clinicianId &&
      (matchPath(URLRoutes.ClinicianNotAccessedPatients, pathname) ||
        matchPath(URLRoutes.ClinicianExpiringOrderDetails, pathname) ||
        matchPath(URLRoutes.ClinicianExpiringOrders, pathname))
        ? [
            {
              label: "Overview",
              to: generatePath(URLRoutes.ClinicianPreviewDashboard, {
                clinicianId,
              }),
            },
            ...(matchPath(URLRoutes.ClinicianNotAccessedPatients, pathname)
              ? [{ label: "Not Accessed Patients", to: "#" }]
              : [
                  {
                    label:
                      getExpiringOrdersLabel(Number(expiringEnumParam)) ?? "",
                    to: generatePath(URLRoutes.ClinicianExpiringOrders, {
                      clinicianId,
                      expiringEnumParam: expiringEnumParam ?? "",
                    }),
                  },
                  ...(order ? [{ label: `#${order.orderNum}`, to: "#" }] : []),
                ]),
          ]
        : [],
    [clinicianId, expiringEnumParam, order, pathname]
  );

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      { label: "Clinicians", to: URLRoutes.CliniciansDashboard },
      {
        label: clinicianInfoLabel || "",
        to: generatePath(URLRoutes.ClinicianPreviewDashboard, {
          clinicianId: clinicianId!,
        }),
      },
      ...dashboardBreadcrumbItems,
      ...(order && !matchPath(URLRoutes.ClinicianExpiringOrderDetails, pathname)
        ? [
            {
              label: "Orders",
              to: generatePath(URLRoutes.ClinicianOrders, {
                clinicianId: clinicianId!,
              }),
            },
            {
              label: `#${order.orderNum}`,
              to: "#",
            },
          ]
        : []),
    ],
    [clinicianInfoLabel, clinicianId, order, pathname, dashboardBreadcrumbItems]
  );

  const navbarItems = useMemo(
    (): INavItem[] => [
      {
        to: generatePath(URLRoutes.ClinicianPreviewDashboard, {
          clinicianId: clinicianId!,
        }),
        label: "Dashboard",
      },
      {
        to: generatePath(URLRoutes.ClinicianPatients, {
          clinicianId: clinicianId!,
        }),
        label: "Patients",
      },
      {
        to: generatePath(URLRoutes.ClinicianOrders, {
          clinicianId: clinicianId!,
        }),
        label: "Orders",
      },
      {
        to: generatePath(URLRoutes.ClinicianProfile, {
          clinicianId: clinicianId!,
        }),
        label: "Profile",
      },
    ],
    [clinicianId]
  );

  const getDashboardData = useCallback(
    (signal?: AbortSignal) => {
      if (!clinicianId) return;

      setLoadingDashboardData(true);

      ClinicianService.getOneDashboardData({ id: clinicianId }, signal)
        .then(({ data }) => {
          setDashboardData(data);
          setLoadingDashboardData(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setLoadingDashboardData(false);
          setSnackbarError();
        });
    },
    [clinicianId, setSnackbarError]
  );

  useEffect(() => {
    const controller = new AbortController();

    getDashboardData(controller.signal);

    return () => controller.abort();
  }, [getDashboardData]);

  useEffect(() => {
    if (!clinicianId) return;

    ClinicianService.getOne(clinicianId)
      .then(({ data }) => setClinician(data))
      .catch(() => setSnackbarError());
  }, [clinicianId, setSnackbarError]);

  const getOrder = useCallback(() => {
    if (!orderId) return;

    setLoadingOrder(true);

    OrderService.getOne({ orderId })
      .then(({ data }) => setOrder(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoadingOrder(false));
  }, [orderId, setSnackbarError]);

  useEffect(() => {
    if (orderId) {
      getOrder();
    } else {
      setOrder(undefined);
    }
  }, [orderId, getOrder]);

  const refreshOrder = useCallback(() => {
    getOrder();
    getDashboardData(); // refresh also dashboard data, maybe order expiration date has been edited
  }, [getOrder, getDashboardData]);

  return (
    <div className="h-full flex flex-col gap-4">
      <Breadcrumbs items={breadcrumbsItems} />

      {/* NavBar is hidden on Clinician Order Details, Expiring Orders,
          Expiring Order Details and Not Accessed Patients pages */}
      {!matchPath(URLRoutes.ClinicianOrderDetails, pathname) &&
        !matchPath(URLRoutes.ClinicianNotAccessedPatients, pathname) &&
        !matchPath(URLRoutes.ClinicianExpiringOrders, pathname) &&
        !matchPath(URLRoutes.ClinicianExpiringOrderDetails, pathname) && (
          <Navbar
            items={navbarItems}
            drawerTitle={"Clinician"}
            drawerLabel={clinicianInfoLabel}
          />
        )}

      <div className="grow h-0">
        <Outlet
          context={{
            dashboardData,
            loadingDashboardData,
            clinician,
            setClinician,
            order,
            loadingOrder,
            refreshOrder,
          }}
        />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default ClinicianViewLayout;

export const useClinicianViewLayoutContext = () => {
  return useOutletContext<{
    dashboardData: IClinicianDashboardData | undefined;
    loadingDashboardData: boolean;
    clinician: IClinician | undefined;
    setClinician: Dispatch<SetStateAction<IClinician | undefined>>;
    order: IOrderDetails | undefined;
    loadingOrder: boolean;
    refreshOrder: () => void;
  }>();
};
