import { useCallback, useEffect, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useRecoilValue } from "recoil";
import { ICommonFilters } from "interfaces/Common";
import { ResponseHeader } from "enums/Common";
import { AccountAuth } from "recoil-states/auth-states";

interface useFetchTableDataProps<TFilters extends ICommonFilters> {
  filters: TFilters;
  onPageIncrement: () => void; // wrap with useCallback in parent component!
  preventFetch?: boolean;
  fetchAPI: (
    params: TFilters,
    abortSignal: AbortSignal
  ) => Promise<AxiosResponse>;
  onError: () => void;
}

const useFetchTableData = <TData, TFilters extends ICommonFilters>(
  props: useFetchTableDataProps<TFilters>
) => {
  const { filters, preventFetch, fetchAPI, onPageIncrement, onError } = props;

  const [data, setData] = useState<TData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const allItemsLoaded = totalCount === data.length;

  const accountAuth = useRecoilValue(AccountAuth);

  const tableRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (preventFetch) return;

    setLoading(true);

    const abortController = new AbortController();

    fetchAPI(filters, abortController.signal)
      .then(({ data, headers }) => {
        const count = Number(headers[ResponseHeader.TotalCount]);

        setData((prev) => (filters.page === 1 ? data : [...prev, ...data]));
        setTotalCount(count);
        setLoading(false);
      })
      .catch((error) => {
        // cancel error will be triggered if new request is sent before old one is finished (user is changing sorting or filters too fast)
        // no need to call onError or set loading to false in that case
        if (!axios.isCancel(error)) {
          onError();
          setLoading(false);
        }
      });

    if (filters.page === 1)
      tableRef.current?.scrollTo({ top: 0, behavior: "smooth" });

    return () => abortController.abort();
  }, [fetchAPI, filters, onError, accountAuth, preventFetch]); // adding accountAuth, so once switched account is logout we can fetch data for logged in user again

  // attach this ref to last element in scroll container
  // when last element comes into view, it will triger next page fetch if more data is available
  const lastElementRef = useCallback(
    (node: Element | null) => {
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];

        if (loading || !isIntersecting || allItemsLoaded) return;

        onPageIncrement();
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, onPageIncrement, allItemsLoaded]
  );

  return { data, setData, loading, tableRef, lastElementRef };
};

export default useFetchTableData;
