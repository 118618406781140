interface NoTableItemsProps {
  className?: string;
  icon?: string;
  message?: string;
  search?: string | number;
}

const NoTableItems = (props: NoTableItemsProps) => {
  const {
    className,
    icon = "icon_no_results",
    message = "No results found",
    search,
  } = props;

  return (
    <div className={`flex flex-col items-center ${className ?? ""}`}>
      <div className="mb-5 w-16 h-16 rounded-full bg-crimson opacity-40 flex justify-center items-center">
        <span className={`icon_svg ${icon} !w-8 !h-8`} />
      </div>
      <div className="text-base font-medium text-center md:w-[370px] md:text-lg leading-5">
        {message}
      </div>
      <div className="text-lg leading-5 italic">{search}</div>
    </div>
  );
};

export default NoTableItems;
