import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { IBaseOrder, IOrderDetails, IOrderFilters } from "interfaces/Order";
import Loading from "components/Loading/Loading";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import {
  createUrlParams,
  formatDashDate,
  formatName,
} from "helpers/CommonHelper";
import OrderService from "services/OrderService";
import { hasOrderExpired } from "helpers/OrderHelper";
import useFetchTableData from "hooks/useFetchTableData";
import useSnackbar from "hooks/useSnackbar";
import { OrderFilterParams, OrderStatus } from "enums/Order";
import CreateEditOrderModal from "components/Modals/CreateEditOrderModal/CreateEditOrderModal";
import tableStyles from "styles/shared/table.module.scss";

interface OrdersTableProps {
  dontReplaceParams?: boolean;
  filters: IOrderFilters;
  onPageIncrement: () => void;
  onSortChange: (value: string) => void;
  refreshData: () => void;
  showPatientColumns?: boolean;
  noItemsMessage?: string;
}

const OrdersTable = ({
  dontReplaceParams,
  filters,
  onPageIncrement,
  onSortChange,
  refreshData,
  showPatientColumns,
  noItemsMessage = "No orders.",
}: OrdersTableProps) => {
  const [, setSearchParams] = useSearchParams();

  const searchFilter = filters[OrderFilterParams.OrderOrPatientName];

  const { snackbarComponent, setSnackbarError } = useSnackbar();
  const { data, loading, tableRef, lastElementRef } = useFetchTableData<
    IBaseOrder,
    IOrderFilters
  >({
    filters,
    onPageIncrement,
    fetchAPI: OrderService.getAll,
    onError: setSnackbarError,
  });

  const [orderToRenew, setOrderToRenew] = useState<IOrderDetails>();
  const [loadingToRenewId, setLoadingToRenewId] = useState<string>();

  const tableColumns = useMemo(
    (): ITableColumn[] => [
      { label: "Order ID", sortProperty: "order_num" },
      ...(showPatientColumns
        ? [
            { label: "Name", sortProperty: "patient_lname" },
            { label: "Date of Birth", sortProperty: "patient_dob" },
          ]
        : []),
      { label: "Ordering Clinician", sortProperty: "ordered_by_fname" },
      { label: "Order Date", sortProperty: "created_on" },
      { label: "Status" },
      { label: "" },
    ],
    [showPatientColumns]
  );

  useEffect(() => {
    if (dontReplaceParams) return;

    const {
      page,
      page_size,
      _total_count,
      patientId,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams, dontReplaceParams]);

  const formatStatusCellContent = ({
    status,
    startDate,
    endDate,
  }: {
    status: OrderStatus;
    startDate: string;
    endDate: string;
  }): ReactNode => {
    switch (true) {
      case status === OrderStatus.Cancelled:
        return "Canceled";
      case hasOrderExpired({ startDate, endDate }):
        return "Expired";
      default:
        return (
          <>
            Expires
            <br />
            {formatDashDate(endDate)}
          </>
        );
    }
  };

  const handleRenewBtnClick = (orderId: string) => {
    setLoadingToRenewId(orderId);

    OrderService.getOne({ orderId })
      .then(({ data }) => setOrderToRenew(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoadingToRenewId(undefined));
  };

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${tableStyles.padding_16_20}`}
          ref={tableRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }) => (
                  <TableCell key={label}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={onSortChange}
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((order, index) => {
                const {
                  id,
                  orderNum,
                  startDate,
                  endDate,
                  orderedByFname,
                  orderedByLname,
                  patientDob,
                  patientFname,
                  patientLname,
                  patientMname,
                  status,
                } = order;

                return (
                  <TableRow
                    key={orderNum}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell>{orderNum}</TableCell>
                    {showPatientColumns && (
                      <>
                        <TableCell>
                          {formatName({
                            firstName: patientFname,
                            lastName: patientLname,
                            middleName: patientMname,
                          })}
                        </TableCell>
                        <TableCell>{formatDashDate(patientDob)}</TableCell>
                      </>
                    )}
                    <TableCell>
                      {orderedByFname && orderedByLname
                        ? `${orderedByFname} ${orderedByLname}`
                        : "-"}
                    </TableCell>
                    <TableCell>{formatDashDate(startDate)}</TableCell>
                    <TableCell>
                      {formatStatusCellContent({
                        status,
                        startDate,
                        endDate,
                      })}
                    </TableCell>
                    <TableCell className={order.canBeRenewed ? "w-56" : "w-28"}>
                      <div className="flex justify-end gap-7 relative">
                        {order.canBeRenewed && (
                          <>
                            {loadingToRenewId === id && (
                              <CircularProgress
                                className="absolute -left-6"
                                size={17}
                              />
                            )}

                            <button
                              className="text-pelorous enabled:hover:underline disabled:opacity-50"
                              disabled={Boolean(loadingToRenewId)}
                              onClick={() => handleRenewBtnClick(id)}
                            >
                              Renew Order
                            </button>
                          </>
                        )}

                        <Link className="text-pelorous hover:underline" to={id}>
                          View Order
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={searchFilter ? "icon_search" : "icon_no_results"}
              message={searchFilter ? "No results found for" : noItemsMessage}
              search={searchFilter ?? undefined}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>

      {orderToRenew && (
        <CreateEditOrderModal
          isRenew
          initialOrder={orderToRenew}
          onClose={() => setOrderToRenew(undefined)}
          onCompleted={refreshData}
          onInitialDataFetchFail={setSnackbarError}
        />
      )}

      {snackbarComponent}
    </>
  );
};

export default OrdersTable;
