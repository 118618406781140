import { useState } from "react";
import DebouncedSearchField from "../../components/DebouncedSearchField/DebouncedSearchField";
import PageTitleDescription from "../../components/PageTitleDescription/PageTitleDescription";
import CliniciansTable from "../../components/Tables/CliniciansTable/CliniciansTable";
import { useSearchParams } from "react-router-dom";
import { OrderDirection, PageSize } from "../../enums/Common";
import { IClinicianFilters } from "../../interfaces/Clinician";

const CliniciansDashboard = () => {
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<IClinicianFilters>({
    _total_count: 1,
    page: 1,
    page_size: PageSize.Default,
    _order_by:
      searchParams.get("_order_by") || `last_name ${OrderDirection.Desc}`,
    "lastName|firstName": searchParams.get("lastName|firstName") || undefined,
  });

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-col gap-4 pr-5 xl:pr-0">
        <PageTitleDescription
          title="Clinicians"
          description="Search and select a clinician to view more details."
        />
        <DebouncedSearchField
          placeholder="Search by Ordering Clinician"
          value={filters["lastName|firstName"]}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              "lastName|firstName": value,
            }))
          }
        />
      </div>

      <div className="grow h-0">
        <CliniciansTable filters={filters} setFilters={setFilters} />
      </div>
    </div>
  );
};

export default CliniciansDashboard;
