import TextField, { TextFieldProps } from "@mui/material/TextField";
import styles from "./LoginInput.module.scss";

const LoginInput = (props: TextFieldProps & { label: string }) => {
  const { label, ...rest } = props;

  return (
    <div className={`${styles.login_input_wrapper} flex flex-col space-y-1`}>
      <label className="text-white text-base font-medium">{label}</label>
      <TextField {...rest} />
    </div>
  );
};

export default LoginInput;
