import { useMemo } from "react";
import moment from "moment";
import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { Color } from "enums/Common";
import { ITrendsData } from "interfaces/Patient";
import { RollupInterval } from "enums/Test";
import NoTestsBox from "components/NoTestsBox/NoTestsBox";
import styles from "./TrendsChart.module.scss";

interface TrendsChartProps {
  data: ITrendsData;
  rollup: RollupInterval;
}

const TrendsChart = ({ data, rollup }: TrendsChartProps) => {
  const noData = useMemo((): boolean => !data?.length, [data]);

  const trendsAvg = useMemo(
    (): number | undefined =>
      data?.length
        ? Math.ceil(
            data.map(({ v }) => v ?? 0).reduce((a, b) => a + b) / data.length
          )
        : undefined,
    [data]
  );

  // API returns unsorted data
  // sort it by date in ascending order
  const sortedData = useMemo((): ITrendsData | null => {
    if (!data) return null;

    const clonedData = [...data];

    switch (rollup) {
      case RollupInterval.Day:
        return clonedData.sort(
          (a, b) =>
            moment(a.d!, "YYYY-MM-DD").unix() -
            moment(b.d!, "YYYY-MM-DD").unix()
        );
      case RollupInterval.Week:
        return clonedData.sort((a, b) =>
          a.y! !== b.y! ? a.y! - b.y! : a.w! - b.w!
        );
      case RollupInterval.Month:
        return clonedData.sort((a, b) =>
          a.y! !== b.y! ? a.y! - b.y! : a.m! - b.m!
        );
      case RollupInterval.Year:
        return clonedData.sort((a, b) => a.y! - b.y!);
    }
  }, [data, rollup]);

  const yData = useMemo(
    (): number[] | undefined => sortedData?.map(({ v }) => v ?? 0),
    [sortedData]
  );

  const xData = useMemo((): string[] | undefined => {
    if (!sortedData) return undefined;

    return sortedData.map(({ d, m, w, y }) => {
      const year = String(y).slice(-2); // show only last 2 numbers, 2023 for example, 23.

      switch (rollup) {
        case RollupInterval.Day:
          return moment(d).format("M/D/YY");
        case RollupInterval.Week:
          // add will create date with the first day in next week
          // we need to subtract one week to create date with first day in specified week
          const date = moment({ year: y }).add(w ? w - 1 : undefined, "weeks");

          return date.format("M/D/YY");
        case RollupInterval.Month:
          return `${m}/${year}`;
        default:
          return String(year);
      }
    });
  }, [sortedData, rollup]);

  const chartOption = useMemo((): EChartsOption => {
    const axisLabel = {
      padding: 8,
      color: Color.Nero,
      fontFamily: "Cabin",
      fontSize: 12,
      lineHeight: 15,
    };

    return {
      grid: {
        top: 40,
        right: 30,
        bottom: 15,
        left: 45,
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: xData,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: { color: Color.VeryLightGrey },
        },
        axisLabel,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          ...axisLabel,
          margin: 20,
        },
        max: 100,
        splitLine: {
          show: true,
          lineStyle: { color: Color.VeryLightGrey },
        },
      },
      series: [
        {
          data: yData,
          type: "line",
          symbolSize: 9,
          lineStyle: { width: 3, color: Color.Pelorous },
          markLine: {
            data: trendsAvg
              ? [
                  {
                    type: "average",
                    name: "AVG",
                    lineStyle: { width: 2, color: Color.CloudBurst },
                    label: { show: false },
                    symbolSize: 0,
                    emphasis: { disabled: true },
                  },
                ]
              : [],
          },
        },
      ],
      tooltip: { trigger: "axis" },
    };
  }, [xData, yData, trendsAvg]);

  return !noData ? (
    <>
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        option={chartOption}
      />

      <div className={styles.percent_text}>
        <span>HIGH</span>
        <span className="icon_svg icon_long_arrow" />
        <span>PERCENT</span>
        <span className="icon_svg icon_long_arrow rotate-180" />
        <span>LOW</span>
      </div>
      <div className={styles.color_bar}>
        {Boolean(trendsAvg) && (
          <span
            className="icon_svg icon_arrow !absolute left-1/2 -translate-x-1/2 translate-y-1/2"
            style={{ bottom: `${trendsAvg}%` }}
          />
        )}
      </div>
    </>
  ) : (
    <NoTestsBox />
  );
};

export default TrendsChart;
