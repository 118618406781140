import { useState } from "react";
import BillingTable from "components/Tables/BillingTable/BillingTable";
import { IBillingFilters } from "interfaces/Billing";
import { OrderDirection, PageSize } from "enums/Common";

interface OrderBillingHistoryProps {
  orderId: string;
  patientId: string;
}

const OrderBillingHistory = (props: OrderBillingHistoryProps) => {
  const { orderId, patientId } = props;

  const [filters, setFilters] = useState<IBillingFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by: `to_date ${OrderDirection.Desc}`,
    orderId,
    patientId,
  });

  return (
    <div className="h-full pr-5">
      <BillingTable
        isSingleOrderHistory
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
};

export default OrderBillingHistory;
