import { NavLink } from "react-router-dom";
import { ISidebarItem } from "../../interfaces/Common";
import styles from "./SidebarItem.module.scss";

const SidebarItem = ({
  icon,
  label,
  hidden,
  onClick,
  to,
  submenuItems,
}: ISidebarItem) => {
  return !hidden ? (
    <>
      {to ? (
        <NavLink
          className={({ isActive }) =>
            `${styles.sidebar_item} ${isActive ? styles.active : ""}`
          }
          to={to}
          onClick={onClick}
        >
          <span className={`icon_svg ${icon}`} />
          {label}
        </NavLink>
      ) : submenuItems && submenuItems.length > 0 ? (
        <button className={styles.sidebar_item_accordion}>
          <div className="flex flex-row items-center justify-start w-full cursor-default">
            <span className={`icon_svg ${icon} mr-2.5`} />
            {label}
          </div>
          <div
            className={`flex flex-col gap-2.5 items-end transition-opacity duration-300 ease-linear w-full ${styles.submenu_items_wrapper}`}
          >
            {submenuItems.map(({ name, to }) => (
              <NavLink
                key={name}
                to={to}
                className={({ isActive }) => `${isActive ? styles.active : ""}`}
                onClick={onClick}
              >
                {name}
              </NavLink>
            ))}
          </div>
        </button>
      ) : (
        <button className={styles.sidebar_item} onClick={onClick}>
          <span className={`icon_svg ${icon}`} />
          {label}
        </button>
      )}
    </>
  ) : null;
};

export default SidebarItem;
