import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import UserService from "services/UserService";
import { IUserRole } from "interfaces/User";
import TooltipInfoIcon from "components/TooltipInfoIcon/TooltipInfoIcon";
import styles from "./RolesDropdown.module.scss";

interface RolesDropdownProps {
  placeholder: string;
  value: number | undefined | null;
  onChange: (value: number) => void;
}

const RolesDropdown = (props: RolesDropdownProps) => {
  const { placeholder, value, onChange } = props;

  const [roles, setRoles] = useState<IUserRole[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    UserService.getRoles()
      .then(({ data }) => setRoles(data))
      .finally(() => setLoading(false));
  }, []);

  // API will return array of roles which be can be set by logged in user
  // disable dropdown if provided role isn't contained in that array
  const disabled =
    loading ||
    (typeof value === "number" && !roles.some(({ id }) => id === value));

  return (
    <div className={`flex flex-col ${styles.select_wrapper}`}>
      <div className="flex justify-between">
        <label className="self-start text-cloudBurst text-base font-medium">
          Role *
        </label>
        <TooltipInfoIcon
          content={
            <div className="flex flex-col gap-4 text-left">
              <p>
                <b>Admin:</b> Admins have the highest level of access for the
                account. Admins are able to add, edit and deactivate other
                account admins and users and view all patient and clinician
                records within the account portal.
              </p>
              <p>
                <b>User:</b> Users are able create and access all patient
                records within the account portal and submit RTM Orders.
              </p>
            </div>
          }
        />
      </div>

      <Select
        displayEmpty
        renderValue={(value) =>
          roles.find(({ id }) => id === value)?.name ?? placeholder
        }
        MenuProps={{
          classes: { root: styles.select_root },
        }}
        disabled={disabled}
        value={value ?? ""}
        onChange={({ target: { value } }) => onChange(Number(value))}
        // show spinner while fetching roles
        endAdornment={loading ? <CircularProgress size={20} /> : undefined}
      >
        {roles.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default RolesDropdown;
