import { Permissions } from "../enums/Common";

// List of permissions:
// https://devext.biomechservices.com:21116/apidoc/v1/user-permissions

export const hasPermissions = (
  permissionMask: string | undefined,
  permissions: Permissions[]
) => {
  if (!permissionMask) return false;

  let permissionList: boolean[] = [];

  for (let i = 0; i < permissions.length; i++) {
    /** permissions[i] referrs to single permission **/
    const permissionIndex = permissionMask.length - permissions[i];

    if (permissionIndex < 0) return false;

    const hasPermission = permissionMask[permissionIndex] === "1";
    permissionList.push(hasPermission);
  }

  return permissionList.every((x) => x);
};

export const hasClientAdminRole = (permissionMask: string | undefined) => {
  return hasPermissions(permissionMask, [Permissions.AdminClient]);
};

export const hasSuperAdminRole = (permissionMask: string | undefined) => {
  return hasPermissions(permissionMask, [Permissions.AdminClientsManagement]);
};
