import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { SlideTimeout } from "../../../enums/Common";
import ButtonCloseModal from "../../Buttons/ButtonCloseModal/ButtonCloseModal";
import { ISensorHistory } from "../../../interfaces/Sensor";
import GeneratedLabelsInfoSection from "../../GeneratedLabelsInfoSection/GeneratedLabelsInfoSection";

interface TrackingInfoModalProps {
  data: ISensorHistory;
  onClose: () => void;
}

// all fields are readonly
const TrackingInfoModal = ({
  data: { outboundShipping, returnShipping },
  onClose,
}: TrackingInfoModalProps) => {
  return (
    <Modal open onClose={onClose}>
      <Slide in direction="left" timeout={SlideTimeout.Default}>
        <div className="py-4 px-8 flex flex-col items-center gap-7 absolute top-2.5 right-0 bottom-0 left-0 bg-white rounded-t-md border border-cloudBurst shadow-xl outline-none lg:left-auto lg:w-[724px]">
          <ButtonCloseModal className="left-4 !top-6" onClick={onClose} />

          <h2 className="text-lg leading-5 text-cloudBurst font-bold">
            Tracking info
          </h2>

          <div className="self-stretch grow h-0 overflow-auto flex flex-col gap-7">
            {outboundShipping && (
              <GeneratedLabelsInfoSection
                shippingLabel={outboundShipping}
                returnLabel={returnShipping}
              />
            )}
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default TrackingInfoModal;
