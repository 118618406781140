import { useCallback, useMemo, useState } from "react";
import { ValidationError } from "../enums/Common";
import { ValidationErrorChangeEvent } from "../interfaces/Common";

// object with key - input name and value: validation error pairs
export interface IValidationErrors {
  [key: string]: ValidationError | undefined;
}

const useValidationErrors = () => {
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>(
    {}
  );

  const hasErrors = useMemo(
    (): boolean =>
      Object.values(validationErrors).some((error) => error !== undefined),
    [validationErrors]
  );

  const handleValidationErrorChange = useCallback(
    ({ error, name }: ValidationErrorChangeEvent) => {
      setValidationErrors((prev) => ({ ...prev, [name]: error }));
    },
    []
  );

  const resetErrors = useCallback(() => {
    setValidationErrors({});
  }, []);

  return {
    hasErrors,
    handleValidationErrorChange,
    resetErrors,
  };
};

export default useValidationErrors;
