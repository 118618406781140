import { useMemo } from "react";
import CardContainer from "components/CardContainer/CardContainer";
import Property from "components/Property/Property";
import { IProperty } from "interfaces/Common";
import { formatDashDate, formatDate } from "helpers/CommonHelper";
import { IOrderVersionDetails } from "interfaces/Order";
import { getPayerTypeLabel } from "helpers/OrderHelper";

interface IOrderDetailsPatientCard {
  order: IOrderVersionDetails;
}

const OrderDetailsPatientCard = ({ order }: IOrderDetailsPatientCard) => {
  const patientProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Name",
        value:
          order.patientLname && order.patientFname
            ? `${order.patientLname}, ${order.patientFname}`
            : undefined,
        required: true,
      },
      {
        property: "DOB",
        value: formatDashDate(order.patientDob),
        required: true,
      },
      ...(order.billable
        ? [
            {
              property: "Payer Type",
              value: getPayerTypeLabel(order.billable),
              required: true,
            },
          ]
        : []),
      ...(order.meta?.insurance && order.meta.insuranceData
        ? [
            {
              property: "Primary Insurance Provider",
              value: order.meta.insuranceData.provider,
              required: true,
            },
            {
              property: "Primary Insurance Policy Number",
              value: order.meta.insuranceData.policy,
              required: true,
            },
            {
              property: "Secondary Insurance Provider",
              value: order.meta.insuranceData.secondaryProvider,
            },
            {
              property: "Secondary Insurance Policy Number",
              value: order.meta.insuranceData.secondaryPolicy,
            },
            {
              property: "NPI Number",
              value: order.meta.insuranceData.npi,
            },
            {
              property: "Date of Injury",
              required: true,
              value: order.meta.insuranceData.injuryDate
                ? formatDate(order.meta.insuranceData.injuryDate)
                : undefined,
            },
          ]
        : []),
    ],
    [order]
  );

  return (
    <CardContainer
      title="Patient"
      childrenClassName={`flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)] ${
        order.meta?.insurance ? "md:last:[&>div]:!w-full" : ""
      }`}
    >
      {patientProperties.map((property) => (
        <Property key={property.property} {...property} />
      ))}

      {order.meta?.insurance && order.meta.insuranceData && (
        <div className="flex flex-col gap-5">
          <Property
            property="Insurance Notes"
            value={order.meta.insuranceData.notes}
          />
          <Property
            required
            property="Diagnosis Information"
            value={order.meta.diagnosisCodes?.join(" - ")}
          />
        </div>
      )}
    </CardContainer>
  );
};

export default OrderDetailsPatientCard;
