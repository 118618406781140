import { useEffect, useRef, useState } from "react";
import axios from "axios";
import OrderExpirationCard from "../../components/PatientDashboard/OrderExpirationCard/OrderExpirationCard";
import UsageCard from "../../components/PatientDashboard/UsageCard/UsageCard";
import QuickReportCard from "../../components/PatientDashboard/QuickReportCard/QuickReportCard";
import PatientService from "../../services/PatientService";
import useSnackbar from "../../hooks/useSnackbar";
import Loading from "../../components/Loading/Loading";
import PatientTrendsAndUsage from "../../components/PatientDashboard/PatientTrendsAndUsage/PatientTrendsAndUsage";
import {
  IPatientTestUsage,
  PatientActiveOrderData,
} from "../../interfaces/Patient";
import { usePatientViewLayoutContext } from "../../components/Layouts/PatientViewLayout/PatientViewLayout";

interface IPatientDashboardData {
  activeOrder: PatientActiveOrderData | null;
  testUsage: IPatientTestUsage[];
}

const PatientDashboard = () => {
  const {
    patient,
    filters: { patientId },
    setFilters,
  } = usePatientViewLayoutContext();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const trendsAndUsageContainerRef = useRef<HTMLDivElement | null>(null);

  const [data, setData] = useState<IPatientDashboardData | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!patientId) return;

    const controller = new AbortController();

    PatientService.getOneDashboardData({ id: patientId }, controller.signal)
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setSnackbarError();
      });

    return () => controller.abort();
  }, [patientId, setSnackbarError]);

  const handleUsageDetailsClick = () => {
    setFilters((prev) => ({ ...prev, showTrends: false }));
    trendsAndUsageContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="relative h-full flex flex-col gap-5 pb-5 overflow-scroll pr-5 xl:pr-0">
        {/* Top Cards */}
        <div className="relative shrink-0 flex flex-col gap-5 [&>div]:shrink-0 [&>div]:h-[350px] lg:h-[350px] lg:[&>div]:grow lg:[&>div]:w-0 lg:[&>div]:h-auto lg:flex-row lg:w-full">
          <OrderExpirationCard data={data?.activeOrder} />
          <UsageCard
            data={data?.testUsage}
            onUsageDetailsClick={handleUsageDetailsClick}
          />
          <QuickReportCard
            lastTestDate={patient?.lastTestDate ?? null}
            onError={setSnackbarError}
          />

          <Loading loading={loading} />
        </div>

        <PatientTrendsAndUsage ref={trendsAndUsageContainerRef} />
      </div>

      {snackbarComponent}
    </>
  );
};

export default PatientDashboard;
