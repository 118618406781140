import { useCallback, useState } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import moment from "moment";
import { DateRange as DateRangeEnum, Datepicker } from "enums/Common";
import styles from "./RangeDatePicker.module.scss";

interface IRangeDatePicker {
  isTableFilter?: boolean;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  isModalFilter?: boolean;
}

interface IRangeSelection {
  selection: {
    startDate?: Date;
    endDate?: Date;
    key?: string;
  };
}

const RangeDatePicker = (props: IRangeDatePicker) => {
  const { isTableFilter, value, onChange, isModalFilter } = props;

  const [selectedOption, setSelectedOption] = useState<Datepicker>(
    value ? Datepicker.Custom : Datepicker.All
  );
  const [range, setRange] = useState<IRangeSelection | undefined>({
    selection: {
      startDate: value
        ? new Date(value.split(DateRangeEnum.Separator)[0])
        : undefined,
      endDate: value
        ? new Date(value.split(DateRangeEnum.Separator)[1])
        : new Date(),
      key: "selection",
    },
  });

  const handleDateChange = useCallback(
    ({ selection }: RangeKeyDict) => {
      const format = "YYYY-MM-DD";
      const startDate = moment(selection.startDate).format(format);
      const endDate = moment(selection.endDate).add(1, "days").format(format);
      const value = `${startDate}${DateRangeEnum.Separator}${endDate}`;

      setRange({ selection });

      // range date picker will trigger range selection on start date selection (end date will be same as start date)
      // trigger onChange only after end date is selected
      if (selection.startDate === selection.endDate) return;

      onChange(value);
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    setRange(undefined);
    onChange(undefined);
    setSelectedOption(Datepicker.All);
  }, [onChange]);

  const handleTodaySelection = useCallback(() => {
    const selection = {
      selection: {
        key: "selection",
        startDate: new Date(),
        endDate: new Date(),
      },
    };
    handleDateChange(selection);
    setSelectedOption(Datepicker.Today);
  }, [handleDateChange]);

  const handle30DaysSelection = useCallback(() => {
    const date = new Date();
    const firstDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 30
    );
    const lastDay = new Date();

    const selection = {
      selection: {
        key: "selection",
        startDate: firstDay,
        endDate: lastDay,
      },
    };

    handleDateChange(selection);
    setSelectedOption(Datepicker.LastMonth);
  }, [handleDateChange]);

  const handleAllSelection = useCallback(() => {
    const selection = {
      selection: {
        startDate: undefined,
        endDate: new Date(),
        key: "selection",
      },
    };

    handleDateChange(selection);
    setSelectedOption(Datepicker.All);
    onChange(undefined);
  }, [handleDateChange, onChange]);

  return (
    <div className="flex flex-col gap-1 relative">
      {!isTableFilter && (
        <div className="flex justify-between items-center">
          <span className="text-base leading-5 font-medium text-cloudBurst">
            Select date range
          </span>
          <button
            className="absolute bottom-4 right-5 uppercase text-sm text-pelorous hover:cursor-pointer disabled:opacity-50 disabled:pointer-events-none xl:relative xl:right-auto xl:bottom-auto xl:normal-case"
            disabled={!value || selectedOption === Datepicker.All}
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      )}

      <div>
        <div
          className={`py-1.5 mb-1 rounded-md flex justify-between border-solid bg-white text-cloudBurst ${
            isTableFilter ? "" : "border border-linkWater"
          } ${styles.datepicker_custom_controls}`}
        >
          <span
            className={`cursor-pointer hover:text-pelorous hover:underline ${
              selectedOption === Datepicker.Today
                ? "text-pelorous underline pointer-events-none"
                : ""
            }`}
            onClick={handleTodaySelection}
          >
            Today
          </span>
          <span
            className={`cursor-pointer hover:text-pelorous hover:underline ${
              selectedOption === Datepicker.LastMonth
                ? "text-pelorous underline pointer-events-none"
                : ""
            }`}
            onClick={handle30DaysSelection}
          >
            Last 30 days
          </span>
          <span
            className={`cursor-pointer hover:text-pelorous hover:underline ${
              selectedOption === Datepicker.All
                ? "text-pelorous underline pointer-events-none"
                : ""
            }`}
            onClick={handleAllSelection}
          >
            All
          </span>
          <span
            className={`cursor-pointer hover:text-pelorous hover:underline ${
              selectedOption === Datepicker.Custom
                ? "text-pelorous underline pointer-events-none"
                : ""
            }`}
            onClick={() => setSelectedOption(Datepicker.Custom)}
          >
            Custom
          </span>
        </div>
        <DateRange
          className={`${styles.range_date_picker} ${
            isModalFilter ? styles.modal_filter : ""
          } ${selectedOption !== Datepicker.Custom ? styles.disabled : ""} ${
            isTableFilter ? "" : "border border-linkWater"
          }`}
          onChange={handleDateChange}
          ranges={range ? [range.selection] : undefined}
          color="#1E87C9"
          rangeColors={["#1E87C9"]}
          weekdayDisplayFormat="EEEEE"
        />
      </div>
    </div>
  );
};

export default RangeDatePicker;
