import { Link, generatePath } from "react-router-dom";
import DashboardCardContainer from "components/DashboardCardContainer/DashboardCardContainer";
import { URLRoutes } from "enums/Routing";

interface PatientRecordsCardProps {
  data?: string[];
  clinicianId?: string;
}

const PatientRecordsCard = ({ data, clinicianId }: PatientRecordsCardProps) => {
  return (
    <DashboardCardContainer
      title="RTM Patient records not accessed"
      subtitle="(Past 30 days)"
      childrenClassName="flex justify-center items-center"
      children={
        data && (
          <div
            className={`${
              data.length === 0 ? "bg-tropicalRainForest" : "bg-crimson"
            } rounded-full w-52 h-52 flex items-center justify-center relative`}
          >
            <span className={`icon_svg icon_folder`} />

            <Link
              className={`mt-3 absolute text-base leading-5 font-bold underline whitespace-pre-wrap text-center ${
                !data.length ? "pointer-events-none" : ""
              }`}
              to={
                clinicianId
                  ? generatePath(URLRoutes.ClinicianNotAccessedPatients, {
                      clinicianId,
                    })
                  : URLRoutes.AccountNotAccessedPatients
              }
            >
              {`${data.length ? "View\n" : ""}${data.length} ${
                data.length === 1 ? "patient" : "patients"
              }`}
            </Link>
          </div>
        )
      }
    />
  );
};

export default PatientRecordsCard;
