import axios from "../config/axios";
import { API } from "../enums/API";
import { IBuyShipmentLabelData } from "../interfaces/Shipping";

class ShippingService {
  getRates = (params: { patientId: string; isReturn: boolean }) => {
    return axios.get(API.ShippingRates, { params });
  };

  buyShipment = (
    data: {
      sensorId: string;
    } & (
      | {
          shipmentLabel: IBuyShipmentLabelData;
          returnLabel: IBuyShipmentLabelData;
        }
      | {
          shipmentLabel: IBuyShipmentLabelData;
        }
      | {
          returnLabel: IBuyShipmentLabelData;
        }
    )
  ) => {
    return axios.post(API.ShippingBuy, data);
  };
}

export default new ShippingService();
