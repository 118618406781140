import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITableColumn } from "interfaces/Common";
import { TestTableProps } from "interfaces/Test";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import {
  formatCompletedOnTime,
  formatDate,
  roundHalfToEven,
} from "helpers/CommonHelper";
import { GaitMetric } from "enums/Test";
import { roundTestScore } from "helpers/TestHelper";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./GaitTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Details", sortProperty: "created_on" },
  { label: "Steps", sortProperty: `metric_${GaitMetric.StepsTotal}` },
  { label: "Cadence", sortProperty: `metric_${GaitMetric.Cadence}` },
  { label: "Impact % (L / R)" },
  {
    label: "Pelvic Tilt [avg]",
    sortProperty: `metric_${GaitMetric.PelvicDeviationDisplacement}`,
  },
  { label: "Gait Score", sortProperty: `metric_${GaitMetric.GaitScore}` },
  { label: "" },
];

export const GaitTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    orderBy,
    onSort,
    onRunReportBtnClick,
    getTestNotesLink,
    getMenuOptions,
  } = props;

  return (
    <Table className={styles.gait_table}>
      <TableHead>
        <TableRow>
          <TableCell>Gait Tests</TableCell>
          <TableCell colSpan={6} />
        </TableRow>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }, index) => (
            <TableCell key={index}>
              {sortProperty ? (
                <TableSortLabel
                  whiteTheme
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map(
          (
            {
              id,
              accession,
              createdOn,
              orderItemId,
              metric,
              performedDuration,
              noteCount,
            },
            index
          ) => (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatCompletedOnTime(createdOn, performedDuration / 1000)}
                <br />
                <span className={styles.accession_label}>Test ID</span>
                <br />
                {accession}
              </TableCell>
              <TableCell>{metric[GaitMetric.StepsTotal] as number}</TableCell>
              <TableCell>
                {Math.round(metric[GaitMetric.Cadence] as number)}
              </TableCell>
              <TableCell>{`${roundHalfToEven(
                metric[GaitMetric.ImpactLeft] as number
              )} / ${roundHalfToEven(
                metric[GaitMetric.ImpactRight] as number
              )}`}</TableCell>
              <TableCell>
                {(
                  metric[GaitMetric.PelvicDeviationDisplacement] as number
                )?.toFixed(1)}
              </TableCell>
              <TableCell className="!text-4xl !font-bold">
                {roundTestScore(metric[GaitMetric.GaitScore] as number)}
              </TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
