import { ComponentProps } from "react";

interface CardContainerProps extends ComponentProps<"div"> {
  title: string;
  disabled?: boolean;
  withoutOpacity?: boolean;
  childrenClassName?: string;
}

const CardContainer = (props: CardContainerProps) => {
  const {
    title,
    disabled,
    withoutOpacity,
    className,
    children,
    childrenClassName,
    ...rest
  } = props;

  return (
    <div
      className={`p-4 rounded-md border border-pelorous flex flex-col gap-5 ${
        withoutOpacity ? "bg-white" : "bg-white/50"
      } ${disabled ? "opacity-50 pointer-events-none" : ""} ${className ?? ""}`}
      {...rest}
    >
      <h2 className="text-lg leading-5 text-pelorous font-bold">{title}</h2>
      <div className={childrenClassName}>{children}</div>
    </div>
  );
};

export default CardContainer;
