import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import DashboardCardContainer from "components/DashboardCardContainer/DashboardCardContainer";
import { OrdersExpirationData } from "interfaces/Order";
import { OrderExpiration } from "enums/Order";

interface IExpirationData {
  title: string;
  remainingLabel: string;
  expiringEnum: OrderExpiration;
  bgColor: string;
}

interface ExpiringOrdersCardProps {
  data?: OrdersExpirationData;
  clinicianId?: string;
}

const ExpiringOrdersCard = ({ data, clinicianId }: ExpiringOrdersCardProps) => {
  const expirationData = useMemo(
    (): IExpirationData[] => [
      {
        title: "Expires today",
        remainingLabel: "0 days remaining",
        expiringEnum: OrderExpiration.ExpiresToday,
        bgColor: "bg-crimson",
      },
      {
        title: "Expires in 1 week",
        remainingLabel: "1-7 days remaining",
        expiringEnum: OrderExpiration.ExpiresInOneWeek,
        bgColor: "bg-tahitiGold",
      },
      {
        title: "Expires in 2 weeks",
        remainingLabel: "8-14 days remaining",
        expiringEnum: OrderExpiration.ExpiresInTwoWeeks,
        bgColor: "bg-malibu",
      },
      {
        title: "Recently expired",
        remainingLabel: "Within last 14 days",
        expiringEnum: OrderExpiration.RecentlyExpired,
        bgColor: "bg-endeavour",
      },
    ],
    []
  );

  const renderCountElement = (expiringEnum: OrderExpiration): ReactNode => {
    const ids = data?.[expiringEnum];
    const count = ids?.length;

    switch (count) {
      case undefined:
        return "-";
      case 0:
        return <span className="text-base leading-5 font-bold">No orders</span>;
      default:
        return (
          <Link
            className="underline text-base leading-5 font-bold"
            to={`expiring-orders/${expiringEnum.toString()}`}
          >
            View {count} {count === 1 ? "order" : "orders"}
          </Link>
        );
    }
  };

  return (
    <DashboardCardContainer
      title="Expiring RTM orders"
      childrenClassName="flex flex-col gap-y-2"
      children={
        <>
          {expirationData.map(
            ({ title, remainingLabel, expiringEnum, bgColor }) => (
              <div
                key={title}
                className={`grow px-2 py-2.5 rounded-lg text-white flex justify-between items-center gap-5 ${bgColor}`}
              >
                <div className="h-full flex flex-col justify-between">
                  <p className="text-base leading-5 font-bold uppercase">
                    {title}
                  </p>
                  <p>{remainingLabel}</p>
                </div>

                {renderCountElement(expiringEnum)}
              </div>
            )
          )}
        </>
      }
    />
  );
};

export default ExpiringOrdersCard;
