import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IMenuOption } from "../../interfaces/Test";
import styles from "./MenuOptions.module.scss";
import { Color } from "../../enums/Common";

interface IMenuOptions {
  options: IMenuOption[];
}

const MenuOptions = ({ options }: IMenuOptions) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <button
        className="flex p-2"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <span className="icon_svg icon_more" />
      </button>

      <Menu
        className={styles.menu_wrapper}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {options.map(({ label, color = Color.Pelorous, disabled, onClick }) => (
          <MenuItem
            key={label}
            style={{ color }}
            disabled={disabled}
            onClick={() => {
              onClick();
              setAnchorEl(null);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuOptions;
