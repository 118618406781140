import { FormEvent, useState } from "react";
import Modal from "@mui/material/Modal";
import InputField from "components/InputField/InputField";
import Button from "components/Buttons/Button/Button";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { BillingStatus } from "enums/Billing";

interface BillingReasonModalProps {
  initialComment: string | null;
  newStatus: BillingStatus;
  onConfirm: (data: { status: BillingStatus; comment: string }) => void;
  onClose: () => void;
}

const BillingReasonModal = (props: BillingReasonModalProps) => {
  const { initialComment, newStatus, onClose, onConfirm } = props;

  const [comment, setComment] = useState<string | null>(initialComment);

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();

    if (!comment?.trim()) return;

    onConfirm({ status: newStatus, comment });
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 max-h-[80%] max-w-[674px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:px-10 md:py-7 outline-none">
        <ButtonCloseModal onClick={onClose} />

        <form className="flex flex-col gap-5" onSubmit={handleConfirm}>
          <h2 className="text-center text-lg font-bold text-cloudBurst leading-5">
            Edit Status
          </h2>

          <InputField
            label="Reason"
            placeholder="Enter reason (explanation required for Not Billed - Other)"
            required
            multiline
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <Button
            className="self-end flex items-center gap-3"
            type="submit"
            disabled={!comment?.trim()}
          >
            Confirm Change
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default BillingReasonModal;
