import { useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import Radio from "@mui/material/Radio";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import CircleIcon from "@mui/icons-material/Circle";
import styles from "./RatesContainer.module.scss";
import ShippingService from "../../../services/ShippingService";
import { ISelectedRate, IShipment } from "../../../interfaces/Shipping";
import Loading from "../../Loading/Loading";
import { URLRoutes } from "../../../enums/Routing";

interface RatesContainerProps {
  patientId: string | null;
  isReturn?: boolean;
  hidden?: boolean;
  hideLabel?: boolean;
  hideEmptyOption?: boolean;
  selected: ISelectedRate | undefined;
  onSelect: (value: ISelectedRate | undefined) => void;
  onFetchFail: () => void;
}

const RatesContainer = ({
  patientId,
  isReturn = false,
  hidden,
  hideLabel,
  hideEmptyOption,
  selected,
  onSelect,
  onFetchFail,
}: RatesContainerProps) => {
  const [shipmentData, setShipmentData] = useState<IShipment | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);

  const noGeneratedRates = Boolean(shipmentData && !shipmentData.rates?.length);

  useEffect(() => {
    // fetch data only once
    if (hidden || shipmentData || !patientId) return;

    ShippingService.getRates({ isReturn, patientId })
      .then(({ data }) => setShipmentData(data))
      .catch(() => onFetchFail())
      .finally(() => setLoading(false));
  }, [shipmentData, hidden, isReturn, patientId, onFetchFail]);

  return (
    <div
      className={`grow flex flex-col gap-1 ${hidden ? "hidden" : ""} ${
        styles.rates_container
      }`}
    >
      {!hideLabel && (
        <p className="text-cloudBurst font-medium">
          {isReturn ? "Return" : "Outgoing"}
        </p>
      )}
      <div className="relative grow h-0 overflow-auto flex flex-col gap-4 px-5 border border-pelorous rounded-md bg-white/50">
        {isReturn && !noGeneratedRates && !loading && !hideEmptyOption && (
          <div
            className={`mt-5 flex gap-2 items-center px-4 py-[30px] border border-pelorous rounded-md ${
              !selected
                ? "bg-pelorous/10 pointer-events-none"
                : "bg-white cursor-pointer"
            }`}
            onClick={() => onSelect(undefined)}
          >
            <Radio
              disableRipple
              icon={<CircleTwoToneIcon />}
              checkedIcon={<CircleIcon />}
              checked={!selected}
            />
            <p className="text-sm leading-4 text-cloudBurst font-medium">
              No return label
            </p>
          </div>
        )}

        {shipmentData?.rates?.map((item) => (
          <div
            key={item.id}
            className={`p-4 border border-pelorous rounded-md flex justify-between last:mb-5 ${
              selected?.id === item.id
                ? "bg-pelorous/10 pointer-events-none"
                : "bg-white cursor-pointer"
            } ${isReturn && !hideEmptyOption ? "" : "first:mt-5"}`}
            onClick={() => onSelect({ ...item, shipmentId: shipmentData.id })}
          >
            <div className="flex items-center gap-2 w-3/5">
              <Radio
                disableRipple
                icon={<CircleTwoToneIcon />}
                checkedIcon={<CircleIcon />}
                checked={selected?.id === item.id}
              />
              <div className="flex flex-col gap-2 grow w-0">
                <p className="text-sm leading-4 text-cloudBurst font-medium">
                  {item.carrier}
                </p>
                <p className="text-sm leading-4 text-cloudBurst/80 font-medium max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {item.service}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-end justify-end gap-2">
              <p className="text-sm leading-4 text-cloudBurst/80 font-medium">
                {item.deliveryDays &&
                  `${item.deliveryDays} ${
                    item.deliveryDays > 1 ? "days" : "day"
                  }`}
              </p>
              <p className="text-cloudBurst font-medium">{`$${item.rate}`}</p>
            </div>
          </div>
        ))}

        {noGeneratedRates && (
          <div className="max-w-full px-3 flex flex-col items-center gap-1 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <p className="text-center w-[350px] max-w-full">
              It is not possible to generate rates for the patient. The
              patient's address must be entered and valid. Please check address
              and try again.
            </p>
            {patientId && (
              <Link
                target="_blank"
                className="text-pelorous hover:underline"
                to={generatePath(URLRoutes.PatientProfile, { patientId })}
              >
                Go to Patient Profile
              </Link>
            )}
          </div>
        )}

        <Loading loading={loading} />
      </div>
    </div>
  );
};

export default RatesContainer;
