import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { FreeformAnglesMetric } from "../../../../../enums/Test";
import { formatFFAMetricValues } from "../../../../../helpers/TestHelper";
import { ITestResults } from "../../../../../interfaces/Test";
import TestResultsTableContainer from "../../../../Tables/TestResultsTableContainer/TestResultsTableContainer";

const FreeformAnglesTestResults = ({
  orderIteration: { metric },
}: ITestResults) => {
  const resultTableColumns: string[] = useMemo(() => ["X", "Y", "X"], []);

  return (
    <div className="flex justify-center">
      <TestResultsTableContainer className="!w-2/3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>Result (Degrees)</TableCell>
            </TableRow>
            <TableRow>
              {resultTableColumns.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {formatFFAMetricValues(
                  metric[FreeformAnglesMetric.X] as number
                )}
              </TableCell>
              <TableCell>
                {formatFFAMetricValues(
                  metric[FreeformAnglesMetric.Y] as number
                )}
              </TableCell>
              <TableCell>
                {formatFFAMetricValues(
                  metric[FreeformAnglesMetric.Z] as number
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TestResultsTableContainer>
    </div>
  );
};

export default FreeformAnglesTestResults;
