import { useMemo } from "react";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

interface OverallChangeBoxProps {
  value: number | null;
}

const OverallChangeBox = ({ value }: OverallChangeBoxProps) => {
  const colorClasses = useMemo((): string => {
    switch (true) {
      case !value:
        return "border-fiord/50 bg-fiord/40";
      case value && value > 0:
        return "border-tropicalRainForest bg-tropicalRainForest/80";
      default:
        return "border-crimson bg-crimson/80";
    }
  }, [value]);

  const overallChangeText = useMemo((): string => {
    if (value === null) return "";

    switch (true) {
      case value === 0:
        return "No change";
      case value > 0:
        return "Overall improvement";
      default:
        return "Overall decline";
    }
  }, [value]);

  return (
    <div
      className={`shrink-0 w-[120px] rounded-[20px] p-2 flex flex-col items-center justify-center gap-2.5 border-2 ${colorClasses}`}
    >
      {value !== null ? (
        <>
          {value !== 0 && (
            <ArrowDownwardRoundedIcon
              sx={{
                fill: "white",
                width: 49,
                height: 56,
                ...(value > 0 && {
                  rotate: "180deg",
                }),
              }}
            />
          )}

          <p className="text-white text-2xl leading-7 font-bold">
            {`${value > 0 ? "+" : ""}${value} %`}
          </p>
          <p className="text-white text-sm leading-4 text-center uppercase">
            ({overallChangeText})
          </p>
        </>
      ) : (
        <p className="text-white text-2xl leading-7 font-bold">N/A</p>
      )}
    </div>
  );
};

export default OverallChangeBox;
