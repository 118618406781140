import { useEffect, useState } from "react";
import axios from "axios";
import { RollupInterval, TestType, Interval } from "../../../enums/Test";
import BlueRibbon from "../BlueRibbon/BlueRibbon";
import OverallChangeBox from "../OverallChangeBox/OverallChangeBox";
import ToggleInterval from "../../ToggleInterval/ToggleInterval";
import TestService from "../../../services/TestService";
import {
  IGaitTrendsData,
  IPatientTestUsage,
  ITrendParams,
} from "../../../interfaces/Patient";
import Loading from "../../Loading/Loading";
import {
  calculateCreatedOnFromInterval,
  getTrendsAndUsageIntervalOptions,
} from "../../../helpers/TrendsAndUsageHelper";
import UsageChart from "../UsageChart/UsageChart";
import TrendsChart from "../../TrendsChart/TrendsChart";
import { IUsageParams } from "../../../interfaces/Test";
import { usePatientViewLayoutContext } from "../../Layouts/PatientViewLayout/PatientViewLayout";

const GaitTrendsAndUsage = () => {
  const {
    filters: { patientId, showTrends, gaitInterval },
    setFilters,
  } = usePatientViewLayoutContext();

  const [trendsData, setTrendsData] = useState<IGaitTrendsData | undefined>(
    undefined
  );
  const [usageData, setUsageData] = useState<IPatientTestUsage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!patientId) return;

    setLoading(true);

    const controller = new AbortController();

    const commonParams = { testId: TestType.Gait, patientId };
    const intervalFilter = calculateCreatedOnFromInterval({
      interval: gaitInterval,
      isTrends: showTrends,
    });

    const trendParams: ITrendParams = {
      ...commonParams,
      rollup: RollupInterval.Day,
      createdOn: intervalFilter,
    };

    const usageParams: IUsageParams = {
      ...commonParams,
      from: intervalFilter,
    };

    (showTrends
      ? TestService.getTrendsData(trendParams, controller.signal)
      : TestService.getUsageData(usageParams, controller.signal)
    )
      .then(({ data }) => {
        if (showTrends) {
          setTrendsData(data);
        } else {
          setUsageData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;

        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [showTrends, patientId, gaitInterval]);

  return (
    <div className="flex flex-col gap-5">
      <BlueRibbon text={`GAIT ${showTrends ? "TRENDS" : "USAGE"}`} />

      <div className="h-[500px] flex gap-5 sm:h-[392px]">
        <div className="grow w-0 flex flex-col rounded-[20px] overflow-hidden rounded-tr-lg border-[3px] border-pattensBlue3">
          <div className="flex flex-col gap-1.5 sm:flex-row">
            <p className="pl-3 mt-4 text-cloudBurst text-lg leading-5 font-bold">{`GAIT ${
              showTrends ? "SCORE" : "TESTS"
            } OVER THE MOST RECENT:`}</p>
            <ToggleInterval<Interval>
              active={gaitInterval}
              options={getTrendsAndUsageIntervalOptions()}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, gaitInterval: value }))
              }
            />
          </div>

          <div className="grow h-0 bg-endeavour/10 relative">
            {loading ? (
              <Loading loading />
            ) : (
              <>
                {showTrends ? (
                  <TrendsChart
                    data={trendsData?.trends ?? []}
                    rollup={RollupInterval.Day}
                  />
                ) : (
                  <UsageChart data={usageData} />
                )}
              </>
            )}
          </div>
        </div>

        {showTrends && trendsData?.trends?.length && (
          <OverallChangeBox value={trendsData.trend} />
        )}
      </div>
    </div>
  );
};

export default GaitTrendsAndUsage;
