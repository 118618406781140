import { Dispatch, ReactNode, SetStateAction, useCallback } from "react";
import { Link, generatePath } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { formatDashDate, formatName } from "helpers/CommonHelper";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { URLRoutes } from "enums/Routing";
import {
  IPatientProgress,
  IPatientProgressFilters,
  IPatientProgressFiltersWithInterval,
} from "interfaces/Patient";
import useFetchTableData from "hooks/useFetchTableData";
import useSnackbar from "hooks/useSnackbar";
import PatientService from "services/PatientService";
import Loading from "components/Loading/Loading";
import { handleShortlistPatient } from "helpers/PatientHelper";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import tableStyles from "styles/shared/table.module.scss";
import styles from "./PatientProgressTable.module.scss";

interface PatientProgressTableProps {
  filters: IPatientProgressFilters;
  setFilters: Dispatch<SetStateAction<IPatientProgressFiltersWithInterval>>;
}

const tableColumns: ITableColumn[] = [
  { label: "Name", sortProperty: "last_name" },
  { label: "Date of Birth", sortProperty: "dob" },
  { label: "Date Range" },
  { label: "Balance", sortProperty: "balance_trend" },
  { label: "Gait", sortProperty: "gait_trend" },
  { label: "Click on the header to sort by column" },
];

const PatientProgressTable = (props: PatientProgressTableProps) => {
  const { filters, setFilters } = props;
  const { clinicianId, _order_by: orderBy } = filters;

  const { snackbarComponent, setSnackbarError, setSnackbarSuccess } =
    useSnackbar();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, setData, loading, tableRef, lastElementRef } =
    useFetchTableData<IPatientProgress, IPatientProgressFilters>({
      filters,
      onPageIncrement,
      fetchAPI: PatientService.getPatientProgress,
      onError: setSnackbarError,
    });

  const renderScore = (score: number | null): ReactNode => {
    if (score === null)
      return (
        <div className="flex items-center justify-center text-fiord">N/A</div>
      );

    let bgColorClass: string | undefined;
    let textColorClass: string | undefined;
    let iconClass: string | undefined;

    switch (true) {
      case score === 0:
        bgColorClass = "bg-fiord";
        textColorClass = "text-fiord";
        iconClass = "icon_minus_2";
        break;
      case score > 0:
        bgColorClass = "bg-tropicalRainForest";
        textColorClass = "text-tropicalRainForest";
        iconClass = "icon_arrow_up";
        break;
      default:
        bgColorClass = "bg-crimson";
        textColorClass = "text-crimson";
        iconClass = "icon_arrow_down";
    }

    return (
      <div
        className={`flex items-center justify-center gap-1 ${textColorClass}`}
      >
        <div
          className={`flex items-center justify-center rounded-full ${bgColorClass} w-5 h-5`}
        >
          <span className={`icon_svg ${iconClass}`}></span>
        </div>
        {/* add + sign for positive numbers */}
        {`${score > 0 ? "+" : ""}${score}%`}
      </div>
    );
  };

  return (
    <>
      <div className="relative grow">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${tableStyles.padding_20} ${styles.patient_progress_table}`}
          ref={tableRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell
                    key={label}
                    className={
                      index === tableColumns.length - 1
                        ? "italic !text-xs !font-normal"
                        : ""
                    }
                  >
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={orderBy}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (
                  {
                    id,
                    firstName,
                    lastName,
                    middleName,
                    dob,
                    balanceTrend,
                    gaitTrend,
                    testDateFrom,
                    testDateTo,
                    isShortlist,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell className="!font-bold">
                      {formatName({ lastName, firstName, middleName })}
                    </TableCell>
                    <TableCell>{formatDashDate(dob)}</TableCell>
                    <TableCell>{`${formatDashDate(
                      testDateFrom
                    )} - ${formatDashDate(testDateTo)}`}</TableCell>
                    <TableCell className="!bg-pattensBlue2/50 w-36">
                      {renderScore(balanceTrend)}
                    </TableCell>
                    <TableCell className="w-40">
                      {renderScore(gaitTrend)}
                    </TableCell>
                    <TableCell className="w-40">
                      <div className="flex justify-end items-center gap-7">
                        <Link
                          className="text-pelorous hover:underline"
                          to={
                            clinicianId
                              ? generatePath(
                                  URLRoutes.ClinicianPatientDashboard,
                                  {
                                    clinicianId,
                                    patientId: id,
                                  }
                                )
                              : generatePath(URLRoutes.PatientDashboard, {
                                  patientId: id,
                                })
                          }
                        >
                          View Details
                        </Link>

                        <button
                          className="flex"
                          onClick={() =>
                            handleShortlistPatient<IPatientProgress>({
                              id,
                              isShortlist,
                              setData,
                              onSuccess: setSnackbarSuccess,
                              onError: setSnackbarError,
                            })
                          }
                        >
                          <span
                            className={`icon_svg ${
                              isShortlist ? "icon_star_filled" : "icon_star"
                            }`}
                          />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon="icon_no_results"
              message={"No results for selected filters"}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </>
  );
};

export default PatientProgressTable;
