import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageTitleDescription from "components/PageTitleDescription/PageTitleDescription";
import { IBillingFilters } from "interfaces/Billing";
import { searchParamsToObject } from "helpers/CommonHelper";
import { OrderDirection, PageSize } from "enums/Common";
import BillingTable from "components/Tables/BillingTable/BillingTable";

const BillingDashboard = () => {
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<IBillingFilters>({
    ...searchParamsToObject(searchParams),
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `last_use ${OrderDirection.Desc}`,
    status: searchParams.get("status")
      ? Number(searchParams.get("status"))
      : undefined,
  });

  return (
    <div className="h-full flex flex-col gap-4 pr-5">
      <PageTitleDescription title="Billing" />

      <div className="grow h-0">
        <BillingTable filters={filters} setFilters={setFilters} />
      </div>
    </div>
  );
};

export default BillingDashboard;
