import Dropdown from "components/Dropdowns/Dropdown/Dropdown";
import { PayerType } from "enums/Order";
import { getPayerTypeLabel } from "helpers/OrderHelper";
import { IDropdownOption } from "interfaces/Common";

interface PayerTypeDropdownProps {
  selected: PayerType | undefined;
  onChange: (value: PayerType) => void;
}

const PayerTypeDropdown = ({ selected, onChange }: PayerTypeDropdownProps) => {
  const options: IDropdownOption<PayerType>[] = [
    PayerType.Commercial,
    PayerType.Medicare,
    PayerType.Medicaid,
    PayerType.PersonalInjury,
    PayerType.SelfPay,
    PayerType.WorkersCompensation,
  ].map((x) => ({ value: x, label: getPayerTypeLabel(x) }));

  return (
    <Dropdown
      hideClearButton
      useInputFieldStyle
      label="Payer Type *"
      error={!selected}
      options={options}
      selected={selected}
      onChange={(value) => (value ? onChange(value) : undefined)}
    />
  );
};

export default PayerTypeDropdown;
