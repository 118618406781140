import { Link } from "react-router-dom";
import { IOperationBoxData } from "../../interfaces/Operations";
import TooltipBox from "../TooltipBox/TooltipBox";

const OperationBox = ({
  count,
  icon,
  title,
  to,
  tooltip,
  redHeader,
}: IOperationBoxData) => {
  return (
    <div className="flex flex-col rounded-[9px] w-full min-w-[250px] min-h-[180px] overflow-hidden shadow-xl md:w-[calc(50%-12px)] lg:w-[calc(25%-18px)] lg:max-w-[300px]">
      <TooltipBox offsetX={26} offsetY={-5} content={tooltip ?? ""}>
        <div
          className={`p-2.5 flex justify-between items-center ${
            redHeader ? "bg-crimson/80" : "bg-fiord"
          }`}
        >
          <p className="text-xl leading-6 text-white font-bold">{title}</p>
          <span className={`icon_svg ${icon}`} />
        </div>
      </TooltipBox>

      <div className="relative grow h-0 bg-white flex justify-center items-center">
        <p className="text-cloudBurst text-5xl font-medium">{count ?? "-"}</p>

        <Link
          className="absolute bottom-5 right-5 border-b border-pelorous text-pelorous text-xl leading-5 font-bold"
          to={to}
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default OperationBox;
