import { ButtonHTMLAttributes } from "react";
import { Color } from "../../../enums/Common";

const ButtonCloseModal = ({
  className,
  color = Color.Pelorous,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { color?: Color }) => {
  return (
    <button
      className={`w-fit flex absolute top-4 right-4 p-0 ${className}`}
      {...rest}
    >
      <span
        className="icon_svg icon_cross"
        style={{ backgroundColor: color }}
      />
    </button>
  );
};

export default ButtonCloseModal;
