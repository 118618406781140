import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useSnackbar from "hooks/useSnackbar";
import { createUrlParams, formatName } from "helpers/CommonHelper";
import { ITableColumn } from "interfaces/Common";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import Loading from "components/Loading/Loading";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { URLRoutes } from "enums/Routing";
import ClinicianService from "services/ClinicianService";
import useFetchTableData from "hooks/useFetchTableData";
import { IClinician, IClinicianFilters } from "interfaces/Clinician";
import tableStyles from "styles/shared/table.module.scss";

interface IClinicianTable {
  filters: IClinicianFilters;
  setFilters: Dispatch<SetStateAction<IClinicianFilters>>;
}

const tableColumns: ITableColumn[] = [
  { label: "Name", sortProperty: "last_name" },
  { label: "Email", sortProperty: "email" },
  { label: "" },
];

const CliniciansTable = ({ filters, setFilters }: IClinicianTable) => {
  const [, setSearchParams] = useSearchParams();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, loading, tableRef, lastElementRef } = useFetchTableData<
    IClinician,
    IClinicianFilters
  >({
    filters,
    onPageIncrement,
    fetchAPI: ClinicianService.getAll,
    onError: setSnackbarError,
  });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${tableStyles.padding_20}`}
          ref={tableRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }) => (
                  <TableCell key={label}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                ({ id, firstName, lastName, middleName, email }, index) => (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell className="!font-bold">
                      {formatName({ lastName, firstName, middleName })}
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell className="w-32">
                      <Link
                        className="text-pelorous hover:underline"
                        to={generatePath(URLRoutes.ClinicianPreviewDashboard, {
                          clinicianId: id,
                        })}
                      >
                        View Details
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={
                filters["lastName|firstName"]
                  ? "icon_search"
                  : "icon_no_results"
              }
              message={
                filters["lastName|firstName"]
                  ? "No results found for"
                  : "No added clinicians."
              }
              search={filters["lastName|firstName"] ?? undefined}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </>
  );
};

export default CliniciansTable;
