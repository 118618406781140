import { useState } from "react";
import PatientsSectionDropdown from "../../components/Dropdowns/PatientsSectionDropdown/PatientsSectionDropdown";
import PageTitleDescription from "../../components/PageTitleDescription/PageTitleDescription";
import PatientsOperationsTable from "../../components/Tables/PatientsOperationsTable/PatientsOperationsTable";
import { PatientsOperationsSection } from "../../enums/Patient";
import { getPatientsOperationsTableSections } from "../../helpers/PatientHelper";

const PatientsOperations = () => {
  const [selectedSections, setSelectedSections] = useState<
    PatientsOperationsSection[]
  >(getPatientsOperationsTableSections());

  return (
    <div className="h-full flex flex-col gap-4 pr-5 xl:pr-0">
      <div className="flex justify-between items-center">
        <PageTitleDescription title="Patients" />

        <PatientsSectionDropdown
          selected={selectedSections}
          onChange={setSelectedSections}
        />
      </div>

      <div className="grow h-0">
        <PatientsOperationsTable sections={selectedSections} />
      </div>
    </div>
  );
};

export default PatientsOperations;
