export enum KeyboardKey {
  Enter = "Enter",
}

export enum LocalStorage {
  Auth = "authClinical",
  AccountAuth = "accountAuth",
  CookiesBannerClosed = "cookiesBannerClosed",
}

export enum RequestHeader {
  ApiToken = "api-token",
}

export enum ResponseHeader {
  TotalCount = "x-pagination-total-count-all",
  ContentDisposition = "content-disposition",
  ContentType = "content-type",
}

export enum BooleanEnum {
  False = 0,
  True = 1,
}

export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export enum PageSize {
  Default = 20,
}

export enum DateRange {
  Separator = "~`",
}

export enum Scale {
  Min = 0.25,
  Step = 0.25,
}

export enum MimeType {
  PDF = "application/pdf",
  CSV = "application/csv",
}

export enum ShortlistType {
  Patient = 6,
}

export enum SnackbarEnum {
  AutohideDuration = 3000, // in milliseconds
}

export enum Color {
  Black = "#000000",
  CloudBurst = "#363D49",
  Crimson = "#DE1D42",
  Endeavour = "#12588F",
  Nero = "#1E1E1E",
  PattensBlue = "#DCEBF6",
  Pelorous = "#1E87C9",
  PrussianBlue = "#083A65",
  TropicalRainForest = "#017F5B",
  VeryLightGrey = "#CCCCCC",
  White = "#FFFFFF",
}

export enum ExportFormat {
  JSON = 1,
  CSV = 2,
}

export enum Permissions {
  AdminAccessSiteAdmin = 1,
  AdminClientsManagement = 2,
  AdminClient = 3,
  AdminClientDepartment = 4,
  AdminClientLocation = 5,
  AdminViewSystemLogs = 6,
  AddEditPatient = 13,
}

export enum ValidationError {
  DefaultEmpty = -1,
  Short = 1,
  AlreadyExists = 2,
  Empty = 3,
  Long = 4,
  InvalidFormat = 5,
}

export enum Datepicker {
  Today = 1,
  LastMonth = 2,
  All = 3,
  Custom = 4,
}

export enum ValidationLoginError {
  Invalid = 1,
  Forbidden = 2,
}

export enum InputFieldLength {
  DefaultMax = 256,
  DiagnosisCodeMax = 10,
  AccountShortNameMax = 5,
}

export enum MenuOption {
  Unselected = -1,
}

export enum DebounceWait {
  Input = 500, // in ms
}

export enum SlideTimeout {
  Default = 400, // in ms
}

export enum AccordionStyle {
  ViewResultsModal = 1,
  AssignSensorModal = 2,
}

export enum Apps {
  InClinic = 1,
  AtHome = 2,
  Remote = 3,
}

export enum NPI {
  InputType = "NPI",
  NumOfDigits = 10,
}

export enum ActiveProductMaskPosition {
  InClinic = 0,
  Remote = 1,
  AtHome = 2,
}
