export enum URLRoutes {
  Empty = "/",
  Login = "/login",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Other = "*",

  AdminDashboard = "/admin",
  BillingDashboard = "/billing",
  Profile = "/profile",

  AccountDashboard = "/dashboard",
  AccountNotAccessedPatients = "/dashboard/not-accessed-patients",
  AccountExpiringOrders = "/dashboard/expiring-orders/:expiringEnumParam",
  AccountExpiringOrderDetails = "/dashboard/expiring-orders/:expiringEnumParam/:orderId",

  OrdersDashboard = "/orders",
  OrderDetails = "/orders/:orderId",

  PatientsDashboard = "/patients",
  PatientView = "/patients/:patientId",
  PatientDashboard = "/patients/:patientId/dashboard",
  PatientProfile = "/patients/:patientId/profile",
  PatientResults = "/patients/:patientId/results",
  PatientTrends = "/patients/:patientId/trends",
  PatientOrders = "/patients/:patientId/orders",
  PatientOrderDetails = "/patients/:patientId/orders/:orderId",
  PatientTestNotes = "/patients/:patientId/notes/:testId",

  CliniciansDashboard = "/clinicians",
  ClinicianView = "/clinicians/:clinicianId",
  ClinicianPreviewDashboard = "/clinicians/:clinicianId/dashboard",
  ClinicianNotAccessedPatients = "/clinicians/:clinicianId/dashboard/not-accessed-patients",
  ClinicianExpiringOrders = "/clinicians/:clinicianId/dashboard/expiring-orders/:expiringEnumParam",
  ClinicianExpiringOrderDetails = "/clinicians/:clinicianId/dashboard/expiring-orders/:expiringEnumParam/:orderId",
  ClinicianPatients = "/clinicians/:clinicianId/patients",
  ClinicianProfile = "/clinicians/:clinicianId/profile",
  ClinicianOrders = "/clinicians/:clinicianId/orders",
  ClinicianOrderDetails = "/clinicians/:clinicianId/orders/:orderId",
  ClinicianPatientView = "/clinicians/:clinicianId/patients/:patientId",
  ClinicianPatientDashboard = "/clinicians/:clinicianId/patients/:patientId/dashboard",
  ClinicianPatientProfile = "/clinicians/:clinicianId/patients/:patientId/profile",
  ClinicianPatientResults = "/clinicians/:clinicianId/patients/:patientId/results",
  ClinicianPatientTrends = "/clinicians/:clinicianId/patients/:patientId/trends",
  ClinicianPatientOrders = "/clinicians/:clinicianId/patients/:patientId/orders",
  ClinicianPatientOrderDetails = "/clinicians/:clinicianId/patients/:patientId/orders/:orderId",
  ClinicianPatientTestNotes = "/clinicians/:clinicianId/patients/:patientId/notes/:testId",

  Accounts = "/accounts",

  OperationsDashboard = "/operations",

  PatientsOperations = "/patients-operations",
  PatientOperationsActivityView = "/patients-operations/:patientId",
  PatientOperationsAllActivity = "/patients-operations/:patientId/all-activity",
  PatientOperationsAttachments = "/patients-operations/:patientId/attachments",

  NewOrders = "/operations/new-orders",
  Sensors = "/operations/sensors",
  SensorsPendingShipment = "/operations/sensors/pending-shipment",
  SensorView = "/operations/sensors/:sensorId",
  SensorDetails = "/operations/sensors/:sensorId/details",
  SensorHistory = "/operations/sensors/:sensorId/history",
}
