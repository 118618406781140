import { useMemo, useState } from "react";
import DebouncedSearchField from "../../components/DebouncedSearchField/DebouncedSearchField";
import { OrderDirection, PageSize } from "../../enums/Common";
import { useSearchParams } from "react-router-dom";
import { ISensorFilters } from "../../interfaces/Sensor";
import SensorStatusDropdown from "../../components/Dropdowns/SensorStatusDropdown/SensorStatusDropdown";
import SensorsTable from "../../components/Tables/SensorsTable/SensorsTable";
import { SensorFilterParams, SensorStatus } from "../../enums/Sensor";
import { INavItem } from "../../interfaces/Common";
import { URLRoutes } from "../../enums/Routing";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

interface ISensorsProps {
  isPendingShipmentPage?: boolean;
}

const Sensors = ({ isPendingShipmentPage }: ISensorsProps) => {
  const [searchParams] = useSearchParams();

  const getInitialStatusFilter = (): SensorStatus | undefined => {
    // on pending shipment page, filter by InWarehouse status
    // dropdown for status change should be hidden
    if (isPendingShipmentPage) {
      return SensorStatus.InWarehouse;
    } else {
      // otherwise look if status exists in query params
      const queryStatusId = searchParams.get(SensorFilterParams.Status);

      return queryStatusId ? Number(queryStatusId) : undefined;
    }
  };

  const [filters, setFilters] = useState<ISensorFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `status_id ${OrderDirection.Desc}`,
    [SensorFilterParams.NameOrPatient]:
      searchParams.get(SensorFilterParams.NameOrPatient) || undefined,
    statusId: getInitialStatusFilter(),
  });

  const formatBreadcrumbStatusLabel = (status: SensorStatus): string => {
    switch (status) {
      case SensorStatus.Unassigned:
        return "Unassigned sensors in warehouse";
      case SensorStatus.InWarehouse:
        return "Assigned sensors in warehouse";
      case SensorStatus.ShippedToCustomer:
        return "Sensors in transit";
      case SensorStatus.AtCustomer:
        return "Sensors at customer";
      case SensorStatus.AssignedInternal:
        return "Sensors that are assigned internally";
      case SensorStatus.Damaged:
        return "Damaged sensors";
      case SensorStatus.Lost:
        return "Lost sensors";
    }
  };

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      { label: "Operations", to: URLRoutes.OperationsDashboard },
      // on all sensors screen, we are showing additional breadcrumb item when status filter is present
      ...(isPendingShipmentPage
        ? [{ label: "Pending Shipment Sensors", to: "#" }]
        : [
            {
              label: "All Sensors",
              to: "#",
              onClick: () =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  statusId: undefined,
                })),
            },
            ...(filters.statusId
              ? [
                  {
                    label: formatBreadcrumbStatusLabel(filters.statusId),
                    to: "",
                  },
                ]
              : []),
          ]),
    ],
    [filters.statusId, isPendingShipmentPage]
  );

  return (
    <div className="h-full flex flex-col gap-4">
      <Breadcrumbs items={breadcrumbsItems} />

      <div className="grow h-0 flex flex-col gap-5">
        <div className="flex flex-col justify-between items-stretch gap-4 pr-5 sm:items-start lg:flex-row lg:items-center xl:pr-0">
          <DebouncedSearchField
            placeholder="Search by Sensor or Patient Name"
            value={filters[SensorFilterParams.NameOrPatient]}
            onChange={(value) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                [SensorFilterParams.NameOrPatient]: value,
              }))
            }
          />

          {!isPendingShipmentPage && (
            <SensorStatusDropdown
              selected={filters.statusId}
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  statusId: value,
                }))
              }
            />
          )}
        </div>

        <div className="grow h-0">
          <SensorsTable filters={filters} setFilters={setFilters} />
        </div>
      </div>
    </div>
  );
};

export default Sensors;
