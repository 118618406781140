export enum API {
  AddOperationsAttachment = "/operations/patient-attachment",
  AddOperationsNote = "/operations/patient-note",
  Address = "/address",
  AddressDetails = "/address/:addressId",
  Account = "/client",
  AccountDirectory = "/client/directory",
  AccountDetails = "/client/:id",
  AccountNameValidation = "/client/validate-name",
  Billing = "/billing/transaction",
  BillingStatusUpdate = "/billing/status",
  Clinician = "/clinician",
  ClinicianDashboard = "/dashboard/clinician",
  ClinicianDashboardAll = "/dashboard/client",
  ClinicianDetails = "/clinician/:clinicianId",
  ClinicianPatients = "/patient/for-clinician",
  DownloadOperationsAttachment = "/operations/patient-attachment-download",
  ForgotPassword = "/user/reset",
  Login = "/user/login",
  Logout = "/user/logout",
  Order = "/order",
  OrderAttachmentUpload = "/order/upload-attachment",
  OrderAttachmentDownload = "/order/download-attachment",
  OrderCancel = "/order/cancel",
  OrderDetails = "/order/:orderId",
  OrderHistory = "/order/history",
  Orders = "/order/athome",
  OrdersUnassigned = "/order/athome-unassigned",
  PatientActiveOrders = "/order/patient-active-orders",
  PatientDetails = "/patient/:patientId",
  Patients = "/patient",
  PatientActivity = "/operations/patient-activity",
  PatientDashboard = "/dashboard/patient",
  PatientDirectory = "/patient/directory",
  PatientOperations = "/operations/patient",
  PatientProgress = "/patient/test-trends",
  PatientWithSensor = "/patient-sensor/assignment",
  RefreshToken = "/user/refresh",
  ReportMultiple = "/report",
  ReportSingle = "/report/single",
  ReportSummary = "/report/summary",
  ReportTrends = "/report/trends",
  ResetPassword = "/user/reset-password",
  SensorAssign = "/sensor/assign",
  SensorDashboard = "/sensor/dashboard",
  SensorHistory = "/sensor/history",
  Sensors = "/sensor",
  SensorStatusUpdate = "/sensor/status-update",
  SensorUnassign = "/sensor/unassign",
  ShippingBuy = "/shipping/buy",
  ShippingRates = "/shipping/rates",
  Shortlist = "/user-shortlist",
  ShortlistRemove = "/user-shortlist/remove",
  TestDetails = "/order-item/:orderItemId",
  TestHistory = "/history/toolkit",
  TestNote = "/order-note",
  Trends = "/history/trends",
  Usage = "/history/usage",
  User = "/user",
  UserDetails = "/user/:userId",
  UserDirecory = "/user/directory",
  UserEmailValidation = "/user/validate-email",
  UserInitialData = "/user/initial-data",
  UserRole = "/user-role",
  UserRoles = "/user-role/roles",
  UserRoleSet = "/user-role/set",
}
