import { LocalStorage } from "enums/Common";
import { IAuth } from "interfaces/Auth";

class LocalStorageService {
  setAuth = (data: IAuth, storageKey?: string) => {
    const key = storageKey ?? LocalStorage.Auth;
    localStorage.setItem(key, JSON.stringify(data));
  };

  getAuth = () => {
    const storageAuth = localStorage.getItem(LocalStorage.Auth);
    return storageAuth ? (JSON.parse(storageAuth) as IAuth) : undefined;
  };

  getAccountAuth = () => {
    const storageAuth = localStorage.getItem(LocalStorage.AccountAuth);
    return storageAuth ? (JSON.parse(storageAuth) as IAuth) : undefined;
  };

  removeAuth = () => {
    localStorage.removeItem(LocalStorage.Auth);
  };

  removeAccountAuth = () => {
    localStorage.removeItem(LocalStorage.AccountAuth);
  };

  setCookiesBannerClosed = () => {
    localStorage.setItem(LocalStorage.CookiesBannerClosed, "true");
  };

  getCookiesBannerClosed = () => {
    return localStorage.getItem(LocalStorage.CookiesBannerClosed);
  };
}

export default new LocalStorageService();
