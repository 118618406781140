import { generatePath } from "react-router-dom";
import axiosInstance from "../config/axios";
import { API } from "../enums/API";
import {} from "../interfaces/Patient";
import { IAddress } from "../interfaces/Address";

class AddressService {
  createAddress = (data: IAddress) => {
    return axiosInstance.post(API.Address, data);
  };

  updateAddress = (addressId: string, data: IAddress) => {
    return axiosInstance.put(
      generatePath(API.AddressDetails, { addressId }),
      data
    );
  };
}

export default new AddressService();
