import { useMemo, useState } from "react";
import { ITrendFilters } from "interfaces/Patient";
import { RollupInterval, TestType } from "enums/Test";
import useSnackbar from "hooks/useSnackbar";
import ToggleInterval from "components/ToggleInterval/ToggleInterval";
import { getTrendsIntervalOptions } from "helpers/TrendsAndUsageHelper";
import GaitTrends from "pages/PatientTrends/GaitTrends/GaitTrends";
import BalanceTrends from "pages/PatientTrends/BalanceTrends/BalanceTrends";
import PatientFiltersInstructions from "components/PatientFiltersInstructions/PatientFiltersInstructions";
import { usePatientViewLayoutContext } from "components/Layouts/PatientViewLayout/PatientViewLayout";
import Button from "components/Buttons/Button/Button";
import CustomReportModal from "components/Modals/CustomReportModal/CustomReportModal";
import { getTestTypesThatSupportTrendsAndSummary } from "helpers/TestHelper";

const PatientTrends = () => {
  const { filters, setFilters } = usePatientViewLayoutContext();

  const { rollup, patientId, createdOn, apps, tests } = filters;

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [isReportsModalOpen, setIsReportsModalOpen] = useState<boolean>(false);

  const testsThatSupportTrends = getTestTypesThatSupportTrendsAndSummary().map(
    ({ value }) => value
  );
  const selectedTests = tests.filter((x) => testsThatSupportTrends.includes(x));

  const isTestSelected = selectedTests.length > 0;
  const isAppSelected = apps.length > 0;

  const trendFilters = useMemo(
    (): ITrendFilters => ({
      patientId,
      rollup,
      createdOn,
      product: filters.apps.length ? filters.apps.join("|") : undefined,
    }),
    [patientId, rollup, createdOn, filters.apps]
  );

  return (
    <div className="h-full flex flex-col gap-5 pr-5 xl:flex-row xl:pr-0">
      <div className="relative grow flex flex-col gap-5">
        <div className="flex flex-col gap-4 md:flex-row md:justify-between">
          <Button
            className="self-start"
            onClick={() => setIsReportsModalOpen(true)}
          >
            Run Custom Report
          </Button>

          <ToggleInterval<RollupInterval>
            active={filters.rollup}
            options={getTrendsIntervalOptions()}
            onChange={(rollup) => setFilters((prev) => ({ ...prev, rollup }))}
          />
        </div>

        {isAppSelected && isTestSelected ? (
          <div className="grow h-0 pb-5 space-y-5 overflow-auto">
            {selectedTests.includes(TestType.Gait) && (
              <GaitTrends filters={trendFilters} onError={setSnackbarError} />
            )}
            {selectedTests.includes(TestType.Balance) && (
              <BalanceTrends
                filters={trendFilters}
                onError={setSnackbarError}
              />
            )}
          </div>
        ) : (
          <PatientFiltersInstructions
            isAppSelected={isAppSelected}
            isTestSelected={isTestSelected}
          />
        )}
      </div>

      {isReportsModalOpen && (
        <CustomReportModal
          initialFilters={{
            apps,
            tests,
            createdOn,
          }}
          onError={setSnackbarError}
          onClose={() => setIsReportsModalOpen(false)}
        />
      )}

      {snackbarComponent}
    </div>
  );
};

export default PatientTrends;
