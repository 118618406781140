import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Color, ResponseHeader } from "enums/Common";
import PatientOperationsService from "services/PatientOperationsService";
import { IAttachmentActivity } from "interfaces/PatientOperations";
import { downloadFile } from "helpers/CommonHelper";

interface DownloadOperationsAttachmentButtonProps {
  attachment: IAttachmentActivity;
  onSuccess: (message: string) => void;
  onError: () => void;
}

const DownloadOperationsAttachmentButton = (
  props: DownloadOperationsAttachmentButtonProps
) => {
  const { attachment, onSuccess, onError } = props;
  const { id, name } = attachment;

  const [downloading, setDownloading] = useState<boolean>(false);

  const handleDownload = () => {
    setDownloading(true);

    PatientOperationsService.getAttachmentFile({ id })
      .then(({ request, headers }) => {
        const mimeType = headers[ResponseHeader.ContentType];
        const file = new File([request.response], name, { type: mimeType });
        const url = URL.createObjectURL(file);

        downloadFile(url, name);
        onSuccess("Attachment has been downloaded successfully.");
      })
      .catch(() => onError())
      .finally(() => setDownloading(false));
  };

  return (
    <button
      disabled={downloading}
      className="shrink-0 self-end flex items-center gap-2.5 text-tropicalRainForest text-sm leading-4 font-medium disabled:opacity-50"
      onClick={handleDownload}
    >
      Download File
      {downloading ? (
        <CircularProgress
          size={18}
          style={{ color: Color.TropicalRainForest }}
        />
      ) : (
        <span className="icon_svg icon_download_2" />
      )}
    </button>
  );
};

export default DownloadOperationsAttachmentButton;
