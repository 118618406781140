import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DebouncedSearchField from "../../components/DebouncedSearchField/DebouncedSearchField";
import { IOrderFilters } from "../../interfaces/Order";
import { OrderDirection, PageSize } from "../../enums/Common";
import OrdersTable from "../../components/Tables/OrdersTable/OrdersTable";
import ButtonCreateOrder from "../../components/Buttons/ButtonCreateOrder/ButtonCreateOrder";
import { OrderFilterParams } from "../../enums/Order";
import { useClinicianViewLayoutContext } from "../../components/Layouts/ClinicianViewLayout/ClinicianViewLayout";

const ClinicianOrders = () => {
  const { clinicianId } = useParams();
  const [searchParams] = useSearchParams();

  const { clinician } = useClinicianViewLayoutContext();

  const [filters, setFilters] = useState<IOrderFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `order_num ${OrderDirection.Desc}`,
    [OrderFilterParams.OrderOrPatientName]:
      searchParams.get(OrderFilterParams.OrderOrPatientName) || undefined,
    authorizedById: clinicianId,
    id: searchParams.get("id") || undefined,
  });

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-wrap gap-5 items-center justify-center sm:justify-between pr-5 xl:pr-0">
        <DebouncedSearchField
          className="order-2 sm:order-1"
          placeholder="Search by Patient Name or Order ID"
          value={filters[OrderFilterParams.OrderOrPatientName]}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              [OrderFilterParams.OrderOrPatientName]: value,
            }))
          }
        />

        <ButtonCreateOrder
          className="order-1 sm:order-2"
          preselectedClinician={clinician}
          onCompleted={() => setFilters((prev) => ({ ...prev, page: 1 }))}
        />
      </div>

      <div className="grow h-0">
        <OrdersTable
          showPatientColumns
          noItemsMessage="Clinician has no orders."
          filters={filters}
          onPageIncrement={() =>
            setFilters((prev) => ({ ...prev, page: prev.page + 1 }))
          }
          onSortChange={(value) =>
            setFilters((prev) => ({ ...prev, page: 1, _order_by: value }))
          }
          refreshData={() => setFilters((prev) => ({ ...prev, page: 1 }))}
        />
      </div>
    </div>
  );
};

export default ClinicianOrders;
