import Modal from "@mui/material/Modal";
import AccountForm from "components/Account/AccountForm/AccountForm";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { IAccountDirectory } from "interfaces/Account";

interface CreateAccountModalProps {
  initialData?: IAccountDirectory;
  onConfirm: () => void;
  onClose: () => void;
  onSuccess: (message: string) => void;
  onError: () => void;
}

const CreateAccountModal = (props: CreateAccountModalProps) => {
  const { onConfirm, onClose, onSuccess, onError, initialData } = props;

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 max-h-[80%] max-w-[745px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-6 md:px-10 md:py-7">
        <ButtonCloseModal className="top-4" onClick={onClose} />

        <h2 className="self-center text-lg font-bold text-cloudBurst leading-5">
          {initialData ? "Edit Account" : "Create New Account"}
        </h2>
        <AccountForm
          onConfirm={onConfirm}
          accountId={initialData?.id}
          onSuccess={onSuccess}
          onError={onError}
        />
      </div>
    </Modal>
  );
};

export default CreateAccountModal;
