import { useCallback, useState } from "react";
import Button from "../../Buttons/Button/Button";
import useValidationErrors from "../../../hooks/useValidationErrors";
import { IManualLabelsEntryData } from "../../../interfaces/Sensor";
import { Carrier, ShippingMethod } from "../../../enums/Sensor";
import ManualLabelsEntryForm from "../../ManualLabelsEntryForm/ManualLabelsEntryForm";

interface ManualLabelsSectionProps {
  onCancel: () => void;
}

const ManualLabelsSection = ({ onCancel }: ManualLabelsSectionProps) => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const [data, setData] = useState<IManualLabelsEntryData>({
    carrier: Carrier.UPS,
    shippingMethod: ShippingMethod.TwoDays,
  });

  const handleConfirm = useCallback(() => {}, []);

  return (
    <div className="h-full flex flex-col gap-7 overflow-auto">
      <ManualLabelsEntryForm
        data={data}
        onChange={(value) => setData((prev) => ({ ...prev, ...value }))}
        onValidationErrorChange={handleValidationErrorChange}
      />

      <div className="self-center flex gap-5">
        <Button
          className="border-2 border-pelorous !bg-white !text-pelorous"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button disabled={hasErrors} onClick={handleConfirm}>
          Confirm Change
        </Button>
      </div>
    </div>
  );
};

export default ManualLabelsSection;
