import { useCallback, useState } from "react";
import { ISnackbar } from "../interfaces/Common";
import Snackbar from "../components/Snackbar/Snackbar";

const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState<ISnackbar>({
    open: false,
    variant: "success",
    message: "",
  });

  const setSnackbarSuccess = useCallback((message: string) => {
    setSnackbar({ open: true, variant: "success", message });
  }, []);

  const setSnackbarError = useCallback(
    (
      message: string | undefined = "Something went wrong. Please try again."
    ) => {
      setSnackbar({ open: true, variant: "error", message });
    },
    []
  );

  return {
    setSnackbarSuccess,
    setSnackbarError,
    snackbarComponent: (
      <Snackbar
        {...snackbar}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      />
    ),
  };
};

export default useSnackbar;
