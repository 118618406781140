import { ISensorDetails } from "../../interfaces/Sensor";
import CardContainer from "../CardContainer/CardContainer";
import SensorStatusDropdown from "../Dropdowns/SensorStatusDropdown/SensorStatusDropdown";
import SensorAssignmentDropdown from "../Dropdowns/SensorAssignmentDropdown/SensorAssignmentDropdown";
import Property from "../Property/Property";
import styles from "./SensorDetailsCard.module.scss";
import { useMemo } from "react";
import { SensorStatus } from "../../enums/Sensor";
import Button from "../Buttons/Button/Button";

interface SensorDetailsCardProps {
  disabled: boolean;
  initiallyAssignedTo: string | undefined;
  data: ISensorDetails;
  onChange: (value: Partial<ISensorDetails>) => void;
  onOpenTrackingInfoClick: () => void;
  onShipSensorClick: () => void;
}

const SensorDetailsCard = ({
  disabled,
  initiallyAssignedTo,
  data: { name, shippingLabel, statusId, patientId },
  onChange,
  onOpenTrackingInfoClick,
  onShipSensorClick,
}: SensorDetailsCardProps) => {
  const buttonData = useMemo(():
    | { label: string; onClick: () => void }
    | undefined => {
    const isTrackingInfoAvailable =
      [SensorStatus.ShippedToCustomer, SensorStatus.AtCustomer].includes(
        statusId
      ) && Boolean(shippingLabel);

    const isShippingAvailable = Boolean(
      patientId && statusId === SensorStatus.InWarehouse
    );

    switch (true) {
      case isTrackingInfoAvailable:
        return {
          label: "Open Tracking info",
          onClick: onOpenTrackingInfoClick,
        };
      case isShippingAvailable:
        return {
          label: "Ship Sensor",
          onClick: onShipSensorClick,
        };
      default:
        return undefined;
    }
  }, [
    statusId,
    patientId,
    shippingLabel,
    onOpenTrackingInfoClick,
    onShipSensorClick,
  ]);

  return (
    <CardContainer
      className={`relative p-7 md:w-96 ${disabled ? styles.arrow_box : ""}`}
      childrenClassName="flex flex-col gap-5"
      title={name}
    >
      <SensorAssignmentDropdown
        disabled={disabled}
        isAssigned={patientId !== null}
        onChange={(value) => onChange({ patientId: value ? "" : null })}
      />

      {initiallyAssignedTo && (
        <div className="h-16">
          <Property property="Assigned to" value={initiallyAssignedTo} />
        </div>
      )}

      <SensorStatusDropdown
        useInputFieldStyle
        hideClearButton
        label="Status"
        disabled={disabled}
        selected={statusId}
        onChange={(value) => onChange({ statusId: value })}
      />

      {buttonData && (
        <Button
          className="absolute right-6 top-5 border-2 border-pelorous !bg-white !text-pelorous"
          disabled={disabled}
          onClick={buttonData.onClick}
        >
          {buttonData.label}
        </Button>
      )}
    </CardContainer>
  );
};

export default SensorDetailsCard;
