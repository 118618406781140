import { useMemo } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITestResults } from "../../../../../interfaces/Test";
import TestResultsTableContainer from "../../../../Tables/TestResultsTableContainer/TestResultsTableContainer";
import { CognitiveMetric } from "../../../../../enums/Test";

const CognitiveTestResults = ({ orderIteration: { metric } }: ITestResults) => {
  const reactionTableColumns: string[] = useMemo(
    () => ["Average", "Median", "Minimum", "Maximum", "Standard Deviation"],
    []
  );

  return (
    <div className="flex flex-col items-center gap-6">
      <TestResultsTableContainer className="!w-32">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Result (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {Math.round(metric[CognitiveMetric.Result] as number)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TestResultsTableContainer>

      <TestResultsTableContainer className="!w-11/12">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>Reaction (ms)</TableCell>
            </TableRow>
            <TableRow>
              {reactionTableColumns.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{metric[CognitiveMetric.Average] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Median] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Minumum] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Maximum] as number}</TableCell>
              <TableCell>
                {metric[CognitiveMetric.StandardDeviation] as number}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TestResultsTableContainer>
    </div>
  );
};

export default CognitiveTestResults;
