import { useMemo } from "react";
import { Interval } from "../../enums/Test";
import styles from "./IntervalToggleButtons.module.scss";

interface IntervalToggleButtonProps {
  active: Interval;
  onChange: (value: Interval) => void;
}

const IntervalToggleButton = (props: IntervalToggleButtonProps) => {
  const { active, onChange } = props;

  const options = useMemo(
    (): {
      value: Interval;
      label: string;
    }[] => [
      {
        value: Interval.OneMonth,
        label: "30 days",
      },
      {
        value: Interval.TwoMonths,
        label: "60 days",
      },
      {
        value: Interval.ThreeMonths,
        label: "90 days",
      },
      {
        value: Interval.All,
        label: "All",
      },
    ],
    []
  );

  return (
    <div
      className={`py-1.5 mb-1 rounded-md flex justify-between border-solid border border-linkWater bg-white text-cloudBurst ${styles.interval_toggle_controls}`}
    >
      {options.map(({ label, value }) => (
        <span
          key={value}
          className={`cursor-pointer ${
            active === value
              ? "text-pelorous underline"
              : "hover:text-pelorous/50"
          } hover:underline`}
          onClick={() => onChange(value)}
        >
          {label}
        </span>
      ))}
    </div>
  );
};

export default IntervalToggleButton;
