import { Accordion as MUIAccordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./Accordion.module.scss";
import { AccordionStyle } from "../../enums/Common";
import { useMemo } from "react";

interface IAccordion {
  style?: AccordionStyle;
  defaultExpanded?: boolean;
  summary: string;
  children: JSX.Element;
}

const Accordion = ({
  style = AccordionStyle.ViewResultsModal,
  defaultExpanded = true,
  summary,
  children,
}: IAccordion) => {
  const styleClassName = useMemo((): string => {
    switch (style) {
      case AccordionStyle.ViewResultsModal:
        return styles.view_results_modal;
      case AccordionStyle.AssignSensorModal:
        return styles.assign_sensor_modal;
    }
  }, [style]);

  return (
    <div className={`${styles.accordion_wrapper} ${styleClassName}`}>
      <MUIAccordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={
            <span className="icon_svg icon_navigate_arrow -rotate-90" />
          }
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MUIAccordion>
    </div>
  );
};

export default Accordion;
