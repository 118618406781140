import { useMemo } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import CircleIcon from "@mui/icons-material/Circle";
import styles from "./RadioSelection.module.scss";

interface IRadioSelection {
  selected: boolean | undefined;
  onChange?: (value: boolean) => void;
}

const RadioSelection = ({ selected, onChange }: IRadioSelection) => {
  const options = useMemo(
    (): { value: boolean; label: string }[] => [
      { value: false, label: "No" },
      { value: true, label: "Yes" },
    ],
    []
  );

  return (
    <FormControl className={styles.radio_selection}>
      <RadioGroup
        value={selected ?? ""}
        onChange={({ target: { value } }) => onChange?.(value === "true")}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={label}
            disabled={!onChange}
            control={
              <Radio
                icon={<CircleTwoToneIcon />}
                checkedIcon={<CircleIcon />}
              />
            }
            value={value}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioSelection;
