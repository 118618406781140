import { useMemo } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DurationType } from "../../../enums/Order";
import styles from "./TestDurationDropdown.module.scss";
import { TestDurationValue } from "../../../interfaces/Test";
import { getTestDurationOptions } from "../../../helpers/TestHelper";
import { extractDurationAndType } from "../../../helpers/OrderHelper";

interface TestDurationDropdownProps {
  duration: number;
  durationType: DurationType;
  onChange: (value: { duration: number; durationType: DurationType }) => void;
}

const TestDurationDropdown = ({
  duration,
  durationType,
  onChange,
}: TestDurationDropdownProps) => {
  const value = useMemo(
    (): TestDurationValue => `${duration}_${durationType}`,
    [duration, durationType]
  );

  return (
    <div className={`flex flex-col ${styles.select_wrapper}`}>
      <label className="self-start text-base text-cloudBurst font-medium">
        Test Duration *
      </label>

      <Select
        MenuProps={{
          classes: { root: styles.select_root },
        }}
        value={value}
        onChange={({ target: { value } }) =>
          onChange(extractDurationAndType(value as TestDurationValue))
        }
      >
        {getTestDurationOptions().map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TestDurationDropdown;
