import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import moment from "moment";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PatientService from "services/PatientService";
import { IPatient, IPatientFilters } from "interfaces/Patient";
import { ITableColumn } from "interfaces/Common";
import Loading from "components/Loading/Loading";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import {
  createUrlParams,
  formatDashDate,
  formatName,
} from "helpers/CommonHelper";
import useSnackbar from "hooks/useSnackbar";
import { URLRoutes } from "enums/Routing";
import useFetchTableData from "hooks/useFetchTableData";
import ClinicianService from "services/ClinicianService";
import MenuOptions from "components/MenuOptions/MenuOptions";
import { IMenuOption } from "interfaces/Test";
import { BooleanEnum, Color } from "enums/Common";
import { OrderStatus } from "enums/Order";
import { handleShortlistPatient } from "helpers/PatientHelper";
import tableStyles from "styles/shared/table.module.scss";

interface PatientsTableProps {
  isClinicianScreen?: boolean;
  hideShortlistBtn?: boolean;
  filters: IPatientFilters;
  setFilters: Dispatch<SetStateAction<IPatientFilters>>;
}

const tableColumns: ITableColumn[] = [
  { label: "Name", sortProperty: "last_name" },
  { label: "ID", sortProperty: "external_id" },
  { label: "Date of Birth", sortProperty: "dob" },
  { label: "Ordering Clinician" },
  { label: "Last Test", sortProperty: "last_test_date" },
  { label: "Order Status" },
  { label: "Status", sortProperty: "is_deleted" },
  { label: "" },
];

const PatientsTable = (props: PatientsTableProps) => {
  const { isClinicianScreen, hideShortlistBtn, filters, setFilters } = props;

  const [, setSearchParams] = useSearchParams();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();
  const { data, setData, loading, tableRef, lastElementRef } =
    useFetchTableData<IPatient, IPatientFilters>({
      filters,
      onPageIncrement,
      fetchAPI: isClinicianScreen
        ? ClinicianService.getClinicianPatients
        : PatientService.getPatients,
      onError: setSnackbarError,
    });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  const noItemsMessage = useMemo((): string => {
    switch (true) {
      case Boolean(filters.name):
        return "No results found for";
      case Boolean(filters.isShortlist):
        return "None of the patients were added to your shortlist.";
      case isClinicianScreen:
        return "Clinician has no patients.";
      default:
        return "No created patients.";
    }
  }, [filters, isClinicianScreen]);

  const handleToggleActivation = useCallback(
    ({ id, isDeleted }: { id: string; isDeleted: boolean }) => {
      const patient: IPatient = data.find((x) => x.id === id)!;

      if (isDeleted) {
        PatientService.updatePatient(id, {
          ...patient,
          isDeleted: false,
        })
          .then(() => {
            setData((prev) =>
              prev.map((item) =>
                item.id === id ? { ...item, isDeleted: false } : item
              )
            );

            setSnackbarSuccess("Patient is successfully activated.");
          })
          .catch(() => {
            setSnackbarError();
          });
      } else {
        PatientService.deletePatient(id)
          .then(() => {
            setData((prev) =>
              prev.map((item) =>
                item.id === id ? { ...item, isDeleted: true } : item
              )
            );

            setSnackbarSuccess("Patient is successfully deactivated.");
          })
          .catch(() => {
            setSnackbarError();
          });
      }
    },
    [setSnackbarSuccess, setSnackbarError, setData, data]
  );

  const getMenuOptions = useCallback(
    ({ id, isDeleted }: { id: string; isDeleted: boolean }): IMenuOption[] => {
      return [
        {
          label: isDeleted ? "Activate" : "Deactivate",
          color: isDeleted ? Color.Pelorous : Color.Crimson,
          onClick: () => handleToggleActivation({ id, isDeleted }),
        },
      ];
    },
    [handleToggleActivation]
  );

  const formatStatusCellContent = ({
    status,
    end,
  }: {
    status: OrderStatus;
    end: string;
  }): ReactNode => {
    const isExpired = moment().isSameOrAfter(end);
    switch (true) {
      case status === OrderStatus.Cancelled:
        return "Canceled";
      case isExpired:
        return "Expired";
      default:
        return (
          <>
            Expires
            <br />
            {formatDashDate(end)}
          </>
        );
    }
  };

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${
            isClinicianScreen
              ? tableStyles.padding_20
              : tableStyles.padding_16_20
          }`}
          ref={tableRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (
                  {
                    id,
                    lastName,
                    middleName,
                    firstName,
                    dob,
                    lastTestDate,
                    lastTestTs,
                    isShortlist,
                    isDeleted,
                    lastOrder,
                    externalId,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell className="!font-bold">
                      {formatName({ lastName, firstName, middleName })}
                    </TableCell>
                    <TableCell>{externalId}</TableCell>
                    <TableCell>{formatDashDate(dob)}</TableCell>
                    <TableCell>{lastOrder?.clinicianName ?? "-"}</TableCell>
                    <TableCell>
                      {formatDashDate(
                        lastTestDate !== undefined ? lastTestDate : lastTestTs
                      )}
                    </TableCell>
                    <TableCell>
                      {lastOrder ? formatStatusCellContent(lastOrder) : "-"}
                    </TableCell>
                    <TableCell>{isDeleted ? "Inactive" : "Active"}</TableCell>
                    <TableCell
                      className={
                        isClinicianScreen || hideShortlistBtn
                          ? "w-[115px]"
                          : "w-[210px]"
                      }
                    >
                      <div
                        className={`flex items-center gap-7 w-auto ${
                          isClinicianScreen || hideShortlistBtn
                            ? "md:w-32"
                            : "md:w-[170px]"
                        }`}
                      >
                        <Link
                          className="text-pelorous hover:underline"
                          to={
                            isClinicianScreen
                              ? generatePath(
                                  URLRoutes.ClinicianPatientDashboard,
                                  {
                                    clinicianId: filters.clinicianId!,
                                    patientId: id,
                                  }
                                )
                              : generatePath(URLRoutes.PatientDashboard, {
                                  patientId: id,
                                })
                          }
                        >
                          View Patient
                        </Link>

                        {!isClinicianScreen && !hideShortlistBtn && (
                          <button
                            className="flex"
                            onClick={() =>
                              handleShortlistPatient<IPatient>({
                                id,
                                isShortlist,
                                setData,
                                isShorlistFilterApplied:
                                  filters.isShortlist === BooleanEnum.True,
                                onSuccess: setSnackbarSuccess,
                                onError: setSnackbarError,
                              })
                            }
                          >
                            <span
                              className={`icon_svg ${
                                isShortlist ? "icon_star_filled" : "icon_star"
                              }`}
                            />
                          </button>
                        )}

                        {isDeleted !== undefined ? (
                          <MenuOptions
                            options={getMenuOptions({ id, isDeleted })}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={
                filters.name || filters.isShortlist
                  ? "icon_search"
                  : "icon_no_results"
              }
              message={noItemsMessage}
              search={filters.name}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>
      {snackbarComponent}
    </>
  );
};

export default PatientsTable;
