import { Link, LinkProps } from "react-router-dom";

const LinkButton = ({ className, ...rest }: LinkProps) => {
  return (
    <Link
      className={`rounded-3xl box-border h-9 flex items-center border-2 border-pelorous bg-white px-5 text-base leading-4 text-pelorous font-medium ${
        className ?? ""
      }`}
      {...rest}
    />
  );
};

export default LinkButton;
