import OrderDetailsAttachmentCard from "components/OrderDetailsAttachmentCard/OrderDetailsAttachmentCard";
import OrderDetailsOrderCard from "components/OrderDetailsOrderCard/OrderDetailsOrderCard";
import OrderDetailsPatientCard from "components/OrderDetailsPatientCard/OrderDetailsPatientCard";
import OrderDetailsShippingCard from "components/OrderDetailsShippingCard/OrderDetailsShippingCard";
import OrderDetailsSummaryCard from "components/OrderDetailsSummaryCard/OrderDetailsSummaryCard";
import OrderDetailsTestsCard from "components/OrderDetailsTestsCard/OrderDetailsTestsCard";
import { Apps } from "enums/Common";
import useSnackbar from "hooks/useSnackbar";
import { IOrderVersionDetails } from "interfaces/Order";

interface OrderVersionCardsProps {
  order: IOrderVersionDetails;
}

const OrderVersionCards = ({ order }: OrderVersionCardsProps) => {
  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const showShippingCard = order.product === Apps.AtHome;

  return (
    <>
      <div className="h-full overflow-auto flex flex-col gap-7 xl:flex-row">
        <div className="flex flex-col gap-7 xl:w-3/5 xl:max-h-full xl:overflow-auto">
          <OrderDetailsPatientCard order={order} />
          <OrderDetailsOrderCard order={order} />
          <OrderDetailsTestsCard order={order} />
          {showShippingCard && <OrderDetailsShippingCard order={order} />}
          <OrderDetailsAttachmentCard
            order={order}
            onDownloaded={setSnackbarSuccess}
            onDownloadFailed={setSnackbarError}
          />
        </div>

        <div className="xl:grow xl:w-0 xl:max-h-full xl:overflow-auto">
          <OrderDetailsSummaryCard order={order} />
        </div>
      </div>

      {snackbarComponent}
    </>
  );
};

export default OrderVersionCards;
