import { useState } from "react";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import LocalStorageService from "services/LocalStorageService";

// banner will be visible until it's closed
// once closed, we are setting flag inside of local storage and banner won't be popping up anymore (on same browser)
const CookiesBanner = () => {
  const [isOpen, setIsOpen] = useState<boolean>(
    !LocalStorageService.getCookiesBannerClosed()
  );

  const handleClose = (): void => {
    LocalStorageService.setCookiesBannerClosed();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen ? (
        <div className="z-10 absolute left-4 right-4 bottom-4 bg-white p-5 pr-10 rounded-[15px] border border-quartz md:left-24 md:right-24 md:bottom-5 lg:left-28 lg:right-28 lg:bottom-6">
          <ButtonCloseModal className="!top-5 !right-5" onClick={handleClose} />

          <p className="text-lg leading-[22px] text-fiord">
            We use cookies on this site to optimize your experience. By
            continuing to use this site, you consent to the use of these
            necessary cookies.{" "}
            <a
              className="inline-block text-base leading-[22px] text-tropicalRainForest font-bold"
              target="_blank"
              rel="noreferrer"
              href="https://s3.amazonaws.com/clinical-prod-app/support/PrivacyPolicy.pdf"
            >
              Learn more
            </a>
          </p>
        </div>
      ) : null}
    </>
  );
};

export default CookiesBanner;
