import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";
import Button from "../Buttons/Button/Button";
import InputField from "../InputField/InputField";
import { IClinician } from "../../interfaces/Clinician";
import useValidationErrors from "../../hooks/useValidationErrors";
import UserService from "../../services/UserService";

interface IEditClinicianForm {
  initialData: IClinician;
  onConfirm: (value: IClinician) => void;
  onCancel: () => void;
}

const EditClinicianForm = ({
  initialData,
  onConfirm,
  onCancel,
}: IEditClinicianForm) => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const [data, setData] = useState<IClinician>(initialData);

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setData((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const noChanges = useMemo(
    (): boolean => isEqual(data, initialData),
    [data, initialData]
  );

  const disableSave = useMemo(
    (): boolean => hasErrors || noChanges,
    [hasErrors, noChanges]
  );

  const validateEmail = useCallback((value: string) => {
    return UserService.validateEmail({ email: value });
  }, []);

  return (
    <>
      <div className="self-center flex gap-5 md:absolute md:top-5 md:right-5">
        <Button
          className="!bg-white !text-pelorous border-2 border-pelorous"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button disabled={disableSave} onClick={() => onConfirm(data)}>
          Save Changes
        </Button>
      </div>

      <div className="flex flex-col gap-5 md:flex-row [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
        <div className="flex flex-col gap-5">
          <InputField
            required
            label="Last Name"
            placeholder="Enter last name"
            name="lastName"
            value={data.lastName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <InputField
            required
            label="First Name"
            placeholder="Enter first name"
            name="firstName"
            value={data.firstName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <InputField
            label="Middle Name/Initial"
            placeholder="Enter middle name/initial"
            name="middleName"
            value={data.middleName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
        </div>

        <div>
          <InputField
            required
            type="email"
            label="Email"
            placeholder="Enter email address"
            name="email"
            value={data.email}
            initialValue={initialData.email}
            validateInput={validateEmail}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
        </div>
      </div>
    </>
  );
};

export default EditClinicianForm;
