import { useMemo } from "react";
import { Color } from "../../../../enums/Common";
import ButtonCloseModal from "../../../Buttons/ButtonCloseModal/ButtonCloseModal";
import orderSubmittedSvg from "../../../../assets/images/order_submitted.svg";

interface OrderSubmittedCardProps {
  isEdit?: boolean;
  isRenew?: boolean;
  onClose: () => void;
}

const OrderSubmittedCard = ({
  isEdit,
  isRenew,
  onClose,
}: OrderSubmittedCardProps) => {
  const title = useMemo((): string => {
    switch (true) {
      case isRenew:
        return "Your order has been renewed!";
      case isEdit:
        return "Your changes have been submitted!";
      default:
        return "Your order has been submitted!";
    }
  }, [isRenew, isEdit]);

  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 box-border w-4/5 h-4/5 max-w-[620px] max-h-[329px] p-7 bg-white rounded-md shadow-2xl flex flex-col items-center gap-6">
      <ButtonCloseModal
        className="!top-5 left-4"
        color={Color.CloudBurst}
        onClick={onClose}
      />

      <h2 className="w-56 text-cloudBurst text-lg leading-6 font-bold text-center">
        {title}
      </h2>

      <div className="grow h-0">
        <img
          alt="Order submitted"
          className="max-h-full max-w-full"
          src={orderSubmittedSvg}
        />
      </div>
    </div>
  );
};

export default OrderSubmittedCard;
