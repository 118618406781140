import InputField from "components/InputField/InputField";

interface UploadedFileInputProps {
  name: string;
  description?: string;
  uploadProgress?: number;
  onDescriptionChange: (value: string | undefined) => void;
  onRemove: () => void;
}

const UploadedFileInput = (props: UploadedFileInputProps) => {
  const { name, description, uploadProgress, onDescriptionChange, onRemove } =
    props;

  return (
    <div className="flex flex-col gap-2.5 [&>div]:w-full md:[&>div]:!w-[calc(50%-5px)] sm:flex-row">
      <div className="bg-aliceBlue rounded-[9px] p-2.5 text-base leading-5 flex items-center gap-2 relative">
        File name:
        <span className="text-pelorous grow w-0 overflow-hidden text-ellipsis whitespace-nowrap">
          {name}
        </span>
        {uploadProgress === undefined ? (
          <button className="flex" onClick={onRemove}>
            <span className="icon_svg icon_cross" />
          </button>
        ) : (
          <div className="bg-pelorous/20 absolute left-0 right-0 -bottom-[7px] h-1 rounded-[10px] overflow-hidden">
            <div
              className="bg-pelorous absolute left-0 top-0 bottom-0 transition-all"
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
        )}
      </div>

      <InputField
        disabled={uploadProgress !== undefined}
        placeholder="Add file description here"
        value={description}
        onChange={({ target: { value } }) =>
          onDescriptionChange(value.length ? value : undefined)
        }
      />
    </div>
  );
};

export default UploadedFileInput;
