import Modal from "@mui/material/Modal";
import Button from "components/Buttons/Button/Button";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { Color } from "enums/Common";

interface ConfirmationDialogProps {
  title: string;
  content: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { title, content, onConfirm, onClose } = props;

  return (
    <Modal open onClose={onClose}>
      <div className="absolute w-4/5 max-w-[420px] flex flex-col items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-2xl bg-white px-10 pt-4 pb-8">
        <ButtonCloseModal
          className="!top-5 left-4"
          color={Color.CloudBurst}
          onClick={onClose}
        />

        <h1 className="text-cloudBurst text-lg leading-5 font-bold">{title}</h1>

        <p className="my-7 text-center">{content}</p>

        <div className="flex gap-5">
          <Button className="w-28 justify-center" onClick={onConfirm}>
            Confirm
          </Button>

          <Button className="w-28 justify-center !bg-crimson" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
