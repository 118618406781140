import { useEffect, useState } from "react";
import axios from "axios";
import { IGaitTrendsData, ITrendFilters } from "../../../interfaces/Patient";
import TestService from "../../../services/TestService";
import TrendsChart from "../../../components/TrendsChart/TrendsChart";
import Loading from "../../../components/Loading/Loading";
import { formatDashDate } from "../../../helpers/CommonHelper";
import { GaitMetric, TestType } from "../../../enums/Test";
import OverallChangeBox from "../../../components/PatientDashboard/OverallChangeBox/OverallChangeBox";
import { getRollupLabel } from "../../../helpers/TrendsAndUsageHelper";

interface IGaitTrendsProps {
  filters: ITrendFilters;
  onError: () => void;
}

const GaitTrends = ({ filters, onError }: IGaitTrendsProps) => {
  const { rollup } = filters;

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IGaitTrendsData | undefined>(undefined);

  useEffect(() => {
    setLoading(true);

    const abortController = new AbortController();

    TestService.getTrendsData(
      { ...filters, testId: TestType.Gait, metricId: GaitMetric.GaitScore },
      abortController.signal
    )
      .then(({ data }) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;

        setLoading(false);
        onError();
      });

    return () => {
      setData(undefined);
      abortController.abort();
    };
  }, [filters, onError]);

  return (
    <>
      <div className="flex gap-5">
        <div className="grow w-0 border-[3px] border-pattensBlue3 rounded-[20px] overflow-hidden">
          <div className="bg-white flex flex-col justify-between gap-3 xl:items-center sm:flex-row md:h-[44px]">
            <p className="pl-5 pt-3 xl:pt-0">
              Gait Trends:{" "}
              <span className="font-medium">
                Average Score Per{" "}
                <span className="uppercase underline">
                  {getRollupLabel(rollup)}
                </span>
              </span>
            </p>
          </div>
          <div className="bg-solitude relative h-[250px] sm:h-[342px]">
            {loading ? (
              <Loading loading />
            ) : (
              <TrendsChart data={data?.trends ?? null} rollup={rollup} />
            )}
          </div>
        </div>

        {!loading && Boolean(data?.trends?.length) && (
          <OverallChangeBox value={data?.trend ?? null} />
        )}
      </div>

      {Boolean(data?.trends?.length) && (
        <div className="flex flex-col justify-between gap-3 sm:flex-row">
          <p className="text-lg text-cloudBurst/80 font-medium leading-5 flex flex-col lg:flex-row lg:gap-1">
            Testing Date Range:
            <span className="font-bold text-cloudBurst">
              {data?.counts[0].from && data?.counts[0].to
                ? `${formatDashDate(data.counts[0].from)} - ${formatDashDate(
                    data.counts[0].to
                  )}`
                : "-"}
            </span>
          </p>

          <div className="flex flex-col sm:items-end">
            <p className="text-lg text-cloudBurst/80 font-medium underline">
              TESTS RECORDED:
            </p>
            <p className="text-lg text-cloudBurst/80 font-medium">
              Complete:{" "}
              <span className="font-bold text-cloudBurst">
                {data?.counts[0].complete}
              </span>
            </p>
            <p className="text-lg text-cloudBurst/80 font-medium">
              Incomplete:{" "}
              <span className="font-bold text-cloudBurst">
                {data?.counts[0].incomplete}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default GaitTrends;
