import { useCallback, useMemo } from "react";
import OrdersTable from "components/Tables/OrdersTable/OrdersTable";
import { IOrderFilters } from "interfaces/Order";
import { usePatientViewLayoutContext } from "components/Layouts/PatientViewLayout/PatientViewLayout";
import ButtonCreateOrder from "components/Buttons/ButtonCreateOrder/ButtonCreateOrder";

const PatientOrders = () => {
  const { patient, clinician, filters, setFilters } =
    usePatientViewLayoutContext();

  const {
    ordersOrderBy,
    _total_count,
    page,
    page_size,
    clinicianId,
    patientId,
  } = filters;

  const orderFilters = useMemo(
    (): IOrderFilters => ({
      patientId,
      authorizedById: clinicianId,
      _order_by: ordersOrderBy,
      _total_count,
      page,
      page_size,
    }),
    [_total_count, clinicianId, patientId, page, page_size, ordersOrderBy]
  );

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  return (
    <div className="h-full flex flex-col gap-5">
      <ButtonCreateOrder
        className="self-end"
        preselectedPatient={patient}
        preselectedClinician={clinician}
        onCompleted={() => setFilters((prev) => ({ ...prev, page: 1 }))}
      />

      <div className="grow h-0">
        <OrdersTable
          dontReplaceParams
          noItemsMessage="Patient has no orders."
          filters={orderFilters}
          onPageIncrement={onPageIncrement}
          onSortChange={(value) =>
            setFilters((prev) => ({ ...prev, page: 1, ordersOrderBy: value }))
          }
          refreshData={() => setFilters((prev) => ({ ...prev, page: 1 }))}
        />
      </div>
    </div>
  );
};

export default PatientOrders;
