import { DurationType } from "enums/Order";
import {
  AnglesLocation,
  AnglesMetric,
  AnglesMotion,
  DistanceType,
  AnglesRegion,
  Eyes,
  Feet,
  Surface,
  TargetRange,
  TestCritera,
  TestStatus,
  CognitiveTestType,
  CognitiveHand,
  TestType,
  SurfaceCognitive,
  IntervalType,
  InternalDefault,
} from "enums/Test";
import { IDropdownOption } from "interfaces/Common";
import {
  IBaseTest,
  IFormatAnglesParams,
  IOrderSensors,
  Metric,
  TestDurationValue,
} from "interfaces/Test";

export const formatStatus = (status: TestStatus): string => {
  switch (status) {
    case TestStatus.Open:
      return "Open";
    case TestStatus.Complete:
      return "Complete";
    case TestStatus.Incomplete:
      return "Incomplete";
    case TestStatus.Cancelled:
      return "Cancelled";
  }
};

export const formatEyesLabel = (value: Eyes): string => {
  switch (value) {
    case Eyes.Open:
      return "Open";
    case Eyes.Closed:
      return "Closed";
  }
};

export const formatSurfaceLabel = (value: Surface): string => {
  switch (value) {
    case Surface.Stable:
      return "Stable";
    case Surface.Unstable:
      return "Unstable";
  }
};

export const formatFeetLabel = (value: Feet): string => {
  switch (value) {
    case Feet.ParallelApart:
      return "Feet Apart";
    case Feet.ParallelTogether:
      return "Feet Together";
    case Feet.SemiTandemLeft:
      return "Semi-tandem,\nLeft Foot Forward";
    case Feet.SemiTandemRight:
      return "Semi-tandem,\nRight Foot Forward";
    case Feet.TandemLeft:
      return "Tandem,\nLeft Foot Forward";
    case Feet.TandemRight:
      return "Tandem,\nRight Foot Forward";
    case Feet.SingleLeft:
      return "Single Left";
    case Feet.SingleRight:
      return "Single Right";
    default:
      return "-";
  }
};

export const formatAnglesRegionLabel = (value: AnglesRegion): string => {
  return value === AnglesRegion.Shoulder ? "Shoulder" : "-";
};

export const formatLocationLabel = (value: AnglesLocation): string => {
  switch (value) {
    case AnglesLocation.Left:
      return "Left";
    case AnglesLocation.Both:
      return "Left and Right";
    case AnglesLocation.Right:
      return "Right";
    default:
      return "-";
  }
};

export const formatMotionLabel = (value: AnglesMotion): string => {
  switch (value) {
    case AnglesMotion.ForwardFlexion:
      return "Forward Flexion";
    case AnglesMotion.Abduction:
      return "Abduction";
    case AnglesMotion.CrossBodyAdduction:
      return "Cross Body Adduction";
    case AnglesMotion.ExternalRotation:
      return "External Rotation\n(Humerus Adducted)";
    case AnglesMotion.Extension:
      return "Extension";
    case AnglesMotion.InternalRotation:
      return "Internal Rotation\n(Humerus Adducted)";
    case AnglesMotion.InternalRotationAbducted:
      return "Internal Rotation\n(Humerus Abducted)";
    case AnglesMotion.ExternalRotationAbducted:
      return "External Rotation\n(Humerus Abducted)";
    default:
      return "-";
  }
};

export const formatTargetRange = (test: IBaseTest) => {
  const { load, startingPosition, surface, eyes, feet, repeatType, distance } =
    test;
  let trMinValue: number | undefined = undefined;
  let trMaxValue: number | undefined = undefined;

  switch (distance) {
    case TargetRange.Coronal:
      trMinValue = load;
      trMaxValue = startingPosition;
      break;
    case TargetRange.Sagittal:
      trMinValue = surface;
      trMaxValue = eyes;
      break;
    case TargetRange.Transverse:
      trMinValue = repeatType;
      trMaxValue = feet;
      break;
  }

  if (trMaxValue !== undefined && trMinValue !== undefined) {
    if (trMaxValue > -1 && trMinValue > -1 && trMaxValue > trMinValue) {
      return `${trMinValue} - ${trMaxValue}`;
    } else {
      return "N/A";
    }
  }
};

export const formatTargetRangeLabel = (value: TargetRange): string => {
  switch (value) {
    case TargetRange.Coronal:
      return "Coronal";
    case TargetRange.Sagittal:
      return "Sagittal";
    case TargetRange.Transverse:
      return "Transverse";
  }
};

export const formatAnglesAvgLeftRight = ({
  side,
  state,
  metric: testMetric,
}: IFormatAnglesParams) => {
  let metric: Metric | undefined = undefined;
  let startAngle: Metric | undefined = undefined;

  switch (state) {
    case AnglesMotion.Abduction:
    case AnglesMotion.InternalRotation: // CORONAL
      metric = testMetric[AnglesMetric.CoronalAvg] as Metric;
      startAngle = testMetric[AnglesMetric.CoronalStartAngle] as Metric;
      break;
    case AnglesMotion.InternalRotationAbducted:
    case AnglesMotion.ExternalRotationAbducted:
    case AnglesMotion.ForwardFlexion:
    case AnglesMotion.Extension: // SAGITTAL
      metric = testMetric[AnglesMetric.SagittalAvg] as Metric;
      startAngle = testMetric[AnglesMetric.SagittalStartAngle] as Metric;
      break;
    case AnglesMotion.CrossBodyAdduction:
    case AnglesMotion.ExternalRotation: // TRANSVERSE
      metric = testMetric[AnglesMetric.TransverseAvg] as Metric;
      startAngle = testMetric[AnglesMetric.TransverseStartAngle] as Metric;
      break;
  }

  if (metric !== undefined) {
    const firstSensorValue = Math.round(metric[1] as number);
    const secondSensorValue =
      Object.keys(metric).length > 1 ? Math.round(metric[2] as number) : "N/A";

    const startAngleFormatted = startAngle ? (startAngle[1] as number) : null;
    let avgBySide:
      | { avgLeft: number | string; avgRight: number | string }
      | undefined = undefined;

    switch (side) {
      case AnglesLocation.Left:
        avgBySide = { avgLeft: firstSensorValue, avgRight: "N/A" };
        break;
      case AnglesLocation.Right:
        avgBySide = { avgLeft: "N/A", avgRight: firstSensorValue };
        break;
      case AnglesLocation.Both:
        avgBySide = { avgLeft: firstSensorValue, avgRight: secondSensorValue };
        break;
    }

    return {
      ...avgBySide,
      startAngle:
        startAngleFormatted === 9999 || startAngleFormatted === null
          ? "-"
          : Math.round(startAngleFormatted).toString(),
    };
  }
};

export const formatRepetitions = (metrics: Metric) => {
  if (metrics[AnglesMetric.RepetitionCount]) {
    const metric = Object.values(metrics[AnglesMetric.RepetitionCount]);
    return Math.max(...metric);
  } else {
    return "-";
  }
};

export const formatTestCriteriaLabel = (value: TestCritera): string => {
  switch (value) {
    case TestCritera.FromStartingPoint:
      return "From Starting Point";
    case TestCritera.RelativeToGround:
      return "Relative to Ground";
  }
};

export const formatMetricLeftRightLabel = (
  value: number,
  degrees?: boolean,
  rounding?: number
): string => {
  const label = value < 0 ? "(L)" : "(R)";
  return `${Math.abs(value).toFixed(rounding || 1)}${
    degrees ? "°" : ""
  }${label}`;
};

export const formatMetricFrontBackLabel = (
  value: number,
  degrees?: boolean,
  rounding?: number
): string => {
  const label = value < 0 ? "(F)" : "(B)";
  return `${Math.abs(value).toFixed(rounding || 1)}${
    degrees ? "°" : ""
  }${label}`;
};

// Freeform Metric Result values
export const formatFFAMetricValues = (value: number | null) => {
  return value === null ? "N/A" : Math.round(value);
};

export const formatGaitAidLabel = (value: number | null): string => {
  return value === 0 || value === null ? "No" : "Yes";
};

export const formatGaitDistanceLabel = (
  distance: number,
  distanceType: number
): string => {
  let distanceLabel: string = "";

  if (distance) {
    switch (distanceType) {
      case DistanceType.Feet:
        distanceLabel = "F";
        break;
      case DistanceType.Meters:
        distanceLabel = "M";
        break;
    }

    return `${distance} ${distanceLabel}`;
  } else {
    return "N/A";
  }
};

export const formatCognitiveSurfaceLabel = (surface: number): string => {
  switch (surface) {
    case SurfaceCognitive.AorB:
      return "A or B";
    case SurfaceCognitive.AandB:
      return "A and B";
    case SurfaceCognitive.A:
      return "A";
    case SurfaceCognitive.AA:
      return "AA";
    default:
      return "-";
  }
};

export const formatCognitiveTypeLabel = (
  feet: number,
  surface: number
): string => {
  switch (feet) {
    case CognitiveTestType.Simple:
      return "SRT";
    case CognitiveTestType.Complex:
      return `CRT (${formatCognitiveSurfaceLabel(surface)})`;
    case CognitiveTestType.Descision:
      return `DRT (${formatCognitiveSurfaceLabel(surface)})`;
    default:
      return "-";
  }
};

export const formatCognitiveHandLabel = (side: number | null): string => {
  switch (side) {
    case CognitiveHand.Right:
      return "Right";
    case CognitiveHand.Left:
      return "Left";
    case CognitiveHand.Both:
      return "Both";
    default:
      return "-";
  }
};

export const formatIntervalLabel = (
  type: IntervalType,
  duration: number,
  distance: number
) => {
  switch (type) {
    case IntervalType.Equal:
      return `Equal (${duration}s)`;
    case IntervalType.Random:
      const formattedTo = distance ? distance : InternalDefault.ToValue;
      return `Random (${duration}s - ${formattedTo}s)`;
    default:
      return "-";
  }
};

export const formatTestNameByID = (id: TestType): string => {
  switch (id) {
    case TestType.Balance:
      return "Balance";
    case TestType.Gait:
      return "Gait";
    case TestType.Tremor:
      return "Tremor";
    case TestType.Symmetry:
      return "Symmetry";
    case TestType.Angles:
      return "Angles";
    case TestType.Cognitive:
      return "Cognitive";
    case TestType.FreeformAngles:
      return "Freeform Angles";
    default:
      return "-";
  }
};

export const getAllTestTypes = (): IDropdownOption<TestType>[] => {
  return [
    TestType.Gait,
    TestType.Balance,
    TestType.Angles,
    TestType.FreeformAngles,
    TestType.Tremor,
    TestType.Cognitive,
    TestType.Symmetry,
  ].map((value) => ({ value, label: formatTestNameByID(value) }));
};

export const getTestTypesThatSupportTrendsAndSummary =
  (): IDropdownOption<TestType>[] => {
    return [TestType.Gait, TestType.Balance].map((value) => ({
      value,
      label: formatTestNameByID(value),
    }));
  };

export const formatBalanceParams = (feet: Feet, eyes: Eyes): string => {
  return `${formatFeetLabel(feet)}, Eyes ${formatEyesLabel(eyes)}`;
};

export const formatAnglesParams = (
  state: AnglesMotion,
  distanceType: number
): string => {
  return `${formatMotionLabel(state)} - ${distanceType} reps`;
};

export const getAnglesMotionOptions = (): AnglesMotion[] => [
  AnglesMotion.Abduction,
  AnglesMotion.CrossBodyAdduction,
  AnglesMotion.Extension,
  AnglesMotion.ExternalRotationAbducted,
  AnglesMotion.ExternalRotation,
  AnglesMotion.ForwardFlexion,
  AnglesMotion.InternalRotationAbducted,
  AnglesMotion.InternalRotation,
];

export const getBalanceFeetOptions = (): Feet[] => [
  Feet.ParallelApart,
  Feet.ParallelTogether,
  Feet.SemiTandemLeft,
  Feet.SemiTandemRight,
  Feet.TandemLeft,
  Feet.TandemRight,
];

export const roundTestScore = (score: number | undefined) => {
  if (!score) return "N/A";

  return Math.round(score);
};

export const getTestDurationOptions = (): {
  label: string;
  value: TestDurationValue;
}[] => [
  {
    label: "10 seconds",
    value: `10_${DurationType.Seconds}`,
  },
  {
    label: "20 seconds",
    value: `20_${DurationType.Seconds}`,
  },
  {
    label: "30 seconds",
    value: `30_${DurationType.Seconds}`,
  },
  {
    label: "1 minute",
    value: `60_${DurationType.Minutes}`,
  },
  {
    label: "6 minutes",
    value: `360_${DurationType.Minutes}`,
  },
];

export const getSensorPositionName = (
  sensors: IOrderSensors[] | undefined,
  index: number
): string => {
  const sensor = sensors?.[index];

  if (!sensor || !sensor.sensorPositionName) return "-";

  return sensor.sensorPositionName;
};
