import { AxiosResponse } from "axios";
import axios from "config/axios";
import { API } from "enums/API";
import {
  IAttachmentActivity,
  INoteActivity,
  IPatientActivityFilters,
  IPatientOperations,
  IPatientOperationsFilters,
  PatientActivity,
} from "interfaces/PatientOperations";

class PatientOperationsService {
  getPatients = (
    params: Partial<IPatientOperationsFilters>,
    signal?: AbortSignal
  ): Promise<AxiosResponse<IPatientOperations[]>> => {
    return axios.get(API.PatientOperations, { params, signal });
  };

  getActivity = (
    params: IPatientActivityFilters,
    signal?: AbortSignal
  ): Promise<AxiosResponse<PatientActivity[]>> => {
    return axios.get(API.PatientActivity, { params, signal });
  };

  getAttachmentFile = (params: { id: string }) => {
    return axios.get(API.DownloadOperationsAttachment, {
      params,
      responseType: "arraybuffer",
    });
  };

  addNote = (data: {
    patientId: string;
    text: string;
  }): Promise<AxiosResponse<INoteActivity>> => {
    return axios.post(API.AddOperationsNote, data);
  };

  addAttachment = (data: {
    patientId: string;
    file: File;
    description?: string;
  }): Promise<AxiosResponse<IAttachmentActivity>> => {
    const { patientId, file, description } = data;

    const formData = new FormData();

    formData.append("patient_id", patientId);
    formData.append("file", file);
    if (description) {
      formData.append("description", description);
    }

    return axios.post(API.AddOperationsAttachment, formData, {
      transformRequest: [() => formData],
    });
  };
}

export default new PatientOperationsService();
