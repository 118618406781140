export enum Products {
  InClinic = "clinical",
  AtHome = "at_home",
  Remote = "remote",
  Research = "research",
}

export enum Billing {
  Billable = 1,
  NotBillable = 2,
  ClinicalStudy = 3,
}
