import { generatePath } from "react-router-dom";
import { AxiosProgressEvent, AxiosResponse } from "axios";
import axios from "config/axios";
import { API } from "enums/API";
import {
  ICreateOrderData,
  IEditOrderData,
  IOrderDetails,
  IOrderFilters,
  IOrderHistoryFilters,
} from "interfaces/Order";

class OrderService {
  getAll = (params: Partial<IOrderFilters>, signal?: AbortSignal) => {
    return axios.get(API.Orders, { params, signal });
  };

  getNew = (params: IOrderFilters, signal?: AbortSignal) => {
    return axios.get(API.OrdersUnassigned, { params, signal });
  };

  getOne = (
    { orderId, ...params }: { orderId: string; versionId?: number },
    signal?: AbortSignal
  ) => {
    return axios.get(generatePath(API.OrderDetails, { orderId }), {
      params,
      signal,
    });
  };

  getHistory = (params: IOrderHistoryFilters, signal?: AbortSignal) => {
    return axios.get(API.OrderHistory, { params, signal });
  };

  create = (data: ICreateOrderData): Promise<AxiosResponse<IOrderDetails>> => {
    return axios.post(API.Order, data);
  };

  edit = (orderId: string, data: IEditOrderData) => {
    return axios.put(generatePath(API.OrderDetails, { orderId }), data);
  };

  cancel = (data: { orderId: string; comment: string }) => {
    return axios.post(API.OrderCancel, data);
  };

  uploadAttachment = (
    data: {
      orderId: string;
      file: File;
      description?: string;
    },
    onUploadProgress?: (e: AxiosProgressEvent) => void
  ) => {
    const { orderId, file, description } = data;

    const formData = new FormData();

    formData.append("order_id", orderId);
    formData.append("file", file);
    if (description) {
      formData.append("description", description);
    }

    return axios.post(API.OrderAttachmentUpload, formData, {
      transformRequest: [() => formData],
      onUploadProgress,
    });
  };

  downloadAttachment = (params: { orderId: string; attachmentId: string }) => {
    return axios.get(API.OrderAttachmentDownload, {
      params,
      responseType: "arraybuffer",
    });
  };
}

export default new OrderService();
