interface ToggleIntervalProps<T> {
  active: T;
  options: { value: T; label: string }[];
  onChange: (value: T) => void;
}

const ToggleInterval = <T,>({
  active,
  options,
  onChange,
}: ToggleIntervalProps<T>) => {
  return (
    <div className="flex flex-col border border-pelorous rounded-[9px] overflow-hidden p-[1px] bg-white sm:flex-row">
      {options.map(({ label, value }, index) => (
        <button
          key={index}
          className={`relative rounded-md py-2.5 text-lg leading-5 bg-inherit text-cloudBurst transition-colors ease-in-out duration-300 sm:w-24 ${
            value === active
              ? "text-white !bg-pelorous font-semibold shadow-md"
              : ""
          } ${
            value !== active &&
            index !== options.length - 1 &&
            index + 1 !== options.findIndex(({ value }) => value === active)
              ? "after:hidden after:content-[''] after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:bg-cloudBurst/30 after:h-3 after:w-0.5 xl:after:block"
              : ""
          }`}
          disabled={active === value}
          onClick={() => onChange(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default ToggleInterval;
