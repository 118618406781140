import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import Button from "components/Buttons/Button/Button";
import TestTypeDropdown from "components/Dropdowns/TestTypeDropdown/TestTypeDropdown";
import ReportingOptionsDropdown from "components/Dropdowns/ReportingOptionsDropdown/ReportingOptionsDropdown";
import { ReportingOption } from "enums/Report";
import ReportingIntervalDropdown from "components/Dropdowns/ReportingIntervalDropdown/ReportingIntervalDropdown";
import RangeDatePicker from "components/RangeDatePicker/RangeDatePicker";
import { RollupInterval, TestType } from "enums/Test";
import ReportsService from "services/ReportsService";
import { getTimezone } from "helpers/CommonHelper";
import { handleExportResults } from "helpers/ExportResultsHelper";
import ReportsModal from "components/Modals/ReportsModal/ReportsModal";
import AppTypeSelection from "components/AppTypeSelection/AppTypeSelection";
import { Apps } from "enums/Common";
import { getTestTypesThatSupportTrendsAndSummary } from "helpers/TestHelper";

interface CustomReportModalProps {
  initialFilters?: Partial<ICustomReportParams>;
  onError: () => void;
  onClose: () => void;
}

interface ICustomReportParams {
  apps: Apps[];
  tests: TestType[];
  options: ReportingOption[];
  rollup?: RollupInterval;
  createdOn?: string;
}

const CustomReportModal = (props: CustomReportModalProps) => {
  const { initialFilters, onError, onClose } = props;

  const { patientId } = useParams();

  const [params, setParams] = useState<ICustomReportParams>({
    apps: initialFilters?.apps ?? [],
    tests: [TestType.Gait, TestType.Balance], // initially only gait and balance selected
    options: initialFilters?.options ?? [],
    createdOn: initialFilters?.createdOn,
    rollup: initialFilters?.rollup,
  });
  const [isReportsModalOpen, setIsReportsModalOpen] = useState<boolean>(false);

  const testsThatSupportTrendsAndSummary =
    getTestTypesThatSupportTrendsAndSummary().map(({ value }) => value);

  const isTrendlineOrSummarySelected = useMemo(
    (): boolean =>
      params.options.some((option) =>
        [
          ReportingOption.ResultsSummaryReport,
          ReportingOption.TrendlineReport,
        ].includes(option)
      ),
    [params.options]
  );

  // rollup interval is related to trend and summary reports
  const disableIntervalDropdown = useMemo(
    (): boolean => !isTrendlineOrSummarySelected,
    [isTrendlineOrSummarySelected]
  );

  const disableRun = useMemo(
    (): boolean =>
      Boolean(
        !params.apps.length ||
          !params.tests.length ||
          !params.options.length ||
          (isTrendlineOrSummarySelected && !params.rollup)
      ),
    [isTrendlineOrSummarySelected, params]
  );

  const commonParams = useMemo(
    () => ({
      patientId: patientId as string,
      _timezone: getTimezone(),
      createdOn: params.createdOn,
    }),
    [patientId, params.createdOn]
  );

  const product = params.apps.length ? params.apps.join("|") : undefined;

  const generateReportPromises = useCallback((): Promise<AxiosResponse>[] => {
    const { rollup, options, tests } = params;

    const requests: Promise<AxiosResponse>[] = [];

    if (options.includes(ReportingOption.TrendlineReport)) {
      for (const testId of tests) {
        requests.push(
          ReportsService.getTrends({
            ...commonParams,
            testId,
            rollup: rollup!,
            product,
          })
        );
      }
    }

    if (options.includes(ReportingOption.ResultsSummaryReport)) {
      for (const testId of tests) {
        requests.push(
          ReportsService.getSummary({
            ...commonParams,
            testId,
            rollup: rollup!,
            product,
          })
        );
      }
    }

    if (options.includes(ReportingOption.IndividualTestReports)) {
      for (const testId of tests) {
        requests.push(
          ReportsService.getMultiple({
            ...commonParams,
            testId,
            showSubjectAttributes: true,
            product,
          })
        );
      }
    }

    return requests;
  }, [params, commonParams, product]);

  const handleRun = useCallback(() => {
    if (params.options.includes(ReportingOption.ExportResults)) {
      for (const testId of params.tests) {
        handleExportResults({
          params: {
            patientId: patientId as string,
            testId,
            product,
          },
          onError,
        });
      }
    }

    // other options will be handled in Reports Modal, we will pass only requests
    if (
      params.options.includes(ReportingOption.ResultsSummaryReport) ||
      params.options.includes(ReportingOption.TrendlineReport) ||
      params.options.includes(ReportingOption.IndividualTestReports)
    ) {
      setIsReportsModalOpen(true);
    } else {
      // if no other options selected, close modal
      onClose();
    }
  }, [params, onClose, onError, patientId, product]);

  return (
    <Modal open onClose={onClose}>
      <div
        className={`box-border bg-white w-11/12 max-h-[90%] max-w-[620px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col md:px-10 md:py-7 md:h-auto ${
          isReportsModalOpen ? "opacity-0" : ""
        }`}
      >
        <ButtonCloseModal className="top-4" onClick={onClose} />

        <h2 className="self-center text-lg font-bold text-cloudBurst leading-5">
          Custom Report Options
        </h2>

        <div className="mt-5 mb-7 flex flex-col gap-3 overflow-auto md:flex-row md:justify-between">
          <div className="flex flex-col gap-5 md:w-[calc(50%-20px)]">
            <AppTypeSelection
              selected={params.apps}
              onChange={(apps) => setParams((prev) => ({ ...prev, apps }))}
            />

            <TestTypeDropdown
              placeholder="Select"
              selected={params.tests}
              onChange={(value) =>
                setParams((prev) => ({
                  ...prev,
                  tests: value,
                  // currently Tremor is not suported by Trends and Summary reports,
                  // remove those options if Tremor is selected
                  options: value.includes(TestType.Tremor)
                    ? prev.options.filter(
                        (x) =>
                          ![
                            ReportingOption.ResultsSummaryReport,
                            ReportingOption.TrendlineReport,
                          ].includes(x)
                      )
                    : prev.options,
                }))
              }
            />

            <ReportingOptionsDropdown
              hideSummaryAndTrendOptions={params.tests.some(
                (x) => !testsThatSupportTrendsAndSummary.includes(x)
              )}
              disabled={!params.tests.length}
              selected={params.options}
              onChange={(value) =>
                setParams((prev) => ({
                  ...prev,
                  options: value,
                  // reset rollup if trend or summary report not selected
                  rollup: value.some((option) =>
                    [
                      ReportingOption.ResultsSummaryReport,
                      ReportingOption.TrendlineReport,
                    ].includes(option)
                  )
                    ? prev.rollup
                    : undefined,
                }))
              }
            />

            <ReportingIntervalDropdown
              disabled={disableIntervalDropdown}
              selected={params.rollup}
              onChange={(value) =>
                setParams((prev) => ({ ...prev, rollup: value }))
              }
            />
          </div>

          <div className="md:w-[calc(50%-20px)]">
            <RangeDatePicker
              value={params.createdOn}
              onChange={(value) =>
                setParams((prev) => ({ ...prev, createdOn: value }))
              }
            />
          </div>
        </div>

        <Button
          className="self-center"
          disabled={disableRun}
          onClick={handleRun}
        >
          Run Custom Report
        </Button>

        {isReportsModalOpen && (
          <ReportsModal
            reportPromises={generateReportPromises()}
            onError={onError}
            onClose={onClose}
          />
        )}
      </div>
    </Modal>
  );
};

export default CustomReportModal;
