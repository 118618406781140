import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import TooltipBox from "../TooltipBox/TooltipBox";
import styles from "./NotesTooltip.module.scss";

interface NotesTooltipProps {
  content: string;
  className?: string;
}

const NotesTooltip = ({ content, className }: NotesTooltipProps) => {
  return (
    <TooltipBox
      content={content}
      textAlign="right"
      offsetX={-30}
      maxWidth={387}
    >
      <div
        className={`flex items-center gap-2.5 text-sm leading-4 text-cloudBurst font-medium ${
          className ?? ""
        }`}
      >
        Notes
        <ChatBubbleTwoToneIcon className={styles.chat_icon} />
      </div>
    </TooltipBox>
  );
};

export default NotesTooltip;
