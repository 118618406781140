import { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import TestingAccordion from "../TestingAccordion/TestingAccordion";
import TestDurationDropdown from "../../../../Dropdowns/TestDurationDropdown/TestDurationDropdown";
import { ICreateBalanceOrderItem } from "../../../../../interfaces/Order";
import TestFrequencyInputs from "../TestFrequencyInputs/TestFrequencyInputs";
import { Feet, TestType } from "../../../../../enums/Test";
import BalanceParameterInput from "./BalanceParameterInput/BalanceParameterInput";
import {
  BalanceData,
  TestsData,
} from "../../../../../recoil-states/create-order-states";
import { getBalanceFeetOptions } from "../../../../../helpers/TestHelper";
import { getDefaultTestsData } from "../../../../../helpers/OrderHelper";

const BalanceTest = () => {
  const { selected, duration, durationType, tests, frequency, frequencyReps } =
    useRecoilValue(BalanceData);
  const setTestsData = useSetRecoilState(TestsData);

  const feetOptions = useMemo((): Feet[] => getBalanceFeetOptions(), []);

  const eyesNotSelected = useMemo(
    (): boolean => tests.some(({ params: { eyes } }) => !eyes),
    [tests]
  );

  const error = useMemo(
    (): boolean =>
      Boolean(selected && (eyesNotSelected || !frequencyReps || !tests.length)),
    [selected, eyesNotSelected, frequencyReps, tests.length]
  );

  const handleDataChange = useCallback(
    (value: Partial<ICreateBalanceOrderItem>) => {
      setTestsData((prev) => ({
        ...prev,
        [TestType.Balance]: {
          ...prev[TestType.Balance],
          ...value,
          selected: value.selected ?? true, // select test on input change
        },
      }));
    },
    [setTestsData]
  );

  useEffect(() => {
    setTestsData((prev) => ({
      ...prev,
      [TestType.Balance]: { ...prev[TestType.Balance], valid: !error },
    }));
  }, [error, setTestsData]);

  const handleSelectionChange = (value: boolean): void => {
    if (value) {
      handleDataChange({ selected: true });
    } else {
      handleDataChange(getDefaultTestsData()[TestType.Balance]);
    }
  };

  return (
    <TestingAccordion
      error={error}
      testName="Balance"
      selected={selected}
      onSelectionChange={handleSelectionChange}
      children={
        <div className="flex flex-col gap-5">
          <div className="flex flex-wrap items-end gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
            <TestDurationDropdown
              duration={duration}
              durationType={durationType}
              onChange={handleDataChange}
            />
            <TestFrequencyInputs
              isTestSelected={selected}
              frequency={frequency}
              frequencyReps={frequencyReps}
              onChange={handleDataChange}
            />
          </div>

          <div className="flex flex-col">
            <div className="flex justify-between">
              <p
                className={`font-medium ${
                  !tests.length ? "text-crimson" : "text-cloudBurst"
                }`}
              >
                Parameters *
              </p>
              {eyesNotSelected && (
                <p className="text-crimson hidden sm:block">
                  Please select at least one option
                </p>
              )}
            </div>

            <div className="flex flex-col">
              {feetOptions.map((option) => (
                <BalanceParameterInput
                  key={option}
                  feet={option}
                  tests={tests}
                  onChange={(value) => handleDataChange({ tests: value })}
                />
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default BalanceTest;
