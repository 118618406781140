import { useMemo } from "react";
import { IDropdownOption } from "interfaces/Common";
import { ReportingOption } from "enums/Report";
import DropdownMultiselection from "components/Dropdowns/DropdownMultiselection/DropdownMultiselection";

interface ReportingOptionsDropdownProps {
  hideSummaryAndTrendOptions?: boolean;
  disabled?: boolean;
  selected: ReportingOption[];
  onChange: (value: ReportingOption[]) => void;
}

const ReportingOptionsDropdown = (props: ReportingOptionsDropdownProps) => {
  const { hideSummaryAndTrendOptions, ...rest } = props;

  const options = useMemo(
    (): IDropdownOption<ReportingOption>[] => [
      ...(hideSummaryAndTrendOptions
        ? []
        : [
            {
              label: "Results Summary Report",
              value: ReportingOption.ResultsSummaryReport,
            },
            {
              label: "Trendline Report",
              value: ReportingOption.TrendlineReport,
            },
          ]),
      {
        label: "Individual Test Reports",
        value: ReportingOption.IndividualTestReports,
      },
      {
        label: "Export Results (.csv file)",
        value: ReportingOption.ExportResults,
      },
    ],
    [hideSummaryAndTrendOptions]
  );

  return (
    <DropdownMultiselection
      label="Select reporting options"
      options={options}
      {...rest}
    />
  );
};

export default ReportingOptionsDropdown;
