export enum SensorStatus {
  Unassigned = 1,
  InWarehouse = 2,
  ShippedToCustomer = 3,
  AtCustomer = 4,
  AssignedInternal = 10,
  Damaged = 20,
  Lost = 21,
}

export enum SensorHistoryAction {
  Assign = 1,
  Unassign = 2,
  StatusUpdate = 3,
  OutboundShippingLabel = 4,
  ReturnShippingLabel = 5,
}

// from UI we can have 3 different edit actions, depending on changed value
export type EditSensorAction = Exclude<
  SensorHistoryAction,
  | SensorHistoryAction.OutboundShippingLabel
  | SensorHistoryAction.ReturnShippingLabel
>;

export enum Carrier {
  UPS = 1,
  FedEx = 2,
  DHL = 3,
}

export enum ShippingMethod {
  TwoDays = 1,
  ThreeDays = 2,
}

export enum SensorFilterParams {
  Status = "statusId",
  NameOrPatient = "name|patient_fn|patient_ln",
  NameOrMacAddress = "name|id",
}
