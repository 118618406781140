import { IPatientFiltersInstruction } from "interfaces/Patient";

const PatientFiltersInstruction = (props: IPatientFiltersInstruction) => {
  const { imgSvg, text, completed } = props;

  return (
    <div className="flex flex-col items-center gap-2.5">
      <div
        className="w-[79px] h-[137px]"
        style={{ backgroundImage: `url(${imgSvg})` }}
      />

      <p className="text-sm leading-4 text-cloudBurst font-bold uppercase">
        {text}
      </p>

      <div
        className={`w-[46px] h-[46px] flex justify-center items-center bg-pelorous rounded-full transition-opacity opacity-0 ${
          completed ? "opacity-100" : ""
        }`}
      >
        <span className="icon_svg icon_check" />
      </div>
    </div>
  );
};

export default PatientFiltersInstruction;
