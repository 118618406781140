import { Link, useLocation } from "react-router-dom";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import ButtonRunReport from "components/Buttons/ButtonRunReport/ButtonRunReport";
import MenuOptions from "components/MenuOptions/MenuOptions";
import { IMenuOption } from "interfaces/Test";
import styles from "./LastTestTableCell.module.scss";

interface LastTestTableCellProps extends TableCellProps {
  noteCount: number;
  menuOptions: IMenuOption[];
  onRunReportBtnClick: () => void;
  testNotesLink: string;
}

// All test tables share same last cell content (View Report button, MenuOptions and Notes)
const LastTestTableCell = (props: LastTestTableCellProps) => {
  const {
    noteCount,
    menuOptions,
    onRunReportBtnClick,
    testNotesLink,
    className,
    ...rest
  } = props;

  const location = useLocation();

  return (
    <TableCell {...rest} className={`w-[170px] ${className ?? ""}`}>
      <div className="flex flex-col items-end gap-1.5">
        <ButtonRunReport onClick={onRunReportBtnClick} />

        <div className="flex items-center gap-2.5">
          <Link
            className={styles.notes_link}
            to={{ pathname: testNotesLink, search: location.search }}
          >
            Note
            <div className={styles.chat_icon_wrapper}>
              <ChatBubbleTwoToneIcon />
              <span className={styles.count}>{noteCount}</span>
            </div>
          </Link>
          <MenuOptions options={menuOptions} />
        </div>
      </div>
    </TableCell>
  );
};

export default LastTestTableCell;
