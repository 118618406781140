import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FreeformAnglesMetric } from "enums/Test";
import { TestTableProps } from "interfaces/Test";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { formatDate, formatTime } from "helpers/CommonHelper";
import {
  formatFFAMetricValues,
  formatTestCriteriaLabel,
} from "helpers/TestHelper";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./FreeformAnglesTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Details", sortProperty: "created_on" },
  { label: "Activity", sortProperty: "starting_position_custom" },
  { label: "Test Criteria" },
  { label: "X" },
  { label: "Y" },
  { label: "Z" },
  { label: "" },
];

const FreeformAnglesTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    orderBy,
    onSort,
    onRunReportBtnClick,
    getTestNotesLink,
    getMenuOptions,
  } = props;

  return (
    <Table className={styles.freeform_angles_table}>
      <TableHead>
        <TableRow>
          <TableCell>Freeform Angles Tests</TableCell>
          <TableCell colSpan={2} />
          <TableCell colSpan={3} className="!text-center">
            Results (Degrees)
          </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }, index) => (
            <TableCell key={index}>
              {sortProperty ? (
                <TableSortLabel
                  whiteTheme
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map(
          (
            {
              id,
              accession,
              createdOn,
              startingPositionCustom,
              orderItemId,
              metric,
              distance,
              noteCount,
            },
            index
          ) => (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatTime(createdOn)}
                <br />
                <span className={styles.accession_label}>Test ID</span>
                <br />
                {accession}
              </TableCell>
              <TableCell>{startingPositionCustom}</TableCell>
              <TableCell>{formatTestCriteriaLabel(distance)}</TableCell>
              <TableCell colSpan={3}>
                <div className="flex">
                  <span className="w-1/3 text-center pr-5">
                    {formatFFAMetricValues(
                      metric[FreeformAnglesMetric.X] as number
                    )}
                  </span>
                  <span className="w-1/3 text-center">
                    {formatFFAMetricValues(
                      metric[FreeformAnglesMetric.Y] as number
                    )}
                  </span>
                  <span className="w-1/3 text-center pl-5">
                    {formatFFAMetricValues(
                      metric[FreeformAnglesMetric.Z] as number
                    )}
                  </span>
                </div>
              </TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default FreeformAnglesTable;
