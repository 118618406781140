import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../Button/Button";
import CreatePatientModal from "../../Modals/CreatePatientModal/CreatePatientModal";
import { IPatient } from "../../../interfaces/Patient";
import PatientService from "../../../services/PatientService";
import useSnackbar from "../../../hooks/useSnackbar";

interface ButtonCreatePatientProps {
  className?: string;
  onCreated: () => void;
}

const ButtonCreatePatient = ({
  className,
  onCreated,
}: ButtonCreatePatientProps) => {
  const [searchParams] = useSearchParams();

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(
    Boolean(searchParams.get("create"))
  );

  const handleCreatePatient = useCallback(
    (data: IPatient) => {
      PatientService.createPatient(data)
        .then(() => {
          setSnackbarSuccess("Patient has been created successfully.");
          onCreated();
        })
        .catch(() => setSnackbarError());

      setIsModalOpened(false);
    },
    [onCreated, setSnackbarSuccess, setSnackbarError]
  );

  return (
    <>
      <Button className={className} onClick={() => setIsModalOpened(true)}>
        Create New Patient
      </Button>

      {isModalOpened && (
        <CreatePatientModal
          onConfirm={handleCreatePatient}
          onClose={() => setIsModalOpened(false)}
        />
      )}

      {snackbarComponent}
    </>
  );
};

export default ButtonCreatePatient;
