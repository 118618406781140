import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./InputField.module.scss";
import {
  DebounceWait,
  InputFieldLength,
  NPI,
  ValidationError,
} from "../../enums/Common";
import { ValidationErrorChangeEvent } from "../../interfaces/Common";
import TooltipInfoIcon from "../TooltipInfoIcon/TooltipInfoIcon";
import { doesPasswordMeetsRequirements } from "../../helpers/CommonHelper";
import {
  formatPhoneNumber,
  removeNonNumericCharacters,
} from "../../helpers/PatientHelper";

const InputField = ({
  reduceFontSizeOnError,
  isNumericField,
  label,
  required,
  multiline,
  value,
  initialValue,
  className,
  autoComplete,
  type,
  name,
  onChange,
  maxLength = multiline ? undefined : InputFieldLength.DefaultMax,
  hideErrorMessage,
  infoTooltipContent,
  validateInput,
  onValidationErrorChange,
  ...rest
}: TextFieldProps & {
  reduceFontSizeOnError?: boolean;
  isNumericField?: boolean;
  value?: string | null;
  initialValue?: string;
  label?: string;
  required?: boolean;
  numeric?: boolean;
  maxLength?: InputFieldLength;
  hideErrorMessage?: boolean;
  infoTooltipContent?: ReactNode;
  validateInput?: (value: string) => Promise<AxiosResponse>;
  onValidationErrorChange?: (e: ValidationErrorChangeEvent) => void;
  autoComplete?: string;
}) => {
  const id = useId();

  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);
  const [validationInProgress, setValidationInProgress] =
    useState<boolean>(false);

  const formattedValue = useMemo((): string => {
    if (!value) return "";

    return type === "phone" ? formatPhoneNumber(value) : value;
  }, [value, type]);

  const checkForBasicValidationError = useCallback(
    (input: string | null | undefined): ValidationError | undefined => {
      switch (true) {
        case required && (input === null || input === undefined):
          return ValidationError.DefaultEmpty;
        case required && input?.trim() === "":
          return ValidationError.Empty;
        case input && maxLength && input.length > maxLength:
          return ValidationError.Long;
        case type === "email" && input && !isEmail(input!):
        case type === "phone" &&
          input &&
          removeNonNumericCharacters(input!) &&
          removeNonNumericCharacters(input!).length !== 10:
        case type === NPI.InputType &&
          input &&
          input?.length !== NPI.NumOfDigits:
        case type === "password" &&
          input &&
          !doesPasswordMeetsRequirements(input):
          return ValidationError.InvalidFormat;
      }
    },
    [maxLength, required, type]
  );

  const validationError = useMemo(
    (): ValidationError | undefined =>
      alreadyExists
        ? ValidationError.AlreadyExists
        : checkForBasicValidationError(value),
    [alreadyExists, value, checkForBasicValidationError]
  );

  const errorMessage = useMemo((): string | undefined => {
    if (hideErrorMessage) return;

    switch (true) {
      case validationError === ValidationError.AlreadyExists:
        return label ? `${label} already exists` : "Value already exists";
      case validationError === ValidationError.Empty:
        return "This field is required";
      case validationError === ValidationError.Long:
        return "Value is too long";
      case type === "email" &&
        validationError === ValidationError.InvalidFormat:
        return "Invalid email format";
      case type === "password" &&
        validationError === ValidationError.InvalidFormat:
        return "Invalid password format";
      case type === "phone" &&
        validationError === ValidationError.InvalidFormat:
        return "Invalid phone number format";
      case type === NPI.InputType &&
        validationError === ValidationError.InvalidFormat:
        return "Invalid NPI format";
      default:
        return undefined;
    }
  }, [validationError, type, hideErrorMessage, label]);

  useEffect(() => {
    if (!name) return;

    onValidationErrorChange?.({ error: validationError, name });
  }, [validationError, onValidationErrorChange, name]);

  const debouncedValidateInput = useMemo(
    () =>
      validateInput
        ? debounce(
            (value: string) =>
              validateInput(value)
                .then(({ data }) => setAlreadyExists(!data.valid))
                .finally(() => setValidationInProgress(false)),
            DebounceWait.Input
          )
        : undefined,
    [validateInput]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      // throw away pending validation if exists
      debouncedValidateInput?.cancel();
      setAlreadyExists(false);

      // allow only numeric values for type number
      if (
        isNumericField &&
        !isNumeric(value, { no_symbols: true }) &&
        value !== ""
      )
        return;

      // call validate function if provided
      if (
        debouncedValidateInput &&
        initialValue !== value &&
        !checkForBasicValidationError(value)
      ) {
        setValidationInProgress(true);
        debouncedValidateInput(value);
      } else {
        setValidationInProgress(false);
      }

      onChange?.(e);
    },
    [
      onChange,
      isNumericField,
      debouncedValidateInput,
      initialValue,
      checkForBasicValidationError,
    ]
  );

  const reduceFontSize = useMemo(
    (): boolean =>
      Boolean(reduceFontSizeOnError && errorMessage && !hideErrorMessage),
    [reduceFontSizeOnError, errorMessage, hideErrorMessage]
  );

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={`self-start text-cloudBurst ${
            reduceFontSize ? "text-xs" : "text-base"
          } leading-5 font-medium transition-all`}
          htmlFor={id}
        >{`${label}${required ? " *" : ""}`}</label>
      )}
      <TextField
        autoComplete={autoComplete ?? "off"}
        id={id}
        multiline={multiline}
        value={formattedValue}
        onChange={handleChange}
        error={
          ![undefined, ValidationError.DefaultEmpty].includes(validationError)
        }
        helperText={
          <>
            {errorMessage}
            {infoTooltipContent && (
              <TooltipInfoIcon content={infoTooltipContent} />
            )}
          </>
        }
        type={type}
        name={name}
        className={`${styles.input_field} ${className ?? ""}`}
        InputProps={{
          // show spinner if validating is in progress
          endAdornment: validationInProgress ? (
            <CircularProgress size={20} />
          ) : undefined,
        }}
        {...rest}
      />
    </div>
  );
};

export default InputField;
