import { useMemo, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { AxiosResponse } from "axios";
import { ISensorDetails, ISensorUpdateBaseData } from "interfaces/Sensor";
import CardContainer from "components/CardContainer/CardContainer";
import { formatSensorStatusLabel } from "helpers/SensorHelper";
import Button from "components/Buttons/Button/Button";
import InputField from "components/InputField/InputField";
import useValidationErrors from "hooks/useValidationErrors";
import { IPatientWithSensor } from "interfaces/Patient";
import DebouncedAutocomplete from "components/DebouncedAutocomplete/DebouncedAutocomplete";
import { formatDashDate, formatName } from "helpers/CommonHelper";
import PatientService from "services/PatientService";
import TooltipBox from "components/TooltipBox/TooltipBox";
import { URLRoutes } from "enums/Routing";
import {
  EditSensorAction,
  SensorHistoryAction,
  SensorStatus,
} from "enums/Sensor";
import SensorService from "services/SensorService";
import Loading from "components/Loading/Loading";

interface EditSensorCardProps {
  action: EditSensorAction;
  initialData: ISensorDetails;
  editedData: ISensorDetails;
  onCancel: () => void;
  onSuccess: () => void;
  onFail: (message?: string) => void;
}

const EditSensorCard = ({
  action,
  initialData,
  editedData,
  onCancel,
  onSuccess,
  onFail,
}: EditSensorCardProps) => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const [patient, setPatient] = useState<IPatientWithSensor | null>(null);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const confirmDisabled = useMemo(
    // if active action is assign, we have additional patient field
    (): boolean =>
      hasErrors || (action === SensorHistoryAction.Assign && !patient),
    [patient, hasErrors, action]
  );

  const title = useMemo((): string => {
    switch (action) {
      case SensorHistoryAction.Assign:
        return "Assign Sensor";
      case SensorHistoryAction.Unassign:
        return "Unassign Sensor";
      case SensorHistoryAction.StatusUpdate:
        return `Change status to “${formatSensorStatusLabel(
          editedData.statusId
        ).toLowerCase()}”`;
    }
  }, [action, editedData.statusId]);

  const formatUnassignWarningMessage = (
    status: SensorStatus
  ): string | undefined => {
    // show message if sensor is shipped
    return [SensorStatus.ShippedToCustomer, SensorStatus.AtCustomer].includes(
      status
    )
      ? "You are unassigning a shipped sensor!"
      : undefined;
  };

  const formatStatusUpdateWarningMessage = (
    initialStatus: SensorStatus,
    editedStatus: SensorStatus
  ): string | undefined => {
    switch (true) {
      case initialStatus === SensorStatus.InWarehouse &&
        editedStatus === SensorStatus.AtCustomer:
      case initialStatus === SensorStatus.AtCustomer &&
        editedStatus === SensorStatus.InWarehouse:
        return "No shipping info provided.";
      case initialStatus === SensorStatus.ShippedToCustomer &&
        editedStatus === SensorStatus.InWarehouse:
        return "Sensor is still in transit.";
      case initialStatus === SensorStatus.AtCustomer &&
        editedStatus === SensorStatus.ShippedToCustomer:
        return "Sensor is already at customer.";
    }
  };

  const warningMessage = useMemo((): string | undefined => {
    switch (action) {
      case SensorHistoryAction.Unassign:
        return formatUnassignWarningMessage(editedData.statusId);
      case SensorHistoryAction.StatusUpdate:
        return formatStatusUpdateWarningMessage(
          initialData.statusId,
          editedData.statusId
        );
    }
  }, [action, initialData.statusId, editedData.statusId]);

  const getConfirmRequest = (): Promise<AxiosResponse> => {
    const commonData: ISensorUpdateBaseData = {
      sensorId: initialData.id,
      comment: notes,
    };

    switch (action) {
      case SensorHistoryAction.Assign:
        return SensorService.assign({ ...commonData, patientId: patient!.id });
      case SensorHistoryAction.Unassign:
        return SensorService.unassign(commonData);
      case SensorHistoryAction.StatusUpdate:
        return SensorService.updateStatus({
          ...commonData,
          statusId: editedData.statusId,
        });
    }
  };

  const handleConfirm = () => {
    setLoading(true);

    getConfirmRequest()
      // pass selected patient data if assign action was active
      .then(() => onSuccess())
      .catch((e) => onFail(e?.response?.data?.message))
      .finally(() => setLoading(false));
  };

  return (
    <CardContainer
      className="relative p-7 md:w-96"
      childrenClassName="flex flex-col gap-6"
      title={title}
    >
      {action === SensorHistoryAction.Assign && (
        <>
          {!patient ? (
            <DebouncedAutocomplete<IPatientWithSensor>
              label="Patient *"
              placeholder="Search by Patient Name"
              value={patient}
              onChange={setPatient}
              getOptions={(value) =>
                PatientService.getPatientsWithSensor({
                  name: value,
                })
              }
              getOptionLabel={({ lastName, firstName }) =>
                `${lastName}, ${firstName}`
              }
              renderOption={(
                props,
                { id, lastName, firstName, dob, clientName, hasSensorAssigned }
              ) => (
                <li {...props} key={id}>
                  <div className="flex flex-col gap-1">
                    <span className="text-pelorous">
                      {formatName({ lastName, firstName })}
                    </span>
                    <span>{`DOB: ${formatDashDate(dob)}`}</span>
                    <span className="text-sm leading-4">{`Account Name: ${clientName}`}</span>
                    {hasSensorAssigned && (
                      <span className="text-crimson text-sm leading-4">
                        This patient already has an assigned sensor.
                      </span>
                    )}
                  </div>
                </li>
              )}
            />
          ) : (
            <div className="h-[66px] flex flex-col">
              <p className="mb-3 self-start text-base font-medium text-cloudBurst">
                Assigned to
              </p>
              <div className="flex items-center justify-between gap-5">
                <TooltipBox
                  content="View patient profile"
                  offsetX={50}
                  offsetY={5}
                >
                  <Link
                    className="text-pelorous font-bold hover:underline"
                    target="_blank"
                    to={generatePath(URLRoutes.PatientProfile, {
                      patientId: patient.id,
                    })}
                  >{`${patient.lastName}, ${patient.firstName}`}</Link>
                </TooltipBox>

                <TooltipBox content="Remove" offsetX={5} offsetY={-40}>
                  <button className="p-0 flex" onClick={() => setPatient(null)}>
                    <span className="icon_svg icon_cross" />
                  </button>
                </TooltipBox>
              </div>
            </div>
          )}
        </>
      )}

      <InputField
        multiline
        rows={4}
        name="notes"
        label={`Notes${
          action === SensorHistoryAction.Assign ? " (optional)" : ""
        }`}
        placeholder="Enter notes for the status change"
        required={action !== SensorHistoryAction.Assign}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        onValidationErrorChange={handleValidationErrorChange}
        helperText={<span className="text-crimson">{warningMessage}</span>}
      />

      <div className="self-end flex gap-5">
        <Button
          className="border-2 border-pelorous !bg-white !text-pelorous"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={warningMessage ? "!bg-crimson" : ""}
          disabled={confirmDisabled}
          onClick={handleConfirm}
        >
          Confirm Change
        </Button>
      </div>

      <Loading loading={loading} />
    </CardContainer>
  );
};

export default EditSensorCard;
