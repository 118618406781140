import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Outlet,
  generatePath,
  matchPath,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { INavItem } from "../../../interfaces/Common";
import { URLRoutes } from "../../../enums/Routing";
import Navbar from "../../Navbar/Navbar";
import { ISensorDetails } from "../../../interfaces/Sensor";
import SensorService from "../../../services/SensorService";
import useSnackbar from "../../../hooks/useSnackbar";
import Loading from "../../Loading/Loading";

const SensorViewLayout = () => {
  const { sensorId } = useParams();
  const { pathname } = useLocation();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [sensor, setSensor] = useState<ISensorDetails | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      { label: "Operations", to: URLRoutes.OperationsDashboard },
      {
        label: matchPath(URLRoutes.SensorsPendingShipment, pathname)
          ? "Pending Shipment Sensors"
          : "All Sensors",
        to: URLRoutes.Sensors,
      },
      ...(sensor
        ? [
            {
              label: sensor.name,
              to: "",
            },
          ]
        : []),
    ],
    [sensor, pathname]
  );

  const navbarItems = useMemo(
    (): INavItem[] => [
      {
        to: generatePath(URLRoutes.SensorDetails, {
          sensorId: sensorId ?? "",
        }),
        label: "Details",
      },
      {
        to: generatePath(URLRoutes.SensorHistory, {
          sensorId: sensorId ?? "",
        }),
        label: "History",
      },
    ],
    [sensorId]
  );

  const getSensorData = useCallback(() => {
    if (!sensorId) return;

    setLoading(true);

    SensorService.getOne(sensorId)
      .then(({ data }) => setSensor(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  }, [sensorId, setSnackbarError]);

  useEffect(() => {
    if (sensorId) {
      getSensorData();
    } else {
      setSensor(undefined);
    }
  }, [getSensorData, sensorId]);

  return (
    <div className="h-full flex flex-col gap-4">
      <Breadcrumbs items={breadcrumbsItems} />

      {/* Show Navbar only on single sensor pages */}
      {(matchPath(URLRoutes.SensorDetails, pathname) ||
        matchPath(URLRoutes.SensorHistory, pathname)) && (
        <Navbar
          items={navbarItems}
          drawerTitle="Sensor"
          drawerLabel={sensor?.name}
        />
      )}

      <div className="relative grow h-0">
        <Outlet context={{ sensor, refreshSensorData: getSensorData }} />
        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default SensorViewLayout;

export const useSensorViewLayoutContext = () => {
  return useOutletContext<{
    sensor: ISensorDetails | undefined;
    refreshSensorData: () => void;
  }>();
};
