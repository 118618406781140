import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageTitleDescription from "components/PageTitleDescription/PageTitleDescription";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import { IOrderFilters } from "interfaces/Order";
import { OrderDirection, PageSize } from "enums/Common";
import OrdersTable from "components/Tables/OrdersTable/OrdersTable";
import ButtonCreateOrder from "components/Buttons/ButtonCreateOrder/ButtonCreateOrder";
import { OrderFilterParams } from "enums/Order";

const OrdersDashboard = () => {
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<IOrderFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `order_num ${OrderDirection.Desc}`,
    [OrderFilterParams.OrderOrPatientName]:
      searchParams.get(OrderFilterParams.OrderOrPatientName) || undefined,
    id: searchParams.get("id") || undefined,
  });

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-col gap-4 pr-5 xl:pr-0">
        <div className="flex flex-col gap-4 justify-between sm:flex-row">
          <PageTitleDescription
            className="w-full xl:w-fit"
            title="RTM Orders"
            description="Preview or create orders for remote therapeutic monitoring."
          />

          <ButtonCreateOrder
            className="shrink-0 self-center"
            onCompleted={() => setFilters((prev) => ({ ...prev, page: 1 }))}
          />
        </div>

        <DebouncedSearchField
          placeholder="Search by Patient Name or Order ID"
          value={filters[OrderFilterParams.OrderOrPatientName]}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              [OrderFilterParams.OrderOrPatientName]: value,
            }))
          }
        />
      </div>

      <div className="grow h-0">
        <OrdersTable
          showPatientColumns
          filters={filters}
          onPageIncrement={onPageIncrement}
          onSortChange={(value) =>
            setFilters((prev) => ({ ...prev, page: 1, _order_by: value }))
          }
          refreshData={() => setFilters((prev) => ({ ...prev, page: 1 }))}
        />
      </div>
    </div>
  );
};

export default OrdersDashboard;
