import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import OrderComponent from "components/OrderComponent/OrderComponent";
import { IOrderDetails } from "interfaces/Order";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { INavItem } from "interfaces/Common";
import { URLRoutes } from "enums/Routing";
import useSnackbar from "hooks/useSnackbar";
import OrderService from "services/OrderService";

const OrderDetails = () => {
  const { orderId } = useParams();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [order, setOrder] = useState<IOrderDetails | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      ...(order
        ? [
            {
              label: "Orders",
              to: URLRoutes.OrdersDashboard,
            },
            {
              label: `#${order.orderNum}`,
              to: "#",
            },
          ]
        : []),
    ],
    [order]
  );

  const getOrder = useCallback(() => {
    if (!orderId) return;

    setLoading(true);

    OrderService.getOne({ orderId })
      .then(({ data }: { data: IOrderDetails }) => setOrder(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  }, [orderId, setSnackbarError]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return (
    <div className="h-full flex flex-col gap-5">
      <Breadcrumbs items={breadcrumbsItems} />

      <div className="grow h-0">
        <OrderComponent
          order={order}
          loading={loading}
          onSuccessfullyEdited={getOrder}
        />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default OrderDetails;
