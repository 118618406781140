import React, { useMemo } from "react";
import { IDropdownOption } from "interfaces/Common";
import Dropdown from "components/Dropdowns/Dropdown/Dropdown";

interface SensorAssignmentDropdownProps {
  disabled?: boolean;
  isAssigned: boolean;
  onChange: (value: boolean) => void;
}

enum Assignment {
  Unassigned = 1,
  Assigned = 2,
}

const SensorAssignmentDropdown = ({
  disabled,
  isAssigned,
  onChange,
}: SensorAssignmentDropdownProps) => {
  const options = useMemo(
    (): IDropdownOption<Assignment>[] => [
      {
        label: "Unassigned",
        value: Assignment.Unassigned,
      },
      {
        label: "Assigned",
        value: Assignment.Assigned,
      },
    ],
    []
  );

  return (
    <Dropdown
      useInputFieldStyle
      hideClearButton
      label="Assignment"
      disabled={disabled}
      options={options}
      selected={isAssigned ? Assignment.Assigned : Assignment.Unassigned}
      onChange={(value) => onChange(value === Assignment.Assigned)}
    />
  );
};

export default SensorAssignmentDropdown;
