import { useState } from "react";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "../../Buttons/ButtonCloseModal/ButtonCloseModal";
import PatientForm from "../../PatientForm/PatientForm";
import { IPatient } from "../../../interfaces/Patient";
import PatientService from "../../../services/PatientService";
import useSnackbar from "../../../hooks/useSnackbar";
import Loading from "../../Loading/Loading";

interface ICreatePatientModal {
  onConfirm: (data: IPatient) => void;
  onClose: () => void;
}

const CreatePatientModal = ({ onConfirm, onClose }: ICreatePatientModal) => {
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  // prevent patient creation if already exists with same first name, last name and DOB
  const createPatientIfItDoesntExist = (patient: IPatient) => {
    setLoading(true);

    const firstName = patient.firstName.trim();
    const lastName = patient.lastName.trim();
    const { dob } = patient;

    PatientService.getPatients({
      firstName,
      lastName,
      dob: dob!,
    })
      .then(({ data }) => {
        const patientExists = data.some(
          (x) =>
            x.firstName === firstName &&
            x.lastName === lastName &&
            x.dob === dob
        );

        if (patientExists) {
          setSnackbarError("Patient with entered data already exists.");
        } else {
          onConfirm(patient);
        }
      })
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 h-5/6 max-w-[812px] max-h-[677px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2.5 md:px-[72px] md:py-10 outline-none">
        <ButtonCloseModal className="top-4" onClick={onClose} />

        <h2 className="text-center text-lg font-bold text-cloudBurst leading-5 mb-11">
          Create New Patient
        </h2>

        <PatientForm onConfirm={createPatientIfItDoesntExist} />

        <Loading loading={loading} />

        {snackbarComponent}
      </div>
    </Modal>
  );
};

export default CreatePatientModal;
