import { RecoilRoot } from "recoil";
import { pdfjs } from "react-pdf";
import Routing from "./components/Routing/Routing";
import "typeface-cabin";

const App = () => {
  // https://github.com/wojtekmaj/react-pdf#create-react-app
  // must be pulled from external CDN, because app is created with create-react-app
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <RecoilRoot>
      <Routing />
    </RecoilRoot>
  );
};

export default App;
