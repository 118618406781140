import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "./CheckboxInput.module.scss";

interface CheckboxInputProps {
  className?: string;
  isTestParamInput?: boolean;
  disabled?: boolean;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const CheckboxInput = (props: CheckboxInputProps) => {
  const { className, isTestParamInput, disabled, label, value, onChange } =
    props;

  return (
    <FormControlLabel
      className={`${styles.checkbox_input} ${
        isTestParamInput ? styles.test_param_input : ""
      } ${className ?? ""}`}
      onClick={(e) => e.stopPropagation()}
      control={
        <Checkbox
          disableRipple
          disabled={disabled}
          checked={value}
          onChange={({ target: { checked } }) => onChange(checked)}
        />
      }
      label={label}
    />
  );
};

export default CheckboxInput;
