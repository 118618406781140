import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import AddOperationsAttachmentButton from "components/Buttons/AddOperationsAttachmentButton/AddOperationsAttachmentButton";
import useSnackbar from "hooks/useSnackbar";
import PatientOperationsService from "services/PatientOperationsService";
import { OperationsActivity } from "enums/PatientOperations";
import {
  IAttachmentActivity,
  IPatientActivityFilters,
} from "interfaces/PatientOperations";
import Loading from "components/Loading/Loading";
import { OrderDirection, PageSize } from "enums/Common";
import useFetchTableData from "hooks/useFetchTableData";
import PatientActivityItem from "components/PatientActivityItem/PatientActivityItem";

const PatientOperationsAttachments = () => {
  const { patientId } = useParams();

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [filters, setFilters] = useState<IPatientActivityFilters>({
    page_size: PageSize.Default,
    _order_by: `created_on ${OrderDirection.Desc}`,
    page: 1,
    _total_count: 1,
    type: OperationsActivity.Attachment,
    patientId,
  });

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, loading, tableRef, lastElementRef } = useFetchTableData<
    IAttachmentActivity,
    IPatientActivityFilters
  >({
    fetchAPI: PatientOperationsService.getActivity,
    onPageIncrement,
    filters,
    onError: setSnackbarError,
  });

  const handleAttachmentSuccessfullyAdded = () => {
    setSnackbarSuccess("Attachment has been added successfully.");
    setFilters((prev) => ({ ...prev, page: 1 })); // triger data fetch
  };

  return (
    <>
      <div className="h-full flex flex-col pr-5 xl:pr-0">
        <AddOperationsAttachmentButton
          className="self-end"
          onSuccess={handleAttachmentSuccessfullyAdded}
          onError={setSnackbarError}
        />

        <h2 className="mb-2 text-cloudBurst text-base leading-5 font-medium">
          Attachments
        </h2>

        <div className="grow h-0 relative">
          {Boolean(data.length) && (
            <div
              className="max-h-full pb-5 overflow-auto border-t border-linkWater"
              ref={tableRef}
            >
              {data.map((item, index) => (
                <PatientActivityItem
                  key={item.id}
                  ref={index === data.length - 1 ? lastElementRef : null}
                  data={item}
                  onDownloadSuccess={setSnackbarSuccess}
                  onDownloadFailed={setSnackbarError}
                />
              ))}
            </div>
          )}

          {!loading && !data.length && (
            <p className="mt-10 text-center text-lg text-cloudBurst font-bold">
              No added attachments for the selected patient
            </p>
          )}

          <Loading loading={loading} />
        </div>
      </div>

      {snackbarComponent}
    </>
  );
};

export default PatientOperationsAttachments;
