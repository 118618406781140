import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import Loading from "components/Loading/Loading";
import PatientsTable from "components/Tables/PatientsTable/PatientsTable";
import { OrderDirection, PageSize } from "enums/Common";
import { IPatientFilters } from "interfaces/Patient";
import { IClinicianDashboardData } from "interfaces/Clinician";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";

interface NotAccessedPatientsProps {
  layoutContext: () => {
    dashboardData: IClinicianDashboardData | undefined;
    loadingDashboardData: boolean;
  };
}

const NotAccessedPatients = ({ layoutContext }: NotAccessedPatientsProps) => {
  const { dashboardData, loadingDashboardData } = layoutContext();

  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<IPatientFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") ?? `last_name ${OrderDirection.Asc}`,
    name: searchParams.get("name") ?? undefined,
  });

  const ids = useMemo(
    (): string[] => (dashboardData ? dashboardData.notAccessPatients : []),
    [dashboardData]
  );

  const filtersWithIds = useMemo(
    (): IPatientFilters => ({
      ...filters,
      id: ids.join("|"),
    }),
    [ids, filters]
  );

  // wait until dashboard data is fetched
  if (loadingDashboardData) return <Loading loading />;

  return !ids.length ? (
    <NoTableItems className="mt-10" />
  ) : (
    <div className="h-full flex flex-col gap-5">
      <DebouncedSearchField
        className="self-end"
        placeholder="Search by Patient Name"
        value={filters.name}
        onChange={(value) =>
          setFilters((prev) => ({
            ...prev,
            page: 1,
            name: value,
          }))
        }
      />

      <div className="grow h-0">
        <PatientsTable
          hideShortlistBtn
          filters={filtersWithIds}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};

export default NotAccessedPatients;
