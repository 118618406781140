import { useMemo } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { PatientsOperationsSection } from "../../../enums/Patient";
import styles from "./PatientsSectionDropdown.module.scss";
import { getPatientsOperationsTableSections } from "../../../helpers/PatientHelper";

interface PatientsSectionDropdownProps {
  selected: PatientsOperationsSection[];
  onChange: (value: PatientsOperationsSection[]) => void;
}

const PatientsSectionDropdown = (props: PatientsSectionDropdownProps) => {
  const { selected, onChange } = props;

  const options = useMemo(
    (): { value: PatientsOperationsSection; label: string }[] => [
      {
        value: PatientsOperationsSection.Information,
        label: "Patient Information",
      },
      {
        value: PatientsOperationsSection.LabAtHomeOPS,
        label: "Lab @ Home OPS",
      },
      {
        value: PatientsOperationsSection.Support,
        label: "Patient Support",
      },
      {
        value: PatientsOperationsSection.Billing,
        label: "Billing",
      },
    ],
    []
  );

  return (
    <Select
      className={styles.section_select}
      MenuProps={{
        classes: { root: styles.select_menu_root },
      }}
      multiple
      displayEmpty
      value={selected}
      onChange={({ target: { value } }) =>
        Array.isArray(value) &&
        // reset to all sections if user deselects all options
        onChange(value.length ? value : getPatientsOperationsTableSections())
      }
      renderValue={() => <span className="icon_svg icon_table_section" />}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={selected.includes(value)} />
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PatientsSectionDropdown;
