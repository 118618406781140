import { useCallback, useMemo, useState } from "react";
import {
  Navigate,
  generatePath,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { OrderExpiration, OrderFilterParams } from "enums/Order";
import { URLRoutes } from "enums/Routing";
import Loading from "components/Loading/Loading";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import OrdersTable from "components/Tables/OrdersTable/OrdersTable";
import { IOrderFilters } from "interfaces/Order";
import { OrderDirection, PageSize } from "enums/Common";
import { IClinicianDashboardData } from "interfaces/Clinician";

interface ExpiringOrdersProps {
  layoutContext: () => {
    dashboardData: IClinicianDashboardData | undefined;
    loadingDashboardData: boolean;
  };
}

const ExpiringOrders = ({ layoutContext }: ExpiringOrdersProps) => {
  const { expiringEnumParam, clinicianId } = useParams();
  const [searchParams] = useSearchParams();

  const { dashboardData, loadingDashboardData } = layoutContext();

  const expiringEnum = Number(expiringEnumParam);

  const isValidEnum =
    expiringEnum === OrderExpiration.ExpiresToday ||
    expiringEnum === OrderExpiration.ExpiresInOneWeek ||
    expiringEnum === OrderExpiration.ExpiresInTwoWeeks ||
    expiringEnum === OrderExpiration.RecentlyExpired;

  const ids = useMemo(
    (): string[] =>
      dashboardData && isValidEnum
        ? dashboardData?.ordersExpiration[expiringEnum]
        : [],
    [dashboardData, expiringEnum, isValidEnum]
  );

  const [filters, setFilters] = useState<IOrderFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") ?? `order_num ${OrderDirection.Desc}`,
    [OrderFilterParams.OrderOrPatientName]:
      searchParams.get(OrderFilterParams.OrderOrPatientName) ?? undefined,
  });

  const filtersWithIds = useMemo(
    (): IOrderFilters => ({
      ...filters,
      id: ids.join("|"),
    }),
    [filters, ids]
  );

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  // navigate to dashboard if user tries to access with invalid expiration enum parameter
  if (!isValidEnum)
    return (
      <Navigate
        replace
        to={
          clinicianId
            ? generatePath(URLRoutes.ClinicianPreviewDashboard, { clinicianId })
            : URLRoutes.AccountDashboard
        }
      />
    );

  // wait until dashboard data is fetched
  if (loadingDashboardData) return <Loading loading />;

  return !ids.length ? (
    <NoTableItems className="mt-10" />
  ) : (
    <div className="h-full flex flex-col gap-5">
      <DebouncedSearchField
        className="self-end"
        placeholder="Search by Patient Name or Order ID"
        value={filters[OrderFilterParams.OrderOrPatientName]}
        onChange={(value) =>
          setFilters((prev) => ({
            ...prev,
            page: 1,
            [OrderFilterParams.OrderOrPatientName]: value,
          }))
        }
      />

      <div className="grow h-0">
        <OrdersTable
          showPatientColumns
          filters={filtersWithIds}
          onPageIncrement={onPageIncrement}
          onSortChange={(value) =>
            setFilters((prev) => ({ ...prev, page: 1, _order_by: value }))
          }
          refreshData={() => setFilters((prev) => ({ ...prev, page: 1 }))}
        />
      </div>
    </div>
  );
};

export default ExpiringOrders;
