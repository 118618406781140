import Dropdown from "components/Dropdowns/Dropdown/Dropdown";
import { Billing } from "enums/Account";
import { IDropdownOption } from "interfaces/Common";

interface BillingDropdownProps {
  value: Billing | undefined;
  onChange: (value: Billing | undefined) => void;
}

const BillingDropdown = ({ value, onChange }: BillingDropdownProps) => {
  const options: IDropdownOption<Billing>[] = [
    { label: "Billable", value: Billing.Billable },
    { label: "Non-billable", value: Billing.NotBillable },
    { label: "Clinical Study", value: Billing.ClinicalStudy },
  ];

  return (
    <Dropdown
      useInputFieldStyle
      hideClearButton
      label="Billing *"
      placeholder="Select Billing Type"
      options={options}
      selected={value}
      onChange={onChange}
    />
  );
};

export default BillingDropdown;
