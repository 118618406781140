import { useParams } from "react-router-dom";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import Button from "components/Buttons/Button/Button";
import InputField from "components/InputField/InputField";
import PatientOperationsService from "services/PatientOperationsService";

interface AddOperationsNoteModalProps {
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}

const AddOperationsNoteModal = (props: AddOperationsNoteModalProps) => {
  const { onClose, onSuccess, onError } = props;

  const { patientId } = useParams();

  const [note, setNote] = useState<string | undefined>(undefined);

  const handleAddNote = () => {
    if (!patientId || !note) return;

    PatientOperationsService.addNote({ patientId, text: note })
      .then(() => onSuccess())
      .catch(() => onError());

    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 max-h-[80%] max-w-[674px] p-6 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-7 md:px-10 md:py-7">
        <ButtonCloseModal onClick={onClose} />

        <h2 className="self-center text-lg font-bold text-cloudBurst leading-5">
          Add Note
        </h2>

        <InputField
          multiline
          rows={5}
          label="Notes (optional)"
          placeholder="Enter notes"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        <Button
          disabled={!note?.trim()}
          className="self-end"
          onClick={handleAddNote}
        >
          Add Note
        </Button>
      </div>
    </Modal>
  );
};

export default AddOperationsNoteModal;
