export enum TestType {
  Balance = 900001,
  Gait = 900002,
  Symmetry = 900003,
  Angles = 900004,
  Cognitive = 900005,
  Tremor = 900006,
  FreeformAngles = 900013,
}

export enum TestStatus {
  Open = 1,
  Complete = 2,
  Incomplete = 3,
  Cancelled = 4,
}

export enum SymmetryMetric {
  Results = 147,
}

export enum CognitiveMetric {
  Average = 103,
  Median = 104,
  Minumum = 105,
  Maximum = 106,
  StandardDeviation = 107,
  Result = 108,
}

export enum CognitiveTestType {
  Simple = 9,
  Complex = 10,
  Descision = 11,
}

export enum CognitiveHand {
  Right = 1,
  Left = 2,
  Both = 3,
}

export enum Eyes {
  Open = 1,
  Closed = 2,
}

export enum Surface {
  Stable = 1,
  Unstable = 2,
}

export enum SurfaceCognitive {
  AorB = 3,
  AandB = 4,
  A = 5,
  AA = 6,
}

export enum TestCritera {
  FromStartingPoint = 1,
  RelativeToGround = 3,
}

export const enum Feet {
  ParallelApart = 1,
  ParallelTogether = 2,
  SemiTandemLeft = 3,
  SemiTandemRight = 4,
  TandemLeft = 5,
  TandemRight = 6,
  SingleLeft = 7,
  SingleRight = 8,
}

export enum BalanceMetric {
  Composite = 86,
  FrontBack = 84,
  LeftRight = 83,
  Rotation = 85,
  DeviationNeutral = 186,
  AvgDeviationLeftRight = 66,
  AvgDeviationFrontBack = 72,
  AvgDeviationDisplacement = 185,
  Movement = 207,
  BalanceScore = 208,
}

export enum AnglesRegion {
  Shoulder = 1,
}

export enum AnglesLocation {
  Left = 1,
  Both = 2,
  Right = 3,
}

export enum AnglesMotion {
  ForwardFlexion = 1,
  Abduction = 2,
  CrossBodyAdduction = 3,
  ExternalRotation = 4,
  Extension = 5,
  InternalRotation = 6,
  InternalRotationAbducted = 7,
  ExternalRotationAbducted = 8,
}

export enum AnglesMetric {
  SagittalAvg = 157,
  CoronalAvg = 161,
  TransverseAvg = 165,
  SagittalStartAngle = 203,
  CoronalStartAngle = 204,
  TransverseStartAngle = 205,
  RepetitionCount = 154,
}

export enum FreeformAnglesMetric {
  X = 173,
  Y = 177,
  Z = 181,
}

export enum GaitMetric {
  Cadence = 1,
  StepsTotal = 2,
  PelvicDeviationDisplacement = 192,
  CadencePercentage = 193,
  ImpactLeft = 196,
  ImpactRight = 197,
  SupportTimeLeft = 198,
  SupportTimeRight = 199,
  SupportTimeDouble = 200,
  PelvicDeviationLeftRight = 75,
  PelvicDeviationFrontBack = 78,
  PelvicDeviationLeft = 64,
  PelvicDeviationRight = 67,
  PelvicDeviationFront = 70,
  PelvicDeviationBack = 73,
  GaitScore = 195,
  ToeOffLeft = 201,
  ToeOffRight = 202,
}

export enum TargetRange {
  Coronal = 0,
  Sagittal = 1,
  Transverse = 2,
}

export enum DistanceType {
  Inch = 1,
  Feet = 2,
  Cm = 3,
  Meters = 4,
}

export enum IntervalType {
  Equal = 1,
  Random = 5,
}

export enum InternalDefault {
  ToValue = 30,
}

export enum RollupInterval {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export enum Interval {
  OneWeek = 1,
  OneMonth = 2,
  TwoMonths = 3,
  ThreeMonths = 4,
  All = 5,
}
