import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useMemo } from "react";
import TestResultsTableContainer from "../../../../Tables/TestResultsTableContainer/TestResultsTableContainer";
import { ITestResults } from "../../../../../interfaces/Test";
import { SymmetryMetric } from "../../../../../enums/Test";

const SymmetryTestResults = ({
  orderIteration: { metric, sensors },
}: ITestResults) => {
  const tableColumns: string[] = useMemo(
    () => ["Sensor Location", "Result (%)"],
    []
  );

  const sortedSensorsByPosition = useMemo(() => {
    return sensors
      ? sensors.sort((a, b) => a.positionNumber - b.positionNumber)
      : undefined;
  }, [sensors]);

  return (
    <div className="flex flex-col gap-6 md:flex-row">
      {sortedSensorsByPosition?.map(
        ({ id, sensorPositionName, positionNumber }) => (
          <TestResultsTableContainer key={id}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableColumns.map((cell, index) => (
                    <TableCell key={index}>{cell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{sensorPositionName}</TableCell>
                  <TableCell>
                    {Math.round(
                      (
                        metric[SymmetryMetric.Results] as {
                          [key: number]: number;
                        }
                      )[positionNumber]
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TestResultsTableContainer>
        )
      )}
    </div>
  );
};

export default SymmetryTestResults;
