import { useRecoilState, useRecoilValue } from "recoil";
import { FileRejection } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import {
  CardsValidation,
  ModalState,
  UploadedAttachments,
} from "recoil-states/create-order-states";
import CardContainer from "components/CardContainer/CardContainer";
import UploadFile from "components/UploadFile/UploadFile";
import { IUploadedAttachment } from "interfaces/Common";
import UploadedFileInput from "components/UploadedFileInput/UploadedFileInput";
import { IOrderDetails } from "interfaces/Order";
import OrderExistingAttachment from "components/OrderExistingAttachment/OrderExistingAttachment";
import { OrderModalState } from "enums/Order";

interface AttachmentsCardProps {
  initialOrder: IOrderDetails | undefined;
  onError: (message?: string) => void;
  onDownloaded: (message: string) => void;
}

const AttachmentsCard = (props: AttachmentsCardProps) => {
  const { initialOrder, onDownloaded, onError } = props;

  const modalState = useRecoilValue(ModalState);
  const [uploadedAttachments, setUploadedAttachments] =
    useRecoilState(UploadedAttachments);
  const cardsValidation = useRecoilValue(CardsValidation);

  const isCardDisabled =
    Object.values(cardsValidation).some((x) => !x) ||
    modalState === OrderModalState.InProgress;

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    const notValidCount = fileRejections.length;

    if (notValidCount) {
      const errorMessage = `${
        notValidCount > 1 ? `${notValidCount} Files` : "File"
      } discarded. File size cannot exceed 20MB.`;
      onError(errorMessage);
    }

    if (acceptedFiles.length) {
      const newAttachments: IUploadedAttachment[] = acceptedFiles.map(
        (file) => ({
          id: uuidv4(),
          file,
        })
      );

      setUploadedAttachments((prev) => [...prev, ...newAttachments]);
    }
  };

  return (
    <CardContainer
      title="Attachments (optional)"
      childrenClassName="flex flex-col gap-7"
      disabled={isCardDisabled}
    >
      <UploadFile multiple onDrop={handleDrop} />

      {Boolean(uploadedAttachments.length) && (
        <div className="flex flex-col gap-2.5">
          <p className="text-lg leading-5 text-pelorous font-bold">Uploaded</p>
          {uploadedAttachments.map(
            ({ id, file, description, uploadProgress }) => (
              <UploadedFileInput
                key={id}
                name={file.name}
                description={description}
                uploadProgress={uploadProgress}
                onDescriptionChange={(value) =>
                  setUploadedAttachments((prev) =>
                    prev.map((x) =>
                      x.id === id ? { ...x, description: value } : x
                    )
                  )
                }
                onRemove={() =>
                  setUploadedAttachments((prev) =>
                    prev.filter((x) => x.id !== id)
                  )
                }
              />
            )
          )}
        </div>
      )}

      {Boolean(initialOrder?.meta?.attachments?.length) && (
        <div className="flex flex-col gap-2.5">
          <p className="text-lg leading-5 text-pelorous font-bold">Existing</p>
          <div className="flex flex-col gap-5">
            {initialOrder?.meta?.attachments?.map((attachment) => (
              <OrderExistingAttachment
                key={attachment.id}
                orderId={initialOrder?.id}
                attachment={attachment}
                onDownloadFailed={onError}
                onDownloaded={onDownloaded}
              />
            ))}
          </div>
        </div>
      )}
    </CardContainer>
  );
};

export default AttachmentsCard;
