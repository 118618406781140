import { useState } from "react";
import Button from "../Button/Button";
import CreateEditOrderModal from "../../Modals/CreateEditOrderModal/CreateEditOrderModal";
import { IPatient } from "../../../interfaces/Patient";
import { IClinician } from "../../../interfaces/Clinician";

interface ButtonCreateOrderProps {
  className?: string;
  preselectedPatient?: IPatient;
  preselectedClinician?: IClinician;
  onCompleted?: () => void;
}

const ButtonCreateOrder = ({
  preselectedPatient,
  preselectedClinician,
  className,
  onCompleted,
}: ButtonCreateOrderProps) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  return (
    <>
      <Button className={className} onClick={() => setIsModalOpened(true)}>
        Create New Order
      </Button>

      {isModalOpened && (
        <CreateEditOrderModal
          preselectedPatient={preselectedPatient}
          preselectedClinician={preselectedClinician}
          onClose={() => setIsModalOpened(false)}
          onCompleted={onCompleted}
        />
      )}
    </>
  );
};

export default ButtonCreateOrder;
