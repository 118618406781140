import { useParams } from "react-router-dom";
import { useClinicianViewLayoutContext } from "components/Layouts/ClinicianViewLayout/ClinicianViewLayout";
import PatientProgress from "components/PatientProgress/PatientProgress";
import OrderCards from "components/OrderCards/OrderCards";

const ClinicianDashboard = () => {
  const { clinicianId } = useParams();

  const { clinician, dashboardData, loadingDashboardData } =
    useClinicianViewLayoutContext();

  return (
    <div className="flex flex-col gap-5 overflow-auto h-full pb-6">
      <OrderCards
        clinician={clinician}
        data={dashboardData}
        loading={loadingDashboardData}
      />
      <PatientProgress clinicianId={clinicianId} />
    </div>
  );
};

export default ClinicianDashboard;
