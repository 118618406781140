import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { SymmetryMetric } from "enums/Test";
import { IOrderSensors, TestTableProps } from "interfaces/Test";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { formatDate, formatTime } from "helpers/CommonHelper";
import { getSensorPositionName } from "helpers/TestHelper";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./SymmetryTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Details", sortProperty: "created_on" },
  { label: "Activity", sortProperty: "starting_position_custom" },
  { label: "Sensor Location" },
  { label: "Result (%)" },
  { label: "Sensor Location" },
  { label: "Result (%)" },
  { label: "" },
];

const SymmetryTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    orderBy,
    onSort,
    onRunReportBtnClick,
    getTestNotesLink,
    getMenuOptions,
  } = props;

  const getPositionSortedSensorsName = (
    sensors: IOrderSensors[] | undefined,
    index: number
  ) => {
    if (!sensors) return "-";

    const sortesSensors = sensors.sort(
      (a, b) => a.positionNumber - b.positionNumber
    );

    return getSensorPositionName(sortesSensors, index);
  };

  return (
    <Table className={styles.symmetry_table}>
      <TableHead>
        <TableRow>
          <TableCell>Symmetry Tests</TableCell>
          <TableCell colSpan={6} />
        </TableRow>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }, index) => (
            <TableCell key={index}>
              {sortProperty ? (
                <TableSortLabel
                  whiteTheme
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map(
          (
            {
              id,
              accession,
              createdOn,
              startingPositionCustom,
              orderItemId,
              sensors,
              metric,
              noteCount,
            },
            index
          ) => (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatTime(createdOn)}
                <br />
                <span className={styles.accession_label}>Test ID</span>
                <br />
                {accession}
              </TableCell>
              <TableCell>{startingPositionCustom}</TableCell>
              <TableCell>
                <TableCell>
                  {getPositionSortedSensorsName(sensors, 0)}
                </TableCell>
              </TableCell>
              <TableCell>
                {sensors?.[0] &&
                  Math.round(
                    (
                      metric[SymmetryMetric.Results] as {
                        [key: number]: number;
                      }
                    )[sensors[0].positionNumber]
                  )}
              </TableCell>
              <TableCell>{getPositionSortedSensorsName(sensors, 1)}</TableCell>
              <TableCell>
                {sensors?.[1] &&
                  Math.round(
                    (
                      metric[SymmetryMetric.Results] as {
                        [key: number]: number;
                      }
                    )[sensors[1].positionNumber]
                  )}
              </TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default SymmetryTable;
