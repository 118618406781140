import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import OrderService from "services/OrderService";
import { IOrderVersionDetails } from "interfaces/Order";
import axios from "axios";
import Loading from "components/Loading/Loading";
import OrderDetailsSummaryCard from "components/OrderDetailsSummaryCard/OrderDetailsSummaryCard";

interface OrderSummaryModalProps {
  orderId: string;
  onError: () => void;
  onClose: () => void; // should be wrapped in useCallback inside of parent component since it's used in useEffect (to prevent unnecessary calls)
}

const OrderSummaryModal = (props: OrderSummaryModalProps) => {
  const { orderId, onError, onClose } = props;

  const [orderData, setOrderData] = useState<IOrderVersionDetails>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();

    OrderService.getOne({ orderId }, controller.signal)
      .then(({ data }) => {
        setOrderData(data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;

        onClose();
        onError();
      });

    return () => controller.abort();
  }, [orderId, onClose, onError]);

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 min-h-[300px] max-h-[90%] max-w-[471px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:px-10 md:py-7 outline-none flex flex-col grow overflow-auto">
        <ButtonCloseModal onClick={onClose} />
        {orderData && (
          <OrderDetailsSummaryCard
            className="p-0 border-none"
            order={orderData}
          />
        )}

        {loading && (
          <div className="grow relative">
            <Loading loading />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrderSummaryModal;
