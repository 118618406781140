import TableContainer from "@mui/material/TableContainer";
import styles from "./TestResultsTableContainer.module.scss";

interface ITestResultsTableContainer {
  className?: string;
  children: JSX.Element;
}

const TestResultsTableContainer = ({
  className,
  children,
}: ITestResultsTableContainer) => {
  return (
    <TableContainer
      className={`${styles.test_results_table_container} ${
        className ? className : ""
      }`}
    >
      {children}
    </TableContainer>
  );
};

export default TestResultsTableContainer;
