import { useCallback } from "react";
import { TableSortLabel as MUITableSortLabel } from "@mui/material";
import { OrderDirection } from "enums/Common";
import arrowDownIcon from "assets/icons/icon_arrow_down.svg";
import arrowDownIconWhite from "assets/icons/icon_arrow_down_white.svg";
import styles from "./TableSortLabel.module.scss";

interface TableSortLabelProps {
  sortProperty: string;
  label: string;
  orderBy: string;
  onSort: (value: string) => void;
  whiteTheme?: boolean;
}

const TableSortLabel = (props: TableSortLabelProps) => {
  const { label, sortProperty, orderBy, onSort, whiteTheme } = props;

  const activeProperty = orderBy
    .split(",")
    .map((x) => x.split(" ")[0]) // remove directions from orderBy
    .join(",");

  const isActive = sortProperty === activeProperty;

  const handleSort = useCallback(() => {
    const newDirection =
      isActive && orderBy.endsWith(OrderDirection.Desc)
        ? OrderDirection.Asc
        : OrderDirection.Desc;

    // map direction in case we have multiple properties
    const newSort = sortProperty
      .split(",")
      .map((x) => `${x} ${newDirection}`)
      .join(",");

    onSort(newSort);
  }, [onSort, orderBy, isActive, sortProperty]);

  return (
    <div className={whiteTheme ? styles.white_label : ""}>
      <MUITableSortLabel
        IconComponent={(props) => (
          <img
            {...props}
            src={whiteTheme ? arrowDownIconWhite : arrowDownIcon}
            alt="arrow"
          />
        )}
        active={isActive}
        direction={orderBy.endsWith(OrderDirection.Asc) ? "asc" : "desc"}
        onClick={handleSort}
      >
        {label}
      </MUITableSortLabel>
    </div>
  );
};

export default TableSortLabel;
