import { useMemo } from "react";
import moment from "moment";
import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import DashboardCardContainer from "../../DashboardCardContainer/DashboardCardContainer";
import { Color } from "../../../enums/Common";
import { IPatientTestUsage } from "../../../interfaces/Patient";

interface UsageCardProps {
  data?: IPatientTestUsage[];
  onUsageDetailsClick: () => void;
}

const UsageCard = ({ data, onUsageDetailsClick }: UsageCardProps) => {
  const noUsage = useMemo((): boolean => !data?.length, [data]);

  // if there were not active orders in certain day, API will not return it in list
  const chartData = useMemo((): IPatientTestUsage[] => {
    if (!data) return [];

    const tempChartData: IPatientTestUsage[] = [];

    // create dummy data for last 30 days with 0 count
    for (let i = 0; i < 30; i++) {
      const currentDate = moment();
      const date = currentDate.subtract(i, "day").format("YYYY-MM-DD");
      tempChartData.push({ date, count: 0 });
    }

    // replace dummy data with API data for days that are returned fom API
    for (const { date, count } of data) {
      const index = tempChartData.findIndex((x) => x.date === date);

      if (index !== -1) {
        tempChartData[index].count = count;
      }
    }

    // save data in time ascending order
    return tempChartData.reverse();
  }, [data]);

  const chartOptions = useMemo((): EChartsOption => {
    const textStyle = {
      color: Color.Nero,
      fontFamily: "Cabin",
      fontSize: 10,
      lineHeight: 12,
    };

    return {
      grid: { top: 20, left: 20, right: 20, bottom: 23, containLabel: true },
      tooltip: { trigger: "axis" },
      xAxis: {
        data: chartData.map(({ date }) => moment(date).format("MM/DD")),
        type: "category",
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
        name: "TESTS/DAY",
        nameLocation: "middle",
        nameGap: 6,
        nameTextStyle: textStyle,
      },
      yAxis: {
        type: "value",
        axisLabel: textStyle,
        splitLine: {
          lineStyle: { color: Color.VeryLightGrey },
          interval: 0,
        },
      },
      series: [
        {
          data: chartData.map(({ count }) => count),
          type: "bar",
          itemStyle: {
            color: Color.TropicalRainForest,
          },
        },
      ],
    };
  }, [chartData]);

  return (
    <DashboardCardContainer
      childrenClassName="rounded-lg h-full bg-endeavour/10 flex justify-center items-center"
      title="RTM Usage"
      subtitle="Last 30 days"
      {...(data?.length
        ? {
            buttonData: {
              text: "View Usage Details",
              onClick: onUsageDetailsClick,
            },
          }
        : {})}
      children={
        data && (
          <>
            {noUsage ? (
              <p className="mx-5 text-lg text-cloudBurst font-bold text-center">
                The patient has not recorded tests in the last 30 days
              </p>
            ) : (
              <ReactECharts
                style={{ height: "100%", width: "100%" }}
                option={chartOptions}
              />
            )}
          </>
        )
      }
    />
  );
};

export default UsageCard;
