import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { BalanceMetric } from "../../../../../enums/Test";
import {
  formatMetricFrontBackLabel,
  formatMetricLeftRightLabel,
} from "../../../../../helpers/TestHelper";
import { ITestResults } from "../../../../../interfaces/Test";
import TestResultsTableContainer from "../../../../Tables/TestResultsTableContainer/TestResultsTableContainer";

const BalanceTestResults = ({ orderIteration: { metric } }: ITestResults) => {
  const deviationHeaderCells: string[] = useMemo(() => {
    return [
      "Displacement",
      "%Neutral",
      "Left/Right",
      "Front/ Back",
      "Rotation",
    ];
  }, []);

  const recoveryHeaderCells: string[] = useMemo(() => {
    return ["Composite", "Right", "Front", "Rotation"];
  }, []);

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="w-full md:w-fit">
        <TestResultsTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>{"Deviation"}</TableCell>
              </TableRow>
              <TableRow>
                {deviationHeaderCells.map((cell, index) => (
                  <TableCell key={index}>{cell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {(
                    metric[BalanceMetric.AvgDeviationDisplacement] as number
                  ).toFixed(2)}
                </TableCell>
                <TableCell>
                  {(metric[BalanceMetric.DeviationNeutral] as number).toFixed(
                    1
                  )}
                  %
                </TableCell>
                <TableCell>
                  {formatMetricLeftRightLabel(
                    metric[BalanceMetric.AvgDeviationLeftRight] as number,
                    true,
                    2
                  )}
                </TableCell>
                <TableCell>
                  {formatMetricFrontBackLabel(
                    metric[BalanceMetric.AvgDeviationFrontBack] as number,
                    true,
                    2
                  )}
                </TableCell>
                <TableCell>
                  {(metric[BalanceMetric.Rotation] as number).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>
      </div>

      <div className="w-full md:w-fit">
        <TestResultsTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>{"Recovery"}</TableCell>
              </TableRow>
              <TableRow>
                {recoveryHeaderCells.map((cell, index) => (
                  <TableCell key={index}>{cell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {(metric[BalanceMetric.Composite] as number).toFixed(2)}
                </TableCell>
                <TableCell>
                  {(metric[BalanceMetric.LeftRight] as number).toFixed(2)}
                </TableCell>
                <TableCell>
                  {(metric[BalanceMetric.FrontBack] as number).toFixed(2)}
                </TableCell>
                <TableCell>
                  {(metric[BalanceMetric.Rotation] as number).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TestResultsTableContainer>
      </div>
    </div>
  );
};

export default BalanceTestResults;
