import { BillingStatus } from "enums/Billing";

export const getBillingStatusLabel = (value: BillingStatus): string => {
  switch (value) {
    case BillingStatus.Unprocessed:
      return "Unprocessed";
    case BillingStatus.ClaimSubmitted:
      return "Claim Submitted";
    case BillingStatus.ClaimResubmitted:
      return "Claim Resubmitted";
    case BillingStatus.NotBilledNoTests:
      return "Not Billed - No Tests";
    case BillingStatus.NotBilledDemo:
      return "Not Billed - Demo";
    case BillingStatus.NotBilledOther:
      return "Not Billed - Other";
  }
};
