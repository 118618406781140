import { AnglesLocation } from "../../../../../enums/Test";
import {
  formatDate,
  formatTime,
  millisecondsToHMS,
} from "../../../../../helpers/CommonHelper";
import {
  formatAnglesRegionLabel,
  formatLocationLabel,
  formatMotionLabel,
  formatRepetitions,
  formatStatus,
} from "../../../../../helpers/TestHelper";
import { ITestDetails } from "../../../../../interfaces/Test";
import PropertyLabel from "../../../../PropertyLabel/PropertyLabel";

const AnglesTestDetails = (props: ITestDetails) => {
  const { test } = props;
  const {
    status,
    createdByFirstName,
    createdByLastName,
    createdOn,
    orderIterations,
    state,
    side,
    distance,
  } = test;

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel
          property="Region"
          value={formatAnglesRegionLabel(distance)}
        />
        <PropertyLabel
          property="Location"
          value={formatLocationLabel(side as AnglesLocation)}
        />
        <PropertyLabel
          property="Duration"
          value={millisecondsToHMS(orderIterations[0].duration)}
        />
        <PropertyLabel property="Status" value={formatStatus(status)} />
        <PropertyLabel
          property="Run by"
          value={`${createdByLastName}, ${createdByFirstName}`}
        />
      </div>
      <div className="flex flex-col gap-4 w-full md:w-1/2">
        <PropertyLabel property="Motion" value={formatMotionLabel(state)} />
        <PropertyLabel
          property="Repetitions"
          value={formatRepetitions(test.orderIterations[0].metric).toString()}
        />
        <PropertyLabel
          property="Date and time"
          value={formatDate(createdOn) + ", " + formatTime(createdOn)}
        />
        <PropertyLabel
          property="Test ID"
          value={orderIterations[0].accession}
        />
      </div>
    </div>
  );
};

export default AnglesTestDetails;
