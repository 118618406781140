import { forwardRef, useMemo } from "react";
import { Link, generatePath, useParams } from "react-router-dom";
import { URLRoutes } from "../../../enums/Routing";
import { ToggleButtons } from "../../ToggleButtons/ToggleButtons";
import GaitTrendsAndUsage from "../GaitTrendsAndUsage/GaitTrendsAndUsage";
import BalanceTrendsAndUsage from "../BalanceTrendsAndUsage/BalanceTrendsAndUsage";
import { IToggleButton } from "../../../interfaces/Common";
import { usePatientViewLayoutContext } from "../../Layouts/PatientViewLayout/PatientViewLayout";

const PatientTrendsAndUsage = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    filters: { showTrends },
    setFilters,
  } = usePatientViewLayoutContext();

  const { clinicianId, patientId } = useParams();

  const toggleButtons = useMemo(
    (): IToggleButton[] => [
      {
        label: "Trends",
        onClick: () => setFilters((prev) => ({ ...prev, showTrends: true })),
      },
      {
        label: "Usage",
        onClick: () => setFilters((prev) => ({ ...prev, showTrends: false })),
      },
    ],
    [setFilters]
  );

  return (
    <div className="relative bg-white p-5 rounded-lg shadow-lg" ref={ref}>
      <Link
        className="absolute top-5 right-5 underline text-pelorous text-sm leading-4 font-bold"
        to={
          clinicianId
            ? generatePath(URLRoutes.ClinicianPatientTrends, {
                clinicianId,
                patientId: patientId!,
              })
            : generatePath(URLRoutes.PatientTrends, {
                patientId: patientId!,
              })
        }
      >
        View All Trends
      </Link>

      <div className="w-2/3 mb-1.5 rounded-full border border-pelorous sm:mx-auto sm:w-fit">
        <ToggleButtons
          buttons={toggleButtons}
          initialActiveIndex={0}
          active={showTrends ? 0 : 1}
        />
      </div>

      <div className="flex flex-col gap-8">
        <GaitTrendsAndUsage />
        <BalanceTrendsAndUsage />
      </div>
    </div>
  );
});

export default PatientTrendsAndUsage;
