import { useParams } from "react-router-dom";
import { IClinician, IClinicianDashboardData } from "interfaces/Clinician";
import CurrentOrdersCard from "components/ClinicianPreviewDashboard/CurrentOrdersCard/CurrentOrdersCard";
import ExpiringOrdersCard from "components/ClinicianPreviewDashboard/ExpiringOrdersCard/ExpiringOrdersCard";
import PatientRecordsCard from "components/ClinicianPreviewDashboard/PatientRecordsCard/PatientRecordsCard";
import Loading from "components/Loading/Loading";

interface OrderCardsProps {
  clinician?: IClinician;
  data?: IClinicianDashboardData;
  loading: boolean;
}

const OrderCards = ({ clinician, data, loading }: OrderCardsProps) => {
  const { clinicianId } = useParams();

  return (
    <div className="relative shrink-0 flex flex-col gap-5 [&>div]:shrink-0 [&>div]:h-[350px] lg:h-[350px] lg:[&>div]:grow lg:[&>div]:w-0 lg:[&>div]:h-auto lg:flex-row lg:w-full">
      <ExpiringOrdersCard
        data={data?.ordersExpiration}
        clinicianId={clinicianId}
      />
      <PatientRecordsCard
        data={data?.notAccessPatients}
        clinicianId={clinicianId}
      />
      <CurrentOrdersCard data={data?.activeOrders} clinician={clinician} />

      <Loading loading={loading} />
    </div>
  );
};

export default OrderCards;
