import { FormEvent, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import InputField from "components/InputField/InputField";
import Button from "components/Buttons/Button/Button";
import styles from "./ConfirmPasswordModal.module.scss";

interface IConfirmPasswordModal {
  onConfirm: (password?: string) => void;
  onClose: () => void;
  error?: string;
  loading: boolean;
}

const ConfirmPasswordModal = ({
  onConfirm,
  onClose,
  error,
  loading,
}: IConfirmPasswordModal) => {
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!password) {
      setErrorMsg(undefined);
    }
  }, [password]);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();

    if (errorMsg) return;
    onConfirm(password);
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-3/12 max-h-[80%] max-w-[745px] px-6 py-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:px-10 md:py-7">
        <ButtonCloseModal className="top-4" onClick={onClose} />

        <form className="flex flex-col gap-6" onSubmit={handleConfirm}>
          <h2 className="self-center text-lg font-bold text-cloudBurst leading-5">
            Confirm password to continue
          </h2>
          <div>
            <InputField
              required
              label="Password"
              placeholder="Enter your password"
              name="confirm-password"
              type="text"
              value={password}
              disabled={loading}
              className={styles.password_field}
              onChange={({ target: { value } }) => {
                setPassword(value);
                setErrorMsg(undefined);
              }}
            />
            {errorMsg && (
              <p className="self-center text-sm text-crimson">{errorMsg}</p>
            )}
          </div>

          <div className="self-end flex w-full justify-end">
            <Button
              type="submit"
              disabled={Boolean(errorMsg) || loading}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ConfirmPasswordModal;
