import { generatePath } from "react-router-dom";
import axiosInstance from "../config/axios";
import { API } from "../enums/API";
import { RequestHeader } from "../enums/Common";
import { ICreateUser, IUpdateUser, IUserFilters } from "../interfaces/User";

class UserService {
  getUsers = (params: Partial<IUserFilters>, signal?: AbortSignal) => {
    return axiosInstance.get(API.UserDirecory, { params, signal });
  };

  createUser = (params: ICreateUser) => {
    return axiosInstance.post(API.User, params);
  };

  updateUser = (userId: string, params: IUpdateUser) => {
    return axiosInstance.put(generatePath(API.UserDetails, { userId }), params);
  };

  getRoles = () => {
    return axiosInstance.get(API.UserRoles);
  };

  setRoles = (data: { userId: string; roleIds: number[] }) => {
    return axiosInstance.post(API.UserRoleSet, data);
  };

  getUserInitialData = (token: string) => {
    return axiosInstance.get(API.UserInitialData, {
      headers: { [RequestHeader.ApiToken]: token },
    });
  };

  getUserRole = (params: { userId: string }) => {
    return axiosInstance.get(API.UserRole, { params });
  };

  validateEmail = (params: { email: string }) => {
    return axiosInstance.get(API.UserEmailValidation, { params });
  };
}

export default new UserService();
