import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Outlet,
  generatePath,
  matchPath,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { INavItem } from "interfaces/Common";
import { URLRoutes } from "enums/Routing";
import ClinicianService from "services/ClinicianService";
import { IClinicianDashboardData } from "interfaces/Clinician";
import axios from "axios";
import useSnackbar from "hooks/useSnackbar";
import { IOrderDetails } from "interfaces/Order";
import OrderService from "services/OrderService";
import { getExpiringOrdersLabel } from "helpers/OrderHelper";

const AccountViewLayout = () => {
  const { pathname } = useLocation();
  const { expiringEnumParam, orderId } = useParams();

  const { setSnackbarError, snackbarComponent } = useSnackbar();

  const [dashboardData, setDashboardData] = useState<IClinicianDashboardData>();
  const [loadingDashboardData, setLoadingDashboardData] =
    useState<boolean>(true);
  const [order, setOrder] = useState<IOrderDetails>();
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      { label: "Overview", to: URLRoutes.AccountDashboard },
      ...(matchPath(URLRoutes.AccountNotAccessedPatients, pathname)
        ? [{ label: "Not Accessed Patients", to: "#" }]
        : [
            {
              label: getExpiringOrdersLabel(Number(expiringEnumParam)) ?? "",
              to: generatePath(URLRoutes.AccountExpiringOrders, {
                expiringEnumParam: expiringEnumParam ?? "",
              }),
            },
            ...(order ? [{ label: `#${order.orderNum}`, to: "#" }] : []),
          ]),
    ],
    [pathname, expiringEnumParam, order]
  );

  const getDashboardData = useCallback(
    (signal?: AbortSignal) => {
      setLoadingDashboardData(true);

      ClinicianService.getAllDashboardData(signal)
        .then(({ data }) => {
          setDashboardData(data);
          setLoadingDashboardData(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setLoadingDashboardData(false);
          setSnackbarError();
        });
    },
    [setSnackbarError]
  );

  const getOrder = useCallback(() => {
    if (!orderId) return;

    setLoadingOrder(true);

    OrderService.getOne({ orderId })
      .then(({ data }) => setOrder(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoadingOrder(false));
  }, [orderId, setSnackbarError]);

  useEffect(() => {
    const controller = new AbortController();

    getDashboardData(controller.signal);

    return () => controller.abort();
  }, [getDashboardData]);

  useEffect(() => {
    if (orderId) {
      getOrder();
    } else {
      setOrder(undefined);
    }
  }, [orderId, getOrder]);

  const refreshOrder = useCallback(() => {
    getOrder();
    getDashboardData(); // refresh also dashboard data, maybe order expiration date has been edited
  }, [getOrder, getDashboardData]);

  return (
    <div className="h-full flex flex-col gap-4">
      {/* Breadcrumbs are hidden on dashboard screen */}
      {!matchPath(URLRoutes.AccountDashboard, pathname) && (
        <Breadcrumbs items={breadcrumbsItems} />
      )}

      <div className="grow h-0">
        <Outlet
          context={{
            dashboardData,
            loadingDashboardData,
            order,
            loadingOrder,
            refreshOrder,
          }}
        />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default AccountViewLayout;

export const useAccountViewLayoutContext = () => {
  return useOutletContext<{
    dashboardData: IClinicianDashboardData | undefined;
    loadingDashboardData: boolean;
    order: IOrderDetails | undefined;
    loadingOrder: boolean;
    refreshOrder: () => void;
  }>();
};
