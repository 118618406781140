import { ReactNode, useCallback } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { IHistoryTest, TestTableProps, Metric } from "interfaces/Test";
import {
  formatCompletedOnTime,
  formatDate,
  secondsToString,
} from "helpers/CommonHelper";
import { BalanceMetric, GaitMetric, TestType } from "enums/Test";
import {
  formatAnglesRegionLabel,
  formatMotionLabel,
  formatFeetLabel,
  formatEyesLabel,
  formatSurfaceLabel,
  formatGaitAidLabel,
  formatGaitDistanceLabel,
  formatCognitiveTypeLabel,
  formatCognitiveHandLabel,
  formatTestNameByID,
  formatIntervalLabel,
  roundTestScore,
  getSensorPositionName,
} from "helpers/TestHelper";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./AllTypesTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Test ID", sortProperty: "accession" },
  { label: "Test type", sortProperty: "test_name" },
  { label: "Test parameters" },
  { label: "Date & time", sortProperty: "created_on" },
  { label: "Score" },
  { label: "" },
];

const AllTypesTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    orderBy,
    onSort,
    onRunReportBtnClick,
    getTestNotesLink,
    getMenuOptions,
  } = props;

  const formatTestParameters = useCallback(
    ({
      testId,
      aid,
      distance,
      distanceType,
      state,
      feet,
      surface,
      durationType,
      duration,
      eyes,
      startingPositionCustom,
      performedDuration,
      side,
      repeat,
      sensors,
    }: IHistoryTest): ReactNode => {
      switch (testId) {
        case TestType.Angles:
          return (
            <>
              {formatAnglesRegionLabel(distance)},
              <br />
              {formatMotionLabel(state)}
            </>
          );
        case TestType.Balance:
          return (
            <>
              {formatFeetLabel(feet)},
              <br />
              {`Eyes ${formatEyesLabel(eyes)}`},
              <br />
              {surface
                ? formatSurfaceLabel(surface) + " Surface"
                : "Surface: -"}
              ,
              <br />
              {`Duration: ${secondsToString(duration)}`},
              <br />
              {`Sensor position: ${getSensorPositionName(sensors, 0)}`}
            </>
          );

        case TestType.Cognitive:
          return (
            <>
              {formatCognitiveTypeLabel(feet, surface)},
              <br />
              {`${formatCognitiveHandLabel(side)} Hand`},
              <br />
              {`${repeat} Repeats`},
              <br />
              {formatIntervalLabel(durationType, duration, distance)}
            </>
          );
        case TestType.FreeformAngles:
        case TestType.Symmetry:
          return (
            <>
              {startingPositionCustom},
              <br />
              {secondsToString(Math.floor(performedDuration / 1000))}
            </>
          );
        case TestType.Gait:
          return (
            <>
              {`Distance ${formatGaitDistanceLabel(distance, distanceType)}`},
              <br />
              {`Duration: ${secondsToString(duration)}`},
              <br />
              {`Aid: ${formatGaitAidLabel(aid)}`},
              <br />
              {`Sensor position: ${getSensorPositionName(sensors, 0)}`}
            </>
          );
      }
    },
    []
  );

  const getScoreByTestId = useCallback(
    ({ testId, metric }: { testId: TestType; metric: Metric }) => {
      switch (testId) {
        case TestType.Balance:
          return roundTestScore(metric[BalanceMetric.BalanceScore] as number);
        case TestType.Gait:
          return roundTestScore(metric[GaitMetric.GaitScore] as number);
      }
    },
    []
  );

  return (
    <Table className={styles.all_types_table}>
      <TableHead>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }, index) => (
            <TableCell key={index}>
              {sortProperty ? (
                <TableSortLabel
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map((test, index) => {
          const {
            id,
            accession,
            testId,
            createdOn,
            orderItemId,
            performedDuration,
            metric,
            noteCount,
          } = test;

          return (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>{accession}</TableCell>
              <TableCell>{formatTestNameByID(testId)}</TableCell>
              <TableCell>{formatTestParameters(test)}</TableCell>
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatCompletedOnTime(createdOn, performedDuration / 1000)}
              </TableCell>
              <TableCell className="!text-4xl !font-bold">
                {getScoreByTestId({ testId, metric })}
              </TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AllTypesTable;
