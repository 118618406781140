import { extractDurationAndType } from "../../../../../../helpers/OrderHelper";
import { IGaitTestItem } from "../../../../../../interfaces/Order";
import { TestDurationValue } from "../../../../../../interfaces/Test";
import CheckboxInput from "../../../../../CheckboxInput/CheckboxInput";
import TestFrequencyInputs from "../../TestFrequencyInputs/TestFrequencyInputs";

interface GaitTestInputProps {
  duration: TestDurationValue;
  label: string;
  tests: IGaitTestItem[];
  onChange: (value: IGaitTestItem[]) => void;
}

const GaitTestInput = ({
  label,
  duration,
  tests,
  onChange,
}: GaitTestInputProps) => {
  const durationParams = extractDurationAndType(duration);
  const currentTest = tests.find(
    ({ duration, durationType }) =>
      duration === durationParams.duration &&
      durationType === durationParams.durationType
  );
  const isSelected = Boolean(currentTest);
  const showFrequencyError =
    isSelected && (!currentTest?.frequency || !currentTest.frequencyReps);

  return (
    <div className="flex flex-col items-center justify-between py-1.5 border-b border-pelorous/30 last:border-b-0 last:pb-0 md:portrait:flex-row lg:flex-row">
      <div className="w-[43%]">
        <CheckboxInput
          isTestParamInput
          label={label}
          value={isSelected}
          onChange={(checked) =>
            // add or remove this test, depending of checked value
            onChange(
              checked
                ? [...tests, durationParams]
                : tests.filter(
                    ({ duration, durationType }) =>
                      !(
                        duration === durationParams.duration &&
                        durationType === durationParams.durationType
                      )
                  )
            )
          }
        />
      </div>

      <div
        className={`grow relative ${
          showFrequencyError
            ? "after:content-[''] after:absolute after:-bottom-[7px] after:left-0 after:right-0 after:bg-crimson after:h-0.5"
            : ""
        }`}
      >
        <TestFrequencyInputs
          className="!flex-row items-center !gap-5 "
          hideLabel
          isTestSelected={isSelected}
          frequency={currentTest?.frequency}
          frequencyReps={currentTest?.frequencyReps}
          onChange={(frequencyParams) =>
            onChange(
              isSelected
                ? tests.map((x) =>
                    x.duration === durationParams.duration &&
                    x.durationType === durationParams.durationType
                      ? { ...x, ...frequencyParams }
                      : x
                  )
                : [...tests, { ...durationParams, ...frequencyParams }]
            )
          }
        />
      </div>
    </div>
  );
};

export default GaitTestInput;
