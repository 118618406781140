import { useMemo } from "react";
import { TestType } from "enums/Test";
import TestTypeDropdown from "components/Dropdowns/TestTypeDropdown/TestTypeDropdown";
import {
  getAllTestTypes,
  getTestTypesThatSupportTrendsAndSummary,
} from "helpers/TestHelper";
import Switch from "components/Switch/Switch";

interface TestTypeSelectionProps {
  showOnlyTestsThatSupportTrends?: boolean;
  selected: TestType[];
  onChange: (value: TestType[]) => void;
  isModalFilter?: boolean;
}

const TestTypeSelection = (props: TestTypeSelectionProps) => {
  const { showOnlyTestsThatSupportTrends, selected, onChange, isModalFilter } =
    props;

  const testTypes = useMemo(
    () =>
      showOnlyTestsThatSupportTrends
        ? getTestTypesThatSupportTrendsAndSummary()
        : getAllTestTypes(),
    [showOnlyTestsThatSupportTrends]
  );

  return (
    <div className="flex flex-col gap-1 relative">
      {isModalFilter && (
        <div className="flex justify-between items-center">
          <p className="text-base font-medium text-cloudBurst">
            Select test type(s)
          </p>
          <button
            className="absolute bottom-4 right-5 uppercase text-sm text-pelorous hover:cursor-pointer disabled:opacity-50 disabled:pointer-events-none xl:relative xl:right-auto xl:bottom-auto xl:normal-case"
            disabled={!selected.length}
            onClick={() => onChange([])}
          >
            Clear
          </button>
        </div>
      )}

      <div
        className={`p-3.5 rounded-md bg-white flex ${
          isModalFilter ? "flex-col gap-y-6 shadow-2xl pb-14" : "hidden"
        }`}
      >
        {testTypes.map(
          ({ label, value }) =>
            isModalFilter && (
              <Switch
                key={value}
                className="flex-row-reverse justify-between"
                label={label}
                checked={selected.includes(value)}
                onChange={(checked) =>
                  onChange(
                    checked
                      ? [...selected, value]
                      : selected.filter((x) => x !== value)
                  )
                }
              />
            )
        )}
      </div>
      {!isModalFilter && (
        <TestTypeDropdown
          showOnlyTestsThatSupportTrends={showOnlyTestsThatSupportTrends}
          selected={selected}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default TestTypeSelection;
