import { useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import moment from "moment";
import DashboardCardContainer from "components/DashboardCardContainer/DashboardCardContainer";
import quickReportImage from "assets/images/quick_report.svg";
import ReportsModal from "components/Modals/ReportsModal/ReportsModal";
import ReportsService from "services/ReportsService";
import { ITrendsAndSummaryReportParams } from "interfaces/Reports";
import { generateLastMonthRange, getTimezone } from "helpers/CommonHelper";
import { RollupInterval } from "enums/Test";
import { getTestTypesThatSupportTrendsAndSummary } from "helpers/TestHelper";
import CustomReportModal from "components/Modals/CustomReportModal/CustomReportModal";
import TooltipBox from "components/TooltipBox/TooltipBox";

interface QuickReportCardProps {
  lastTestDate: string | null;
  onError: () => void;
}

enum QuickReportModals {
  SummaryDaily = 1,
  Custom = 2,
}

const QuickReportCard = ({ lastTestDate, onError }: QuickReportCardProps) => {
  const { patientId } = useParams();

  const [activeModal, setActiveModal] = useState<QuickReportModals | undefined>(
    undefined
  );

  // disable buttons if patient doesn't have recorded tests in last 30 days
  // add 1 day, because moment diff doesn't count current date
  const disableBtns =
    !lastTestDate ||
    moment().add(1, "day").diff(moment(lastTestDate), "days") > 30;
  const btnsTooltip = disableBtns
    ? "The patient has not recorded tests in the last 30 days"
    : undefined;

  const generateQuickReportPromises = (): Promise<AxiosResponse>[] => {
    if (!patientId) return [];

    // quick report returns summary reports
    const testTypes = getTestTypesThatSupportTrendsAndSummary().map(
      ({ value }) => value
    );
    const createdOn = generateLastMonthRange();

    const commonParams: Omit<ITrendsAndSummaryReportParams, "testId"> = {
      patientId,
      createdOn,
      _timezone: getTimezone(),
      rollup: RollupInterval.Day,
    };

    return testTypes.map((testId) =>
      ReportsService.getSummary({ ...commonParams, testId })
    );
  };

  return (
    <>
      <DashboardCardContainer
        childrenClassName="grid place-content-center"
        title="Run quick report"
        subtitle="Last 30 days (Summary)"
        buttonData={{
          text: "Access Custom Reports",
          disabled: disableBtns,
          tooltip: btnsTooltip,
          onClick: () => setActiveModal(QuickReportModals.Custom),
        }}
        children={
          <TooltipBox content={btnsTooltip}>
            <span>
              <button
                disabled={disableBtns}
                className="disabled:opacity-50"
                onClick={() => setActiveModal(QuickReportModals.SummaryDaily)}
              >
                <img src={quickReportImage} alt="calendar" />
              </button>
            </span>
          </TooltipBox>
        }
      />

      {activeModal === QuickReportModals.SummaryDaily && (
        <ReportsModal
          reportPromises={generateQuickReportPromises()}
          onClose={() => setActiveModal(undefined)}
          onError={onError}
        />
      )}

      {activeModal === QuickReportModals.Custom && (
        <CustomReportModal
          initialFilters={{ createdOn: generateLastMonthRange() }}
          onClose={() => setActiveModal(undefined)}
          onError={onError}
        />
      )}
    </>
  );
};

export default QuickReportCard;
