import { useMemo } from "react";
import { TestType } from "enums/Test";
import {
  getAllTestTypes,
  getTestTypesThatSupportTrendsAndSummary,
} from "helpers/TestHelper";
import DropdownMultiselection from "components/Dropdowns/DropdownMultiselection/DropdownMultiselection";

interface TestTypeDropdownProps {
  showOnlyTestsThatSupportTrends?: boolean;
  placeholder?: string;
  selected: TestType[];
  onChange: (value: TestType[]) => void;
}

const TestTypeDropdown = (props: TestTypeDropdownProps) => {
  const { showOnlyTestsThatSupportTrends, ...rest } = props;

  const testTypes = useMemo(
    () =>
      showOnlyTestsThatSupportTrends
        ? getTestTypesThatSupportTrendsAndSummary()
        : getAllTestTypes(),
    [showOnlyTestsThatSupportTrends]
  );

  return (
    <DropdownMultiselection
      label="Select test type(s)"
      options={testTypes}
      {...rest}
    />
  );
};

export default TestTypeDropdown;
