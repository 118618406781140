import { Link } from "react-router-dom";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import { INavItem } from "../../interfaces/Common";

interface IBreadcrumbsProps {
  items: INavItem[];
}

const Breadcrumbs = ({ items }: IBreadcrumbsProps) => {
  return (
    <MUIBreadcrumbs
      className="hidden md:block"
      separator={
        <span className="icon_svg icon_navigate_arrow rotate-180 !bg-malibu" />
      }
    >
      {items.map(({ label, to, onClick }, index) => {
        return index + 1 !== items.length ? (
          <Link
            className="text-base leading-4 text-cloudBurst font-medium hover:underline"
            key={to}
            to={to ?? ""}
            onClick={onClick}
          >
            {label}
          </Link>
        ) : (
          <p key={to} className="text-base leading-4 text-pelorous font-medium">
            {label}
          </p>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
