import { useState } from "react";
import { Calendar } from "react-date-range";
import moment from "moment";
import Popover from "@mui/material/Popover";
import TableInputFilter from "components/TableInputFilter/TableInputFilter";
import { formatDashDate } from "helpers/CommonHelper";
import styles from "./TableDatepickerFilter.module.scss";

interface TableDatepickerFilterProps {
  // when filtering by single day, API expects range with format: date~`nextDate
  // exception is dob parameter (date of birth) since it's saved as plain text in format YYYY-MM-DD
  removeNextDay?: boolean;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const TableDatepickerFilter = (props: TableDatepickerFilterProps) => {
  const { removeNextDay, value, onChange } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);

  const dateValue = value ? moment(value, "YYYY-MM-DD").toDate() : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date: Date) => {
    const dateStringValue = moment(date).format("YYYY-MM-DD");
    const nextDateStrinValue = moment(date).add(1, "day").format("YYYY-MM-DD");
    const newValue = removeNextDay
      ? dateStringValue
      : `${dateStringValue}~\`${nextDateStrinValue}`;

    onChange(newValue);
    handleClose();
  };

  return (
    <>
      <TableInputFilter
        disabled
        className={`${styles.date_input} ${value ? styles.not_empty : ""} ${
          open ? styles.open : ""
        }`}
        placeholder={value ? formatDashDate(value) : "Select date"}
        value={value}
        onChange={onChange}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />

      <Popover
        className={styles.calendar_wrapper}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: -5, horizontal: 0 }}
      >
        <Calendar
          maxDate={new Date()} // disable future dates
          weekdayDisplayFormat="EEEEE" // one letter weekdays
          date={dateValue}
          onChange={handleDateChange}
        />
      </Popover>
    </>
  );
};

export default TableDatepickerFilter;
