import noTestsImage from "assets/images/no_tests.svg";

const NoTestsBox = () => {
  return (
    <div className="h-full flex flex-col items-center justify-center gap-9">
      <img src={noTestsImage} alt="no tests" />
      <p className="text-xl leading-6 text-nero font-medium">
        No tests match selected filters.
      </p>
    </div>
  );
};

export default NoTestsBox;
