import { useMemo } from "react";
import Dropdown from "components/Dropdowns/Dropdown/Dropdown";
import { Apps } from "enums/Common";
import useAvailableApps from "hooks/useAvailableApps";
import { IDropdownOption } from "interfaces/Common";

interface OrderTypeDropdownProps {
  selected: Apps | undefined;
  onChange: (value: Apps) => void;
}

const OrderTypeDropdown = ({ selected, onChange }: OrderTypeDropdownProps) => {
  const { canCreateAtHomeOrder, canCreateRemoteOrder } = useAvailableApps();

  const options = useMemo(
    (): IDropdownOption<Apps>[] => [
      ...(canCreateRemoteOrder
        ? [{ label: "LAB REMOTE (staff assisted testing)", value: Apps.Remote }]
        : []),
      ...(canCreateAtHomeOrder
        ? [{ label: "LAB AT HOME (patient self-testing)", value: Apps.AtHome }]
        : []),
    ],
    [canCreateAtHomeOrder, canCreateRemoteOrder]
  );

  return (
    <Dropdown
      hideClearButton
      useInputFieldStyle
      label="Order type *"
      error={!selected}
      options={options}
      selected={selected}
      onChange={(value) => (value ? onChange(value) : undefined)}
    />
  );
};

export default OrderTypeDropdown;
