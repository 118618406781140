import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TestTableProps } from "interfaces/Test";
import { ITableColumn } from "interfaces/Common";
import { formatDate, formatTime } from "helpers/CommonHelper";
import { CognitiveMetric } from "enums/Test";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import {
  formatCognitiveTypeLabel,
  formatIntervalLabel,
} from "helpers/TestHelper";
import LastTestTableCell from "components/LastTestTableCell/LastTestTableCell";
import styles from "./CognitiveTable.module.scss";

const tableColumns: ITableColumn[] = [
  { label: "Details", sortProperty: "created_on" },
  { label: "Test Parameters", sortProperty: `` },
  { label: "Result (%)", sortProperty: `metric_${CognitiveMetric.Result}` },
  { label: "Average", sortProperty: `metric_${CognitiveMetric.Average}` },
  { label: "Median", sortProperty: `metric_${CognitiveMetric.Median}` },
  { label: "Minimum", sortProperty: `metric_${CognitiveMetric.Minumum}` },
  { label: "Maximum", sortProperty: `metric_${CognitiveMetric.Maximum}` },
  {
    label: "Standard Deviation",
    sortProperty: `metric_${CognitiveMetric.StandardDeviation}`,
  },
  { label: "" },
];

const CognitiveTable = (props: TestTableProps) => {
  const {
    lastElementRef,
    tests,
    onSort,
    onRunReportBtnClick,
    getTestNotesLink,
    orderBy,
    getMenuOptions,
  } = props;

  return (
    <Table className={styles.cognitive_table}>
      <TableHead>
        <TableRow>
          <TableCell>Cognitive Tests</TableCell>
          <TableCell />
          <TableCell align="center" colSpan={6}>
            Reaction (ms)
          </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          {tableColumns.map(({ label, sortProperty }) => (
            <TableCell key={label}>
              {sortProperty ? (
                <TableSortLabel
                  whiteTheme
                  label={label}
                  sortProperty={sortProperty}
                  orderBy={orderBy}
                  onSort={onSort}
                />
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tests.map(
          (
            {
              id,
              accession,
              createdOn,
              metric,
              orderItemId,
              duration,
              durationType,
              distance,
              feet,
              surface,
              repeat,
              noteCount,
            },
            index
          ) => (
            <TableRow
              key={id}
              ref={index === tests.length - 1 ? lastElementRef : null}
            >
              <TableCell>
                {formatDate(createdOn)}
                <br />
                {formatTime(createdOn)}
                <br />
                <span className={styles.accession_label}>Test ID</span>
                <br />
                {accession}
              </TableCell>
              <TableCell>
                <div className="!whitespace-pre-wrap">{`${formatCognitiveTypeLabel(
                  feet,
                  surface
                )}, ${repeat} Repeats,  ${formatIntervalLabel(
                  durationType,
                  duration,
                  distance
                )}`}</div>
              </TableCell>
              <TableCell>{metric[CognitiveMetric.Result] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Average] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Median] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Minumum] as number}</TableCell>
              <TableCell>{metric[CognitiveMetric.Maximum] as number}</TableCell>
              <TableCell>
                {metric[CognitiveMetric.StandardDeviation] as number}
              </TableCell>
              <LastTestTableCell
                noteCount={noteCount}
                onRunReportBtnClick={() => onRunReportBtnClick(id)}
                testNotesLink={getTestNotesLink(id)}
                menuOptions={getMenuOptions(orderItemId, id)}
              />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default CognitiveTable;
