import { useRef, useState } from "react";
import { IOrderDetails, IOrderHistoryFilters } from "interfaces/Order";
import Loading from "components/Loading/Loading";
import { OrderDirection, PageSize } from "enums/Common";
import OrderHistoryTable from "components/Tables/OrderHistoryTable/OrderHistoryTable";
import OrderService from "services/OrderService";
import axios from "axios";
import OrderVersionCards from "components/OrderVersionCards/OrderVersionCards";

interface OrderHistoryProps {
  orderId: string;
  onError: () => void;
}

const OrderHistory = ({ orderId, onError }: OrderHistoryProps) => {
  const [filters, setFilters] = useState<IOrderHistoryFilters>({
    _order_by: `created_on ${OrderDirection.Desc}`,
    page_size: PageSize.Default,
    _total_count: 1,
    page: 1,
    orderId,
  });
  const [versionSnapshot, setVersionSnapshot] = useState<IOrderDetails>();
  const [loading, setLoading] = useState<boolean>(false);

  const controllerRef = useRef<AbortController>();

  const hideTable: boolean = Boolean(loading || versionSnapshot);

  const handleGetVersionSnapshot = (versionId: number) => {
    setLoading(true);

    controllerRef.current = new AbortController();

    OrderService.getOne({ orderId, versionId }, controllerRef.current.signal)
      .then(({ data }) => {
        setVersionSnapshot(data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        onError();
        setLoading(false);
      });
  };

  const handleBackBtnClick = () => {
    setVersionSnapshot(undefined);
    controllerRef.current?.abort();
  };

  return (
    <div className="h-full relative">
      <div className={`h-full ${hideTable ? "opacity-0" : ""}`}>
        <OrderHistoryTable
          filters={filters}
          setFilters={setFilters}
          onError={onError}
          onViewVersionClick={handleGetVersionSnapshot}
        />
      </div>

      {versionSnapshot && (
        <div className="h-full absolute inset-0 flex flex-col gap-4">
          <button
            className="self-start flex items-center gap-2 text-cloudBurst"
            onClick={handleBackBtnClick}
          >
            <span className="icon_svg icon_navigate_arrow !bg-cloudBurst" />
            Back to history
          </button>

          <div className="grow h-0">
            <OrderVersionCards order={versionSnapshot} />
          </div>
        </div>
      )}

      <Loading loading={loading} />
    </div>
  );
};

export default OrderHistory;
