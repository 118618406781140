import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { Color, SlideTimeout } from "enums/Common";
import { IPatient } from "interfaces/Patient";
import OrderSteps from "components/OrderSteps/OrderSteps";
import { IClinician } from "interfaces/Clinician";
import PatientCard from "components/Modals/CreateEditOrderModal/PatientCard/PatientCard";
import OrderCard from "components/Modals/CreateEditOrderModal/OrderCard/OrderCard";
import ShippingCard from "components/Modals/CreateEditOrderModal/ShippingCard/ShippingCard";
import SummaryCard from "components/Modals/CreateEditOrderModal/SummaryCard/SummaryCard";
import TestsCard from "components/Modals/CreateEditOrderModal/TestsCard/TestsCard";
import {
  CardsValidation,
  DiagnosisCodes,
  Duration,
  Patient,
  Clinician,
  TestsData,
  UploadedAttachments,
  ModalState,
  Billable,
  Product,
  InitialPatient,
} from "recoil-states/create-order-states";
import Loading from "components/Loading/Loading";
import OrderSubmittedCard from "components/Modals/CreateEditOrderModal/OrderSubmittedCard/OrderSubmittedCard";
import useSnackbar from "hooks/useSnackbar";
import { IOrderDetails } from "interfaces/Order";
import PatientService from "services/PatientService";
import ClinicianService from "services/ClinicianService";
import { getTestDataFromExistingOrder } from "helpers/OrderHelper";
import AttachmentsCard from "components/Modals/CreateEditOrderModal/AttachmentsCard/AttachmentsCard";
import { OrderModalState } from "enums/Order";

interface CreateEditOrderModalProps {
  isRenew?: boolean;
  preselectedPatient?: IPatient;
  preselectedClinician?: IClinician;
  initialOrder?: IOrderDetails;
  onInitialDataFetchFail?: () => void;
  onClose: () => void;
  onCompleted?: () => void;
}

const CreateEditOrderModal = ({
  isRenew,
  preselectedPatient,
  preselectedClinician,
  initialOrder,
  onInitialDataFetchFail,
  onCompleted,
  onClose,
}: CreateEditOrderModalProps) => {
  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [loadingInitialData, setLoadingInitialData] = useState<boolean>(false);

  const [modalState, setModalState] = useRecoilState(ModalState);
  const setPatient = useSetRecoilState(Patient);
  const setInitialPatient = useSetRecoilState(InitialPatient);
  const setClinician = useSetRecoilState(Clinician);
  const setDiagnosisCodes = useSetRecoilState(DiagnosisCodes);
  const setDuration = useSetRecoilState(Duration);
  const setBillable = useSetRecoilState(Billable);
  const setProduct = useSetRecoilState(Product);
  const setTestsData = useSetRecoilState(TestsData);

  const resetModalState = useResetRecoilState(ModalState);
  const resetPatient = useResetRecoilState(Patient);
  const resetInitialPatient = useResetRecoilState(InitialPatient);
  const resetClinician = useResetRecoilState(Clinician);
  const resetDiagnosisCodes = useResetRecoilState(DiagnosisCodes);
  const resetDuration = useResetRecoilState(Duration);
  const resetBillable = useResetRecoilState(Billable);
  const resetProduct = useResetRecoilState(Product);
  const resetTestsData = useResetRecoilState(TestsData);
  const resetUploadedAttachments = useResetRecoilState(UploadedAttachments);
  const resetCardsValidation = useResetRecoilState(CardsValidation);

  const title = useMemo((): string => {
    switch (true) {
      case Boolean(isRenew && initialOrder):
        return "Renew Order";
      case Boolean(initialOrder):
        return "Edit Order";
      default:
        return "Create New Order";
    }
  }, [isRenew, initialOrder]);

  useEffect(() => {
    if (preselectedPatient) {
      setPatient(preselectedPatient);
    }

    if (preselectedClinician) {
      setClinician(preselectedClinician);
    }
  }, [preselectedPatient, preselectedClinician, setPatient, setClinician]);

  useEffect(() => {
    if (!initialOrder?.patientId || modalState !== OrderModalState.Initial)
      return;
    // populate recoil states with initial data

    const {
      patientId,
      authorizedById,
      meta,
      durationMonth,
      billable,
      product,
    } = initialOrder;
    const abortController = new AbortController();

    setLoadingInitialData(true);

    const requests = [
      PatientService.getOne(patientId, abortController.signal),
      ClinicianService.getOne(authorizedById, abortController.signal),
    ];

    Promise.all(requests)
      .then(([{ data: patientData }, { data: clinicianData }]) => {
        // map patient data from order meta (patient maybe updated profile)
        const tempPatientData: IPatient = {
          ...patientData,
          ...(meta && {
            cellPhone: meta.phone,
            email: meta.email,
            meta: {
              insuranceData: meta.insuranceData ?? undefined,
              isDemoUser: patientData.meta?.isDemoUser,
            },
            ...(meta.shipping && {
              address: {
                street: meta.shipping.street1,
                street2: meta.shipping.street2,
                city: meta.shipping.city,
                state: meta.shipping.state,
                postCode: meta.shipping.zip,
              },
            }),
          }),
        };

        setPatient(tempPatientData);
        setInitialPatient(tempPatientData);
        setDiagnosisCodes(meta?.diagnosisCodes ?? []);
        setClinician({
          ...clinicianData,
          ...(initialOrder.meta?.referringPhysician && {
            referringPhysician: initialOrder.meta.referringPhysician,
          }),
        });
        setDuration(String(durationMonth));
        setProduct(product);
        setBillable(billable);
        setTestsData(getTestDataFromExistingOrder(initialOrder));
        setLoadingInitialData(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;

        onInitialDataFetchFail?.();
        onClose();
      });

    return () => abortController.abort();
  }, [
    initialOrder,
    setSnackbarError,
    setPatient,
    setInitialPatient,
    setClinician,
    onInitialDataFetchFail,
    onClose,
    setDiagnosisCodes,
    setDuration,
    setBillable,
    setProduct,
    setTestsData,
    modalState,
  ]);

  useEffect(() => {
    // reset recoil states on modal unmount
    return () => {
      resetModalState();
      resetPatient();
      resetInitialPatient();
      resetClinician();
      resetDiagnosisCodes();
      resetDuration();
      resetBillable();
      resetProduct();
      resetTestsData();
      resetUploadedAttachments();
      resetCardsValidation();
    };
  }, [
    resetModalState,
    resetPatient,
    resetInitialPatient,
    resetClinician,
    resetDiagnosisCodes,
    resetDuration,
    resetBillable,
    resetProduct,
    resetTestsData,
    resetUploadedAttachments,
    resetCardsValidation,
  ]);

  const handleCompleted = useCallback(() => {
    setModalState(OrderModalState.Submitted);
    onCompleted?.();
  }, [onCompleted, setModalState]);

  const handleFailed = useCallback(
    (message?: string) => {
      setSnackbarError(message);
      setModalState(OrderModalState.Initial);
    },
    [setSnackbarError, setModalState]
  );

  const handleAttachmentsUploadFailed = useCallback(
    (count: number) => {
      const message = `Failed to upload ${count} ${
        count > 1 ? "Attachments" : "Attachment"
      }.`;
      setSnackbarError(message);
    },
    [setSnackbarError]
  );

  return (
    <Modal open onClose={onClose}>
      <>
        {modalState !== OrderModalState.Submitted ? (
          <div className="w-full h-[calc(100%-25px)] max-w-[1246px] absolute bottom-0 left-1/2 -translate-x-1/2 outline-none">
            <Slide in direction="up" timeout={SlideTimeout.Default}>
              <div className="bg-aliceBlue w-full h-full box-border rounded-t-md flex flex-col ">
                <ButtonCloseModal
                  className="!top-5 left-4"
                  color={Color.CloudBurst}
                  onClick={onClose}
                />

                <div className="bg-white rounded-[inherit] py-5 text-center">
                  <h2 className="text-lg leading-5 text-cloudBurst font-bold">
                    {title}
                  </h2>
                </div>

                <div className="grow relative h-0 flex flex-col gap-7 pt-5 px-5 md:px-10">
                  {!loadingInitialData && (
                    <>
                      <OrderSteps />

                      <div className="grow h-0 flex flex-col gap-7 overflow-auto lg:flex-row">
                        <div className="flex flex-col gap-7 [&>div]:shrink-0 lg:pb-7 lg:grow lg:w-0 lg:overflow-auto">
                          <PatientCard
                            isPatientPreselected={Boolean(
                              preselectedPatient || initialOrder
                            )}
                          />

                          <OrderCard
                            isClinicianPreselected={Boolean(
                              preselectedClinician
                            )}
                          />

                          <TestsCard />

                          <ShippingCard />

                          <AttachmentsCard
                            initialOrder={initialOrder}
                            onDownloaded={setSnackbarSuccess}
                            onError={setSnackbarError}
                          />
                        </div>

                        <div className="pb-7 lg:w-[427px] lg:overflow-auto">
                          <SummaryCard
                            isRenew={isRenew}
                            initialOrder={initialOrder}
                            onStarted={() =>
                              setModalState(OrderModalState.InProgress)
                            }
                            onCompleted={handleCompleted}
                            onFailed={handleFailed}
                            onAttachmentsUploadFailed={
                              handleAttachmentsUploadFailed
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <Loading loading={loadingInitialData} />
                </div>
              </div>
            </Slide>
          </div>
        ) : (
          <div>
            <OrderSubmittedCard
              {...(isRenew
                ? { isRenew: true }
                : { isEdit: Boolean(initialOrder) })}
              onClose={onClose}
            />
          </div>
        )}
        {snackbarComponent}
      </>
    </Modal>
  );
};

export default CreateEditOrderModal;
