import { useState } from "react";
import Modal from "@mui/material/Modal";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import Button from "components/Buttons/Button/Button";
import InputField from "components/InputField/InputField";
import TestService from "services/TestService";
import Switch from "components/Switch/Switch";
import { ITestNote } from "interfaces/Test";

type TestNoteModalProps = {
  onSuccess: (message: string) => void;
  onError: () => void;
  onClose: () => void;
} & (
  | { testId: string; initialData?: never }
  | { initialData: ITestNote; testId?: never }
);

const TestNoteModal = (props: TestNoteModalProps) => {
  const { testId, initialData, onSuccess, onError, onClose } = props;

  const [text, setText] = useState<string | undefined>(initialData?.text);
  const [includeOnReport, setIncludeOnReport] = useState<boolean>(
    initialData?.includeOnReport ?? false
  );

  const isEdit = Boolean(initialData);

  const notNotEntered = !text?.trim();

  const handleAddNote = () => {
    if (!text?.trim() || !testId) return;

    TestService.createNote({
      orderItemIterationId: testId,
      text,
      includeOnReport,
    })
      .then(() => onSuccess("Note has been added successfully."))
      .catch(() => onError());

    onClose();
  };

  const handleUpdateNote = () => {
    if (!text?.trim() || !initialData) return;

    TestService.updateNote(initialData.id, {
      text,
      includeOnReport,
    })
      .then(() => onSuccess("Note has been edited successfully."))
      .catch(() => onError());

    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <div className="box-border bg-white w-11/12 max-h-[80%] max-w-[674px] p-6 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:px-10 md:py-7">
        <ButtonCloseModal onClick={onClose} />

        <h2 className="text-center mb-6 text-lg font-bold text-cloudBurst leading-5">
          {isEdit ? "Edit" : "Add"} Note
        </h2>

        <div className="flex flex-col gap-5">
          <InputField
            multiline
            rows={5}
            placeholder="Enter notes"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <Switch
            label="Include on report"
            disabled={notNotEntered}
            checked={includeOnReport}
            onChange={setIncludeOnReport}
          />

          <Button
            disabled={notNotEntered}
            className="self-end"
            onClick={() => (isEdit ? handleUpdateNote() : handleAddNote())}
          >
            {isEdit ? "Confirm Change" : "Add Note"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TestNoteModal;
