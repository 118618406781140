interface TotalPriceBoxProps {
  className?: string;
  value: number;
}

const TotalPriceBox = ({ value, className }: TotalPriceBoxProps) => {
  return (
    <div className={`h-9 w-36 rounded-md flex md:w-52 ${className ?? ""}`}>
      <div className="w-2/5 flex items-center justify-center bg-white rounded-tl-md rounded-bl-md border border-pelorous text-pelorous text-lg leading-5 font-medium">
        Total:
      </div>
      <div className="grow w-0 flex items-center justify-center bg-pelorous rounded-tr-md rounded-br-md text-white text-lg leading-5 font-medium">
        {`$${value.toFixed(2)}`}
      </div>
    </div>
  );
};

export default TotalPriceBox;
