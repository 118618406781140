import DropdownMultiselection from "components/Dropdowns/DropdownMultiselection/DropdownMultiselection";
import { IDropdownOption } from "interfaces/Common";
import { Products } from "enums/Account";

interface ProductsDropdownProps {
  selected: Products[] | undefined;
  onChange: (value: Products[]) => void;
}

const ProductsDropdown = ({ selected, onChange }: ProductsDropdownProps) => {
  const hasResearch = selected?.includes(Products.Research);

  const options: IDropdownOption<Products>[] = [
    {
      label: "In Clinic",
      value: Products.InClinic,
    },
    {
      label: "At Home",
      value: Products.AtHome,
    },
    {
      label: "Remote",
      value: Products.Remote,
    },
  ];

  return (
    <DropdownMultiselection
      clearHidden
      useInputFieldStyle
      label="Products *"
      options={options}
      placeholder="Select Product"
      selected={selected?.filter((x) => x !== Products.Research) ?? []}
      // research product can't be set or removed trough Web Portal
      // if already set for some account, keep it when changing other products
      onChange={(value) =>
        onChange(hasResearch ? [Products.Research, ...value] : value)
      }
    />
  );
};

export default ProductsDropdown;
