import { useState } from "react";
import Popover from "@mui/material/Popover";
import TableInputFilter from "components/TableInputFilter/TableInputFilter";
import { formatDashDate } from "helpers/CommonHelper";
import RangeDatePicker from "components/RangeDatePicker/RangeDatePicker";
import { DateRange } from "enums/Common";
import styles from "./TableRangeDatepickerFilter.module.scss";

interface TableRangeDatepickerFilterProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const TableRangeDatepickerFilter = (props: TableRangeDatepickerFilterProps) => {
  const { value, onChange } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getPlaceholder = (): string => {
    if (!value) return "Select date";

    const [start, end] = value.split(DateRange.Separator);

    return `${formatDashDate(start)}-${formatDashDate(end)}`;
  };

  const handleChange = (value: string | undefined): void => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <TableInputFilter
        disabled
        className={`${styles.date_input} ${value ? styles.not_empty : ""} ${
          open ? styles.open : ""
        }`}
        placeholder={getPlaceholder()}
        value={value}
        onChange={onChange}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />

      <Popover
        className={styles.calendar_wrapper}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: -5, horizontal: 0 }}
      >
        <RangeDatePicker isTableFilter value={value} onChange={handleChange} />
        <button
          className="absolute right-1 bottom-1 text-pelorous text-sm leading-4 font-medium disabled:opacity-50"
          disabled={!value}
          onClick={() => handleChange(undefined)}
        >
          Clear
        </button>
      </Popover>
    </>
  );
};

export default TableRangeDatepickerFilter;
