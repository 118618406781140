import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { IPatient } from "interfaces/Patient";
import { IAddress } from "interfaces/Address";
import CardContainer from "components/CardContainer/CardContainer";
import useValidationErrors from "hooks/useValidationErrors";
import InputField from "components/InputField/InputField";
import {
  CardsValidation,
  HideShipping,
  ModalState,
  Patient,
} from "recoil-states/create-order-states";
import { OrderModalState } from "enums/Order";

interface IFieldData {
  label: string;
  placeholder: string;
  name: keyof IPatient | keyof IAddress;
  value: string | null | undefined;
  type?: HTMLInputTypeAttribute;
  isAddressField?: boolean;
  notRequired?: boolean;
}

const ShippingCard = () => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const modalState = useRecoilValue(ModalState);
  const hideShipping = useRecoilValue(HideShipping);
  const [patient, setPatient] = useRecoilState(Patient);
  const [cardsValidation, setCardsValidation] = useRecoilState(CardsValidation);

  const isCardDisabled =
    !cardsValidation.patient ||
    !cardsValidation.order ||
    !cardsValidation.tests ||
    modalState === OrderModalState.InProgress;

  const leftColumnFieldsData = useMemo(
    (): IFieldData[] => [
      {
        label: "Address Line 1",
        placeholder: "Enter address",
        name: "street",
        value: patient?.address?.street,
        isAddressField: true,
      },
      {
        label: "Address Line 2",
        placeholder: "Enter address",
        name: "street2",
        value: patient?.address?.street2,
        notRequired: true,
        isAddressField: true,
      },
      {
        label: "City",
        placeholder: "Enter city",
        name: "city",
        value: patient?.address?.city,
        isAddressField: true,
      },
      {
        label: "State",
        placeholder: "Enter state",
        name: "state",
        value: patient?.address?.state,
        isAddressField: true,
      },
      {
        label: "ZIP",
        placeholder: "Enter zip code",
        name: "postCode",
        value: patient?.address?.postCode,
        isAddressField: true,
      },
    ],
    [patient?.address]
  );

  const rightColumnFieldsData = useMemo(
    (): IFieldData[] => [
      {
        label: "Phone",
        placeholder: "Enter phone number",
        name: "cellPhone",
        value: patient?.cellPhone ?? patient?.phone,
        type: "phone",
      },
      {
        label: "Email",
        placeholder: "Enter email address",
        name: "email",
        value: patient?.email,
        type: "email",
        notRequired: true,
      },
    ],
    [patient?.cellPhone, patient?.email, patient?.phone]
  );

  useEffect(() => {
    let shippingValid: boolean;

    switch (true) {
      case hideShipping:
        shippingValid = true;
        break;
      case Boolean(patient):
        shippingValid = !hasErrors;
        break;
      default:
        shippingValid = false;
    }

    setCardsValidation((prev) => ({
      ...prev,
      shipping: shippingValid,
    }));
  }, [hasErrors, setCardsValidation, patient, hideShipping]);

  const handleInputChange = useCallback(
    (
      {
        target: { name, value },
      }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      isAddressField?: boolean
    ) => {
      if (isAddressField) {
        setPatient((prev) => ({
          ...prev!,
          address: { ...prev!.address!, [name]: value },
        }));
        return;
      }

      setPatient((prev) => ({ ...prev!, [name]: value }));
    },
    [setPatient]
  );

  return hideShipping ? null : (
    <CardContainer
      title="Shipping"
      key={patient?.id}
      className="p-5"
      disabled={isCardDisabled}
    >
      <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
        {[leftColumnFieldsData, rightColumnFieldsData].map(
          (fieldsData, index) => (
            <div key={index} className="flex flex-col gap-5">
              {fieldsData.map(
                ({ isAddressField, notRequired, value, ...rest }) => (
                  <InputField
                    key={rest.label}
                    {...rest}
                    // pass empty string for required fields with undefined and null values
                    // that way error will be shown immediately
                    value={
                      !notRequired && (value === undefined || value === null)
                        ? ""
                        : value
                    }
                    required={!notRequired}
                    onChange={(e) => handleInputChange(e, isAddressField)}
                    onValidationErrorChange={handleValidationErrorChange}
                  />
                )
              )}
            </div>
          )
        )}
      </div>
    </CardContainer>
  );
};

export default ShippingCard;
