import { atom, selector } from "recoil";
import { IPatient } from "interfaces/Patient";
import { IOrderClinician } from "interfaces/Clinician";
import {
  ICreateAnglesOrderItem,
  ICreateBalanceOrderItem,
  ICreateGaitOrderItem,
  IGrouppedCreateOrderItems,
  INewOrderCardsValidation,
} from "interfaces/Order";
import { TestType } from "enums/Test";
import { getDefaultTestsData } from "helpers/OrderHelper";
import { IUploadedAttachment } from "interfaces/Common";
import { OrderModalState, PayerType } from "enums/Order";
import { Apps } from "enums/Common";

export const ModalState = atom<OrderModalState>({
  key: "ModalState",
  default: OrderModalState.Initial,
});

export const Patient = atom<IPatient | null>({
  key: "Patient",
  default: null,
});

export const InitialPatient = atom<IPatient | null>({
  key: "InitialPatient",
  default: null,
});

export const Clinician = atom<IOrderClinician | null>({
  key: "Clinician",
  default: null,
});

export const DiagnosisCodes = atom<string[]>({
  key: "DiagnosisCodes",
  default: [],
});

export const Duration = atom<string | undefined>({
  key: "Duration",
  default: undefined,
});

export const Billable = atom<PayerType | undefined>({
  key: "Billable",
  default: undefined,
});

export const Product = atom<Apps | undefined>({
  key: "Product",
  default: undefined,
});

export const TestsData = atom<IGrouppedCreateOrderItems>({
  key: "TestsData",
  default: getDefaultTestsData(),
});

export const UploadedAttachments = atom<IUploadedAttachment[]>({
  key: "UploadedAttachments",
  default: [],
});

// value = true -> card is valid
export const CardsValidation = atom<INewOrderCardsValidation>({
  key: "CardsValidation",
  default: {
    patient: false,
    order: false,
    tests: false,
    shipping: false,
  },
});

// no shipping for remote orders
export const HideShipping = selector<boolean>({
  key: "HideShipping",
  get: ({ get }) => get(Product) === Apps.Remote,
});

// test selectors:
export const GaitData = selector<ICreateGaitOrderItem>({
  key: "GaitData",
  get: ({ get }) => get(TestsData)[TestType.Gait],
});

export const BalanceData = selector<ICreateBalanceOrderItem>({
  key: "BalanceData",
  get: ({ get }) => get(TestsData)[TestType.Balance],
});

export const AnglesData = selector<ICreateAnglesOrderItem>({
  key: "AnglesData",
  get: ({ get }) => get(TestsData)[TestType.Angles],
});
