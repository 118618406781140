import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import LoginInput from "../../components/LoginInput/LoginInput";
import { KeyboardKey } from "../../enums/Common";
import { URLRoutes } from "../../enums/Routing";
import AuthService from "../../services/AuthService";
import useSnackbar from "../../hooks/useSnackbar";

const ForgotPassword = () => {
  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [successfullyRequested, setSuccessfullyRequested] =
    useState<boolean>(false);

  useEffect(() => {
    setIsSubmitDisabled(!email || !isEmail(email) || successfullyRequested);
  }, [email, successfullyRequested]);

  const handleSubmit = useCallback(() => {
    setIsSubmitDisabled(true);

    AuthService.forgotPassword({ email: email as string, portal: true })
      .then(() => {
        setSuccessfullyRequested(true);

        setSnackbarSuccess("Request successfully submitted.");
      })
      .catch(() => {
        setSnackbarError();
      });
  }, [email, setSnackbarSuccess, setSnackbarError]);

  const handleKeyPress = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key !== KeyboardKey.Enter || isSubmitDisabled) return;

      handleSubmit();
    },
    [handleSubmit, isSubmitDisabled]
  );

  return (
    <div onKeyDown={handleKeyPress}>
      <div className="mb-6 text-center text-white text-lg font-bold">
        Forgot your password
      </div>

      <div className="px-6 mb-6 text-center text-white text-base font-medium">
        {successfullyRequested
          ? "You will receive an email with instructions on how to reset your password."
          : "Submit your email address to receive a password reset."}
      </div>

      <div className="mb-9 flex flex-col space-y-4 md:portrait:space-y-5 lg:space-y-5">
        <LoginInput
          label="Email"
          placeholder="Enter your email"
          name="email"
          autoComplete="off"
          value={email || ""}
          onChange={({ target: { value } }) => setEmail(value.trim())}
          disabled={successfullyRequested}
        />

        <Link
          to={URLRoutes.Login}
          className="text-center text-pattensBlue text-base font-bold underline"
        >
          Back to Log In
        </Link>
      </div>

      <div className="text-center">
        <button
          className="rounded-3xl border-solid border-2 border-pattensBlue mb-9 w-36 py-3 bg-prussianBlue text-white text-base font-medium disabled:opacity-50"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          {successfullyRequested ? "Submitted" : "Submit"}
        </button>
      </div>

      {snackbarComponent}
    </div>
  );
};

export default ForgotPassword;
