import { generatePath } from "react-router-dom";
import axios from "config/axios";
import { API } from "enums/API";
import { IAccountFilters, ICreateAccount } from "interfaces/Account";

class AccountService {
  getDirectory = (params: Partial<IAccountFilters>, signal?: AbortSignal) => {
    return axios.get(API.AccountDirectory, { params, signal });
  };

  getOne = (id: string, signal?: AbortSignal) => {
    return axios.get(generatePath(API.AccountDetails, { id }), { signal });
  };

  create = (data: ICreateAccount) => {
    return axios.post(API.Account, data);
  };

  edit = (id: string, data: Partial<ICreateAccount>) => {
    return axios.put(generatePath(API.AccountDetails, { id }), data);
  };

  validateName = (params: { name: string }) => {
    return axios.get(API.AccountNameValidation, { params });
  };
}

export default new AccountService();
