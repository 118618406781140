import CircularProgress from "@mui/material/CircularProgress";

interface ILoading {
  loading: boolean;
}

const Loading = ({ loading }: ILoading) => {
  return loading ? (
    <div className="absolute top-0 left-0 !h-full !w-full flex justify-center items-center backdrop-blur-sm z-[999]">
      <CircularProgress />
    </div>
  ) : null;
};

export default Loading;
