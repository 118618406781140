import { useMemo } from "react";
import CardContainer from "components/CardContainer/CardContainer";
import Property from "components/Property/Property";
import { IProperty } from "interfaces/Common";
import { IOrderVersionDetails } from "interfaces/Order";
import { formatPhoneNumber } from "helpers/PatientHelper";

interface IOrderDetailsShippingCard {
  order: IOrderVersionDetails;
}

const OrderDetailsShippingCard = ({ order }: IOrderDetailsShippingCard) => {
  const leftColumnProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Address Line 1",
        value: order.meta?.shipping?.street1,
        required: true,
      },
      { property: "Address Line 2", value: order.meta?.shipping?.street2 },
      { property: "City", value: order.meta?.shipping?.city, required: true },
      {
        property: "State",
        value: order.meta?.shipping?.state,
        required: true,
      },
      { property: "ZIP", value: order.meta?.shipping?.zip, required: true },
    ],
    [order.meta?.shipping]
  );

  const rightColumnProperties = useMemo(
    (): IProperty[] => [
      { property: "Phone", value: formatPhoneNumber(order.meta?.phone ?? "") },
      { property: "Email", value: order.meta?.email },
    ],
    [order.meta]
  );

  return (
    <CardContainer
      title="Shipping"
      childrenClassName="flex flex-wrap flex-col gap-5 md:flex-row [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]"
    >
      {[leftColumnProperties, rightColumnProperties].map(
        (properties, index) => (
          <div key={index} className="flex flex-col gap-5">
            {properties.map((property) => (
              <Property key={property.property} {...property} />
            ))}
          </div>
        )
      )}
    </CardContainer>
  );
};

export default OrderDetailsShippingCard;
