import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { IDropdownOption } from "interfaces/Common";
import { BillingStatus } from "enums/Billing";
import { getBillingStatusLabel } from "helpers/BillingHelper";
import styles from "./BillingStatusDropdown.module.scss";

interface BillingStatusDropdownProps {
  isInCell?: boolean;
  value: BillingStatus | undefined;
  onChange: (value: BillingStatus | undefined) => void;
}

const options: IDropdownOption<BillingStatus>[] = [
  BillingStatus.Unprocessed,
  BillingStatus.ClaimSubmitted,
  BillingStatus.ClaimResubmitted,
  BillingStatus.NotBilledNoTests,
  BillingStatus.NotBilledDemo,
  BillingStatus.NotBilledOther,
].map((x) => ({ value: x, label: getBillingStatusLabel(x) }));

const BillingStatusDropdown = (props: BillingStatusDropdownProps) => {
  const { isInCell, value, onChange } = props;

  const getWrapperClassName = (): string => {
    switch (true) {
      case isInCell:
        return styles.cell_billing_dropdown_wrapper;
      case !value:
        return `${styles.filter_billing_dropdown_wrapper} ${styles.empty}`;
      default:
        return styles.filter_billing_dropdown_wrapper;
    }
  };

  return (
    <div className={getWrapperClassName()}>
      <Select
        displayEmpty
        renderValue={(value) =>
          value
            ? options.find((x) => x.value === value)?.label
            : "Select status"
        }
        MenuProps={{
          classes: { root: styles.menu_root },
        }}
        value={value ?? ""}
        onChange={(e) => onChange(Number(e.target.value))}
        variant={isInCell ? "outlined" : "standard"}
        endAdornment={
          !isInCell &&
          value && (
            <InputAdornment position="end">
              <IconButton onClick={() => onChange(undefined)}>
                <span className="icon_svg icon_cross" />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default BillingStatusDropdown;
