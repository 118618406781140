import { useEffect, useState } from "react";
import axios from "axios";
import { IBalanceTrendsData, ITrendFilters } from "interfaces/Patient";
import TestService from "services/TestService";
import Loading from "components/Loading/Loading";
import TrendsChart from "components/TrendsChart/TrendsChart";
import { formatEyesLabel, formatFeetLabel } from "helpers/TestHelper";
import { formatDashDate } from "helpers/CommonHelper";
import { BalanceMetric, TestType } from "enums/Test";
import OverallChangeBox from "components/PatientDashboard/OverallChangeBox/OverallChangeBox";
import { getRollupLabel } from "helpers/TrendsAndUsageHelper";
import NoTestsBox from "components/NoTestsBox/NoTestsBox";

interface IBalanceTrendsProps {
  filters: ITrendFilters;
  onError: () => void;
}

const BalanceTrends = ({ filters, onError }: IBalanceTrendsProps) => {
  const { rollup } = filters;

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IBalanceTrendsData | undefined>(undefined);

  const noData = loading ? false : !data?.length;
  // Open Eyes charts should be shown first
  const sortedData = data ? data.sort((a, b) => a.eyes - b.eyes) : undefined;

  useEffect(() => {
    setLoading(true);

    const abortController = new AbortController();

    TestService.getTrendsData(
      {
        ...filters,
        testId: TestType.Balance,
        metricId: BalanceMetric.BalanceScore,
      },
      abortController.signal
    )
      .then(({ data }) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;

        setLoading(false);
        onError();
      });

    return () => {
      setData(undefined);
      abortController.abort();
    };
  }, [filters, onError]);

  return loading || noData ? (
    <div className="border-[3px] border-pattensBlue3 rounded-[20px] overflow-hidden">
      <div className="bg-white h-[44px] flex flex-col justify-between gap-3 xl:items-center sm:flex-row">
        <p className="pl-5 pt-3 xl:pt-0">Balance Trends</p>
      </div>
      <div className="bg-solitude relative h-[250px] sm:h-[342px]">
        {loading ? <Loading loading /> : <NoTestsBox />}
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-7 pb-5">
      {sortedData?.map(
        ({ trends, eyes, feet, from, to, count, trend }, index) => (
          <div key={index} className="shrink-0 flex flex-col gap-5">
            <div className="flex gap-5">
              <div className="grow w-0 shrink-0 border-[3px] border-pattensBlue3 rounded-[20px] overflow-hidden">
                <div className="bg-white flex flex-col justify-between gap-3 xl:items-center sm:flex-row md:h-[44px]">
                  <p className="pl-5 pt-3 xl:pt-0">
                    {`Balance Trends - ${formatFeetLabel(
                      feet
                    )}, Eyes ${formatEyesLabel(eyes)}: `}
                    <span className="font-medium">
                      Average Score Per{" "}
                      <span className="uppercase underline">
                        {getRollupLabel(rollup)}
                      </span>
                    </span>
                  </p>
                </div>
                <div className="bg-solitude relative h-[250px] sm:h-[342px]">
                  <TrendsChart data={trends} rollup={rollup} />
                </div>
              </div>

              <OverallChangeBox value={trend} />
            </div>

            <div className="flex flex-col justify-between gap-3 sm:flex-row">
              <p className="text-lg text-cloudBurst/80 font-medium leading-5 flex flex-col lg:flex-row lg:gap-1">
                Testing Date Range:
                <span className="font-bold text-cloudBurst">
                  {`${formatDashDate(from)} - ${formatDashDate(to)}`}
                </span>
              </p>

              <div className="flex flex-col sm:items-end">
                <p className="text-lg text-cloudBurst/80 font-medium underline">
                  TESTS RECORDED:
                </p>
                <p className="text-lg text-cloudBurst font-bold">{count}</p>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default BalanceTrends;
