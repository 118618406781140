import { useMemo } from "react";
import { IDropdownOption } from "interfaces/Common";
import { RollupInterval } from "enums/Test";
import Dropdown from "components/Dropdowns/Dropdown/Dropdown";

interface ReportingIntervalDropdownProps {
  disabled: boolean;
  selected: RollupInterval | undefined;
  onChange: (value: RollupInterval | undefined) => void;
}

const ReportingIntervalDropdown = (props: ReportingIntervalDropdownProps) => {
  const options = useMemo(
    (): IDropdownOption<RollupInterval>[] => [
      {
        label: "Daily",
        value: RollupInterval.Day,
      },
      {
        label: "Weekly",
        value: RollupInterval.Week,
      },
      {
        label: "Monthly",
        value: RollupInterval.Month,
      },
      // hidden year option until implemented on API side
      // {
      //   label: "Yearly",
      //   value: RollupInterval.Year,
      // },
    ],
    []
  );

  return (
    <Dropdown
      showRadioButton
      label="Select reporting interval"
      options={options}
      {...props}
    />
  );
};

export default ReportingIntervalDropdown;
