import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import axios from "axios";
import InputField from "components/InputField/InputField";
import Button from "components/Buttons/Button/Button";
import { DeepPartial, IDropdownOption } from "interfaces/Common";
import useValidationErrors from "hooks/useValidationErrors";
import { IAccount, ICreateAccount } from "interfaces/Account";
import { InputFieldLength } from "enums/Common";
import CheckboxInput from "components/CheckboxInput/CheckboxInput";
import AccountService from "services/AccountService";
import { TestType } from "enums/Test";
import ProductsDropdown from "components/Dropdowns/ProductsDropdown/ProductsDropdown";
import styles from "./AccountForm.module.scss";
import BillingDropdown from "components/Dropdowns/BillingDropdown/BillingDropdown";
import { Billing, Products } from "enums/Account";
import { getAllTestTypes } from "helpers/TestHelper";

interface AccountFormProps {
  accountId?: string;
  onConfirm: () => void;
  onSuccess: (message: string) => void;
  onError: () => void;
}

const inClinicTests = [
  TestType.Angles,
  TestType.FreeformAngles,
  TestType.Tremor,
  TestType.Cognitive,
  TestType.Symmetry,
];

// on create, no initial data will be passed, so we need to make all params optional
type PartialAccount = DeepPartial<ICreateAccount>;

const AccountForm = (props: AccountFormProps) => {
  const { accountId, onConfirm, onSuccess, onError } = props;

  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const [initialData, setInitialData] = useState<IAccount>();
  const [accountData, setAccountData] = useState<PartialAccount>(
    accountId
      ? {}
      : {
          enabledTests: [TestType.Gait, TestType.Balance], // preselect Gait and Balance when creating new account
          products: [Products.AtHome], // preselect At Home product when creating new account
        }
  );

  const disableInClinicTests = !accountData.products?.includes(
    Products.InClinic
  );

  const testOptions: IDropdownOption<TestType>[] = getAllTestTypes().map((x) =>
    inClinicTests.includes(x.value)
      ? {
          ...x,
          label: `${x.label} (at Clinic only)`,
          disabled: inClinicTests.includes(x.value)
            ? disableInClinicTests
            : false,
        }
      : x
  );

  useEffect(() => {
    if (!accountId) return;

    const controller = new AbortController();

    AccountService.getOne(accountId, controller.signal)
      .then(({ data }) => {
        setInitialData(data);
        setAccountData(data);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        onError();
      });

    return () => controller.abort();
  }, [accountId, onError]);

  const noChangedData = useMemo((): boolean => {
    // sort arrays because isEqual will return false for arrays with same values but on different positions
    const sortedInitialData: PartialAccount = {
      ...initialData,
      products: initialData?.products.sort(),
      enabledTests: initialData?.enabledTests.sort(),
    };
    const sortedEnteredData: PartialAccount = {
      ...accountData,
      products: accountData.products?.sort(),
      enabledTests: accountData.enabledTests?.sort(),
    };

    return isEqual(sortedInitialData, sortedEnteredData);
  }, [initialData, accountData]);

  const disableSubmit = useMemo(
    (): boolean =>
      hasErrors ||
      noChangedData ||
      !accountData?.billable ||
      // one of [InClinic,Remote,AtHome] has to be selected
      !accountData.products?.filter((x) => x !== Products.Research).length ||
      !accountData.enabledTests?.length,
    [hasErrors, noChangedData, accountData]
  );

  const handleInputChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAccountData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const handleEdit = () => {
    if (
      !accountData?.name ||
      !accountData.shortName ||
      !accountData.products ||
      !accountData.enabledTests ||
      !accountData.billable ||
      !accountId
    )
      return;

    const { name, shortName, products, enabledTests, billable, customPolicy } =
      accountData;

    const data: ICreateAccount = {
      name,
      shortName,
      products,
      enabledTests,
      billable,
      customPolicy: { isWorkerComp: customPolicy?.isWorkerComp ?? false },
    };

    AccountService.edit(accountId, data)
      .then(() => {
        onConfirm();
        onSuccess("Account has been edited successfully.");
      })
      .catch(() => onError());
  };

  const handleCreate = () => {
    if (
      !accountData?.name ||
      !accountData.shortName ||
      !accountData.products ||
      !accountData.enabledTests ||
      !accountData.billable
    )
      return;

    const { name, shortName, products, enabledTests, billable, customPolicy } =
      accountData;

    const data: ICreateAccount = {
      name,
      shortName,
      products,
      enabledTests,
      billable,
      customPolicy: { isWorkerComp: customPolicy?.isWorkerComp ?? false },
    };

    AccountService.create(data)
      .then(() => {
        onConfirm();
        onSuccess("Account has been created successfully.");
      })
      .catch(() => onError());
  };

  const handleTestSelection = (testId: TestType) => {
    setAccountData((prev) => ({
      ...prev,
      enabledTests: prev?.enabledTests?.includes(testId)
        ? prev.enabledTests.filter((x) => x !== testId)
        : [...(prev?.enabledTests ?? []), testId],
    }));
  };

  const handleProductsChange = (newProducts: Products[]) => {
    setAccountData((prev) => {
      let newTests: TestType[] | undefined;

      const clinicInNewValue = newProducts.includes(Products.InClinic);
      const clinicInPrevValue = prev.products?.includes(Products.InClinic);

      const inCinicAdded = !clinicInPrevValue && clinicInNewValue;
      const inCinicRemoved = clinicInPrevValue && !clinicInNewValue;
      const emptyProductsPreviously = !prev.products?.length;

      switch (true) {
        case inCinicAdded:
          // select all tests when In Clinic product is added
          newTests = testOptions.map((x) => x.value);
          break;
        case inCinicRemoved:
          // remove in clinic tests when In Clinic product is removed
          newTests = prev.enabledTests?.filter(
            (x) => !inClinicTests.includes(x)
          );
          break;
        case emptyProductsPreviously:
          // select only Gait and Balance if previously there wasn't selected products
          newTests = [TestType.Gait, TestType.Balance];
          break;
        default:
          newTests = prev.enabledTests;
      }

      return { ...prev, products: newProducts, enabledTests: newTests };
    });
  };

  const validateName = useCallback((value: string) => {
    return AccountService.validateName({ name: value });
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-12px)] md:justify-between overflow-auto">
        <div className="flex flex-col gap-5">
          <InputField
            required
            label="Account Name"
            placeholder="Enter account name"
            name="name"
            value={accountData?.name}
            initialValue={initialData?.name}
            validateInput={validateName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <InputField
            required
            label="Short Name"
            placeholder="Enter short name"
            name="shortName"
            maxLength={InputFieldLength.AccountShortNameMax}
            value={accountData?.shortName}
            onChange={handleInputChange}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <ProductsDropdown
            selected={accountData?.products}
            onChange={handleProductsChange}
          />
          <BillingDropdown
            value={accountData?.billable}
            onChange={(billable) =>
              setAccountData((prev) => ({
                ...prev,
                billable,
                // reset compensation checkbox on billing change
                customPolicy: { isWorkerComp: false },
              }))
            }
          />
          <div className="w-full md:w-4/5">
            <CheckboxInput
              label={`Enable Workers Compensation\nInsurance Fields`}
              disabled={accountData?.billable !== Billing.Billable}
              value={accountData?.customPolicy?.isWorkerComp ?? false}
              onChange={(isWorkerComp) =>
                setAccountData((prev) => ({
                  ...prev,
                  customPolicy: { isWorkerComp },
                }))
              }
            />
          </div>
        </div>

        <div>
          <label>Tests *</label>
          <div className={`flex flex-col ${styles.account_form_tests_wrapper}`}>
            {testOptions.map(({ value, label, disabled }) => (
              <CheckboxInput
                key={value}
                disabled={disabled}
                label={label}
                value={accountData?.enabledTests?.includes(value) ?? false}
                onChange={() => handleTestSelection(value)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="self-end flex w-full justify-end">
        <Button
          disabled={disableSubmit}
          onClick={accountId ? handleEdit : handleCreate}
        >
          {accountId ? "Save Changes" : "Create New Account"}
        </Button>
      </div>
    </div>
  );
};

export default AccountForm;
