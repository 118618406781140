import { useEffect, useMemo, useState } from "react";
import { useSensorViewLayoutContext } from "../../components/Layouts/SensorViewLayout/SensorViewLayout";
import SensorDetailsCard from "../../components/SensorDetailsCard/SensorDetailsCard";
import { ISensorDetails } from "../../interfaces/Sensor";
import EditSensorCard from "../../components/EditSensorCard/EditSensorCard";
import ShipSensorCard from "../../components/ShipSensorCard/ShipSensorCard";
import TrackingInfoCard from "../../components/TrackingInfoCard/TrackingInfoCard";
import { formatName } from "../../helpers/CommonHelper";
import {
  EditSensorAction,
  SensorHistoryAction,
  SensorStatus,
} from "../../enums/Sensor";
import useSnackbar from "../../hooks/useSnackbar";

const SensorDetails = () => {
  // initial sensor data
  const { sensor, refreshSensorData } = useSensorViewLayoutContext();
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [isShipActive, setIsShipActive] = useState<boolean>(false);
  const [isTrackingInfoActive, setIsTrackingInfoActive] =
    useState<boolean>(false);
  const [editedData, setEditedData] = useState<ISensorDetails | undefined>(
    sensor
  );

  const activeEditAction = useMemo((): EditSensorAction | undefined => {
    if (!sensor || !editedData) return undefined;

    switch (true) {
      case sensor.patientId === null && editedData.patientId !== null:
        return SensorHistoryAction.Assign;
      case sensor.patientId !== null && editedData.patientId === null:
        return SensorHistoryAction.Unassign;
      case sensor.statusId !== editedData.statusId:
        return SensorHistoryAction.StatusUpdate;
    }
  }, [sensor, editedData]);

  useEffect(() => {
    setEditedData(sensor);
  }, [sensor]);

  useEffect(() => {
    if (!sensor) return;

    // open ship card, once ready for shipment (sensor is assigned and has in warehouse status)
    setIsShipActive(
      Boolean(sensor.patientId && sensor.statusId === SensorStatus.InWarehouse)
    );
  }, [sensor]);

  return (
    <div className="pb-5 h-full overflow-auto flex flex-col gap-6 pr-5 lg:flex-row lg:items-start xl:pr-0">
      {sensor && editedData && (
        <>
          <SensorDetailsCard
            disabled={
              Boolean(activeEditAction) || isShipActive || isTrackingInfoActive
            }
            initiallyAssignedTo={
              sensor.patientId
                ? formatName({
                    firstName: sensor.patientFn,
                    lastName: sensor.patientLn,
                  })
                : undefined
            }
            data={editedData}
            onChange={(value) =>
              setEditedData((prev) => ({ ...prev!, ...value }))
            }
            onOpenTrackingInfoClick={() => setIsTrackingInfoActive(true)}
            onShipSensorClick={() => setIsShipActive(true)}
          />

          {activeEditAction && (
            <EditSensorCard
              action={activeEditAction}
              initialData={sensor}
              editedData={editedData}
              // reset data to initial on cancel
              onCancel={() => setEditedData(sensor)}
              onSuccess={refreshSensorData}
              onFail={setSnackbarError}
            />
          )}

          {isShipActive && (
            <ShipSensorCard
              sensor={sensor}
              onCancel={() => setIsShipActive(false)}
              onSuccess={refreshSensorData}
              onError={setSnackbarError}
            />
          )}

          {isTrackingInfoActive && (
            <TrackingInfoCard
              sensor={sensor}
              onClose={() => setIsTrackingInfoActive(false)}
              onSuccess={refreshSensorData}
              onError={setSnackbarError}
            />
          )}
        </>
      )}

      {snackbarComponent}
    </div>
  );
};

export default SensorDetails;
