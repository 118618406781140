import { useMemo, useState } from "react";
import CheckboxInput from "components/CheckboxInput/CheckboxInput";
import { Eyes, Feet } from "enums/Test";
import { IBalanceTestItem } from "interfaces/Order";
import { formatFeetLabel } from "helpers/TestHelper";
import ConfirmationDialog from "components/Modals/ConfirmationDialog/ConfirmationDialog";
import Switch from "components/Switch/Switch";

interface BalanceParameterInputProps {
  feet: Feet;
  tests: IBalanceTestItem[];
  onChange: (value: IBalanceTestItem[]) => void;
}

const BalanceParameterInput = (props: BalanceParameterInputProps) => {
  const { feet, tests, onChange } = props;

  const [isEyesClosedConfirmationOpen, setIsEyesClosedConfirmationOpen] =
    useState<boolean>(false);

  const currentFeetTests = tests.filter(({ params }) => params.feet === feet);
  const isSelected = Boolean(currentFeetTests.length);

  const eyesOptions = useMemo(
    (): { label: string; value: Eyes }[] => [
      {
        label: "Eyes Open",
        value: Eyes.Open,
      },
      {
        label: "Eyes Closed",
        value: Eyes.Closed,
      },
    ],
    []
  );

  const showError = useMemo(
    (): boolean =>
      isSelected &&
      !currentFeetTests.some(({ params: { eyes } }) => Boolean(eyes)),
    [isSelected, currentFeetTests]
  );

  const handleAddEyes = (value: Eyes): void => {
    const newValue = [
      ...tests.filter(({ params }) => !(params.feet === feet && !params.eyes)),
      { params: { feet, eyes: value } },
    ];
    onChange(newValue);
  };

  const handleRemoveEyes = (value: Eyes): void => {
    const newValue = tests.filter(
      ({ params }) => !(params.feet === feet && params.eyes === value)
    );
    onChange(newValue);
  };

  const handleEyesChange = (checked: boolean, value: Eyes): void => {
    // show confirmation dialog when user wants to add Eyes Closed test
    if (checked && value === Eyes.Closed) {
      setIsEyesClosedConfirmationOpen(true);
      return;
    }

    if (checked) {
      handleAddEyes(value);
    } else {
      handleRemoveEyes(value);
    }
  };

  const handleConfirmEyesClosedDialog = (): void => {
    handleAddEyes(Eyes.Closed);
    setIsEyesClosedConfirmationOpen(false);
  };

  return (
    <>
      <div className="flex flex-col justify-between gap-5 py-2.5 border-b border-pelorous/30 last:border-b-0 last:pb-0 md:portrait:flex-row lg:flex-row">
        <CheckboxInput
          isTestParamInput
          label={formatFeetLabel(feet)}
          value={isSelected}
          onChange={(checked) =>
            // add or remove this param, depending of checked value
            onChange(
              checked
                ? [...tests, { params: { feet } }]
                : tests.filter(({ params }) => params.feet !== feet)
            )
          }
        />

        <div
          className={`flex gap-4 relative ${
            // disable switches if param isn't selected
            !isSelected ? "opacity-50 pointer-events-none" : ""
          } ${
            showError
              ? "after:content-[''] after:absolute after:-bottom-[11px] after:left-0 after:right-0 after:bg-crimson after:h-0.5"
              : ""
          }`}
        >
          {eyesOptions.map(({ label, value }) => (
            <Switch
              key={label}
              className="flex-row-reverse"
              label={label}
              checked={currentFeetTests.some(
                ({ params: { eyes } }) => eyes === value
              )}
              onChange={(checked) => handleEyesChange(checked, value)}
            />
          ))}
        </div>
      </div>

      {isEyesClosedConfirmationOpen && (
        <ConfirmationDialog
          title="Eyes Closed Testing"
          content="Patient may be unsupervised, are you sure you want to order Eyes
          Closed Balance testing?"
          onConfirm={handleConfirmEyesClosedDialog}
          onClose={() => setIsEyesClosedConfirmationOpen(false)}
        />
      )}
    </>
  );
};

export default BalanceParameterInput;
