import { useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useIdleTimer } from "react-idle-timer";
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import biomechLogo from "assets/images/biomech_logo.svg";
import backgroundArc from "assets/images/background_arc.svg";
import { Auth, AccountAuth } from "recoil-states/auth-states";
import AuthService from "services/AuthService";
import { URLRoutes } from "enums/Routing";
import { checkRefreshTokenExpiration } from "helpers/CommonHelper";
import LocalStorageService from "services/LocalStorageService";
import styles from "./ProtectedLayout.module.scss";

const IDLE_TIME = 120_000; // 2 minutes

const ProtectedLayout = () => {
  const navigate = useNavigate();

  const auth = useRecoilValue(Auth);
  const [accountAuth, setAccountAuth] = useRecoilState(AccountAuth);

  // check expiration when user goes idle and is back active
  useIdleTimer({
    timeout: IDLE_TIME,
    onIdle: checkRefreshTokenExpiration,
    onActive: checkRefreshTokenExpiration,
  });

  const signedInLabel = useMemo(
    () => (accountAuth ?? auth)?.initialData?.user.clientName,
    [auth, accountAuth]
  );

  const logoutAccount = () => {
    AuthService.logout().then(() => {
      LocalStorageService.removeAccountAuth();
      setAccountAuth(undefined);
      navigate(URLRoutes.Accounts);
    });
  };

  return (
    <div className="flex flex-col h-full md:portrait:flex-row lg:flex-row">
      {/* hidde on mobile devices */}
      <div className="w-40 hidden md:portrait:block lg:block">
        <Sidebar showGradientLine />
      </div>

      {/* show on mobile devices */}
      <div className="md:portrait:hidden lg:hidden">
        <Header />
      </div>

      <div className="relative h-full grow flex flex-col md:portrait:w-0 lg:w-0">
        <div
          className={`grow h-0 relative pl-5 pt-6 xl:pr-5 ${styles.outlet_wrapper}`}
        >
          <div
            style={{ backgroundImage: `url(${backgroundArc})` }}
            className="hidden -z-10 opacity-20 absolute left-0 top-0 w-11/12 h-full bg-no-repeat bg-cover bg-right md:block md:portrait:w-full md:portrait:h-1/2 xl:w-3/4"
          />
          <img
            className="hidden w-52 absolute left-24 top-32 opacity-20 md:block md:max-lg:landscape:-translate-y-10 md:portrait:w-auto md:portrait:top-1/2 lg:top-1/2 md:portrait:-translate-y-1/2 lg:-translate-y-1/2 lg:w-auto xg:left-40 -z-10"
            src={biomechLogo}
            alt="logo"
          />
          <Outlet />
        </div>

        <div
          className={`flex items-center pl-3 border-2 border-white text-white text-xs sm:flex-row sm:portrait:flex-col justify-between md:portrait:border-l-0 md:portrait:-ml-[1px] lg:border-l-0 lg:-ml-[1px] ${
            accountAuth ? "bg-crimson" : "bg-endeavour py-1.5"
          }`}
        >
          <p>Signed in as {auth?.initialData?.user.name}</p>
          <p className="font-bold">{signedInLabel}</p>
          <div className="shrink-0 w-40">
            {accountAuth && (
              <button
                className="w-full flex justify-center items-center gap-1.5 bg-cloudBurst text-lg"
                onClick={logoutAccount}
              >
                <span className={`icon_svg icon_logout`} />
                Switch back
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectedLayout;
