import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import isEmail from "validator/lib/isEmail";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AuthService from "services/AuthService";
import LoginInput from "components/LoginInput/LoginInput";
import { ILoginData, IAuth, IAuthInitialData } from "interfaces/Auth";
import { KeyboardKey, ValidationLoginError } from "enums/Common";
import LocalStorageService from "services/LocalStorageService";
import { URLRoutes } from "enums/Routing";
import UserService from "services/UserService";
import { Auth } from "recoil-states/auth-states";

const EMAIL_FIELD_NAME = "email";

const Login = () => {
  const [data, setData] = useState<ILoginData>({ email: "", password: "" });
  const [isLogInDisabled, setIsLoginDisabled] = useState<boolean>(true);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [isDataInvalid, setIsDataInvalid] = useState<
    ValidationLoginError | undefined
  >(undefined);

  const setAuth = useSetRecoilState(Auth);

  useEffect(() => {
    setIsLoginDisabled(!(isEmail(data.email) && data.password.length));
  }, [data]);

  const handleDataChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: name === EMAIL_FIELD_NAME ? value.trim() : value,
    }));
    setIsDataInvalid(undefined);
  }, []);

  const handleLogin = useCallback(() => {
    setIsLoginDisabled(true);

    AuthService.login(data)
      .then(
        ({ data: authData }: { data: Omit<IAuth, "initialData"> }) => authData
      )
      .then(
        (authData) =>
          // send token with this API, since at this point local storage is empty and token will not be attached with interceptor
          // after that we can combine login and initial-data in one state
          new Promise<{
            authData: IAuth;
            initialData: IAuthInitialData;
          }>((resolve, reject) => {
            UserService.getUserInitialData(authData.token)
              .then(({ data: initialData }: { data: IAuthInitialData }) =>
                resolve({ authData, initialData })
              )
              .catch((e) => reject(e));
          })
      )
      .then(({ authData, initialData }) => {
        const auth: IAuth = { ...authData, initialData };
        LocalStorageService.setAuth(auth);
        setAuth(auth);
      })
      .catch((e) => {
        setIsDataInvalid(
          e.response.data.code === 101
            ? ValidationLoginError.Forbidden
            : ValidationLoginError.Invalid
        );
      });
  }, [data, setAuth]);

  const validationLoginErrorMessage = useMemo(() => {
    switch (isDataInvalid) {
      case ValidationLoginError.Invalid:
        return "Your email or password is incorrect.";
      case ValidationLoginError.Forbidden:
        return "Website access granted to Clinicians only.";
      default:
        return "";
    }
  }, [isDataInvalid]);

  const handleKeyPress = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key !== KeyboardKey.Enter || isLogInDisabled) return;

      handleLogin();
    },
    [isLogInDisabled, handleLogin]
  );

  return (
    <div onKeyDown={handleKeyPress}>
      <div className="mb-6 text-center text-white text-lg font-bold">
        Log into your account
      </div>

      <div className="mb-9 flex flex-col space-y-4 md:portrait:space-y-5 lg:space-y-5">
        <LoginInput
          label="Email"
          placeholder="Enter your email"
          name={EMAIL_FIELD_NAME}
          value={data.email}
          onChange={handleDataChange}
          error={Boolean(validationLoginErrorMessage)}
        />

        <LoginInput
          label="Password"
          placeholder="Enter your password"
          name="password"
          type={isPasswordShown ? "text" : "password"}
          value={data.password}
          onChange={handleDataChange}
          error={Boolean(validationLoginErrorMessage)}
          InputProps={{
            endAdornment: data.password.length ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsPasswordShown((prev) => !prev)}>
                  <span
                    className={`icon_svg ${
                      isPasswordShown
                        ? "icon_visibility"
                        : "icon_visibility_off"
                    }`}
                  />
                </IconButton>
              </InputAdornment>
            ) : (
              ""
            ),
          }}
        />

        <Link
          to={URLRoutes.ForgotPassword}
          className="text-center text-pattensBlue text-base font-bold underline"
        >
          Forgot password?
        </Link>
      </div>

      <div className="text-center">
        <button
          className="rounded-3xl border-solid border-2 border-pattensBlue mb-1.5 px-12 py-3 bg-prussianBlue text-white text-base font-medium disabled:opacity-50"
          disabled={isLogInDisabled}
          onClick={handleLogin}
        >
          Log In
        </button>

        <div className="h-6 mb-1.5 text-white text-base font-medium">
          {validationLoginErrorMessage}
        </div>

        <p className="text-base leading-5 text-white/80 font-medium">
          By continuing to use the app, you accept BioMech's{" "}
          <a
            className="text-white font-bold underline"
            target="_blank"
            rel="noreferrer"
            href="https://s3.amazonaws.com/clinical-prod-app/support/TermsOfUse.pdf"
          >
            terms of use
          </a>{" "}
          and{" "}
          <a
            className="text-white font-bold underline"
            target="_blank"
            rel="noreferrer"
            href="https://s3.amazonaws.com/clinical-prod-app/support/PrivacyPolicy.pdf"
          >
            privacy policy.
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
