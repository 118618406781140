import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import isJSON from "validator/lib/isJSON";
import { LocalStorage } from "enums/Common";
import { Auth, AccountAuth } from "recoil-states/auth-states";

/* 
this hook syncs login, logout and account switch between open tabs
*/
const useStorageChange = () => {
  const setAuth = useSetRecoilState(Auth);
  const setAccountAuth = useSetRecoilState(AccountAuth);

  const handleStorageChange = useCallback(
    ({ key, newValue }: StorageEvent) => {
      // Auth change
      if (key === LocalStorage.Auth) {
        if (newValue && isJSON(newValue)) {
          setAuth(JSON.parse(newValue));
        } else {
          setAuth(undefined);
          setAccountAuth(undefined);
        }
      }
      //   Account Auth change
      else if (key === LocalStorage.AccountAuth) {
        if (newValue && isJSON(newValue)) {
          setAccountAuth(JSON.parse(newValue));
        } else {
          setAccountAuth(undefined);
        }
      }
    },
    [setAuth, setAccountAuth]
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, [handleStorageChange]);
};

export default useStorageChange;
