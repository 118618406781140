import { Dispatch, SetStateAction } from "react";
import { Gender, PatientsOperationsSection } from "../enums/Patient";
import PatientService from "../services/PatientService";
import { IBasePatient, IPatient } from "../interfaces/Patient";
import { formatDashDate } from "./CommonHelper";

export const formatGender = (gender: Gender) => {
  switch (gender) {
    case Gender.Male:
      return "Male";
    case Gender.Female:
      return "Female";
    case Gender.NonBinary:
      return "Non-Binary";
    case Gender.TransgenderMale:
      return "Transgender Male";
    case Gender.TransgenderFemale:
      return "Transgender Female";
    case Gender.Other:
      return "Other";
    case Gender.DoesNotWishToDisclose:
      return "Does not wish to disclose";
    default:
      return "-";
  }
};

export const removeNonNumericCharacters = (value: string): string => {
  return value.replace(/\D/g, "");
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    // Remove non-numeric characters
    const numericInput = removeNonNumericCharacters(phoneNumber);

    // Format the phone number as (XXX) XXX-XXXX
    const formattedNumber = numericInput.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  }
  return phoneNumber;
};

export const handleShortlistPatient = <T extends IBasePatient>({
  id,
  isShortlist,
  isShorlistFilterApplied,
  setData,
  onSuccess,
  onError,
}: {
  id: string;
  isShortlist: boolean;
  isShorlistFilterApplied?: boolean;
  setData: Dispatch<SetStateAction<T[]>>;
  onSuccess: (message: string) => void;
  onError: () => void;
}) => {
  (isShortlist
    ? PatientService.removeShortlist(id)
    : PatientService.shortlist(id)
  )
    .then(() => {
      // if shortlist filter is toogled remove patient from array
      if (isShorlistFilterApplied) {
        setData((prev) => prev.filter((p) => p.id !== id));
      }
      // if not, change shortlist value
      else {
        setData((prev) =>
          prev.map((patient) =>
            patient.id === id
              ? {
                  ...patient,
                  isShortlist: !isShortlist,
                }
              : patient
          )
        );
      }

      onSuccess(
        isShortlist
          ? "Patient successfully removed from the shortlist."
          : "Patient successfully added to the shortlist."
      );
    })
    .catch(() => {
      onError();
    });
};

export const getPatientsOperationsTableSections =
  (): PatientsOperationsSection[] => [
    PatientsOperationsSection.Information,
    PatientsOperationsSection.LabAtHomeOPS,
    PatientsOperationsSection.Support,
    PatientsOperationsSection.Billing,
  ];

export const getPatientInfoLabel = (
  patient: IPatient | undefined
): string | undefined => {
  if (!patient) return undefined;

  const { lastName, firstName, dob } = patient;

  return `${lastName}, ${firstName} (DOB:${formatDashDate(dob)})`;
};
