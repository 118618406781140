import { useMemo } from "react";
import CardContainer from "components/CardContainer/CardContainer";
import Property from "components/Property/Property";
import { IProperty } from "interfaces/Common";
import { IOrderVersionDetails } from "interfaces/Order";
import { formatName } from "helpers/CommonHelper";
import { getOrderTypeLabel } from "helpers/OrderHelper";

interface IOrderDetailsOrderCard {
  order: IOrderVersionDetails;
}

const OrderDetailsOrderCard = ({ order }: IOrderDetailsOrderCard) => {
  const { orderedByFname, orderedByLname, durationMonth, product, meta } =
    order;

  const orderProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Ordering Clinician",
        required: true,
        value:
          orderedByFname && orderedByLname
            ? `${orderedByLname}, ${orderedByFname}`
            : undefined,
      },
      {
        property: "Testing Duration (in months)",
        value: durationMonth,
        required: true,
      },
      {
        property: "Order type",
        value: getOrderTypeLabel(product),
        required: true,
      },
      ...(meta?.referringPhysician
        ? [
            {
              property: "Referring Physician",
              value: formatName(meta.referringPhysician),
            },
            {
              property: "Referring Physician NPI",
              value: meta?.referringPhysician.npi,
            },
          ]
        : []),
    ],
    [orderedByFname, orderedByLname, product, durationMonth, meta]
  );

  return (
    <CardContainer
      title="Order"
      childrenClassName="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]"
    >
      {orderProperties.map((property) => (
        <Property key={property.property} {...property} />
      ))}
    </CardContainer>
  );
};

export default OrderDetailsOrderCard;
