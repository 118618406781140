import { ReactNode } from "react";
import RadioSelection from "../RadioSelection/RadioSelection";
import TooltipInfoIcon from "../TooltipInfoIcon/TooltipInfoIcon";

interface RadioInputProps {
  label: string;
  required?: boolean;
  infoTooltipContent?: ReactNode;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
}

const RadioInput = (props: RadioInputProps) => {
  const { label, required, infoTooltipContent, value, onChange } = props;

  const error = required && typeof value === "undefined";

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2.5">
        {label && (
          <label
            className={`self-start text-base font-medium ${
              error ? "text-crimson" : "text-cloudBurst"
            }`}
          >{`${label}${required ? " *" : ""}`}</label>
        )}
        {infoTooltipContent && <TooltipInfoIcon content={infoTooltipContent} />}
      </div>
      <div className="h-10 flex items-center">
        <RadioSelection selected={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default RadioInput;
