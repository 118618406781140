import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { AccordionStyle, SlideTimeout } from "../../../enums/Common";
import { IBaseOrder } from "../../../interfaces/Order";
import ButtonCloseModal from "../../Buttons/ButtonCloseModal/ButtonCloseModal";
import Button from "../../Buttons/Button/Button";
import { ISensor } from "../../../interfaces/Sensor";
import Accordion from "../../Accordion/Accordion";
import DebouncedAutocomplete from "../../DebouncedAutocomplete/DebouncedAutocomplete";
import SensorService from "../../../services/SensorService";
import { formatSensorStatusLabel } from "../../../helpers/SensorHelper";
import Property from "../../Property/Property";
import TooltipBox from "../../TooltipBox/TooltipBox";
import { IProperty } from "../../../interfaces/Common";
import { formatDashDate } from "../../../helpers/CommonHelper";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { URLRoutes } from "../../../enums/Routing";
import { SensorFilterParams, SensorStatus } from "../../../enums/Sensor";
import Loading from "../../Loading/Loading";
import useSnackbar from "../../../hooks/useSnackbar";

interface AssignSensorModalProps {
  order: IBaseOrder;
  onClose: () => void;
  onSuccess: () => void;
}

const AssignSensorModal = ({
  order: { meta, patientFname, patientLname, patientDob, patientId },
  onClose,
  onSuccess,
}: AssignSensorModalProps) => {
  const navigate = useNavigate();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [sensor, setSensor] = useState<ISensor | null>(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const patientLeftColumnProperties = useMemo(
    (): IProperty[] => [
      { property: "Last Name", value: patientLname, required: true },
      { property: "First Name", value: patientFname, required: true },
      {
        property: "DOB",
        value: formatDashDate(patientDob),
        required: true,
      },
      { property: "Email", value: meta?.email },
      { property: "Phone", value: meta?.phone },
    ],
    [meta, patientFname, patientLname, patientDob]
  );

  const patientRightColumnProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Address Line 1",
        value: meta?.shipping?.street1,
        required: true,
      },
      { property: "Address Line 2", value: meta?.shipping?.street2 },
      { property: "City", value: meta?.shipping?.city, required: true },
      { property: "State", value: meta?.shipping?.state, required: true },
      { property: "ZIP", value: meta?.shipping?.zip, required: true },
    ],
    [meta?.shipping]
  );

  const handleAssign = () => {
    setLoading(true);

    SensorService.assign({ patientId: patientId!, sensorId: sensor!.id })
      .then(() => {
        onSuccess();
        setIsConfirmationOpen(true);
      })
      .catch(() => setSnackbarError())
      .finally(() => setLoading(false));
  };

  return (
    <Modal open onClose={onClose}>
      <Slide in direction="left" timeout={SlideTimeout.Default}>
        <div
          className={`py-4 px-8 flex flex-col gap-7 absolute top-2.5 right-0 bottom-0 left-0 bg-white rounded-t-md border border-cloudBurst shadow-xl outline-none lg:left-auto lg:w-[714px] ${
            isConfirmationOpen ? "opacity-0" : ""
          }`}
        >
          <ButtonCloseModal className="left-4 !top-6" onClick={onClose} />

          <div className="flex flex-col items-center gap-4">
            <h2 className="self-center text-lg leading-5 text-cloudBurst font-bold">
              Assign sensor to patient
            </h2>
            <Button
              className="sm:absolute sm:top-4 sm:right-8"
              disabled={!sensor}
              onClick={handleAssign}
            >
              Assign Sensor
            </Button>
          </div>

          <div className="grow h-0 flex flex-col gap-7 overflow-auto">
            <Accordion
              style={AccordionStyle.AssignSensorModal}
              summary="Sensor Details"
            >
              {!sensor ? (
                <div className="w-full  sm:w-[386px]">
                  <DebouncedAutocomplete<ISensor>
                    label="Sensor *"
                    placeholder="Search by Name or Mac address"
                    value={sensor}
                    onChange={setSensor}
                    getOptions={(value) =>
                      SensorService.getAll({
                        [SensorFilterParams.NameOrMacAddress]: value,
                        statusId: SensorStatus.Unassigned,
                      })
                    }
                    getOptionLabel={({ name }) => name}
                    renderOption={(props, { id, name, statusId }) => (
                      <li {...props} key={id}>
                        <div className="flex flex-col gap-1">
                          <span className="text-pelorous ">{name}</span>
                          <span>{formatSensorStatusLabel(statusId)}</span>
                        </div>
                      </li>
                    )}
                  />
                </div>
              ) : (
                <div className="flex flex-wrap gap-5 [&>div]:w-full [&>div]:h-[66px] md:[&>div]:!w-[calc(50%-10px)]">
                  <div>
                    <div className="w-fit relative">
                      <Property
                        required
                        property="Sensor"
                        value={sensor.name}
                      />
                      <TooltipBox content="Remove Sensor" offsetX={125}>
                        <span>
                          <ButtonCloseModal
                            className="!-right-9 bottom-1.5 !top-auto"
                            onClick={() => setSensor(null)}
                          />
                        </span>
                      </TooltipBox>
                    </div>
                  </div>

                  <Property
                    property="Sensor status"
                    value={formatSensorStatusLabel(sensor.statusId)}
                  />
                </div>
              )}
            </Accordion>

            <Accordion
              defaultExpanded={false}
              style={AccordionStyle.AssignSensorModal}
              summary="Patient Details"
            >
              <div className="flex gap-5 flex-wrap [&>div]:w-full md:order-none md:[&>div]:!w-[calc(50%-10px)]">
                <div className="flex flex-col gap-5 [&>div]:h-16">
                  {patientLeftColumnProperties.map((property) => (
                    <Property key={property.property} {...property} />
                  ))}
                </div>

                <div className="flex flex-col gap-5 [&>div]:min-h-[64px]">
                  {patientRightColumnProperties.map((property) => (
                    <Property key={property.property} {...property} />
                  ))}
                </div>
              </div>
            </Accordion>
          </div>

          <Loading loading={loading} />

          {isConfirmationOpen && (
            <ConfirmationModal
              title="Ship Sensor"
              message={`Sensor ${sensor?.name} successfully assigned to ${patientFname} ${patientLname}.\nDo you want to enter shipping information for this sensor right away?`}
              onCancel={onClose}
              onConfirm={() =>
                navigate(
                  generatePath(URLRoutes.SensorDetails, {
                    sensorId: sensor!.id,
                  })
                )
              }
            />
          )}

          {snackbarComponent}
        </div>
      </Slide>
    </Modal>
  );
};

export default AssignSensorModal;
