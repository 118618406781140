import { IProperty } from "../../interfaces/Common";
import RadioSelection from "../RadioSelection/RadioSelection";

const Property = ({ property, required, value }: IProperty) => {
  return (
    <div className="flex flex-col gap-1.5">
      <p className="self-start text-base leading-5 font-medium text-cloudBurst">{`${property}${
        required ? " *" : ""
      }`}</p>

      {/* show radio buttons for boolean value */}
      {typeof value === "boolean" ? (
        <RadioSelection selected={value} />
      ) : Array.isArray(value) ? (
        <div>
          {value.map((x, index) => (
            <p
              key={index}
              className="text-base left-5 font-medium text-pelorous"
            >
              {x}
            </p>
          ))}
        </div>
      ) : (
        <p className="text-base left-5 font-medium text-pelorous">
          {value || "-"}
        </p>
      )}
    </div>
  );
};

export default Property;
