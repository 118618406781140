import { ChangeEvent, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Button from "components/Buttons/Button/Button";
import CardContainer from "components/CardContainer/CardContainer";
import DebouncedAutocomplete from "components/DebouncedAutocomplete/DebouncedAutocomplete";
import InputField from "components/InputField/InputField";
import Property from "components/Property/Property";
import useValidationErrors from "hooks/useValidationErrors";
import { IClinician } from "interfaces/Clinician";
import ClinicianService from "services/ClinicianService";
import {
  CardsValidation,
  Duration,
  Clinician,
  ModalState,
  Product,
} from "recoil-states/create-order-states";
import { NPI } from "enums/Common";
import { OrderModalState } from "enums/Order";
import OrderTypeDropdown from "components/Dropdowns/OrderTypeDropdown/OrderTypeDropdown";

interface OrderCardProps {
  isClinicianPreselected: boolean;
}

const OrderCard = ({ isClinicianPreselected }: OrderCardProps) => {
  const { hasErrors, handleValidationErrorChange } = useValidationErrors();

  const modalState = useRecoilValue(ModalState);
  const [product, setProduct] = useRecoilState(Product);
  const [clinician, setClinician] = useRecoilState(Clinician);
  const [duration, setDuration] = useRecoilState(Duration);
  const [cardsValidation, setCardsValidation] = useRecoilState(CardsValidation);

  const isCardDisabled =
    !cardsValidation.patient || modalState === OrderModalState.InProgress;
  const isRefferingPhysicianEntered = Boolean(clinician?.referringPhysician);
  const isDurationValid = Boolean(duration && Number(duration) > 0);

  useEffect(() => {
    setCardsValidation((prev) => ({
      ...prev,
      order: clinician
        ? !hasErrors && isDurationValid && product !== undefined
        : false,
    }));
  }, [hasErrors, setCardsValidation, clinician, isDurationValid, product]);

  const handleChangeRefferingPhysicianInput = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    // reset, in case all fields are cleared
    if (clinician?.referringPhysician) {
      const {
        [name as keyof typeof clinician.referringPhysician]: prevValue,
        ...rest
      } = clinician.referringPhysician;
      if (!value && Object.values(rest).every((x) => !x)) {
        setClinician((prev) => ({ ...prev!, referringPhysician: undefined }));
        return;
      }
    }

    setClinician((prev) => ({
      ...prev!,
      referringPhysician: { ...prev?.referringPhysician, [name]: value },
    }));
  };

  return (
    <CardContainer
      className="p-5 relative"
      childrenClassName="flex flex-col gap-5"
      title="Order"
      disabled={isCardDisabled}
    >
      {clinician && !isClinicianPreselected && (
        <Button
          className="absolute top-5 right-5 border-2 border-pelorous !bg-white !text-pelorous"
          onClick={() => setClinician(null)}
        >
          Change Clinician
        </Button>
      )}

      <div className="flex flex-wrap gap-5 [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
        <OrderTypeDropdown selected={product} onChange={setProduct} />
      </div>

      <div className="flex flex-wrap items-start gap-5">
        <div className="w-[386px] h-[66px]">
          {!clinician ? (
            <DebouncedAutocomplete<IClinician>
              showEmptyError
              label="Ordering Clinician *"
              placeholder="Search by Clinician Name"
              value={clinician}
              onChange={setClinician}
              getOptions={(value) =>
                ClinicianService.getAll({
                  "lastName|firstName": value,
                })
              }
              getOptionLabel={({ lastName, firstName }) =>
                `${lastName}, ${firstName}`
              }
              renderOption={(props, { id, lastName, firstName, email }) => (
                <li {...props} key={id}>
                  <div className="flex flex-col gap-1">
                    <span className="text-pelorous ">
                      {`${lastName}, ${firstName}`}
                    </span>
                    <span>{email}</span>
                  </div>
                </li>
              )}
            />
          ) : (
            <Property
              required
              property="Ordering Clinician"
              value={`${clinician.lastName}, ${clinician.firstName}`}
            />
          )}
        </div>

        <div className="relative">
          <InputField
            required
            hideErrorMessage
            isNumericField
            className="w-20"
            label="Testing Duration"
            name="duration"
            error={!isDurationValid}
            value={duration}
            onChange={({ target: { value } }) => setDuration(value)}
            onValidationErrorChange={handleValidationErrorChange}
          />
          <span className="absolute -right-6 bottom-2.5 text-cloudBurst text-base font-medium">
            months
          </span>
        </div>

        {clinician && (
          <div className="w-full flex flex-col gap-1">
            <p className="text-pelorous text-base leading-5 font-medium">
              Referring Physician (if applicable)
            </p>

            <div className="w-full flex flex-wrap gap-5 [&>div]:grow">
              <InputField
                reduceFontSizeOnError
                required={isRefferingPhysicianEntered}
                label="First Name"
                placeholder="Enter first name"
                name="firstName"
                value={clinician.referringPhysician?.firstName ?? ""}
                onChange={handleChangeRefferingPhysicianInput}
                onValidationErrorChange={handleValidationErrorChange}
              />
              <InputField
                reduceFontSizeOnError
                required={isRefferingPhysicianEntered}
                label="Last Name"
                placeholder="Enter last name"
                name="lastName"
                value={clinician.referringPhysician?.lastName ?? ""}
                onChange={handleChangeRefferingPhysicianInput}
                onValidationErrorChange={handleValidationErrorChange}
              />
              <InputField
                isNumericField
                reduceFontSizeOnError
                type={NPI.InputType}
                disabled={!isRefferingPhysicianEntered}
                required={isRefferingPhysicianEntered}
                label="NPI Number"
                placeholder="Enter NPI number"
                name="npi"
                value={clinician.referringPhysician?.npi ?? ""}
                onChange={handleChangeRefferingPhysicianInput}
                onValidationErrorChange={handleValidationErrorChange}
              />
            </div>
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default OrderCard;
