import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import CardContainer from "components/CardContainer/CardContainer";
import { IProperty } from "interfaces/Common";
import Property from "components/Property/Property";
import { useClinicianViewLayoutContext } from "components/Layouts/ClinicianViewLayout/ClinicianViewLayout";
import Button from "components/Buttons/Button/Button";
import EditClinicianForm from "components/EditClinicianForm/EditClinicianForm";
import { IsClientAdmin } from "recoil-states/auth-states";
import useSnackbar from "hooks/useSnackbar";
import { IClinician } from "interfaces/Clinician";
import UserService from "services/UserService";

const ClinicianProfile = () => {
  const { clinician, setClinician } = useClinicianViewLayoutContext();

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [isEditActive, setIsEditActive] = useState<boolean>(false);

  const isClientAdmin = useRecoilValue(IsClientAdmin);

  const leftColumnProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Last Name",
        value: clinician?.lastName,
        required: true,
      },
      {
        property: "First Name",
        value: clinician?.firstName,
        required: true,
      },
      {
        property: "Middle Name/Initial",
        value: clinician?.middleName,
      },
    ],
    [clinician]
  );

  const rightColumnProperties = useMemo(
    (): IProperty[] => [
      {
        property: "Email",
        value: clinician?.email,
        required: true,
      },
    ],
    [clinician?.email]
  );

  const handleEdit = useCallback(
    (data: IClinician) => {
      // we can use PUT user endpoint since clinician is user with specific type
      UserService.updateUser(data.id, {
        ...data,
        middleName: data.middleName ?? undefined,
      })
        .then(() => {
          setSnackbarSuccess("Clinician has been edited successfully.");
          setClinician(data);
          setIsEditActive(false);
        })
        .catch(() => setSnackbarError());
    },
    [setClinician, setSnackbarError, setSnackbarSuccess]
  );

  return (
    <div className="max-h-full overflow-auto w-full pr-5 lg:w-[728px] xl:pr-0">
      <CardContainer
        title="Clinician Information"
        className="relative px-7 py-5"
        childrenClassName="flex flex-col gap-5"
      >
        {isClientAdmin && !isEditActive && (
          <Button
            disabled={!clinician}
            className="self-center !bg-white !text-pelorous border-2 border-pelorous md:absolute md:top-5 md:right-5"
            onClick={() => setIsEditActive(true)}
          >
            Edit Clinician
          </Button>
        )}

        {isEditActive && clinician ? (
          <EditClinicianForm
            initialData={clinician}
            onConfirm={handleEdit}
            onCancel={() => setIsEditActive(false)}
          />
        ) : (
          <div className="flex flex-col gap-5 md:flex-row [&>div]:w-full md:[&>div]:!w-[calc(50%-10px)]">
            {[leftColumnProperties, rightColumnProperties].map(
              (properties, index) => (
                <div key={index} className="flex flex-col gap-5 [&>div]:h-16">
                  {properties.map((property) => (
                    <Property key={property.property} {...property} />
                  ))}
                </div>
              )
            )}
          </div>
        )}
      </CardContainer>

      {snackbarComponent}
    </div>
  );
};

export default ClinicianProfile;
