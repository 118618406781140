import { useCallback, useState } from "react";
import { isEqual } from "lodash";
import Modal from "@mui/material/Modal";
import RangeDatePicker from "../../RangeDatePicker/RangeDatePicker";
import TestTypeSelection from "../../TestTypeSelection/TestTypeSelection";
import AppTypeSelection from "../../AppTypeSelection/AppTypeSelection";
import { IPatientLayoutFilters } from "../../../interfaces/Patient";

interface PatientLayoutFiltersModalProps {
  appliedFilters: IPatientLayoutFilters;
  onApply: (value: IPatientLayoutFilters) => void;
  onClose: () => void;
}

const PatientLayoutFiltersModal = (props: PatientLayoutFiltersModalProps) => {
  const { appliedFilters, onApply, onClose } = props;

  const [enteredFilters, setEnteredFilters] =
    useState<IPatientLayoutFilters>(appliedFilters);

  const handleApplyFilters = useCallback(() => {
    onApply(enteredFilters);
    onClose();
  }, [onApply, onClose, enteredFilters]);

  return (
    <Modal open onClose={onClose}>
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-aliceBlue p-8">
        <div className="h-full mx-auto max-w-md flex flex-col gap-6 justify-center">
          <div className="overflow-auto flex flex-col gap-5">
            <RangeDatePicker
              isModalFilter
              value={enteredFilters.createdOn}
              onChange={(value) =>
                setEnteredFilters((prev) => ({ ...prev, createdOn: value }))
              }
            />

            <AppTypeSelection
              isModalFilter
              selected={enteredFilters.apps}
              onChange={(apps) =>
                setEnteredFilters((prev) => ({ ...prev, apps }))
              }
            />

            <TestTypeSelection
              isModalFilter
              selected={enteredFilters.tests}
              onChange={(tests) =>
                setEnteredFilters((prev) => ({ ...prev, tests }))
              }
            />
          </div>

          <div className="flex justify-end gap-5">
            <button className="text-base text-pelorous" onClick={onClose}>
              Cancel
            </button>
            <button
              className="bg-tropicalRainForest rounded-3xl text-white py-1 px-4 disabled:opacity-50 disabled:pointer-events-none"
              disabled={isEqual(appliedFilters, enteredFilters)}
              onClick={handleApplyFilters}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PatientLayoutFiltersModal;
