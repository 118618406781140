import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import TableInputFilter from "components/TableInputFilter/TableInputFilter";
import TableDatepickerFilter from "components/TableDatepickerFilter/TableDatepickerFilter";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import Loading from "components/Loading/Loading";
import {
  createUrlParams,
  formatDashDate,
  formatName,
} from "helpers/CommonHelper";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import { IBilling, IBillingFilters } from "interfaces/Billing";
import BillingService from "services/BillingService";
import tableStyles from "styles/shared/table.module.scss";
import TooltipBox from "components/TooltipBox/TooltipBox";
import BillingStatusDropdown from "components/Dropdowns/BillingStatusDropdown/BillingStatusDropdown";
import styles from "./BillingTable.module.scss";
import { BillingStatus } from "enums/Billing";
import BillingReasonModal from "components/Modals/BillingReasonModal/BillingReasonModal";
import OrderSummaryModal from "components/Modals/OrderSummaryModal/OrderSummaryModal";
import TableRangeDatepickerFilter from "components/TableRangeDatepickerFilter/TableRangeDatepickerFilter";
import EllipsisTableCell from "components/EllipsisTableCell/EllipsisTableCell";
import { getBillingStatusLabel } from "helpers/BillingHelper";

interface BillingTableProps {
  isSingleOrderHistory?: boolean;
  filters: IBillingFilters;
  setFilters: Dispatch<SetStateAction<IBillingFilters>>;
}

const BillingTable = (props: BillingTableProps) => {
  const { isSingleOrderHistory, filters, setFilters } = props;

  const [, setSearchParams] = useSearchParams();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();
  const { data, setData, loading, tableRef, lastElementRef } =
    useFetchTableData<IBilling, IBillingFilters>({
      filters,
      onPageIncrement,
      fetchAPI: BillingService.getAll,
      onError: setSnackbarError,
    });

  const [pendingCommentData, setPendingCommentData] = useState<{
    billingId: number;
    status: BillingStatus;
  }>();
  // show order summary modal when this state is present
  const [selectedOrderId, setSelectedOrderId] = useState<string>();

  // fewer columns are shown on single order billing history
  const tableColumns: ITableColumn[] = [
    ...(isSingleOrderHistory
      ? []
      : [
          { label: "Account", sortProperty: "client" },
          { label: "Patient", sortProperty: "lname,fname" },
          { label: "DOB", sortProperty: "dob" },
        ]),
    { label: "Order ID", sortProperty: "order_num" },
    { label: "Start Date", sortProperty: "from_date" },
    { label: "End Date", sortProperty: "to_date" },
    ...(isSingleOrderHistory
      ? []
      : [
          { label: "Last Use", sortProperty: "last_use" },
          { label: "Days Used", sortProperty: "days_cnt" },
        ]),
    { label: "Status", sortProperty: "status" },
  ];

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  const updateBilling = (
    id: number,
    data: { status: BillingStatus; comment?: string }
  ) => {
    const { status, comment } = data;

    BillingService.update({ transactionId: id, status, comment })
      .then(() => {
        setData((prev) =>
          prev.map((x) =>
            x.id === id
              ? {
                  ...x,
                  status,
                  comment: comment ?? x.comment,
                }
              : x
          )
        );
        setSnackbarSuccess("The status has been successfully edited.");
      })
      .catch(() => setSnackbarError());
  };

  const handleStatusChange = (
    id: number,
    data: { status: BillingStatus | undefined; comment: string | undefined }
  ): void => {
    const { status, comment } = data;

    if (!status) return;

    // NotBilledOther status requires reason to be entered
    // Modal with reason box will open when pendingReasonId state is set
    if (status === BillingStatus.NotBilledOther) {
      setPendingCommentData({
        billingId: id,
        status: BillingStatus.NotBilledOther,
      });
      return;
    }

    updateBilling(id, { status, comment });
  };

  const handleCommentModalConfirm = (
    id: number,
    data: { status: BillingStatus; comment: string }
  ): void => {
    updateBilling(id, data);
    setPendingCommentData(undefined);
  };

  const handleCloseOrderSummaryModal = useCallback(() => {
    setSelectedOrderId(undefined);
  }, []);

  return (
    <>
      <div
        className={`h-full relative -mr-5 ${styles.billing_table_wrapper} ${
          isSingleOrderHistory ? "" : styles.limit_cell_widths // no need to limit widths on single order billing (only 4 colums are shown)
        }`}
      >
        <TableContainer
          className={tableStyles.shared_table_container}
          ref={tableRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        whiteTheme
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {!isSingleOrderHistory && (
                  <>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by account"
                        value={filters.client}
                        onChange={(client) =>
                          setFilters((prev) => ({ ...prev, page: 1, client }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by patient name"
                        value={filters.name}
                        onChange={(name) =>
                          setFilters((prev) => ({ ...prev, page: 1, name }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableDatepickerFilter
                        removeNextDay
                        value={filters.dob}
                        onChange={(dob) =>
                          setFilters((prev) => ({ ...prev, page: 1, dob }))
                        }
                      />
                    </TableCell>
                  </>
                )}
                <TableCell>
                  {/* already prefiltered by order on single order billing */}
                  {!isSingleOrderHistory && (
                    <TableInputFilter
                      isNumericField
                      placeholder="Search by ID"
                      value={filters.orderNum}
                      onChange={(orderNum) =>
                        setFilters((prev) => ({ ...prev, page: 1, orderNum }))
                      }
                    />
                  )}
                </TableCell>
                <TableCell>
                  <TableRangeDatepickerFilter
                    value={filters.fromDate}
                    onChange={(fromDate) =>
                      setFilters((prev) => ({ ...prev, page: 1, fromDate }))
                    }
                  />
                </TableCell>
                <TableCell>
                  <TableRangeDatepickerFilter
                    value={filters.toDate}
                    onChange={(toDate) =>
                      setFilters((prev) => ({ ...prev, page: 1, toDate }))
                    }
                  />
                </TableCell>
                {!isSingleOrderHistory && (
                  <>
                    <TableCell />
                    <TableCell />
                  </>
                )}
                <TableCell>
                  <BillingStatusDropdown
                    value={filters.status}
                    onChange={(status) =>
                      setFilters((prev) => ({ ...prev, page: 1, status }))
                    }
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (
                  {
                    id,
                    client,
                    daysCnt,
                    dob,
                    lastUse,
                    orderId,
                    orderNum,
                    fname,
                    lname,
                    fromDate,
                    toDate,
                    status,
                    comment,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    {!isSingleOrderHistory && (
                      <>
                        <EllipsisTableCell text={client} />
                        <EllipsisTableCell
                          text={formatName({
                            firstName: fname,
                            lastName: lname,
                          })}
                        />
                        <TableCell>{formatDashDate(dob)}</TableCell>
                      </>
                    )}
                    <TableCell>
                      <TooltipBox
                        content="Click to see Order Summary"
                        offsetX={10}
                        offsetY={10}
                      >
                        <button
                          className="hover:text-pelorous hover:underline"
                          onClick={() => setSelectedOrderId(orderId)}
                        >
                          {orderNum}
                        </button>
                      </TooltipBox>
                    </TableCell>
                    <TableCell>{formatDashDate(fromDate)}</TableCell>
                    <TableCell>{formatDashDate(toDate)}</TableCell>
                    {!isSingleOrderHistory && (
                      <>
                        <TableCell>{formatDashDate(lastUse)}</TableCell>
                        <TableCell>{daysCnt}</TableCell>
                      </>
                    )}
                    <TableCell className="relative">
                      {/* this cell is read only on single order billing history */}

                      {isSingleOrderHistory ? (
                        getBillingStatusLabel(status)
                      ) : (
                        <BillingStatusDropdown
                          isInCell
                          value={status}
                          onChange={(value) =>
                            handleStatusChange(id, {
                              status: value,
                              comment: comment ?? undefined,
                            })
                          }
                        />
                      )}

                      {comment && (
                        <TooltipBox
                          content={
                            isSingleOrderHistory
                              ? comment
                              : "Click to view or edit Reason"
                          }
                          offsetX={-20}
                        >
                          <button
                            className={styles.comment_btn}
                            onClick={() =>
                              isSingleOrderHistory
                                ? undefined
                                : setPendingCommentData({
                                    billingId: id,
                                    status,
                                  })
                            }
                          >
                            <ChatBubbleTwoToneIcon />
                          </button>
                        </TooltipBox>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems icon="icon_search" message="No results found" />
          </div>
        )}

        <Loading loading={loading} />

        {pendingCommentData && (
          <BillingReasonModal
            initialComment={
              data.find((x) => x.id === pendingCommentData.billingId)
                ?.comment ?? null
            }
            newStatus={pendingCommentData.status}
            onConfirm={(data) =>
              handleCommentModalConfirm(pendingCommentData.billingId, data)
            }
            onClose={() => setPendingCommentData(undefined)}
          />
        )}

        {selectedOrderId && (
          <OrderSummaryModal
            orderId={selectedOrderId}
            onError={setSnackbarError}
            onClose={handleCloseOrderSummaryModal}
          />
        )}
      </div>

      {snackbarComponent}
    </>
  );
};

export default BillingTable;
