import TooltipBox from "../TooltipBox/TooltipBox";
import { IShippingLabel } from "../../interfaces/Shipping";

interface TrackingLabelPropertyProps {
  isReturnLabel?: boolean;
  data: IShippingLabel;
}

const TrackingLabelProperty = ({
  isReturnLabel,
  data: { trackingUrl, labelUrl, carrier },
}: TrackingLabelPropertyProps) => {
  return (
    <div className="flex flex-col gap-1.5">
      <p className="self-start text-base font-medium text-cloudBurst">
        {isReturnLabel ? "Return label" : "Outgoing label"}
      </p>

      <div className="flex justify-between items-center">
        <TooltipBox
          content="Open tracking in a new tab"
          offsetX={-40}
          offsetY={5}
        >
          <a
            className="flex items-center gap-[3px] text-pelorous text-sm leading-4 font-medium"
            href={trackingUrl}
            target="_blank"
            rel="noreferrer"
          >
            Open Tracking
            <span className="icon_svg icon_new_tab" />
          </a>
        </TooltipBox>

        <a
          className="flex items-center gap-[3px] text-tropicalRainForest text-sm leading-4 font-medium"
          href={labelUrl}
          target="_blank"
          rel="noreferrer"
        >
          Download Label
          <span className="icon_svg icon_download_2" />
        </a>
      </div>
    </div>
  );
};

export default TrackingLabelProperty;
